export default [
  {
    name: '__checkbox',   
    titleClass: 'center aligned',
    dataClass: 'center aligned',
  },
  {
    name: 'fullname',
    title: 'ชื่อ',
    sortField: 'fullname',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'email',
    title: 'อีเมล',
    sortField: 'email',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    title: 'สิทธิการเข้าถึง',
    name: 'type',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'actions',
    dataClass: "center",
    titleClass: "center aligned",
  }
]