<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ช่วยเหลือ > </span>
          <b>เงื่อนไขและข้อกำหนด</b>
          <div class="h3 mt-4">เงื่อนไขและข้อกำหนด</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div v-if="data" class="col-lg-12">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-12 border-bottom mb-1">
                        <h6>สำหรับ Buyer</h6>
                      </div>
                      <div class="col-md-12 p-md-4">
                        <vue-editor v-model="data.termOfUseBuyer" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="bg-white rounded">
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-12 border-bottom mb-1">
                        <h6>สำหรับ Supplier</h6>
                      </div>
                      <div class="col-md-12 p-md-4">
                        <vue-editor v-model="data.termOfUseSupplier" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$parent.$parent.$parent.currentUser.adminRole == 'owner' || $parent.$parent.$parent.currentUser.adminRole == 'admin'" class="row mt-3">
              <div class="col-md-12 text-right">
                <b-button
                  @click="apiGetData()"
                  variant="outline-secondary"
                  class="mr-3"
                  >ยกเลิก</b-button
                >
                <b-button @click="submitBtn()" variant="primary"
                  >บันทึก</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "Privacy",
   components: { VueEditor },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.apiGetData();
  },
  methods: {
    
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/helper/termofuse`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.$showLoader();
      let data = this.data;
      this.$http
        .patch(`api/admins/helper/termofuse/update`, data)
        .then((res) => {
          self.apiGetData();
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>