<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-lg-5 col-md-8">
        <!-- <div class="alert alert-info">
            <strong>Normal User</strong> - U: user P: user<br />
            <strong>Administrator</strong> - U: admin P: admin
          </div> -->
        <div class="text-center mb-4">
          <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
          v.{{ this.$version }}
        </div>
        <div class="box box-login">
          <form class="" @submit.prevent="onSubmit">
            <div class="title text-center">เข้าสู่ระบบ</div>
            <div class="form-group">
              <label for="username">อีเมล</label>
              <b-form-input
                id="username"
                :class="{ 'is-invalid': errors.email }"
                v-model="username"
              ></b-form-input>
              <div
                v-if="submitted && errors.email"
                class="invalid-feedback"
              >
                {{errors.email}}
              </div>
            </div>
            <div class="form-group">
              <label htmlFor="password">รหัสผ่าน</label>
              <b-input-group >
                <template  #append>
                  <button class="icon-input" :class="{ 'is-invalid': errors.password }" @click="showPass = !showPass" type="button"><eva-icon :name="!showPass ? 'eye-off-outline' : 'eye-outline'" fill="#6c757d"></eva-icon></button>
                </template>
                <b-form-input :type="!showPass ? 'password' : 'text'"
                 v-model="password"
                :class="{ 'is-invalid': errors.password }"
                name="password"
                ></b-form-input>
              </b-input-group>
              <div
                v-if="errors.password"
                class="invalid-feedback d-block"
              >
                {{errors.password}}
              </div>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  id="remember"
                  v-model="remember"
                  name="remember"
                  value="remember"
                  :unchecked-value="!remember"
                >
                  จำฉันไว้ในระบบ
                </b-form-checkbox>
                <b
                  ><router-link :to="{ name: 'forgotpassword' }"
                    >ลืมรหัสผ่าน</router-link
                  ></b
                >
              </div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary w-100 text-center"
                :disabled="loading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  v-show="loading"
                ></span>
                <span>เข้าสู่ระบบ</span>
              </button>
            </div>
          </form>
        </div>
        <div class="mt-4 text-center">
          <b
            >ยังไม่มีบัญชีผู้ใช้?
            <router-link :to="{ name: 'signup' }">สมัครสมาชิก</router-link></b
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { authenticationService } from "../../_services/authentication.service";

export default {
  name: 'LoginPage',
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      username: "",
      password: "",
      remember: false,
      submitted: false,
      loading: false,
      returnUrl: "",
      errors: [],
      showPass: false
    };
  },
  created() {
    this.loginAuth()
  },
  methods: {
    loginAuth() {
      // redirect to home if already logged in
      if (authenticationService.currentUserValue) {
        switch (authenticationService.currentUserValue.role) {
          case "admin":
            this.$router.push({
              name: "admin.dashboard"
            });
            break;
          case "buyer":
            this.$router.push({
              name: "buyer.dashboard"
            });
            break;
          case "supplier":
            this.$router.push({
              name: "supplier.payments"
            });
            break;
        }
      }
      // get return url from route parameters or default to '/'
      this.returnUrl = this.$route.query.returnUrl || "/";

      let remember = localStorage.getItem('remember')
      if (remember) {
        this.username = remember
        this.remember = 'remember'
      }
    },
    onSubmit() {
      this.submitted = true;
      this.errors = {}
      this.loading = true;
      authenticationService.login(this.username, this.password).then(
        (data) => {
          if(data.errors){
            this.errors = data.errors.data
            this.loading = false;
            return
          }
          if(this.remember == 'remember'){
            localStorage.setItem('remember',this.username)
          }else{
            localStorage.removeItem('remember')
          }
          location.reload(true)
        },
        (error) => {
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style lang="scss">
.title {
  font-size: 28px;
  margin-bottom: 1.5rem;
}
.box {
  background-color: #ffffff;
}
</style>