export const formatNum = {
    numberic, price,
  };
  
  function price(num, fix) {
    var p = Number(num).toFixed(fix).split(".");
    return p[0].split("").reduceRight(function(acc, num, i, orig) {
        if ("-" === num && 0 === i) {
            return num + acc;
        }
        var pos = orig.length - i - 1
        return  num + (pos && !(pos % 3) ? "," : "") + acc;
    }, "") + (p[1] ? "." + p[1] : "");
  }

  function numberic(num){
    return  num.replace(/[^0-9]+/g, ''); 
  }
