<template>
  <div>
    <div id="formSubmit">
        <h4>Loading...</h4>
    </div>
  </div>
</template>
<script>
export default {
  name: "Ekyc",
  data() {
    return {
        fiormSubmit: ""
    };
  },
  created() {
      this.apiGetFormCredit()
  },
  methods: {
    apiGetFormCredit() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/users/ekyc/form/register`)
        .then((res) => {
          self.$hideLoader();
            setTimeout(() => {
                $('#formSubmit').html(res.data.data.html);
            }, 1000);
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
};
</script>
  