<template>
  <component :is="tag" @click.native="hideSidebar" v-bind="$attrs" tag="li">
    <a v-if="!$attrs.child" class="nav-link" v-bind="$attrs">
      <slot>
        <i v-if="link.icon" :class="link.icon"></i>
        <p>{{ link.name }}</p>
      </slot>
    </a>
    <li v-else to="/" tag="li" class="nav-item">
      <a class="nav-link" v-bind="$attrs"
        ><slot>
          <i v-if="link.icon" :class="link.icon"></i>
          <p>{{ link.name }}</p>
        </slot></a
      >
      <b-collapse :id="$attrs.toggleId" v-bind="$attrs" class="mt-2">
        <ul class="nav">
          <li class="nav-item">
            <!----><!----><a href="/table-list/regular" class="nav-link"
              ><span class="sidebar-mini">R</span
              ><span class="sidebar-normal">Regular Tables</span></a
            >
          </li>
          <li class="nav-item">
            <!----><!----><a href="/table-list/extended" class="nav-link"
              ><span class="sidebar-mini">E</span
              ><span class="sidebar-normal">Extended Tables</span></a
            >
          </li>
          <li class="nav-item">
            <!----><!----><a href="/table-list/paginated" class="nav-link"
              ><span class="sidebar-mini">P</span
              ><span class="sidebar-normal">Paginated Tables</span></a
            >
          </li>
        </ul>
      </b-collapse>
    </li>
  </component>
</template>
<script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style>
</style>
