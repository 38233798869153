import { formatNum }from '../_formats/number'
import { formatDate } from '../_formats/date'
import VuetableFieldSwitch from '../components/Table/Checkbox.vue'
export default [
  {
    name: VuetableFieldSwitch,
    titleClass: 'center aligned',
    width: "5%",
    dataClass: 'center aligned',
    disabled: {
      field: (data) => data.Status === '3',
    }
  },
  {
    title: 'Transfer to Supplier (฿)',
    name: 'TransferAmount',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'TransferAmount',
    width: "5%",
    formatter: value =>  formatNum.price(value, 2)
  },
  {
    title: 'Supplier Merchant ID',
    name: 'MerchantId',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'MerchantId',
  },
  {
    title: 'บริษัท',
    name: 'Company',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'Company',
  },
  {
    title: 'ธนาคาร',
    name: 'Bank',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'Bank',
  },
  {
    title: 'ชื่อบัญชี',
    name: 'AccountName',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'AccountName',
  },
  {
    title: 'เลขบัญชี',
    name: 'AccountNo',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'AccountNo',
  },
  {
    title: 'สาขา',
    name: 'Branch',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'Branch',
  },
  {
    title: 'สถานะ',
    name: 'Status',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'Status',
  },
  {
    title: 'Pay to Supplier Date',
    name: 'TransferDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'TransferDate',
    formatter: value => formatDate.thai(value)
  },
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned w-80px",
  }
]