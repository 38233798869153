<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">ลูกค้า</div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="p-lg-3">
                <div class="form-filter mb-2 d-flex justify-content-start">
                  <div class="form-inline"></div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        aria-describedby="button-addon2"
                        v-model="keyword"
                        @keyup.enter="search()"
                      />
                      <div class="input-group-append">
                        <button
                          @click="search()"
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon
                            name="search-outline"
                            class="color-dark"
                          ></eva-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <router-link
                      :to="{
                        name: 'supplier.customers.show',
                        params: { id: props.rowData.buyerId },
                      }"
                    >
                      <eva-icon
                        name="credit-card-outline"
                        class="color-primary"
                      ></eva-icon>
                      ดูรายการชำระเงิน</router-link
                    >
                  </div>
                  <div slot="name" slot-scope="props">
                    <div class="d-flex align-items-center">
                      <b-avatar
                        class="icon-profile mr-2"
                        :text="props.rowData.name.substr(0, 1)"
                      ></b-avatar>
                      <span>{{ props.rowData.name }}</span>
                    </div>
                  </div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/supplier_customer.js";
import Status from "../../../_fields/status_supplier.js";
import _ from "lodash";

export default {
  name: "Customer",
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      fields: FieldsDef,
      statusList: Status,
      perPage: 10,
      tablePaginationTotal: 0,
      data: [],
      keyword: "",
      removeId: null,
      tablePaginationTotal: 0,
    };
  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
  },

  mounted() {
    this.apiGetList();
  },

  methods: {
    apiGetList(keyword = "") {
      let url = "api/suppliers/customers/index";
      url = url + "?keyword=" + keyword;
      this.$http.get(url).then((response) => {
        this.data = response.data.data;
      });
    },
    search() {
      this.apiGetList(this.keyword);
    },
    status(status, txt) {
      switch (status) {
        case 1:
          return (
            "<span class='badge badge-pill badge-success'>" + txt + "</span>"
          );
        case 2:
          return (
            "<span class='badge badge-pill badge-danger'>" + txt + "</span>"
          );
        default:
          return (
            "<span class='badge badge-pill badge-warning'>" + txt + "</span>"
          );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total;
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    removeUser(id) {
      this.removeId = id;
      this.$bvModal.show("modalApprove");
    },
    apiRemoveUser() {
      let self = this;
      this.$showLoader();
      this.$http
        .delete(`api/admins/` + this.removeId + `/destroy`)
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$bvModal.hide("modalApprove");
          this.apiGetList();
        });
    },
  },
};
</script>
<style src="@/assets/semantic-ui-css/semantic.css"></style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
