<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center mt-5">
            <h3>เริ่มจัดการบัญชีของบริษัทคุณ</h3>
            <h6 class="text-secondary">โปรไฟล์ของบริษัทคุณใกล้เสร็จแล้ว</h6>
          </div>
          <!-- <div class="col-10 mt-4 px-5 mb-3">
            <div class="stepper">
              <ul class="nav nav-tabs" role="tablist">
                <li
                  role="presentation"
                  :class="(user.stepRegister == 4 ? 'active' : '') + (user.stepRegister > 4 ? ' completed' : '')"
                >
                  <a
                    class="persistant-disabled"
                    href="#"
                    data-toggle="tab"
                    role="tab"
                    title="Step 1"
                  >
                    <span class="round-tab">1</span>
                  </a>
                </li>
                <li
                  role="presentation"
                  :class="(user.stepRegister < 5 ? 'disabled' : '') + 
                  (user.stepRegister == 6 ? ' completed' : '')"
                >
                  <a
                    class="persistant-disabled"
                    href="#"
                    role="tab"
                    title="Step 2"
                  >
                    <span class="round-tab">2</span>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
          <div class="col-md-10">
            <!-- <div class="row align-self-stretch step">
              <div class="col-md-6">
                <div
                  class="h-100 p-md-3 p-2 text-center text-center d-flex align-items-start flex-column"
                >
                  <div class="mb-auto w-100">
                    <img
                      src="../../../assets/image/ekyc1.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="w-100">
                    <h6 class="my-3">ยืนยันตัวตน</h6>
                    <router-link
                      v-if="user.stepRegister == 4"
                      class="btn btn-primary w-100"
                      :to="{ name: 'buyer.register.kyc.new' }"
                      >ลงทะเบียน</router-link
                    >
                    <b-button v-else class="w-100 border-success text-success bg-teal"
                      >เรียบร้อยแล้ว</b-button
                    >
                  </div>
                </div>
              </div> -->
              <div class="col align-self-center">
                <!-- <div class="col-md-4"> -->
                <div
                  :class="
                    user.stepRegister == 4
                      ? 'text-secondary disabled'
                      : 'active'
                  "
                  class="h-100 p-md-3 p-2 text-center d-flex align-items-start flex-column"
                >
                  <div class="mb-auto w-100">
                    <img
                      src="../../../assets/image/company1.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="w-100">
                    <h6 class="my-3">ลงทะเบียนนิติบุคคล</h6>
                    <router-link v-if="user.stepRegister <= 5" :class="'btn w-50 ' + (user.stepRegister == 5 ? 'btn-primary' : 'btn-secondary-50')" 
                      :to="{ name: 'supplier.register.juristic_person' }"
                    :disabled="user.stepRegister != 5">
                      ลงทะเบียน
                    </router-link>
                    <b-button v-else class="w-50 border-success text-success bg-teal"
                      >เรียบร้อยแล้ว</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SupplierRegisterVerify',
  data() {
    return {
      user: {},
      error: null,
      showView: "main",
    };
  },
  created() {
    this.apiCheckEkyc()
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCheckEkyc() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/users/ekyc/check/status/register`)
        .then((res) => {
          self.$hideLoader();
          this.apiProfile();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
};
</script>
<style scoped lang="scss">
.stepper {
  .nav-tabs {
    position: relative;
    border-bottom: unset;
    &::before{
      position: absolute;
      content: '';
      width: 50%;
      left: 25%;
      border-bottom: 1px solid #dee2e6;
    }
    li:first-child a .round-tab {
      left: calc(50% - 50px);
    }
    li:last-child a .round-tab {
      left: calc(50% - 0px);
    }
  }

  .nav-tabs > li {
    width: 50%;
    position: relative;
    &.completed {
      &::after {
        background: #34bc9b;
      }
    }
    &:last-child {
      &::after {
        background: transparent;
      }
    }
    &.active:last-child {
      .round-tab {
        background: #34bc9b;
        &::after {
          content: "✔";
          color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0;
          display: block;
        }
      }
    }
  }
  .nav-tabs [data-toggle="tab"] {
    width: 25px;
    height: 25px;
    margin: 20px auto;
    border-radius: 100%;
    border: none;
    padding: 0;
    color: #f1f1f1;
  }

  .nav-tabs > .active > [data-toggle="tab"] {
    color: #34bc9b;
    cursor: default;
    border: none;
  }
  .tab-pane {
    position: relative;
    padding-top: 50px;
  }
  .round-tab {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: inline-block;
    border-radius: 25px;
    background: #1A32B1;
    border: 4px solid #2835ac85;
    color: #ffffff;
    z-index: 2;
    position: absolute;
    left: calc(50% - 25px);
    top: -25px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
  }
  .completed .round-tab {
    background: #34bc9b;
    color: #34bc9b;
    border: 4px solid #98edcd;
    &::after {
      content: "✔";
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      display: block;
    }
  }
  .active {
    .round-tab {
      background: #1A32B1;
      border: 4px solid #a8aff131;
      &::after {
        display: none;
      }
    }
    &.next .round-tab {
      background: #1AB77C;
      border: 4px solid #a8aff131;
      &::after {
        display: none;
      }
    }
  }
  .disabled .round-tab {
    background: #fff;
    color: #f1f1f1;
    border-color: #f1f1f1;
    &::after {
      display: none;
    }
  }
}
.step {
  .disabled {
    opacity: 0.5;
    img {
    }
  }
}
</style>