import Vue from 'vue'
window.$ = window.jQuery = require("jquery");
import http from './_http/http'
Vue.prototype.$http = http


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('Loading', Loading)
Vue.use(Loading);
let loader = null;

function hideLoader() {
  // destroy previous
  if (loader) {
    loader.hide();
    loader = null;
  }
}

function showLoader(opacity = 0.3) {
  hideLoader();
  loader = Vue.$loading.show({
    opacity: opacity
  })
}
Vue.prototype.$showLoader = showLoader
Vue.prototype.$hideLoader = hideLoader

function onRowClass (dataItem, index) {
  switch (dataItem.statusNumber) {
    case 2:
      return 'tr-danger'
    case 4:
      return 'tr-warning'
    case 0:
      return 'tr-warning'
    default:
      return ''
  }
}
Vue.prototype.$onRowClass = onRowClass
import { version } from '../package.json';
Vue.prototype.$version = version;
function cardColumn (type,number = '')  {
  switch (type) {
    case "VISA":
      return "<img class='mr-1' width='30px' src='"+require("./assets/card/visa1-gray.png")+"'>"+number;
    case "Master":
      return "<img class='mr-1' width='30px' src="+require("./assets/card/mastercard1-gray.png")+">"+number;
    case "JCB":
      return "<img class='mr-1' width='30px' src="+require("./assets/card/jcb1-gray.png")+">"+number;
    default:
      return "";
  }
}
Vue.prototype.$cardColumn = cardColumn

function numberInput(e) {
  if (/\D/g.test(e.target.value))
  {
    e.target.value = e.target.value.replace(/\D/g, '');
  }
}
Vue.prototype.$numberInput = numberInput

// setup fake backend
import { configureFakeBackend } from "./_helpers/fake-backend";
configureFakeBackend();

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from 'vue-select'
import './assets/sass/colors.scss'
import Vuelidate from "vuelidate";
import EvaIcons from 'vue-eva-icons'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'; 
Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox);


Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(EvaIcons)
Vue.component('v-select', vSelect)

import './registerServiceWorker'

// plugin setup
Vue.use(LightBootstrap)

// router setup
import router from './routes/routes'

import App from './App.vue'

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  router: router,
  render: h => h(App)
})
