export default [
    {
        name: 'supplierId',
        title: 'Supplier ID',
        sortField: 'supplierId',
        dataClass: 'center aligned',
        titleClass: 'center aligned'
    },
    {
      name: 'companyName',
      title: 'Supplier',
      sortField: 'companyName',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
    {
      name: 'paysolutionMerchant',
      title: 'Paysolution Merchant ID',
      sortField: 'paysolutionMerchant',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
    {
      name: 'email',
      title: 'อีเมล',
      sortField: 'email',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
    {
      name: 'phone',
      title: 'โทร',
      sortField: 'phone',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
    {
      name: 'createDateFormat',
      title: 'วันที่ลงทะเบียน',
      sortField: 'createDate',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
    {
      title: 'สถานะ',
      name: 'statusName',
      dataClass: "center aligned",
      titleClass: "center aligned",
    },
   
    {
      name: 'actions',
      dataClass: "center aligned",
      titleClass: "center aligned",
    }
   
  ]