<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">แก้ไขบัตรเครดิต</div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">ข้อมูลบัตรเครดิต</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded">
              <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                รายละเอียด
              </h5>
              <div class="px-3 pt-3 pb-4">
                <div class="font-weight-semibold">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'customSwitch'"
                      :checked="card.statusDefault"
                      v-model="card.statusDefault"
                      @change="apiDefault(card.id, $event)"
                    />
                    <label class="custom-control-label" :for="'customSwitch'"
                      >ตั้งเป็นค่า Default</label
                    >
                  </div>
                  <div class="h6 font-weight-semibold mt-4">หมายเลขบัตร</div>
                  <div>
                    <img
                      width="36px"
                      v-if="card.cardType == 'VISA'"
                      src="../../../assets/card/visa1-gray.png"
                      alt=""
                    />
                    <img
                      width="36px"
                      v-if="card.cardType == 'MASTER CARD'"
                      src="../../../assets/card/mastercard1-gray.png"
                      alt=""
                    />
                    <img
                      width="36px"
                      v-if="card.cardType == 'JCB'"
                      src="../../../assets/card/jcb1-gray.png"
                      alt=""
                    />
                    <span class="text-secondary">
                      *** {{ card.cardNumber }}</span
                    >
                  </div>
                  <div class="h6 font-weight-semibold mt-4">จำนวนวงเงินบัตร</div>
                  <span class="text-secondary font-weight-normal"
                    >ระบุเพื่อคำนวณวงเงินบัตรทั้งหมดของคุณ</span
                  >
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <div class="input-group">
                        <b-form-input
                        :disabled="card.statusShow"
                        type="number"
                        class="border-right-0"
                        v-model="card.limit_money"
                        :class="{ 'is-invalid': errors.limit_money }"
                        name="password"
                        ></b-form-input>
                        <span class="input-group-append bg-white border-left-0">
                            <span class="input-group-text bg-transparent" style="color:#6a7b90"> ฿</span>
                        </span>
                      </div>
                      <div v-if="errors.limit" class="invalid-feedback d-block">
                        {{ errors.limit }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="card.statusShow"
                      name="checkbox-1"
                      class="font-weight-normal"
                    >
                      ไม่ต้องการระบุวงเงินบัตร
                    </b-form-checkbox>
                  </div>
                  <div class="mt-2">
                    <b-form-checkbox
                      v-model="card.active"
                      class="font-weight-normal"
                    >
                      เปิดใช้งานบัตร
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-md-12 text-right">
                <b-button @click="apiCredit(true)" variant="primary"
                  >ยืนยัน</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BuyerCreditEdit",
  data() {
    return {
      card: {
        statusShow: false
      },
      errors: {},
      noneLimit: false
    };
  },
  created() {
    this.apiGetCredit();
  },
  methods: {
    async apiDefault(id) {
      try {
        this.$showLoader();
        const res = await this.$http.post(`api/buyers/paysolution/default`, {
          creditCardId: id
        });

        this.apiGetCredit();
        self.$hideLoader();
        self.$notifications.notify({
          message: res.data.message,
          icon: "checkmark-circle-2",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "success"
        });
      } catch (error) {
        self.error = error;
        self.$hideLoader();
      }
    },
    apiGetCredit() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/paysolution/card/` + this.$route.params.id)
        .then((res) => {
          this.card = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCredit(val) {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .patch(`api/buyers/paysolution/update/card/` + this.$route.params.id, {
          limit: this.card.limit_money,
          statusShow: this.card.statusShow,
          statusDefault: this.card.statusDefault,
          active: this.card.active
        })
        .then((res) => {
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success"
          });
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    }
  },
  watch: {
    "card.statusShow"(val) {
      if (val == true) {
        this.card.limit = 0;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
  vertical-align: text-top;
}
th,
td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
</style>
