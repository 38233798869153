<template>
  <div>
    <div v-if="data.payment" class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            ><router-link
              class="text-secondary font-weight-semibold"
              :to="{ name: 'buyer.payments' }"
              >รายการชำระเงิน</router-link
            >
            >
          </span>
          <span class="font-weight-semibold"> รายการชำระเงิน #{{ data.payment.id }}</span>
          <div class="h3 mt-4">รายการชำระเงิน #{{ data.payment.id }}</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4 mb-2">
            <div class="bg-white">
              <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                รายละเอียด
              </h5>
              <div class="px-3 pt-2 pb-4">
                <div class="h6 font-weight-semibold mt-1">สถานะ</div>
                <div
                  v-html="
                    status(data.detail.statusNumber, data.detail.statusName)
                  "
                ></div>
                <div class="h6 font-weight-semibold mt-3">Ref No.</div>
                <span class="text-secondary  small">{{ data.detail.refNo }}</span>
                <div class="h6 font-weight-semibold mt-3">วันที่สร้าง</div>
                <span class="text-secondary  small">{{ data.detail.createdAt }}</span>
                <div  v-if="data.detail.statusNumber == 5" class="h6 font-weight-semibold mt-3">วันที่ยกเลิก</div>
                <span v-if="data.detail.statusNumber == 5" class="text-secondary small">{{ data.detail.cancelAt }}<br>{{data.detail.cancelBy}}</span>
              </div>
            </div>
            <div class="pt-0" >
              <div class="pt-2">
                <b-button v-if="detail.statusNumber === 9" class="text-center" variant="primary" block   @click="$router.push({ name:'buyer.calendar.paylink', params: { id: payment.id}})"><eva-icon name="diagonal-arrow-right-up" class="color-white"></eva-icon> ทำรายการชำระเงิน</b-button>
              </div>
            </div>
            <div class="mt-3">
              <button
                v-if="
                  (data.detail.statusNumber == 0 || data.detail.statusNumber === 9) && data.payment.statusCancelNumber == 5
                "
                class="btn border bg-white w-100 font-weight-semibold mb-3"
                @click="
                  showModalDelete(
                    payment.id,
                    payment.statusCancelNumber,
                    payment.statusCancel
                  )
                "
              >
                <eva-icon
                  width="16px"
                  class="color-secondary"
                  name="radio-button-on"
                ></eva-icon>
                {{ data.payment.statusCancel }}
              </button>
              <button
                v-if="payment.statusCancelNumber === 4 && payment.statusCancelNumber === 1"
                class="btn border bg-white w-100 font-weight-semibold mb-3"
                @click="
                  showModalDelete(
                    payment.id,
                    payment.statusCancelNumber,
                    payment.statusCancel

                  )
                "
              >
                <eva-icon
                  width="16px"
                  class="color-secondary"
                  name="radio-button-on"
                ></eva-icon>
                {{ data.payment.statusCancel }}
              </button>
              <button
                v-if="payment.statusCancelNumber === 4"
                class="btn border bg-white w-100 font-weight-semibold mb-3"
                @click="
                  showModalDeleteCancel(
                    payment.id,
                    payment.statusCancelNumber,
                  )
                "
              >
                <eva-icon
                  width="16px"
                  class="color-secondary"
                  name="radio-button-on"
                ></eva-icon>
                {{ data.payment.statusCancel }}
              </button>
              <button
                v-if="data.detail.statusNumber == 2"
                class="btn border bg-primary w-100 text-white mb-3"
                @click="
                  $router.push({
                    name: 'buyer.calendar.renew',
                    params: { id: data.payment.id },
                  })
                "
              >
                <eva-icon
                  width="16px"
                  class="color-white"
                  name="refresh-outline"
                ></eva-icon>
                ทำรายการอีกครั้ง
              </button>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded">
                  <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                    ข้อมูล Supplier
                  </h5>
                  <div class="px-3 pt-0 pb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">
                          ชื่อ Supplier
                        </div>
                        <span class="text-secondary">{{
                          data.supplier.name
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">ธนาคาร</div>
                        <span class="text-secondary"
                          ><img
                            width="26px"
                            :src="data.supplier.bankLogo"
                            alt=""
                          />
                          {{ data.supplier.bankName }}</span
                        >
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">หมวดหมู่</div>
                        <span class="text-secondary">{{
                          data.supplier.supplierCategoryName
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">เลขที่บัญชี</div>
                        <span class="text-secondary">{{
                          data.supplier.bankAccountNumber
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">ที่อยู่</div>
                        <span class="text-secondary">{{
                          data.supplier.address
                        }}</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">ชื่อบัญชี</div>
                        <span class="text-secondary">{{
                          data.supplier.bankAccountName
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded">
                  <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                    ข้อมูลชำระเงิน
                  </h5>
                  <div class="px-3 pt-0 pb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">จำนวน</div>
                        <span class="text-secondary"
                          >฿{{ formatPrice(data.payment.amount, 2) }}</span
                        >
                        <div v-if="detail.statusNumber === 9">
                          <div class="h6 font-weight-semibold mt-3">สัดส่วนชำระค่าธรรมเนียม</div>
                          <span v-if="payment.buyerFeeRate > 0" class="text-secondary"
                            >{{ payment.buyerFeeRate }}%</span>
                          <span v-else class="text-secondary">-</span >
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">วันที่ชำระ</div>
                        <span v-if="payment.paymentDate" class="text-secondary">{{
                          data.payment.paymentDate
                        }}</span>
                        <span v-else class="text-secondary">-</span>
                        <div v-if="detail.statusNumber === 9">
                          <div class="h6 font-weight-semibold mt-3">วันหมดอายุการเรียกเก็บเงิน</div>
                          <span v-if="detail.expireAt" class="text-secondary">{{ detail.expireAt }}</span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">ค่าธรรมเนียม</div>
                        <span v-if="data.payment.totalAmount !== 0.1" class="text-secondary"
                          >฿{{ formatPrice(data.payment.fee, 2) }}</span
                        >
                        <span v-else class="text-secondary">-</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">
                          วันที่ตัดบัตร
                        </div>
                        <span  v-if="payment.paymentDebit" class="text-secondary">{{
                          data.payment.paymentDebit
                        }}</span>
                      <span v-else class="text-secondary">-</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">ยอดรวม (รวม VAT 7%)</div>
                        <span v-if="data.payment.totalAmount !== 0.1" class="text-secondary"
                          >฿{{ formatPrice(data.payment.totalAmount, 2) }}</span
                        >
                        <span v-else class="text-secondary">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-semibold
                      d-flex
                      justify-content-between
                    "
                  >
                    <h5>เอกสารการชำระเงิน</h5>
                    <div v-if="!isEditDoc">
                      <button
                        @click="downloadAll()"
                        type="button"
                        class="btn btn-outline-primary"
                      >
                        <eva-icon name="download-outline"></eva-icon>
                        ดาวน์โหลดทั้งหมด
                      </button>
                      <!-- <b-button
                        v-if="data.detail.statusNumber == 0"
                        @click="isEditDoc = true"
                        variant="outline-primary"
                        class=" ml-3"
                      >
                        <eva-icon name="edit-outline"></eva-icon>
                        แก้ไข</b-button
                      > -->
                    </div>
                  </div>
                  <div class="px-3 pt-2 pb-4">
                    <div v-if="!isEditDoc" class="row">
                      <div class="col-lg-12">
                        <div class="h6 font-weight-semibold mt-3">
                          Payment Reference
                        </div>
                        <span class="text-secondary">{{
                          data.document.paymentReference
                        }}</span>
                      </div>
                      <div class="col-lg-12">
                        <div class="h6 font-weight-semibold mt-3">
                          เอกสารการชำระเงิน
                        </div>
                      </div>
                      <div
                        v-for="(file, index) in data.document.files"
                        :key="index"
                        class="col-md-6 mb-3 list-file"
                      >
                        <div
                          :title="file.name"
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img width="40px" :src="imgFile(file.mimeType)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                <a :href="file.download" download="">
                                  {{ file.name }}
                                </a>
                              </div>
                              <!-- <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small> -->
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <a :href="file.download" download="">
                                <eva-icon
                                  name="download"
                                  class="cursor-pointer color-secondary"
                                ></eva-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="px-3 pt-2 pb-4 font-weight-semibold">
                        <div class="font-weight-semibold mt-1">
                          Payment Reference
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <input
                              v-model="paymentRef"
                              type="text"
                              class="form-control"
                              placeholder="ใส่เลขที่ invoice หรือ เลขที่เอกสาร"
                              :class="{ 'is-invalid': errors.paymentRef }"
                            />
                            <div
                              v-if="errors.paymentRef"
                              class="invalid-feedback d-block"
                            >
                              {{ errors.paymentRef }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="row mb-3">
                          <div class="col-12">
                            <div class="h6 font-weight-semibold mt-1">
                              อัปโหลดเอกสาร
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <drop-files
                              :class="{ 'is-invalid': errors.files }"
                            ></drop-files>
                            <div
                              class="mt-2 mb-3 small text-secondary text-bottom-file"
                            >
                              ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB
                              สูงสุด 10 ไฟล์
                            </div>
                            <div
                              v-if="errors.files"
                              class="invalid-feedback d-block"
                            >
                              {{ errors.files }}
                            </div>
                          </div>
                          <div class="col-md-6">
                            <!-- <div class="row border-bottom mb-3">
                              <div
                                v-for="(file, index) in data.document.files"
                                :key="index"
                                class="col-12 mb-3 list-file "
                              >
                                <div
                                  class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                  "
                                >
                                  <div class="">
                                    <img width="40px" :src="imgFile(file.mimeType)" />

                                  </div>
                                  <div class="pl-3 w-70">
                                    <div
                                      class="
                                        h-100
                                        flex-column
                                        align-items-start
                                      "
                                    >
                                      <div class="mb-auto name">
                                        {{ file.filename }}
                                      </div>
                                      
                                    </div>
                                  </div>
                                  <div class="h-100">
                                    <div
                                      class="d-flex align-items-center h-100"
                                    >
                                      <eva-icon
                                        @click="remove(files.indexOf(file))"
                                        name="close-circle"
                                        class="primary cursor-pointer"
                                        fill="#6c757d"
                                      ></eva-icon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            <div class="row">
                              <div
                                v-for="(file, index) in files"
                                :key="index"
                                class="col-12 mb-3 list-file"
                              >
                                <div
                                  class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                  "
                                >
                                  <div class="">
                                    <img
                                      height="60"
                                      :src="imgFileName(file.name)"
                                    />
                                  </div>
                                  <div class="pl-3 w-70">
                                    <div
                                      class="
                                        h-100
                                        flex-column
                                        align-items-start
                                      "
                                    >
                                      <div class="mb-auto name">
                                        {{ file.name }}
                                      </div>
                                      <small class="text-secondary size">{{
                                        sizeFile(file.size)
                                      }}</small>
                                    </div>
                                  </div>
                                  <div class="h-100">
                                    <div
                                      class="d-flex align-items-center h-100"
                                    >
                                      <eva-icon
                                        @click="remove(files.indexOf(file))"
                                        name="close-circle"
                                        class="primary cursor-pointer"
                                        fill="#6c757d"
                                      ></eva-icon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-12">
                            <b-button
                              @click="submitBtnEditDoc()"
                              class="mr-3"
                              variant="primary"
                              >บันทึก</b-button
                            >
                            <b-button
                              @click="isEditDoc = false"
                              variant="outline-secondary"
                              >ยกเลิก</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded mb-3">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-semibold
                      d-flex
                      justify-content-between
                    "
                  >
                    <h5>วิธีการชำระเงิน</h5>
                    <!-- <div v-if="!isEditCard">
                      <b-button
                        v-if="data.detail.statusNumber == 0"
                        @click="isEditCard = true"
                        variant="outline-primary"
                      >
                        <eva-icon name="edit-outline"></eva-icon>
                        แก้ไข</b-button
                      >
                    </div> -->
                  </div>
                  <div class="px-3 pt-2 pb-4">
                    <div v-if="!isEditCard" class="row">
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">บัตรเครดิต</div>
                        <div class="">
                          <img
                            width="36px"
                            v-if="data.creditData.creditCardType == 'VISA'"
                            src="../../../assets/card/visa1-gray.png"
                            alt=""
                          />
                          <img
                            width="36px"
                            v-if="data.creditData.creditCardType == 'Master'"
                            src="../../../assets/card/mastercard1-gray.png"
                            alt=""
                          />
                          <img
                            width="36px"
                            v-if="data.creditData.creditCardType == 'JCB'"
                            src="../../../assets/card/jcb1-gray.png"
                            alt=""
                          />
                          <span class="mx-3">●●●●</span>
                          {{ data.creditData.creditCardNumber }}
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="row align-items-end mb-3">
                        <div class="col-lg-6 mt-3">
                          <div
                            v-for="(item, index) in credits"
                            :key="index"
                            class="credit-select"
                          >
                            <input
                              type="radio"
                              :id="'cre' + index"
                              name="credit"
                              :value="item.creditCardId"
                              v-model="creditCardId"
                            />
                            <label class="list" :for="'cre' + index">
                              <div>
                                <span class="mr-2">●●●●</span>
                                <span class="mr-2">●●●●</span>
                                <span>●●●●</span>
                                {{ item.creditCardNumber }}
                              </div>
                              <div>
                                <img
                                  height="20px"
                                  :src="imgCard(item.creditCardType)"
                                  alt=""
                                />
                              </div>
                            </label>
                          </div>
                          <div class="credit-select">
                            <b-card no-body class="list mb-0">
                              <b-card-header
                                header-tag="header"
                                class="p-0"
                                role="tab"
                              >
                                <div @click="newWebCreate()">เพิ่มบัตรใหม่</div>
                              </b-card-header>
                            </b-card>
                          </div>
                        </div>
                        <div class="col-lg-6 pl-lg-0 pl-1">
                          <button
                            @click="apiCredit()"
                            title="โหลดรายการใหม่"
                            class="btn btn-secondary btn-box"
                          >
                            <eva-icon
                              width="20px"
                              name="sync-outline"
                              class="color-white"
                            ></eva-icon>
                          </button>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-12 ">
                          <b-button
                            @click="submitBtnEditCard()"
                            class="mr-3"
                            variant="primary"
                            >บันทึก</b-button
                          >
                          <b-button
                            @click="isEditCard = false"
                            variant="outline-secondary"
                            >ยกเลิก</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <b-modal id="modalApprove" centered :title="statusName">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="warning" class="" @click="apiChangeStatus()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalDeleteCancel" centered title="ต้องการยกเลิกคำขอ">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalDeleteCancel')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="warning" class="" @click="apiChangeStatus()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import DropFiles from "src/components/Files/DropFiles";
import status from "../../../_fields/status.js";

export default {
  name: "PaymentShow",
  components: {
    "drop-files": DropFiles,
  },
  data() {
    return {
      data: {},
      errors: {},
      buyer: {},
      detail: {},
      document: {},
      payment: {},
      supplier: {},
      statusName:null,
      isEditDoc: false,
      isEditCard: false,
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      paymentRef: null,
      files: [],
      addCredit: {
        ccv: null,
        typeCreditCard: null,
        fullname: null,
        cardNumber: null,
        limitMoney: null,
        expiredDate: null,
      },
      credits: [],
      creditCardId: null,
      deleteId: null,
      statusCancel: null,
      currentStatus: null,
    };
  },
  created() {
    this.apiData();
    this.apiCredit();
  },
  methods: {
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/payment/` + this.$route.params.id + `/show`)
        .then((response) => {
          this.data = response.data.data;
          let data = this.data
          self.buyer = data.buyer;
          self.detail = data.detail;
          self.document = data.document;
          self.payment = data.payment;
          self.supplier = data.supplier;
          self.$hideLoader();
        });
    },
    formatPrice(a, b) {
      return formatNum.price(Number(a), Number(b));
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    imgFile(type) {
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    imgFileName(filename) {
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    submitBtnEditDoc() {
      let self = this;
      this.$showLoader();
      var formData = new FormData();
      formData.append("paymentRef", this.paymentRef);
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }
      this.$http
        .post(
          `api/buyers/payment/` + this.$route.params.id + `/add/documents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          self.apiData();
          self.isEditDoc = false;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    createCredit() {
      let self = this;
      this.$showLoader();

      this.$http
        .post(`api/buyers/creditcard/store`, this.addCredit)
        .then((res) => {
          this.credit = res.data.data;
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.apiCredit();
        });
    },
    apiCredit() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/creditcard/index`)
        .then((res) => {
          this.credits = res.data.data;
          let cre = this.credits.find(
            ({ statusDefault }) => statusDefault === true
          );
          if (cre) {
            this.creditCardId = cre.creditCardId;
          }
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    newWebCreate() {
      window.open("/buyer/credit/create", "popup", "width=1280,height=600");
    },
    imgCard(type) {
      switch (type) {
        case "VISA":
          return require("@/assets/card/visa1-gray.png");
        case "Master":
          return require("@/assets/card/mastercard1-gray.png");
        case "JCB":
          return require("@/assets/card/jcb1-gray.png");
        default:
          return "";
      }
    },
    submitBtnEditCard() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/payment/` + this.$route.params.id + `/change/card`, {
          creditCardId: self.creditCardId,
        })
        .then((res) => {
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          self.apiData();
          self.isEditCard = false;
          self.$hideLoader();
        });
    },
    showModalDelete(paymentId, currentStatus, statusText) {
      this.deleteId = paymentId;
      this.currentStatus = currentStatus;
      this.statusCancel = false;
      this.statusName = `ต้องการ${statusText}`;
      this.$bvModal.show("modalApprove");
    },
    showModalDeleteCancel(paymentId, currentStatus) {
      this.deleteId = paymentId;
      this.currentStatus = currentStatus;
      this.statusCancel = false;
      this.$bvModal.show("modalDeleteCancel");
    },
    apiChangeStatus() {
      let url = "/api/payment/flag-status/updated",
      body = {
        paymentId: this.deleteId,
        paymentStatus: this.currentStatus,
        approved: this.statusCancel,
      },
      modal = this.currentStatus === 4 ? "modalDeleteCancel":"modalApprove"
      this.$bvModal.hide(`${modal}`);
      this.$showLoader();
      this.$http.patch(url, body).then(response => {
        this.$bvModal.hide(`${modal}`);
        this.apiData();
          this.$hideLoader();
          this.$notifications.notify({
            message: response.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
      }).catch(error => console.log(error))
    },
    downloadAll() {
      this.data.document.files.forEach((f) => {
        window.open(f.download);
      });
    },
  },
};
</script>
