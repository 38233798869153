<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            >ข้อมูลนิติบุคคล > รายละเอียดนิติบุคคล >
          </span>
          <span class="font-weight-semibold">แก้ไขรายละเอียดนิติบุคคล</span>
          <div class="h3 mt-4">แก้ไขรายละเอียดนิติบุคคล</div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-3">
              <h5 class="px-1 font-weight-semibold">
                รายละเอียด
              </h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <label>ชื่อบริษัท (TH)</label>
                    <input
                      type="text"
                      v-model="data.companyNameTh"
                      class="form-control"
                      :class="{ 'is-invalid': errors.companyNameTh }"
                    />
                    <div
                      v-if="errors.companyNameTh"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyNameTh }}
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label>ชื่อบริษัท (EN)</label>
                    <input
                      type="text"
                      v-model="data.companyNameEng"
                      class="form-control"
                      :class="{ 'is-invalid': errors.companyNameEng }"
                    />
                    <div
                      v-if="errors.companyNameEng"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyNameEng }}
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label>เลขนิติบุคคล</label>
                    <input
                      type="text"
                      v-model="data.companyJuristic"
                      class="form-control"
                      :class="{ 'is-invalid': errors.juristicNumber }"
                    />
                    <div
                      v-if="errors.juristicNumber"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.juristicNumber }}
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label>ที่อยู่</label>
                    <input
                      type="text"
                      v-model="data.addressOne"
                      class="form-control"
                      :class="{ 'is-invalid': errors.addressOne }"
                    />
                    <div
                      v-if="errors.addressOne"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.addressOne }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>เขต/อำเภอ</label>
                    <input
                      type="text"
                      v-model="data.amphure"
                      class="form-control"
                      :class="{ 'is-invalid': errors.amphure }"
                    />
                    <div
                      v-if="errors.amphure"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.amphure }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>จังหวัด</label>
                    <v-select
                      v-model="province"
                      :options="provinces"
                      :class="{ 'is-invalid': errors.provinceId }"
                    ></v-select>
                    <div
                      v-if="errors.provinceId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.provinceId }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>ประเทศ</label>
                    <v-select
                      v-model="countryId"
                      :options="counties"
                      :class="{ 'is-invalid': errors.countryId }"
                    ></v-select>
                    <div
                      v-if="errors.countryId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.countryId }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>รหัสไปรษณีย์</label>
                    <input
                      type="text"
                      v-model="data.postcode"
                      class="form-control"
                      :class="{ 'is-invalid': errors.postcode }"
                    />
                    <div
                      v-if="errors.postcode"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.postcode }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>เบอร์โทรศัพท์บริษัท</label>
                    <input
                      type="text"
                      v-model="data.companyPhone"
                      class="form-control"
                      @keyup="$numberInput($event)"
                      :class="{ 'is-invalid': errors.companyPhone }"
                    />
                    <div
                      v-if="errors.companyPhone"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyPhone }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>อีเมลบริษัท</label>
                    <input
                      type="text"
                      v-model="data.companyEmail"
                      class="form-control"
                      :class="{ 'is-invalid': errors.companyEmail }"
                    />
                    <div
                      v-if="errors.companyEmail"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyEmail }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>เว็บไซต์ (ถ้ามี)</label>
                    <input
                      type="text"
                      v-model="data.companyWebsite"
                      class="form-control"
                      :class="{ 'is-invalid': errors.companyWebsite }"
                    />
                    <div
                      v-if="errors.companyWebsite"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyWebsite }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>หมวดหมู่ Buyer</label>
                    <v-select
                      v-model="supplierCategoryId"
                      :options="categories"
                      :class="{ 'is-invalid': errors.supplierCategoryId }"
                    ></v-select>
                    <div
                      v-if="errors.supplierCategoryId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.supplierCategoryId }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 text-right">
              <button
                class="btn bg-white border mr-4"
                @click="$router.push({ name: 'supplier.juristic.company' })"
              >
                ยกเลิก
              </button>
              <button class="btn btn-primary" @click="submitBtn()">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Juristic",
  data() {
    return {
      errors: {},
      data: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      categories: [],
      categoryId: null,
      supplierCategoryId: null,
    };
  },
  created() {
    this.apiJuristic();
    this.apiProvinces();
    this.apiCounties();
    this.apiCategories();
  },
  methods: {
    apiJuristic() {
      let self = this;
      this.$showLoader();
      this.$http.get(`api/buyers/juristic/profile`).then((res) => {
        this.data = res.data.data;
        self.$hideLoader();
      });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function(x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.data.provinceId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function(x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.data.countryId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCategories() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/category/suppliers`)
        .then((res) => {
          let categories = res.data.data;
          this.categories = categories.map(function(x) {
            return (x = { label: x.categoryName, code: x.supplierCategoryId });
          });
          setTimeout(() => {
            this.supplierCategoryId = this.categories.find(
              (c) => c.code == this.data.supplierCategoryId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      let data = {
        provinceId: this.province ? this.province.code : null,
        countryId: this.countryId ? this.countryId.code : null,
        supplierCategoryId: this.supplierCategoryId
          ? this.supplierCategoryId.code
          : null,
        amphure: this.data.amphure,
        addressOne: this.data.addressOne,

        juristicNumber: this.data.companyJuristic,
        postcode: this.data.postcode,
        website: this.data.companyWebsite,
        companyPhone: this.data.companyPhone,
        companyEmail: this.data.companyEmail,

        companyNameTh: this.data.companyNameTh,
        companyNameEng: this.data.companyNameEng,
      };
      this.$http
        .patch(`api/buyers/update/juristic/profile`, data)
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
  },
};
</script>
