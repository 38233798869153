<template>
  <div>
    <div v-if="data.supplierName" class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-bold" :to="{name:'admin.suppliers'}">Supplier</router-link> >
          <router-link class="text-secondary font-weight-bold" :to="{name:'admin.suppliers.show',id:data.supplierId}">{{data.supplierName}}</router-link> > 
          </span>
          <b> เอกสารบริษัท</b>
          <div class="page-name d-flex justify-content-between">
            <div class="h3 mt-lg-4">เอกสารบริษัท</div>
            <div>
              <button type="button" class="btn btn-primary mr-3" @click="downloadAll()">
                <eva-icon name="download" class="color-white"></eva-icon> ดาวน์โหลดทั้งหมด
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="">
              <div class="py-2-1 font-weight-bold">เอกสารที่อัปโหลด</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div
                    class="px-3 py-2-1 border-bottom font-weight-bold d-flex justify-content-between"
                  >
                    <div>สถานะ</div>
                    <span
                        :class="
                          'badge badge-pill' +
                          (data.statusNumber == 0
                            ? ' badge-warning'
                            : '') +
                          (data.statusNumber == 2
                            ? ' badge-danger'
                            : '') +
                          (data.statusNumber == 1
                            ? ' badge-success'
                            : '')
                        "
                        >{{ data.statusName }}</span
                      >
                  </div>
                  <div class="px-3 py-3">
                    <div class="row">
                      <div v-for="(item, index) in data.lists" :key="index" class="col-lg-12 px-4">
                        <div class="border-bottom py-3">
                          <div class="h6 font-weight-bold mt-3">
                            {{item.topic}}
                          </div>
                          <div class="row">
                            <div v-for="(file, fileIn) in item.files" :key="fileIn" class="col-md-6 mb-3 ">
                              <div :title="file.filename" class="d-flex justify-content-between list-file">
                                <div class="">
                                  <img
                                    height="40px"
                                    :src="imgFile(file.fileMimeType)"
                                  />
                                </div>
                                <div class="pl-1 w-70">
                                  <div
                                    class="h-100 flex-column align-items-start"
                                  >
                                    <div class="mb-auto name small">
                                      <a :href="file.download" download="">
                                    {{ file.filename }}
                                    </a>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a :href="file.download" download="">
                                  <eva-icon
                                    name="download"
                                    class="cursor-pointer color-secondary"
                                  ></eva-icon>
                                  </a>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row justify-content-center mt-5">
      <div class="col-12 mt-5 text-center"><h3>ไม่พบข้อมูล</h3></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Document",
  data() {
    return {
      data:{}
    }
  },
  created() {
    this.apiData()
  },
  methods: {
    apiData(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/supplier/all/status/`+this.$route.params.id+`/documents`)
        .then((res) => {
          this.data = res.data.data.documents;
          self.$hideLoader();
        })
    },
    status(status,txt) {
      switch (status) {
        case 1:
          return "<span class='badge badge-pill badge-success'>"+txt+"</span>";
        case 2:
          return "<span class='badge badge-pill badge-danger'>"+txt+"</span>";
        default:
          return "<span class='badge badge-pill badge-warning'>"+txt+"</span>";
      }
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
    downloadAll(){
      var timeout = 0
      this.data.lists.forEach(l => {
        if (l.files) {
          l.files.forEach(f => {
            timeout += 1000
            setTimeout(() => {
            window.open(f.download)
            }, timeout);
          })
        }
      });
    }
  },
};
</script>