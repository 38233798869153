<template>
  <div class="row align-items-center">
    <div class="col-12">
      <div class="row row justify-content-center ">
        <div class="col-md-4 text-center">
          <img
            src="../../../assets/image/account-rejected@2x.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-md-12 text-center">
          <h3 class="mt-5">
            <eva-icon
              name="close-circle"
              class="color-danger"
              width="40"
              height="40"
            ></eva-icon>
            บัญชีของคุณไม่ผ่านการอนุมัติ
          </h3>
          <span class="text-secondary">
            เนื่องจากพบว่าบริษัทของคุณมีประวัติการทุจริตทางด้านการเงิน
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
