<template>
  <div class="container-fluid">
     <Infomation></Infomation>
  </div>
</template>
<script>
import Static from './components/Static.vue';
import Infomation from './components/BuyerInfo.vue';
  export default {
    name: 'BuyerRegisterVerify',
    components: {
      Static,
      Infomation
    },
    data() {
      return {
        user: {},
        error: null,
        showView: "main",
      };
    },
    created() {
      this.apiCheckEkyc()
    },
    methods: {
      apiProfile(checkEkyc = false) {
        let self = this;
        this.$showLoader();
        this.$http
          .get(`api/buyers/profile`)
          .then((res) => {
            this.user = res.data.data;
            if (this.user.stepRegister == 1 && checkEkyc == true) {
              self.$router.push({
                name: "buyer.register.kyc"
              });
            }
            self.$hideLoader();
          })
          .catch(function (err) {
            self.error = err;
            self.$hideLoader();
          });
      },
      apiCheckEkyc() {
        let self = this;
        this.$showLoader();
        this.$http
          .post(`api/users/ekyc/check/status/register`)
          .then((res) => {
            this.apiProfile(res.data.data.isValid);
          })
          .catch(function (err) {
            self.error = err;
            self.$hideLoader();
          });
      },
    },
  };
</script>
<style scoped lang="scss">
  .stepper {
    .nav-tabs {
      position: relative;
      border-bottom: unset;

      &::before {
        position: absolute;
        content: '';
        width: 70%;
        left: 15%;
        border-bottom: 1px solid #dee2e6;
      }

      li:first-child a .round-tab {
        left: calc(50% - 50px);
      }

      li:last-child a .round-tab {
        left: calc(50% - 0px);
      }
    }

    .nav-tabs>li {
      width: 33.33%;
      position: relative;

      &.completed {
        &::after {
          background: #34bc9b;
        }
      }

      &:last-child {
        &::after {
          background: transparent;
        }
      }

      &.active:last-child {
        .round-tab {
          background: #1A32B1;

          &::after {
            content: "";
            color: #fff;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 0;
            display: block;
          }
        }
      }
    }

    .nav-tabs [data-toggle="tab"] {
      width: 25px;
      height: 25px;
      margin: 20px auto;
      border-radius: 100%;
      border: none;
      padding: 0;
      color: #f1f1f1;
    }

    .nav-tabs>.active>[data-toggle="tab"] {
      color: #34bc9b;
      cursor: default;
      border: none;
    }

    .tab-pane {
      position: relative;
      padding-top: 50px;
    }

    .round-tab {
      width: 50px;
      height: 50px;
      line-height: 45px;
      display: inline-block;
      border-radius: 25px;
      background: #1A32B1;
      border: 2px solid #2835ac85;
      color: #ffffff;
      z-index: 2;
      position: absolute;
      left: calc(50% - 25px);
      top: -25px;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
    }

    .completed .round-tab {
      background: #34bc9b;
      color: #34bc9b;
      border: 4px solid #98edcd;

      &::after {
        content: "✔";
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        display: block;
      }
    }

    .active {
      .round-tab {
        background: #1A32B1;
        border: 4px solid #a8aff131;

        &::after {
          display: none;
        }
      }

      &.next .round-tab {
        background: #1AB77C;
        border: 4px solid #a8aff131;

        &::after {
          display: none;
        }
      }
    }

    .disabled .round-tab {
      background: #fff;
      color: #f1f1f1;
      border-color: #f1f1f1;

      &::after {
        display: none;
      }
    }
  }

  .step {
    .disabled {
      opacity: 0.5;

      img {}
    }
  }
</style>