<template>
  <div>
    <h4>Loading...</h4>
  </div>
</template>
<script>
export default {
  name: "Bypass",
  data() {
    return {};
  },
  created() {
    this.$showLoader();
    this.getToken();
  },
  methods: {
     getToken() {
       this.$http
        .post(`api/users/bypass`, { uid: this.$route.params.id })
        .then((res) => {
          localStorage.setItem("currentUser", JSON.stringify(res.data.data));
          this.$router.go(this.$router.currentRoute)
        })
        .catch(function (err) {
          this.error = err;
          this.$hideLoader();
        });
    }
  },
};
</script>
  