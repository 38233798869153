<template>
  <div class="container-fluid">
    <div class="row bg-white justify-content-center">
      <div class="col-md-10 my-5">
        <div class="d-flex justify-content-between">
          <div>
            <ul class="list-unstyled">
              <b-media tag="li">
                <template #aside>
                  <b-avatar v-if="user.email" variant="light" size="4em" class="text-primary icon-profile-m" :text="user.email.substr(0,1)"></b-avatar>
                </template>
                <span></span>
                <div class="mt-0 mb-1">
                  <span class="badge badge-primary rounded-pill mr-3 px-3"
                    >Supplier</span
                  >
                  <span
                    v-if="user.statusNumber == 0"
                    class="badge badge-warning rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 1"
                    class="badge badge-success rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 5"
                    class="badge badge-secondary rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 2"
                    class="badge badge-danger rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <div class="font-weight-bold">
                    {{ user.email }}
                  </div>
                </div>
              </b-media>
            </ul>
          </div>
          <div class="text-right">
            <b-button variant="outline-secondary" @click="logout()">
              <eva-icon name="log-out-outline"></eva-icon>
              ออกจากระบบ
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ข้อมูลบัญชี</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>รายละเอียด</h5>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <b-button
                    @click="$router.push({ name: 'supplier.profile.edit' })"
                    variant="outline-primary"
                  >
                    <eva-icon name="edit-outline"></eva-icon>
                    แก้ไข</b-button
                  >
                </div>
              </div>
              <hr class="m-0" />
              <div class="row mt-3">
                <div class="col-md-6 px-md-4 mb-3">
                  <div>
                    <b>ชื่อ</b>
                  </div>
                  <div class=" text-secondary">{{ user.firstname ? user.firstname : "-" }}</div>
                </div>
                <div class="col-md-6 px-md-4 mb-3">
                  <div>
                    <b>นามสกุล</b>
                  </div>
                  <div class=" text-secondary">{{ user.lastname ? user.lastname : "-" }}</div>
                </div>
                <div class="col-md-6 px-md-4 mb-3">
                  <div>
                    <b>อีเมล</b>
                  </div>
                  <div class=" text-secondary">{{ user.email ? user.email : "-" }}</div>
                </div>
                <div class="col-md-6 px-md-4 mb-3">
                  <div>
                    <b>เบอร์โทร</b>
                  </div>
                  <div class=" text-secondary">{{ user.phone ? user.phone : "-" }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.type &&  user.type != 'juristic_person'" class="col-md-10 mb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>ที่อยู่</h5>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <b-button
                    @click="$router.push({ name: 'supplier.profile.edit' })"
                    variant="outline-primary"
                  >
                    <eva-icon name="edit-outline"></eva-icon>
                    แก้ไข</b-button
                  >
                </div>
              </div>
              <hr class="m-0" />
              <div class="row mt-3">
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>ที่อยู่ปัจจุบัน</b>
                  </div>
                  <div class=" text-secondary">{{ user.addressOne + ' ' + user.addressTwo + ' ' + user.amphure + ' ' + user.provinceName + ' ' + user.postcode + ' ' + user.countryName}}</div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>ที่อยู่ใบกำกับภาษี</b>
                  </div>
                  <div class=" text-secondary">{{ user.receiptBuyerAddressOne + ' ' + user.receiptBuyerAddressTwo + ' ' + user.receiptBuyerAmphure + ' ' + user.receiptBuyerProvinceName + ' ' + user.receiptBuyerPostcode + ' ' + user.receiptBuyerCountryName}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ความปลอดภัย</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>รหัสผ่าน</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="$router.push({ name: 'supplier.profile.edit' })"
                    variant="outline-primary"
                  >
                    <eva-icon name="lock-outline"></eva-icon>
                    เปลี่ยนรหัสผ่าน</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="user.supplierStatusApprove == true" class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ค่าตั้งต้นหลังบ้าน</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>ค่าตั้งต้นหลังบ้าน</h5>
                  <div class="text-secondary">{{user.defaultSettingName}}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="$router.push({ name: 'supplier.profile.edit' })"
                    variant="outline-primary"
                  >
                    <eva-icon name="star-outline"></eva-icon>
                    เลือกค่าตั้งต้น</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  name: 'profile',
  data() {
    return {
      user: {},
      error: null,
    };
  },
  created() {
    this.apiProfile();
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    logout(){
      authenticationService.logout()
      this.$router.push({name:'login'});
    }
  },
};
</script>
