<template class="dashboard">
  <div>
    <Buyer v-if="$parent.$parent.$parent.currentUser.defaultSetting == 1" />
    <Supplier v-if="!$parent.$parent.$parent.role.adminAuth && $parent.$parent.$parent.currentUser.defaultSetting === 0" />
    <Admin v-if="$parent.$parent.$parent.role.adminAuth" />
  </div>
</template>

<script>
import Buyer from "../buyers/dashboard/Dashboard";
import Supplier from "../suppliers/dashboard/Dashboard";
import Admin from "../admins/dashboard/Dashboard";
export default {
  components: {
    Buyer,
    Supplier,
    Admin
  },
  data() {
    return {
      loading: true,
      userFromApi: null,
    };
  },
  created() {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.dashboard {
  width: 100%;
  position: relative;
}
</style>
