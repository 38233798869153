<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="text-center mb-4">
          <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
        </div>
        <div v-if="!success" class="box box-signup">
          <form class="" @submit.prevent="onSubmit">
            <div class="title text-center">เปลี่ยนรหัสผ่าน</div>
            <div class="form-group mt-3">
              <label htmlFor="password">รหัสผ่านใหม่</label>
              <b-input-group >
                <template  #append>
                  <button class="icon-input" :class="{ 'is-invalid': submitted && errors.password }" @click="showPass = !showPass" type="button"><eva-icon :name="!showPass ? 'eye-off-outline' : 'eye-outline'" fill="#6c757d"></eva-icon></button>
                </template>
                <b-form-input :type="!showPass ? 'password' : 'text'"
                 v-model.trim="$v.password.$model"
                :class="{ 'is-invalid': submitted && errors.password }"
                name="password"
                ></b-form-input>
              </b-input-group>
              <div v-if="errors.password" class="invalid-feedback d-block">
                      {{ errors.password }}
                    </div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary w-100 text-center"
                :disabled="loading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  v-show="loading"
                ></span>
                <span>ยืนยัน</span>
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="success"
          class="box box-signup text-center"
        >
          <img
            src="./../../assets/image/changepass-completed.png"
            class="img-fuild"
          />
          <div class="h5 mt-4">
            ระบบได้ทำการเปลี่ยนรหัสผ่านเรียบร้อยแล้ว
          </div>
          <router-link
            :to="{ name: 'login' }"
            class="btn btn-primary w-100 text-center mt-3"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-show="loading"
            ></span>
            <span>เข้าสู่ระบบ</span>
          </router-link>
        </div>
        <div v-if="!success" class="my-3 text-center">
          <router-link :to="{ name: 'login' }">กลับหน้าเข้าสู่ระบบ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      submitted: false,
      password: "",
      errors:{},
      loading: false,
      returnUrl: "",
      error: "",
      success: false,
      showPass: false
    };
  },
  validations: {
    password: { required },
  },
  created() {},
  methods: {
    onSubmit() {
      let self = this;
      this.submitted = true;
      self.errors = {}
      self.loading = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        self.loading = false;
        return;
      }
      this.$http
        .post(`api/users/update/password`, {
          password: this.password,
          token: this.$route.params.token,
        })
        .then((res) => {
          self.success = true;
          self.loading = false;
        })
        .catch(function(err) {
          self.loading = false;
          self.errors = err.response.data.errors.data;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 28px;
  margin-bottom: 1.5rem;
}
.box {
  background-color: #ffffff;
}
</style>