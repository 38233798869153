<template>
  <div>
    <div v-if="detail.name" class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            ><router-link
              class="text-secondary font-weight-semibold"
              :to="{ name: 'supplier.customers' }"
              >ลูกค้า</router-link
            >
            >
          </span>
          <span class="font-weight-semibold"> {{ detail.name }}</span>
          <div class="h3 mt-4">{{ detail.name }}</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div v-if="detail" class="col-md-4 mb-4">
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-semibold d-flex justify-content-between"
              >
                <div>สถานะ</div>
                <span
                  :class="
                    'badge badge-pill' +
                      (detail.statusNumber == 0 ? ' badge-warning' : '') +
                      (detail.statusNumber == 2 ? ' badge-danger' : '') +
                      (detail.statusNumber == 1 ? ' badge-success' : '')
                  "
                  >{{ detail.statusName }}</span
                >
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex">
                  <div class="mr-3">
                    <b-avatar
                      v-if="detail.email"
                      class="icon-profile-m mr-2"
                      :text="detail.email.substr(0, 1)"
                    ></b-avatar>
                  </div>
                  <div>
                    <div class="font-weight-semibold">
                      อีเมล:
                      <small class="text-secondary"> {{ detail.email }}</small>
                    </div>
                    <div class="font-weight-semibold">
                      โทรศัพท์:
                      <small class="text-secondary"> {{ detail.phone }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="paid" class="col-md-4 mb-4">
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom title-card-secondary"
              >
                ชำระแล้ว 30 วันล่าสุด (฿)
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-semibold h5 pt-2">
                      ฿{{ formatNum.price(paid.money, 2).slice(0, -3) }}.<small>{{
                        paid.money.toFixed(2).slice(-2)
                      }}</small>
                    </div>
                    <small>{{ paid.countList }}</small>
                  </div>
                  <img
                    class="img-fluid"
                    style="max-width: 50px"
                    src="./../../../assets/icon/success@2x.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="waitingForPayment" class="col-md-4 mb-4">
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom title-card-secondary"
              >
                รอชำระ 30 วันล่าสุด (฿)
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-semibold h5 pt-2">
                      ฿{{
                        formatNum.price(waitingForPayment.money, 2).slice(0, -3)
                      }}.<small>{{
                        waitingForPayment.money.toFixed(2).slice(-2)
                      }}</small>
                    </div>
                    <small>{{ waitingForPayment.countList }}</small>
                  </div>
                  <div
                    class="rounded-circle bg-info"
                    style="width:50px;padding:5px"
                  >
                    <eva-icon
                      name="clock-outline"
                      class="color-white"
                      width="40px"
                      height="40px"
                    ></eva-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="p-lg-3">
                <div class="form-filter mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <select
                      class="form-control mr-lg-2 font-weight-semibold"
                      name=""
                      id=""
                      v-model="selectStatus"
                    >
                      <option value="">สถานะ ทั้งหมด</option>
                      <option
                        v-for="(item, index) in searchStatus"
                        :key="index"
                        :value="item.statusNumber"
                      >
                        {{ item.statusName }}
                      </option>
                    </select>
                    <date-range-picker
                      v-model="dateRange"
                      :date-format="dateFormat"
                      :locale-data="datepick.local"
                      :apply="apiGetList"
                      opens="right"
                    >
                      <template v-slot:input="dateRange" style="padding: 0px">
                        <div class="input-group mb-3">
                          <input
                            class="form-control w-210px text-center"
                            :value="
                              (dateRange.startDate
                                ? _dateFns(dateRange.startDate) + ' - '
                                : 'ค้นหาจากวันที่ชำระ') +
                                (dateRange.endDate
                                  ? _dateFns(dateRange.endDate)
                                  : '')
                            "
                          />
                          <div class="input-group-append">
                            <span class="input-group-text p-0 px-2"
                              ><eva-icon
                                name="calendar-outline"
                                class="color-secondary"
                              ></eva-icon
                            ></span>
                          </div>
                        </div>
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        aria-describedby="button-addon2"
                        v-model="keyword"
                        @keyup.enter="apiGetList()"
                      />
                      <div class="input-group-append">
                        <button
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          @click="apiGetList()"
                          type="button"
                          id="button-addon2"
                        >
                          <i class="fas fa-search fa-2x"></i>
                        </button>
                      </div>
                    </div>
                    <a
                      href="javascript:;"
                      @click="clickExportLink"
                      class="btn border"
                    >
                      <eva-icon name="download-outline"></eva-icon> Export
                    </a>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <router-link
                      :to="{
                        name: 'supplier.payments.show',
                        params: { id: props.rowData.paymentId },
                      }"
                    >
                      <eva-icon
                        width="20px"
                        name="eye-outline"
                        class="color-primary"
                      ></eva-icon>
                    </router-link>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="
                      $cardColumn(
                        props.rowData.cardType,
                        props.rowData.cardNumber
                      )
                    "
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/supplier_customer_payment.js";
import status from "../../../_fields/status.js";
import {format} from "date-fns"

export default {
  name: "CustomerShow",
  components: {
    Vuetable,
    DateRangePicker,
    VuetablePagination,
  },
  data() {
    return {
      formatNum: formatNum,
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      loaded: false,
      searchStatus: [],
      chartdata: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      datepick: {
        local: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          monthNames: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          firstDay: 0,
        },
      },
      fields: FieldsDef,
      exportLink: "",
      perPage: 10,
      tablePaginationTotal: 0,
      data: [],
      keyword: "",
      cardLists: {},
      deleteId: null,
      statusCancel: null,
      detail: {},
      paid: {},
      waitingForPayment: {},
      selectStatus: "",
    };
  },

  created() {
    this.apiSearchStatus();
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
    selectStatus(val) {
      this.apiGetList();
    },
    dateRange() {
      this.apiGetList();
    },
  },
  mounted: function() {
    this.apiGetList();
  },
  methods: {
    dateFormat(classes) {
      return classes;
    },
    _dateFns(date) {
        if (!date) return null;
        return format(date, 'dd/MMM/yyyy')
      },
    clickExportLink() {
      let url = this.exportLink;
      url = url + "?keyword=" + this.keyword + "&status=" + this.selectStatus;
      if (this.dateRange.startDate && this.dateRange.endDate) {
        url =
          url +
          "&dateStart=" +
          this.dateRange.startDate +
          "&dateEnd=" +
          this.dateRange.endDate;
      }
      window.open(url);
    },
    apiSearchStatus() {
      this.$http.get(`api/buyers/payment/status`).then((response) => {
        this.searchStatus = response.data.data;
      });
    },
    apiGetList() {
      let url =
        `api/suppliers/payment/` + this.$route.params.id + `/show/lists`;
      url = url + "?keyword=" + this.keyword + "&status=" + this.selectStatus;
      if (this.dateRange.startDate && this.dateRange.endDate) {
        url =
          url +
          "&dateStart=" +
          this.dateRange.startDate +
          "&dateEnd=" +
          this.dateRange.endDate;
      }
      this.$http.get(url).then((response) => {
        let data = response.data.data.payments;
        this.detail = response.data.data.detail;
        this.exportLink = response.data.exportLink;
        this.paid = response.data.data.paid;
        this.waitingForPayment = response.data.data.waitingForPayment;
        this.data = data.map((d) => {
          return {
            ...d,
            amountStr: "฿" + formatNum.price(d.amount, 2),
          };
        });
      });
    },
    search() {
      this.apiGetList(this.keyword);
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    showModalDelete(paymentId, statusCancel) {
      this.deleteId = paymentId;
      this.statusCancel = statusCancel;
      this.$bvModal.show("modalApprove");
    },
    apiRemove() {
      let self = this;
      self.$bvModal.hide("modalApprove");
      this.$showLoader();
      this.$http
        .get(
          `api/buyers/payment/` +
            this.deleteId +
            `/cancel?status=` +
            this.statusCancel
        )
        .then((res) => {
          self.apiGetList();
          self.$bvModal.hide("modalApprove");
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.w-210px {
  width: 260px;
}
</style>
