<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">
          ข้อมูลการโอนเงินให้ Supplier
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 d-flex border-bottom justify-content-between"
              >
                <div class="font-weight-bold">ค้นหารายงาน</div>
              </div>
              <div class="px-3 py-2-1">
                <form @submit.prevent="conditionFrom">
                  <div class="row">
                    <div class="col-sm-12 col-lg-3">
                      <div class="form-group">
                        <div>
                          <b>Month Code</b>
                        </div>
                        <b-form-input
                          id="month-code"
                          :class="{ 'is-invalid': errors.length }"
                          v-model="monthCode"
                        >
                        </b-form-input>
                        <div
                          v-if="errors.length"
                          class="invalid-feedback d-block"
                        >
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <!-- <div>
                        <b>สถานะ</b>
                      </div>
                      <div>
                        <v-select
                          :options="statusList"
                          label="name"
                          v-model="selectedStatus"
                          :value="selectedStatus"
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.name }}
                          </template>
                        </v-select>
                      </div> -->
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <!-- <div class="form-group">
                        <div>
                          <b>ธนาคาร</b>
                        </div>
                        <v-select
                          :options="banks"
                          label="label"
                          v-model="selectedBank"
                          :value="selectedBank"
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.label }}
                          </template>
                        </v-select>
                      </div> -->
                    </div>
                    <div class="col-sm-12 col-lg-3">
                      <div class="form-group py-4">
                        <button type="submit" class="btn btn-primary btn-block">
                          ค้นหา
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1">
                <div class="mb-2 d-flex justify-content-between">
                  <div class="form-inline" v-if="data.length">
                    <div class="px-2">
                      <downloadexcel
                        class="btn border"
                        :fetch="fetchData"
                        :fields="exportFields"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        type="xls"
                        name="ข้อมูลการโอนเงินให้ Supplier.xls"
                      >
                        <eva-icon name="download-outline"></eva-icon>Export to
                        xls
                      </downloadexcel>
                    </div>
                    <div class="px-2">
                      <downloadexcel
                        class="btn border"
                        :fetch="fetchData"
                        :fields="exportFields"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        type="csv"
                        name="ข้อมูลการโอนเงินให้ Supplier.csv"
                      >
                        <eva-icon name="download-outline"></eva-icon>Export to
                        CSV
                      </downloadexcel>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-3 py-2-1">
                <template>
                  <vuetable
                    ref="vuetable"
                    :api-mode="false"
                    :data="data"
                    :fields="fields"
                    :per-page="perPage"
                    :row-class="$onRowClass"
                    detail-row-component="detail-row"
                    track-by="SupplierId"
                    @vuetable:checkbox-toggled="checkboxItem"
                    @vuetable:checkbox-toggled-all="checkboxItem"
                  >
                    <div
                      slot="Status"
                      slot-scope="props"
                      v-html="status(props.rowData.Status)"
                    ></div>
                    <div slot="actions" slot-scope="props">
                      <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template #button-content class="w-auto">
                          <eva-icon
                            name="more-vertical"
                            class="color-secondary action-icon"
                          ></eva-icon>
                        </template>
                        <b-dropdown-item
                          v-if="
                            props.rowData.Status === 6 ||
                            props.rowData.Status === '6'
                          "
                          @click="
                            transferMoney(monthCode, props.rowData.SupplierId)
                          "
                        >
                          ยืนยันการโอนเงิน
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="
                            $router.push({
                              name: 'admin.report.merchant-transaction',
                              params: {
                                tranfercode: monthCode,
                                merchantID: props.rowData.MerchantId,
                                supplierId: props.rowData.SupplierId,
                              },
                            })
                          "
                        >
                          <eva-icon
                            width="18px"
                            name="clipboard-outline"
                            class="color-dark"
                          ></eva-icon>
                          ตรวจสอบ
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </vuetable>
                  <div style="padding-top: 10px" class="footer-table">
                    <div class="ui floated menu">
                      <div class="d-flex align-items-center">
                        <div>Showing</div>
                        <select v-model="perPage" class="form-control mx-1">
                          <option :value="10">10</option>
                          <option :value="20">20</option>
                          <option :value="30">30</option>
                          <option :value="50">50</option>
                        </select>
                        <div>items</div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="px-3 py-2-1 summary-block">
                <div class="text-right summary-content">
                  <div class="row">
                    <div class="col-md-4 offset-md-8 text-left">
                      <div class="row">
                        <div class="col-8">ยอดเงินรวม (฿) (Total)</div>
                        <div class="col-4">
                          <span class="h4">{{ summary }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">รวมรายการ (Transaction)</div>
                        <div class="col-4">
                          <span class="h4">{{ paymentCount }}</span> รายการ
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8 approved-btn">
                          <button
                            class="btn btn-primary btn-block"
                            :disabled="buttonDisabled"
                            v-on:click="changeStatus"
                          >
                            ยืนยันการเปลี่ยนสถานะเป็นชำระเงินแล้ว
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-bold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <div>สถานะรายการชำระเงิน</div>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    :class="
                      'row py-2-1 ' +
                      (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNum }from "@/_formats/number";
import { formatDate } from "@/_formats/date";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Vuetable from "vuetable-2";
import VuetablePagination from "@/components/pagination/VuetablePagination";
import FieldsDef from "@/_fields/supplier_transaction.js";
import status from "@/_fields/status.js";
import axios from "axios";
import downloadexcel  from "vue-json-excel";
export default {
  name: "TransactionTransfer",
  components: {
    Vuetable,
    VuetablePagination,
    DateRangePicker,
    downloadexcel 
  },
  data() {
    return {
      monthCode: "",
      selectedStatus: "",
      selectedBank: "",
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      banks: [],
      submitted: false,
      errors: [],
      checkedRows: [],
      selected: null,
      data: [],
      summary: null,
      paymentCount: null,
      fields: FieldsDef,
      perPage: 10,
      tablePaginationTotal: 0,
      disabledBtn: false,
      exportFields: null
      
    };
  },
  created() {
    this.apiBank();
  },
  computed: {
    buttonDisabled: function(){
    	return !this.disabledBtn
    }
  },
  methods: {
    apiBank() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/banks`)
        .then((res) => {
          let banks = res.data.data;
          this.banks = banks.map(function (x) {
            return (x = {
              label: x.bankName,
              code: x.bankId,
              img: x.bankImage,
            });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiTransferSupplier(monthCode = null) {
      let self = this;
      this.$showLoader();
      this.monthCode = monthCode;
      let config = {
        method: "get",
        // url: `https://stg-apigw.paysoon.net/payso/transferlist/${monthCode}`,
        url: `${process.env.VUE_APP_APIGO}/transferlist/${monthCode}`,
        headers: { 
          'apikey': process.env.VUE_APP_API_KEY },
      };
      axios(config)
        .then((response) => {
          this.data = response.data;
          this.selected = null;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.errors = err;
          self.$hideLoader();
        });
    },
    changeStatus() {
      let supplierIds = this.selected
      supplierIds.forEach( (id) => {
        this.transferMoney(this.monthCode, id)
        this.disabledBtn = false
      })
    },
    async conditionFrom() {
      this.submitted = true;
      this.errors = [];
      if (this.monthCode === "") {
        this.errors.push("Month Code is required.");
      } else {
        this.apiTransferSupplier(this.monthCode);
      }
    },
    status(status) {
      let statusClass = this.statusList.find((s) => s.id == status);
      return `<span class="badge badge-pill ${statusClass.class}">${statusClass.name}</span>`;
    },
    transferMoney(monthCode, supplierId){
      this.$showLoader();
      let config = {
        method: "post",
        headers: { 
          'apikey': process.env.VUE_APP_API_KEY },
        url: `${process.env.VUE_APP_APIGO}/transfersup/${monthCode}/${supplierId}`,
      };
      axios(config)
        .then((response) => {
          this.apiTransferSupplier(monthCode);
          this.$notifications.notify({
            message: response.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$hideLoader();
        })
        .catch((error) =>{

        })
    },
    checkboxItem() {
      let paymentLists = this.data
      let selectedList = this.$refs.vuetable.selectedTo
      this.selected = this.$refs.vuetable.selectedTo
      let supplierIds = [];
      let paymentSummary = 0;
      selectedList.forEach((supplierId) => {
        paymentLists.map((payment) => {
          if(payment.SupplierId === supplierId && payment.Status === '6') {
            paymentSummary +=  parseFloat(payment.TransferAmount)
            supplierIds.push(payment.SupplierId);
          }
        }, supplierId, {supplierIds, paymentSummary})
      }, {supplierIds, paymentSummary})
      if(supplierIds.length > 0){
        this.disabledBtn = true
      }
      this.selected = supplierIds
      this.paymentCount = supplierIds.length
      this.summary = formatNum.price(paymentSummary, 2)
    },
    async fetchData(){
      let exportField = {
        "Transfer to Supplier": "TransferAmount",
        "Supplier Merchant ID": "MerchantId",
        "บริษัท": "Company",
        "ธนาคาร": "Bank",
        "ชื่อบัญชี": "AccountName", 
        "เลขบัญชี": "AccountNo", 
        "สาขา": "Branch", 
        "สถานะ": {
          field: "Status",
          callback: (Status) => {
            let status = this.statusList.find((s) => s.id === parseInt(Status));
            return `${status.name}`; 
          }
        },
        "วันที่โอนเงินให้ Supplier": {
            field: "TransferDate",
            callback: (TransferDate) => {
                return formatDate.thai(TransferDate); 
            }
        }
      }
      this.exportFields = exportField
     return this.data
    },
    startDownload(){
          this.$showLoader();
    },
    finishDownload(){
         this.$hideLoader();
    }
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
  },
  mounted() {
    this.apiTransferSupplier(this.monthCode);
  },
};
</script>
<style lang="scss" scoped>
.summary-block {
  border-top: 1px solid #dee2e6;
}
.summary-content {
  padding: 30px;
}
.approved-btn {
  padding-top: 25px;
}
</style>