import { authenticationService } from "../_services/authentication.service";

export function handleResponse(response) {
    const data = response;
    if (response.status > 290) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
 
}
