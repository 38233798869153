export default [

  {
    name: 'userName',
    title: 'ชื่อ Supplier',
    sortField: 'userName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'paymentCreatedAt',
    title: 'วันที่เพิ่ม',
    sortField: 'paymentCreatedAtFormat',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'supplierCategoryName',
    title: 'หมวดหมู่',
    sortField: 'supplierCategoryName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'bankName',
    title: 'ธนาคาร',
    sortField: 'bankName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'bankNumber',
    title: 'เลขบัญชี',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },{
    name: 'bankUsername',
    title: 'ชื่อบัญชี',
    sortField: 'bankUsername',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
 
]