<template>
  <div>
    <div  class="row justify-content-center">
      <div v-if="info.buyer" class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-bold" :to="{name:'admin.buyers'}">Buyer</router-link> ></span>
          <b> {{ info.buyer.name }}</b>
          <div class="h3 mt-4">{{ info.buyer.name }}</div>
        </div>
      </div>
      <div class="col-lg-11 col-md-12">
        <div class="row mt-lg-4 mt-md-3">
          <div v-if="info.detail" class="col-md-4">
            <div
              class="
                d-flex
                flex-column
                justify-content-between
                box-hover
                text-left
                bg-white
                rounded
                h-100
              "
            >
              <div class="mb-2 border-bottom p-3 text-center">
                <b-avatar v-if="info.buyer.email" class="icon-profile-m" :text="info.buyer.email.substr(0,1)"></b-avatar>
                  <b-avatar v-else class="icon-profile-m" text=""></b-avatar>
              </div>
              <div
                class="mb-2 border-bottom px-3 d-flex justify-content-between"
              >
                <div>สถานะ</div>
                <div>
                  <toggle-button
                    :disabled="$parent.$parent.$parent.currentUser.adminRole != 'owner' && $parent.$parent.$parent.currentUser.adminRole != 'admin'"
                    :value="info.detail.active"
                    @change="changeActive()"
                    color="#1AB77C"
                    :width="75"
                    :sync="true"
                    :labels="{ checked: 'เปิดใช้งาน', unchecked: 'ปิดใช้งาน' }"
                  />
                </div>
              </div>
              <div class="mb-2 px-3 d-flex justify-content-between">
                <div>วงเงินบัตรรวม</div>
                <div>{{ formatNum.price(info.detail.limitMoney, 2) }}</div>
              </div>
              <div @click="$router.push({name:'admin.buyers.document',id:$route.params.id})" class="mb-2 px-3 d-flex justify-content-between hover-link">
                <div>เอกสาร</div>
                <div class="d-flex ">
                  <eva-icon name="arrow-ios-forward-outline"></eva-icon>
                </div>
              </div>
            </div>
          </div>
          <div v-if="info.buyer" class="col-md-4">
            <div class="box-hover text-left bg-white rounded h-100">
              <div class="d-flex align-content-stretch flex-wrap h-100">
                <div
                  class="
                    h-50px
                    w-100
                    mb-2
                    border-bottom
                    px-3
                    py-2-1
                    align-items-center
                    d-flex
                    justify-content-between
                  "
                >
                  <h5 class="mb-0">ข้อมูลผู้ใช้</h5>
                  <div>
                    <button
                      v-if="$parent.$parent.$parent.currentUser.adminRole == 'owner' || $parent.$parent.$parent.currentUser.adminRole == 'admin'"
                      @click="
                        $router.push({
                          name: 'admin.buyers.profile',
                          id: $route.params.id,
                        })
                      "
                      class="btn btn-outline-primary btn-sm"
                    >
                      <eva-icon name="edit-outline" width="20px"></eva-icon>
                      แก้ไข</button
                    >
                  </div>
                </div>
                <div class="px-3 w-100">
                  <div class="h6">
                    อีเมล<br />
                    <small class="text-secondary">{{ info.buyer.email }}</small>
                  </div>
                  <div class="h6 mb-2">
                    เบอร์โทรศัพท์<br />
                    <small class="text-secondary">{{ info.buyer.phone }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="info.address" class="col-md-4">
            <div class="box-hover text-left bg-white rounded h-100">
              <div class="d-flex align-content-stretch flex-wrap h-100">
                <div
                  class="
                   h-50px
                    w-100
                    mb-2
                    border-bottom
                    px-3
                    py-2-1
                    align-items-center
                    d-flex
                    justify-content-between
                  "
                >
                  <h5 class="mb-0">ที่อยู่</h5>
                </div>
                <div class="px-3 font-weigth-blod">
                  <div class="h6">
                    ที่อยู่<br />
                    <small class="text-secondary">{{
                      info.address.fullAddress
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cardLists" class="row mt-lg-4 mt-md-3">
          <div
            v-for="(item, index) in cardLists"
            :key="index"
            class="col-lg-3 col-md-6"
          >
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom font-weight-bold"
              >
                {{ allStatus[item.id].name }} 30 วันล่าสุด (฿)
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-bold">
                      {{ formatNum.price(item.money, 2).slice(0, -3) }}.<small>{{
                        formatNum.price(item.money, 2).slice(-2)
                      }}</small>
                    </div>
                    <small>{{ item.countList }} รายการ</small>
                  </div>
                  <div>
                    <div
                      class="text-center"
                      v-if="item.statusImage == 1"
                      style="
                        background: #728cde;
                        border-radius: 50px;
                        height: 25px;
                        width: 25px;
                      "
                    >
                      <eva-icon
                        width="20px"
                        name="clock-outline"
                        class="color-white"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 2">
                      <eva-icon
                        width="30px"
                        height="30px"
                        name="checkmark-circle-2"
                        class="color-success"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 3">
                      <eva-icon
                        width="30px"
                        height="30px"
                        name="alert-circle"
                        class="color-danger"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 4">
                      <eva-icon
                        width="30px"
                        height="30px"
                        name="close-circle"
                        class="color-secondary"
                      ></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1 border-bottom font-weight-bold">
                สถิติการชำระเงิน
              </div>
              <div class="px-lg-5 py-lg-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex"><div class="rounded-circle mr-2 mt-1" style="height:15px;width:15px;background-color: #4a5dc1;"></div>ยอดที่ชำระแล้ว<div class="rounded-circle mr-2 ml-4 mt-1" style="height:15px;width:15px;background: #f45668;"></div>ค่าธรรมเนียม</div>
                  <date-range-picker
                    v-model="dateChart"
                    :date-format="dateFormat"
                    :locale-data="datepick.local"
                    opens="left"
                  >
                    <template v-slot:input="dateChart" style="padding: 0px">
                      <div class="input-group mb-3">
                        <input
                            class="form-control w-210px text-center"
                            :value="
                              (dateChart.startDate
                                ? _dateFns(dateChart.startDate) + ' - '
                                : 'ค้นหาจากวันที่ชำระ') +
                              (dateChart.endDate
                                ? _dateFns(dateChart.endDate)
                                : '')"
                          />
                        <div class="input-group-append">
                          <span class="input-group-text p-0 px-2"
                            ><eva-icon name="calendar-outline" class="color-secondary"></eva-icon></span>
                        </div>
                      </div>
                    </template>
                  </date-range-picker>
                </div>
                <canvas id="graph" width="400" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1 border-bottom font-weight-bold">
                รายการชำระเงิน
              </div>
              <div class="px-3 py-2-1">
                <div class="mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <select
                      class="form-control mr-lg-2 font-weight-bold"
                      name=""
                      id=""
                      v-model="selectStatus"
                    >
                      <option value="">สถานะ ทั้งหมด</option>
                      <option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <date-range-picker
                      v-model="dateRange"
                      :date-format="dateFormat"
                      :locale-data="datepick.local"
                      opens="right"
                    >
                      <template v-slot:input="dateRange" style="padding: 0px">
                        <div class="input-group mb-3">
                          <input
                            class="form-control w-210px text-center"
                            :value="
                              (dateRange.startDate
                                ? _dateFns(dateRange.startDate) + ' - '
                                : 'ค้นหาจากวันที่ชำระ') +
                              (dateRange.endDate
                                ? _dateFns(dateRange.endDate)
                                : '')
                            "
                          />
                          <div class="input-group-append">
                            <span class="input-group-text p-0 px-2"
                              ><eva-icon
                                name="calendar-outline"
                                class="color-secondary"
                              ></eva-icon
                            ></span>
                          </div>
                        </div>
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        v-model="keyword"
                        aria-describedby="button-addon2"
                        @keyup.enter="apiGetPaymentList()"
                      />
                      <div class="input-group-append">
                        <button
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          @click="apiGetPaymentList()"
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon name="search-outline"></eva-icon
                          ><i class="fas fa-search fa-2x"></i>
                        </button>
                      </div>
                    </div>
                    <a href="javascript:;" @click="clickExportLink" class="btn border">
                      <eva-icon name="download-outline"></eva-icon> Export
                    </a>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :row-class="$onRowClass"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="w-auto">
                        <eva-icon
                          name="more-vertical"
                          class="color-secondary action-icon"
                        ></eva-icon>
                      </template>
                    
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 0"
                        :to="{
                          name: 'admin.approve.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 4"
                        :to="{
                          name: 'admin.approve.cancellation.request.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber != 0 && props.rowData.statusNumber != 4"
                        :to="{
                          name: 'admin.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="$cardColumn(props.rowData.cardType,props.rowData.cardNumber)"
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                    <select v-model="perPage" class="form-control mx-1">
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="30">30</option>
                      <option :value="50">50</option>
                    </select>
                    <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
<vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/admin_buyer_payment.js";
import status from "../../../_fields/status.js";
import { ToggleButton } from "vue-js-toggle-button";
import Chart from "chart.js";
import DateRangePicker from "vue2-daterange-picker";
import {format} from "date-fns"
export default {
  name: "buyerShow",
  components: {
    Vuetable,
    VuetablePagination,
    ToggleButton,
    DateRangePicker,
  },
  data() {
    return {
      data: {},
      statusList: status.slice(0,7).concat(status.slice(7+1)),
      allStatus: status,
      fields: FieldsDef,
      payments: [],
      perPage: 10,
      tablePaginationTotal: 0,
      formatNum: formatNum,
      chartdata: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateChart: {
        startDate: null,
        endDate: null,
      },
      datepick: {
        local: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          firstDay: 0,
        },
      },
      exportLink: '',
      searchStatus: [],
      keyword: "",
      selectStatus: "",
      charts: [],
      dataCharts: {},
      myChart: null,
      keyword: "",
      cardLists:[],
      info:{}
    };
  },
  created() {
    this.apiInfo();
  },
  methods: {
    clickExportLink(){
      let url = `api/exports/payment?buyer=${this.$route.params.id}&keyword=${this.keyword}&status=${this.selectStatus}`
        if (this.dateRange.startDate) {
          let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
          let endDate =  format(this.dateRange.endDate, 'yyyy-MM-dd');
          url = `${url}&dateStart=${startDate}&dateEnd=${endDate}`;
        }
        this.$http.get(url, {
          responseType: 'blob'
          }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');
          
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `payments${format(new Date(), 'ddMMyyyy')}.csv`);
            document.body.appendChild(fileLink)
            fileLink.click();
          })
    },
    apiInfo(){
      let url =`api/admins/buyers/${this.$route.params.id}/show`
      this.$http.get(url).then((response) => {
        this.info = response.data.data;
      })
    },
    apiGetPaymentList(){
      let url = `api/payment/list?buyer=${this.$route.params.id}&keyword=${ this.keyword}&status=${this.selectStatus}`
      if (this.dateRange.startDate) {
        let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
        let endDate =  format(this.dateRange.endDate, 'yyyy-MM-dd');
        url = `${url}&dateStart=${startDate}&dateEnd=${endDate}`
      }
      this.$http.get(url).then((response) => {
        this.payments = response.data.data.paymentLists;
        this.exportLink = response.data.exportLink;
        this.payments = this.payments.map((d) => {
          return {
            ...d,
            amountStr: '฿' + formatNum.price(d.amount, 2),
            buyerFeeStr: '฿' + formatNum.price(d.buyerFeeNet, 2),
            supplierFeeStr: '฿' + formatNum.price(d.supplierFeeNet, 2),
            feeStr: '฿' + formatNum.price(d.fee, 2),
            totalAmountStr: '฿' + formatNum.price(d.totalAmount, 2),
          };
        });
      });
    },
    apiSummary(){
        let url = `api/payment/monthly-summary?buyer=${this.$route.params.id}`;
        this.$http.get(url).then((response) => {
          this.cardLists = response.data.data.cardLists;
        });
    },
    apiGetCharts(){
        let startDate, endDate
        if (this.dateChart.startDate) {
          startDate = format(this.dateChart.startDate, 'yyyy-MM-dd')
          endDate = format(this.dateChart.endDate, 'yyyy-MM-dd')
        }
        let url = `api/payment/chart/${startDate}/${endDate}?buyer=${this.$route.params.id}`
        this.$http.get(url).then((response) => {
          this.charts = response.data.data.chartLists;
          this.chartDateRange = response.data.data.dateDetail;
           this.dataCharts = response.data.data.dataCharts;
        });
      },
    search() {
      this.apiGetPaymentList(this.keyword);
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
     
      if (this.payments.length < 1) return;

      let local = this.payments;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    changeActive() {
      this.$http.get(`api/admins/buyers/` + this.$route.params.id + `/enableordisabled/status`).then((response) => {
        this.apiGetPaymentList();
      });
    },
    dateFormat(classes) {
      return classes;
    },
    _dateFns(date) {
        if (!date) return null;
        return format(date, 'dd/MMM/yyyy')
      },
    
  },
  watch: {
    payments(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },perPage(){
      this.$refs.vuetable.refresh();
    },
    selectStatus(val) {
      this.apiGetPaymentList();
    },
    charts(val) {
      let ref = this;
      if(this.myChart != null){
        this.myChart.destroy()
      }
      var ctx = document.getElementById("graph").getContext("2d");
      let month = val.map((d) => {
        return d.month;
      });
      let amount = val.map((d) => {
        return d.value;
      });
      let fee = val.map((d) => {
        return d.fee;
      });
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: month,
          datasets: [
            {
              label: "#ยอดที่ชำระแล้ว",
              data: amount,
              lineTension: 0,
              fill: false,
              borderColor: "#4a5dc1",
              backgroundColor: "#ffffff",
              pointBorderWidth: 3,
            },
            {
              label: "#ค่าธรรมเนียม",
              data: fee,
              lineTension: 0,
              fill: false,
              borderColor: "#f45668",
              pointBorderWidth: 3,
              backgroundColor: "#ffffff",
            },
          ],
        },
        options: {
          legend: {
            display: false,
            align: 'center',
            labels: {
              padding: 30,
              usePointStyle: true,
              pointStyle: 'circle',
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value) {
                    return "฿" + ref.formatNum.price(value, 2);
                  },
                },
              },
            ],
          },
        },
      });
    },
    dateRange() {
      this.apiGetPaymentList();
    },
    dateChart() {
      this.apiGetCharts();
    },
  },
  mounted() {
   this.apiGetPaymentList()
   this.apiSummary()
   this.apiGetCharts()
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.h-50px {
  height: 50px;
}
.w-210px {
  width: 260px;
}

.list-file .name {
  white-space: unset !important;
}
.hover-link{
  cursor: pointer;
  &:hover{
    background: #f8f9fa;
  }
}
</style>