<template>
  <div class="sidebar" :style="sidebarStyle" :data-image="null">
    <div class="page-wrapper chiller-theme toggled">
      <a id="show-sidebar" class="btn btn-sm btn-dark" href="#">
        <i class="fas fa-bars"></i>
      </a>
      <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
          <div class="sidebar-brand">
            <img height="100%" src="./../../assets/logo_paysoon-white.png" alt="" />
            <div id="close-sidebar">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <div class="sidebar-menu mt-lg-4 mt-md-3">
            <ul>
              <li v-for="(menu, m) in menus" :key="m" :class="{'sidebar-dropdown' : menu.child}">
                <a v-if="menu.child" href="#">
                  <div v-if="menu.icon == 'custom-payment'"></div>
                  <eva-icon v-else :name="menu.icon" ></eva-icon>
                  <span>{{ menu.title }}</span>
                </a>
                <div v-if="menu.child" class="sidebar-submenu">
                  <ul>
                    <li v-for="(item, index) in menu.child" :key="index">
                       <router-link :to="{name:item.to}" 
                        >
                        <span>{{ item.title }}</span>
                         <span v-if="item.noti" class="badge badge-pill badge-danger">{{ item.noti }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <router-link v-if="!menu.child" :to="{name:menu.to}" exact>
                  <i class="eva" v-if="menu.icon == 'custom-payment'" style="display: inline-block;">
                    <svg id="payment" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 48 48">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_2413" data-name="Rectangle 2413" width="48" height="48" transform="translate(161 2705)" />
                        </clipPath>
                      </defs>
                      <g id="payment-2" data-name="payment" transform="translate(-161 -2705)" clip-path="url(#clip-path)">
                        <path id="wallet" d="M30.425,0H7.8a1.561,1.561,0,0,0-1.56,1.56v3.9H4.681a1.561,1.561,0,0,0-1.56,1.56v3.9H1.56A1.561,1.561,0,0,0,0,12.482v23.4a1.561,1.561,0,0,0,1.56,1.56H31.205a6.248,6.248,0,0,0,6.241-6.241V7.021A7.03,7.03,0,0,0,30.425,0ZM6.241,8.581H28.085v2.34H6.241ZM3.121,22.624H7.8a1.56,1.56,0,1,1,0,3.121H3.121Zm31.205,8.581a3.124,3.124,0,0,1-3.12,3.12H3.121V28.865H7.8a4.681,4.681,0,0,0,0-9.362H3.121V14.042h27.3a6.985,6.985,0,0,0,3.9-1.186Zm-3.12-20.362V7.021a1.561,1.561,0,0,0-1.56-1.56H9.362V3.121H30.425a3.9,3.9,0,0,1,.78,7.722Zm0,0" transform="translate(166.555 2710.373)"/>
                      </g>
                    </svg>
                  </i>
                  <eva-icon v-else :name="menu.icon" :fill="$route.name == menu.to ? '#1A32B1': '#333333'"></eva-icon>
                  <span>{{ menu.title }}</span>
                  <span v-if="menu.noti" class="badge badge-pill badge-danger">{{ menu.noti }}</span>
                </router-link>
              </li>
              <li class="d-lg-none">
                <router-link  :to="{ name: linkProfile }" exact>
                  <eva-icon name="person-outline" :fill="$route.name == linkProfile ? '#1A32B1': '#333333'"></eva-icon>
                  <span>บัญชีของฉัน</span>
                </router-link>
              </li>
              <li class="d-lg-none">
                <a  href="#" @click="logout()">
                  <eva-icon name="log-out-outline" fill="#333333"></eva-icon>
                  <span>ออกจากระบบ</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
          <!-- <a href="#">
          <i class="fa fa-bell"></i>
          <span class="badge badge-pill badge-warning notification">3</span>
        </a> -->
        </div>
      </nav>
    </div>
  </div>
</template>
<style lang="sass">
@import "../../assets/sass/lbd/_variables"
.my-color
  color: $primary-color

  .sidebar .sidebar-wrapper
    display: flex
    flex-direction: column

.sidebar .nav-main__links
  flex: 1

.sidebar .sidebar-wrapper .logo .logo__container
  padding-left: 10px
</style>
<script>
import { authenticationService } from "../../_services/authentication.service";
export default {
  data() {
    return {
      linkProfile: '',
    }
  },
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: "PaySon",
    },
    backgroundImage: {
      type: String,
      default: "img/sidebar-5.jpg",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundColor: `#FFFFFF`,
      };
    },
  },
  mounted() {
    $(".sidebar-dropdown > a").click(function () {
      $(".sidebar-submenu").slideUp(200);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(200);
        $(this).parent().addClass("active");
      }
    });

    $("#close-sidebar").click(function () {
      $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function () {
      $(".page-wrapper").addClass("toggled");
    });
  },
  created(){
    let currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.linkProfile = `${currentUser.role}.profile`
  },
  methods:{
    logout() {
      authenticationService.logout();
      this.$router.push({name:'login'});
    },
  }
};
</script>

