const thaiRegex = /[^\u0E00-\u0E7F0-9 (),.]+$/;
const englishRegex = /[^a-zA-Z0-9.,()_-\s]+$/;

export const StringFormat = { ThaiString, EnglishString } 
function ThaiString (thaiString){

    return  thaiString.replace(thaiRegex, ''); 
}
function EnglishString(englishString){
    return  englishString.replace(englishRegex, ''); 
}