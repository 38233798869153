<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ช่วยเหลือ > </span>
          <b>ติดต่อเรา</b>
          <div class="h3 mt-4">ติดต่อเรา</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="">
              <div class="px-1 font-weight-bold">ข้อมูลติดต่อ</div>
            </div>
          </div>
          <div v-if="data" class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-12 border-bottom mb-1">
                        <h6>ผ่ายบริการลูกค้า</h6>
                      </div>

                      <div class="col-md-6 form-group px-md-4">
                        <div>
                          <b>เบอร์โทรศัพท์</b>
                        </div>
                         <div>
                          <input
                            @keyup="$numberInput($event)"
                            type="text"
                            class="form-control input-number"
                            v-model="data.phone"
                          :class="{ 'is-invalid': errors.phone }"
                          />
                          <div v-if="errors.phone" class="invalid-feedback d-block">
                            {{ errors.phone }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 form-group px-md-4">
                        <div>
                          <b>เบอร์โทรศัพท์มือถือ</b>
                        </div>
                        <div>
                          <input
                            type="text"
                            @keyup="$numberInput($event)"
                            class="form-control"
                            v-model="data.mobile"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 form-group px-md-4">
                        <div>
                          <b>LINE ID</b>
                        </div>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="data.lineId"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 form-group px-md-4">
                        <div>
                          <b>อีเมล</b>
                        </div>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="data.email"
                          />
                        </div>
                      </div>
                      <div class="col-md-12 form-group px-md-4">
                        <div>
                          <b>ที่อยู่</b>
                        </div>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="บ้านเลขที่ ตึก ชั้น"
                            v-model="data.addressOne"
                          />
                        </div>
                        <div class="mt-3 mb-3">
                          <input
                            type="text"
                            placeholder="ถนน แขวง"
                            class="form-control"
                            v-model="data.addressTwo"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 form-group">
                      <div>
                        <b>เขต/อำเภอ</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.amphure"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>จังหวัด</b>
                      </div>
                      <div>
                        <v-select
                          v-model="provinceId"
                          :options="provinces"
                        ></v-select>
                        <div
                          v-if="errors.provinceId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.provinceId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>ประเทศ</b>
                      </div>
                      <div>
                        <v-select
                          v-model="countryId"
                          :options="counties"
                        ></v-select>
                        <div
                          v-if="errors.countryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.countryId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>รหัสไปรษณีย์</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="data.postcode"
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$parent.$parent.$parent.currentUser.adminRole == 'owner' || $parent.$parent.$parent.currentUser.adminRole == 'admin'" class="row mt-3">
                <div class="col-md-12 text-right">
                  
                  <b-button
                    @click="apiGetData()"
                    variant="outline-secondary"
                    class="mr-3"
                    >ยกเลิก</b-button
                  >
                  <b-button
                    @click="submitBtn()"
                    variant="primary"
                    >บันทึก</b-button
                  >
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contact",
  data() {
    return {
      data: {},
      errors:{},
      provinces:[],
      counties:[],
      countryId: null,
      provinceId: null,

    };
  },
  created() {
    this.apiGetData();
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.provinceId = this.provinces.find(
              (c) => c.code == this.data.provinceId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.data.countryId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/helper/contactus`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.$showLoader();
      let data = this.data
      data.provinceId = this.provinceId ? this.provinceId.code : null
      data.countryId = this.countryId ? this.countryId.code : null
      this.$http
        .patch(`api/admins/helper/contactus/update`, data)
        .then((res) => {
          self.apiGetData();
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
  },
};
</script>
