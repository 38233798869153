<template >
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-bold" :to="{name:'admin.users'}">จัดการผู้ใช้</router-link> ></span>
          <b> แก้ไขข้อมูลผู้ใช้</b>
          <div class="h3 mt-4">แก้ไขข้อมูลผู้ใช้</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-md-12 mt-5">
            <div class="row justify-content-between">
              <div class="col-md-3">
                <h5>บัญชีผู้ใช้</h5>
                <span class="text-secondary"
                  >เพิ่มผู้ใช้งานและกำหนดสิทธิ์ของการเข้าถึงหลังจากนั้นระบบจะส่งการยืนยันการเข้าถึงให้กับผู้ใช้งานผ่านทางอีเมล</span
                >
              </div>
              <div class="col-md-9">
                <div class="bg-white p-3 rounded mb-1">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <div>
                        <b>ชื่อ</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          v-model="firstname"
                          class="form-control"
                        :class="{ 'is-invalid': errors.firstname }"
                        />
                        <div
                          v-if="errors.firstname"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.firstname }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>นามสกุล</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          v-model="lastname"
                          class="form-control"
                        :class="{ 'is-invalid': errors.lastname }"
                        />
                        <div
                          v-if="errors.lastname"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.lastname }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>อีเมล</b>
                      </div>
                      <div>
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                        :class="{ 'is-invalid': errors.email }"
                        />
                        <div
                          v-if="errors.email"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.email }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>เบอร์โทร</b>
                        <small class="text-secondary">(Optional)</small>
                      </div>
                      <div>
                        <input
                          type="text"
                          v-model="phone"
                          @keyup="$numberInput($event)"
                          class="form-control"
                        :class="{ 'is-invalid': errors.phone }"
                        />
                        <div
                          v-if="errors.phone"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.phone }}
                        </div>
                      </div>
                    </div>
                    <!-- <div v-if="$parent.$parent.$parent.currentUser.adminRole != 'viewer'" class="col-md-12 form-group">
                      <div>
                        <b>รหัสผ่าน</b>
                      </div>
                      <div class="mt-2">
                        <button @click="resetPass" type="button" class="btn btn-outline-primary">
                         <eva-icon name="lock-outline"></eva-icon>
                          รีเซตรหัสผ่าน
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="row justify-content-between">
              <div class="col-md-3">
                <h5>สิทธิการเข้าถึง</h5>
              </div>
              <div class="col-md-9">
                <div class="bg-white px-2 rounded mb-1">
                  <div class="row">
                    <div class="col-md-12 p-md-4">
                      <b-form-group
                        class="font-weight-bold"
                        label="สิทธิการเข้าถึงของผู้ใช้"
                      >
                        <b-form-radio
                          v-for="(item, index) in options"
                          :key="index"
                          :value="item.value"
                          v-model="adminRole"
                          name="adminRole"
                          size="lg"
                        >
                          <small
                            ><b>{{ item.title }}</b
                            ><br /><span class="text-secondary">
                              {{ item.text }}</span
                            ></small
                          >
                        </b-form-radio>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div 
          v-if="$parent.$parent.$parent.currentUser.adminRole == 'owner' || $parent.$parent.$parent.currentUser.adminRole == 'admin'"
           class="col-md-12 mt-3">
            <div class="text-right">
              <b-button
                class="mr-3"
                @click="$router.push({ name: 'admin.users' })"
                variant="outline-secondary"
                >ยกเลิก</b-button
              >
              <b-button @click="submitBtn()" variant="primary">บันทึก</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserShow",
  components: {},
  data() {
    return {
      selected: null,
      errors: {},
      showPass: false,
      options: [
        {
          text: "สามารถสร้างและกำหนดสิทธิ์ผู้ใช้งานได้ ทำรายการอนุมัติ และดูรายละเอียดของรายการชำระเงิน รายละเอียดของผู้ชำระเงินและผู้รับชำระเงินได้",
          value: "admin",
          title: "Admin",
        },
        {
          text: "สามารถทำการอนุมัติ และดูรายละเอียดของรายการชำระเงิน รายละเอียดของผู้ชำระเงินและผู้รับชำระเงิน",
          value: "approver",
          title: "Approver",
        },
        {
          text: "ดูรายละเอียดของรายการชำระเงิน รายละเอียดของผู้ชำระเงินและผู้รับชำระเงินได้",
          value: "viewer",
          title: "Viewer",
        },
      ],
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      adminRole: null,
    };
  },
  created() {
    this.apiData();
  },
  methods: {
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/` + this.$route.params.id + `/show`)
        .then((res) => {
          self.$hideLoader();
          let data = res.data.data;
          this.firstname = data.firstname;
          this.lastname = data.lastname;
          this.email = data.email;
          this.phone = data.phone;
          this.adminRole = data.position;
        });
    },
    submitBtn() {
      let self = this;
      this.$showLoader();
      this.errors = {};
      this.$http
        .patch(`api/admins/` + this.$route.params.id + `/update`, {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          adminRole: this.adminRole,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "admin.users" });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    resetPass(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/` + this.$route.params.id + `/reset/password`)
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "admin.users" });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.w-210px {
  width: 210px;
}
</style>