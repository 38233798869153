<template>
  <div :class="($sidebar.showSidebar ? 'nav-open': '') + ' h-100'">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>

<script>
import { authenticationService } from "./_services/authentication.service";
import { Role } from "./_helpers/role";
export default {
  name: "app",
  data() {
    return {
      currentUser: null,
    };
  },
  computed: {
    role() {
      return  Role[this.currentUser.role]
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
  },
  updated(){
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
  }
};
</script>
<style lang="scss">
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
