<template>
<div>
    <div v-show="nextStep" class="row justify-content-center">
        <div class="col-lg-11 col-md-12">
            <div class="page-name mt-lg-4 mt-md-3">
                <div class="h3 mt-4">ขั้นที่ 1: ลงทะเบียนยืนยันตัวตน</div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-2">
                        <h5 class="px-1">รายละเอียด</h5>
                        <div class="px-1 py-2 text-secondary small line-sub">โปรดกรอกข้อมูลให้ครบถ้วน</div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div>
                                    <label v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">ชื่อผู้ประสานงาน</label>
                                    <label v-else>ชื่อ</label>
                                </div>
                                <div>
                                    <input type="text" class="form-control"
                                        v-model="firstname" :class="{ 'is-invalid': errors.firstname }" />
                                    <div v-if="errors.firstname" class="invalid-feedback d-block">
                                        {{ errors.firstname }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div>
                                    <label v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">นามสกุลผู้ประสานงาน</label>
                                    <label v-else>นามสกุล</label>
                                </div>
                                <div>
                                    <input type="text" class="form-control" max="13"
                                        v-model="lastname" :class="{ 'is-invalid': errors.lastname }" />
                                    <div v-if="errors.lastname" class="invalid-feedback d-block">
                                        {{ errors.lastname }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">
                            <div class="col-md-12 col-lg-12  mb-3">
                                <label>ชื่อบริษัท (TH)</label>
                                <div>
                                    <input type="text" class="form-control" max="13"
                                        v-model="companyNameTH" :class="{ 'is-invalid': errors.companyNameTH }" />
                                    <div v-if="errors.companyNameTH" class="invalid-feedback d-block">
                                        {{ errors.companyNameTH }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label>ชื่อบริษัท (EN)</label>
                                <div>
                                    <input type="text" class="form-control" max="13"
                                        v-model="companyNameEN" :class="{ 'is-invalid': errors.companyNameEN }" />
                                    <div v-if="errors.companyNameEN" class="invalid-feedback d-block">
                                        {{ errors.companyNameEN }}
                                    </div>
                                </div>
                            </div>
        
                        </div>
                        <div class="row" v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label>เลขที่นิติบุลคล</label>
                                <div>
                                    <input type="text" class="form-control" maxlength="13"
                                        v-model="juristicNumber" :class="{ 'is-invalid': errors.juristicNumber }" />
                                    <div v-if="errors.juristicNumber" class="invalid-feedback d-block">
                                        {{ errors.juristicNumber }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <div>
                                    <label v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">ที่อยู่จดทะเบียน</label>
                                    <label v-else>ที่อยู่ตามบัตรประชาชน</label>
                                </div>
                                <div>
                                    <div>
                                        <input type="text" class="form-control"
                                            placeholder="บ้านเลขที่ ตึก ชั้น" v-model="addressOne"
                                            :class="{ 'is-invalid': errors.addressOne }" />
                                        <div v-if="errors.addressOne" class="invalid-feedback d-block">
                                            {{ errors.addressOne }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <input type="text" placeholder="ถนน แขวง" class="form-control"
                                        v-model="addressTwo" :class="{ 'is-invalid': errors.addressTwo }" />
                                    <div v-if="errors.addressTwo" class="invalid-feedback d-block">
                                        {{ errors.addressTwo }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label>เขต/อำเภอ</label>
                                <div>
                                    <input type="text" class="form-control" v-model="amphure"
                                        :class="{ 'is-invalid': errors.amphure }" />
                                    <div v-if="errors.amphure" class="invalid-feedback d-block">
                                        {{ errors.amphure }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>จังหวัด</label>
                                <div>
                                    <v-select v-model="province" :options="provinces" placeholder="เลือกจังหวัด"
                                        :class="{ 'is-invalid': errors.provinceId }"></v-select>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>ประเทศ</label>
                                <div>
                                    <v-select v-model="countryId" :options="counties" placeholder="เลือกประเทศ"
                                        :class="{ 'is-invalid': errors.countryId }"></v-select>
                                    <div v-if="errors.countryId" class="invalid-feedback d-block">
                                        {{ errors.countryId }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>รหัสไปรษณีย์</label>
                                <div>
                                    <input type="text" class="form-control" v-model="postcode"
                                        :class="{ 'is-invalid': errors.postcode }" maxlength="5"/>
                                    <div v-if="errors.postcode" class="invalid-feedback d-block">
                                        {{ errors.postcode }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'" class="row">
                            <div class="col-md-6 mb-3">
                                <label>เบอร์โทรศัพท์บริษัท</label>
                                <div>
                                    <VuePhoneNumberInput  default-country-code="TH" required 
                                    :error="errors.companyPhone? true :false"
                                        v-model="companyPhone" />
                                    <div v-if="errors.companyPhone" class="invalid-feedback d-block">
                                        {{ errors.companyPhone }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>อีเมลบริษัท</label>
                                <div>
                                    <input type="email" class="form-control"
                                        v-model="companyEmail" :class="{ 'is-invalid': errors.companyEmail }" />
                                    <div v-if="errors.companyEmail" class="invalid-feedback d-block">
                                        {{ errors.companyEmail }}
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="row" v-if="this.$parent.$parent.$parent.currentUser.type  === 'juristic_person'">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label>เว็บไซต์บริษัท (ถ้ามี)</label>
                                <div>
                                    <input type="text" class="form-control" v-model="companyWebsite"  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-2">
                        <h5 class="px-1">อัปโหลดเอกสาร</h5>
                        <div class="px-1 py-2 text-secondary small line-sub" v-if="this.$parent.$parent.$parent.currentUser.type === 'natural_person'">โปรดอัปโหลดเอกสารยืนยันตัวตนให้ครบถ้วน</div>
                        <div class="px-1 py-2 text-secondary small line-sub" v-else>โปรดอัปโหลดเอกสารยืนยันตัวตนของผู้ประสานงานให้ครบถ้วน</div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="border-bottom pb-2"  v-if="this.$parent.$parent.$parent.currentUser.type === 'juristic_person'">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>หนังสือรับรองบริษัท</label>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mt-1">
                                        <DropFiles
                                            :max="1"
                                            group="companyCertificates"
                                            :class="{ 'is-invalid': errors.companyCertificates }"
                                        />
                                    <div class="small text-secondary text-bottom-file mb-3">
                                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB สูงสุด
                                        1 ไฟล์
                                    </div>
                                        <div class="row">
                                        <div
                                            v-for="(file, index) in groupFiles.companyCertificates"
                                            :key="index"
                                            class="col-12 mb-3 list-file"
                                        >
                                            <div class="d-flex justify-content-between align-items-center">
                                            <div class="">
                                                <img height="60" :src="imgFile(file.name)" />
                                            </div>
                                            <div class="pl-3 w-70">
                                                <div class="h-100 flex-column align-items-start">
                                                <div class="mb-auto name">
                                                    {{ file.name }}
                                                </div>
                                                <small class="text-secondary size">{{
                                                    sizeFile(file.size)
                                                }}</small>
                                                </div>
                                            </div>
                                            <div class="h-100">
                                                <div class="d-flex align-items-center h-100">
                                                <eva-icon
                                                    @click="remove(files.indexOf(file), 'companyCertificates')"
                                                    name="close-circle"
                                                    class="primary cursor-pointer"
                                                    fill="#6c757d"
                                                ></eva-icon>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="errors.companyCertificates" class="invalid-feedback d-block">
                                        {{ errors.companyCertificates }}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="this.$parent.$parent.$parent.currentUser.type === 'natural_person'?'border-bottom pb-2': 'pt-3'">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label v-if="this.$parent.$parent.$parent.currentUser.type === 'natural_person'">สำเนาบัตรประจำตัวประชาชน</label>
                                    <label v-else>สำเนาบัตรประจำตัวประชาชนผู้ประสานงาน</label>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mt-1">
                                        <DropFiles
                                            :max="1"
                                            group="idCardCopies"
                                            :class="{ 'is-invalid': errors.idCardCopies }"
                                        />
                                    <div class="small text-secondary text-bottom-file mb-3">
                                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB สูงสุด
                                        1 ไฟล์
                                    </div>
                                        <div class="row">
                                        <div
                                            v-for="(file, index) in groupFiles.idCardCopies"
                                            :key="index"
                                            class="col-12 mb-3 list-file"
                                        >
                                            <div class="d-flex justify-content-between align-items-center">
                                            <div class="">
                                                <img height="60" :src="imgFile(file.name)" />
                                            </div>
                                            <div class="pl-3 w-70">
                                                <div class="h-100 flex-column align-items-start">
                                                <div class="mb-auto name">
                                                    {{ file.name }}
                                                </div>
                                                <small class="text-secondary size">{{
                                                    sizeFile(file.size)
                                                }}</small>
                                                </div>
                                            </div>
                                            <div class="h-100">
                                                <div class="d-flex align-items-center h-100">
                                                <eva-icon
                                                    @click="remove(files.indexOf(file), 'idCardCopies')"
                                                    name="close-circle"
                                                    class="primary cursor-pointer"
                                                    fill="#6c757d"
                                                ></eva-icon>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="errors.idCardCopies" class="invalid-feedback d-block">
                                        {{ errors.idCardCopies }}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label v-if="this.$parent.$parent.$parent.currentUser.type  === 'natural_person'">สำเนาทะเบียนบ้าน</label>
                                    <label v-else>สำเนาทะเบียนบ้านผู้ประสานงาน</label>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mt-1">
                                        <DropFiles
                                            :max="1"
                                            group="houseRegistrationCopies"
                                            :class="{ 'is-invalid': errors.houseRegistrationCopies }"
                                        />
                                        <div class="small text-secondary text-bottom-file mb-3">
                                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB สูงสุด
                                            1 ไฟล์
                                        </div>
                                        <div class="row">
                                            <div
                                                v-for="(file, index) in groupFiles.houseRegistrationCopies"
                                                :key="index"
                                                class="col-12 mb-3 list-file"
                                            >
                                                <div class="d-flex justify-content-between align-items-center">
                                                <div class="">
                                                    <img height="60" :src="imgFile(file.name)" />
                                                </div>
                                                <div class="pl-3 w-70">
                                                    <div class="h-100 flex-column align-items-start">
                                                    <div class="mb-auto name">
                                                        {{ file.name }}
                                                    </div>
                                                    <small class="text-secondary size">{{
                                                        sizeFile(file.size)
                                                    }}</small>
                                                    </div>
                                                </div>
                                                <div class="h-100">
                                                    <div class="d-flex align-items-center h-100">
                                                    <eva-icon
                                                        @click="remove(files.indexOf(file), 'houseRegistrationCopies')"
                                                        name="close-circle"
                                                        class="primary cursor-pointer"
                                                        fill="#6c757d"
                                                    ></eva-icon>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="errors.houseRegistrationCopies" class="invalid-feedback d-block">
                                            {{ errors.houseRegistrationCopies }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mb-4">
                <button v-if="this.nextStep" @click="changeStep()" class="btn btn-primary">
                    ถัดไป
                </button>
                <button v-if="this.previousStep" @click="changeStep()" class="btn btn-white">
                    ย้อนกลับ
                </button>
                <button v-if="this.previousStep" class="btn btn-primary">
                    บันทึก
                </button>
            </div>
        </div>
    </div>
    <div v-if="nextStep !== true">
        <InvoiceAddress v-if="user.type ==='natural_person'" v-show="previousStep" :user="user" :mainAddress="mainAddress" :provinces="provinces" :counties="counties" @changedStep="changeStep"/>
        <JuristicAddress v-else v-show="previousStep" :user="user" :mainAddress="mainAddress" :provinces="provinces" :counties="counties" @changedStep="changeStep"/>
    </div>
 </div>   
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import DropFiles from "../../../../../src/components/Files/DropFiles";
import InvoiceAddress from "../components/InvoiceAddress.vue";
import JuristicAddress from "../components/JuristicAddress.vue";
import { StringFormat }  from "../../../../_formats/string"
import { formatNum } from "../../../../_formats/number"
export default {
    name: "Infomation",
    components:{
        DropFiles,
        VuePhoneNumberInput,
        InvoiceAddress,
        JuristicAddress
    },
    created() {
        this.apiProvinces();
        this.apiCounties();
        this.apiProfile();
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            addressOne: '',
            addressTwo: '',
            amphure: '',
            provinceId: '',
            province: '',
            provinces: [],
            counties: [],
            countryId: null,
            postcode: null,
            companyNameTH: '',
            companyNameEN: '',
            juristicNumber: null,
            companyPhone: '',
            companyEmail: '',
            companyWebsite: null,
            user: null,
            files: [],
            groupFiles:{
                companyCertificates: [],
                idCardCopies: [],
                houseRegistrationCopies: []
            },
            mainAddress: null,
            errors: {},
            errorsReceiptAddress: {},
            previousStep: false,
            nextStep: true
        }
    },
    methods: {
        apiProfile() {
            let self = this;
            this.$showLoader();
            this.$http
                .get(`api/buyers/profile`)
                .then((res) => {
                    this.user = res.data.data
                    self.$hideLoader();
            })
            .catch(function (err) {
            self.error = err;
            self.$hideLoader();
            });
        },
        apiProvinces() {
            let self = this;
            this.$showLoader();
            this.$http
                .get(`api/datas/get/provinces`)
                .then((res) => {
                    let provinces = res.data.data;
                    this.provinces = provinces.map(function (x) {
                        return (x = {
                            label: x.provinceName,
                            code: x.provinceId
                        });
                    });
                    self.$hideLoader();
                })
                .catch(function (err) {
                    self.error = err;
                    self.$hideLoader();
                });
        },
        apiCounties() {
            let self = this;
            this.$showLoader();
            this.$http
                .get(`api/datas/get/counties`)
                .then((res) => {
                    let counties = res.data.data;
                    this.counties = counties.map(function (x) {
                        return (x = {
                            label: x.countryName,
                            code: x.countryId
                        });
                    });
                    self.$hideLoader();
                })
                .catch(function (err) {
                    self.error = err;
                    self.$hideLoader();
                });
        },
        changeStep(value) {
            const url = this.$parent.$parent.$parent.currentUser.type  === 'natural_person'? '/api/buyers/validate/main-address': '/api/buyers/validate/juristic-address'
            const formData = new FormData();
            let self = this;

             for (const [key, group] of Object.entries(this.groupFiles)) {
                for (let i = 0; i < group.length; i++) {
                    formData.append(key, group[i]);
                }
            }
    
            if(this.$parent.$parent.$parent.currentUser.type === 'juristic_person'){
                formData.append('companyNameTH', this.companyNameTH);
                formData.append('companyNameEN', this.companyNameEN);
                formData.append('juristicNumber', this.juristicNumber);
                formData.append('companyPhone', this.companyPhone);
                formData.append('companyEmail', this.companyEmail);
                if(this.companyWebsite){
                    formData.append('companyWebsite', this.companyWebsite);
                }
            }
            formData.append('firstname', this.firstname);
            formData.append('lastname', this.lastname);

            formData.append('addressOne', this.addressOne);
            formData.append('addressTwo', this.addressTwo);
            formData.append('amphure', this.amphure);
            formData.append('provinceId', this.province?.code);
            formData.append('postcode', this.postcode);
            formData.append('countryId', this.countryId?.code);
            // return

           
            this.$http
                .post(url, formData)
                .then((res) => {
                    self.$hideLoader();
                    if(this.$parent.$parent.$parent.currentUser.type === 'juristic_person'){
                        this.mainAddress = {
                            type: this.user.type,
                            firstname: this.firstname,
                            lastname: this.lastname,
                            juristicNumber: this.juristicNumber,
                            companyNameTH: this.companyNameTH,
                            companyNameEN: this.companyNameEN,
                            companyPhone: this.companyPhone,
                            companyEmail: this.companyEmail,
                            addressOne: this.addressOne,
                            addressTwo: this.addressTwo,
                            amphure: this.amphure,
                            provinceId: this.provinceId,
                            province: this.province,
                            provinces: this.province,
                            countryId: this.countryId,
                            postcode: this.postcode,
                            files: this.groupFiles
                        }
                        if(this.companyWebsite){ this.mainAddress.companyWebsite = this.companyWebsite }
                    }else{
                        this.mainAddress = {
                            type: this.user.type,
                            firstname: this.firstname,
                            lastname: this.lastname,
                            addressOne: this.addressOne,
                            addressTwo: this.addressTwo,
                            amphure: this.amphure,
                            provinceId: this.provinceId,
                            province: this.province,
                            provinces: this.province,
                            countryId: this.countryId,
                            postcode: this.postcode,
                            files: this.groupFiles
                        }
                    }
                    
                    if (this.previousStep) {
                        this.nextStep = true
                        this.previousStep = false
                    } else {
                        this.nextStep = false
                        this.previousStep = true
                    }
                }).catch(function (err) {
                    self.errors = err.response.data.errors.data;
                });

        },
        imgFile(filename) {
            var parts = filename.split(".");
            let type = parts[parts.length - 1];
            switch (type.toLowerCase()) {
                case "pdf":
                    return require("@/assets/icon/file-pdf@2x.png");
                case "docx":
                    return require("@/assets/icon/file-doc@2x.png");
                case "png":
                    return require("@/assets/icon/file-png@2x.png");
                case "jpg":
                case "jpeg":
                    return require("@/assets/icon/file-jpg@2x.png");
                default:
                    return "";
            }
        },
        sizeFile(size) {
            var i = Math.floor(Math.log(size) / Math.log(1024));
            return (
                (size / Math.pow(1024, i)).toFixed(2) * 1 +
                " " +
                ["B", "KB", "MB", "GB", "TB"][i]
            );
        },
        remove(i, group = null) {
            if (group) {
                this.groupFiles[group].splice(i, 1);
            } else {
                this.groupFiles.splice(i, 1);
            }
        },
  
    },
    watch: {
        juristicNumber() {
            this.juristicNumber = formatNum.numberic(this.juristicNumber)  
        },
        postcode(){
            this.postcode = formatNum.numberic(this.postcode)  
        },
        amphure(){
            this.amphure = StringFormat.ThaiString(this.amphure)
        },
        companyNameTH(){
            this.companyNameTH = StringFormat.ThaiString( this.companyNameTH)
        },
        companyNameEN(){
            this.companyNameEN = StringFormat.EnglishString( this.companyNameEN)
        }
    }
}
</script>

<style>

</style>