import Vue from "vue";
import VueRouter from "vue-router";

import DashboardLayout from '../layout/DashboardLayout'
// GeneralViews
import NotFound from '../views/NotFoundPage'
import { authenticationService } from "../_services/authentication.service";
import { Role } from "../_helpers/role";
import Dashboard from "../views/home/Dashboard";
import AdminProfile from "../views/admins/profile/Profile";
import AdminProfileEdit from "../views/admins/profile/ProfileEdit";
import LoginPage from "../views/login/LoginPage";
import SignupPage from "../views/login/SignupPage";
import SignupSuccessPage from "../views/login/SignupSuccessPage";
import ForgotPasswordEmail from "../views/forgotpassword/EmailPage";
import ForgotPasswordToken from "../views/forgotpassword/PasswordPage";
import InvoiceSummarize from "../views/payment/InvoiceSummarize"
import UserConfirmed from '../views/users/UserComfirmed'
//Admin
import AdminPayment from "../views/admins/payment/Payment";
import AdminPaymentShow from "../views/admins/payment/PaymentShow";
import AdminApproveSupplier from "../views/admins/approve/supplier/Supplier";
import AdminApproveSupplierApprove from "../views/admins/approve/supplier/SupplierApprove";
import AdminApprovePayment from "../views/admins/approve/payment/Payment";
import AdminApprovePaymentShow from "../views/admins/approve/payment/PaymentApprove";
import AdminApproveCancelPaymentShow from "../views/admins/approve/paymentcancel/PaymentApprove";
import AdminApprovePaymentCancel from "../views/admins/approve/paymentcancel/Payment";
import AdminApprovePaysolution from "../views/admins/supplier/SupplierPaysoAccount";
import AdminSupplier from "../views/admins/supplier/Supplier";
import AdminSupplierShow from "../views/admins/supplier/SupplierShow";
import AdminSupplierCompany from "../views/admins/supplier/SupplierCompany";
import AdminSupplierDocument from "../views/admins/supplier/SupplierDocument";
import AdminSupplierProfile from "../views/admins/supplier/SupplierProfile";
import AdminBuyer from "../views/admins/buyer/Buyer";
import AdminBuyerShow from "../views/admins/buyer/BuyerShow";
import AdminBuyerProfile from "../views/admins/buyer/BuyerProfile";
import AdminBuyerDocument from "../views/admins/buyer/BuyerDocument";
import AdminUser from "../views/admins/user/User";
import AdminUserShow from "../views/admins/user/UserShow";
import AdminUserCreate from "../views/admins/user/UserCreate";
import AdminHelpContact from "../views/admins/help/HelpContact";
import AdminHelpPrivacy from "../views/admins/help/HelpPrivacy";
import AdminHelpTerms from "../views/admins/help/HelpTerms";
import AdminTransactionReport from "../views/admins/report/TransactionReport";
import AdminTransfertoSupplierReport from "../views/admins/report/TransfertoSupplierReport";
import AdminUserByPass from "../views/admins/user/Bypass";

// buyer
import BuyerPayment from "../views/buyers/payment/Payment";
import BuyerPaymentShow from "../views/buyers/payment/PaymentShow";
import BuyerCalendar from "../views/buyers/calendar/Calendar";
import BuyerCalendarCheckout from "../views/buyers/calendar/Checkout";
import BuyerCalendarRenew from "../views/buyers/calendar/CalendarRenew";
import BuyerCredit from "../views/buyers/credit/Credit";
import BuyerCreditEdit from "../views/buyers/credit/CreditEdit";
import BuyerCreditCreate from "../views/buyers/credit/CreditCreate";
import BuyerSupplier from "../views/buyers/supplier/Supplier";
import BuyerJuristic from "../views/buyers/juristic/Juristic";
import BuyerJuristicAddress from "../views/buyers/juristic/Address";
import BuyerProfile from "../views/buyers/profile/Profile";
import BuyerProfileEdit from "../views/buyers/profile/ProfileEdit";
import BuyerRegisterVerify from "../views/buyers/verify/register";
import BuyerRegisterVerifyKYC from "../views/buyers/verify/registerKYC";
import BuyerRegisterVerifyCredit from "../views/buyers/verify/registerCredit";
import BuyerRegisterVerifyCalendar from "../views/buyers/verify/registerCalendar";
import BuyerRegisterVerifyKYCNew from "../views/buyers/verify/registerKYCNew";
import BuyerHelpContact from "../views/buyers/help/HelpContact";
import BuyerHelpPrivacy from "../views/buyers/help/HelpPrivacy";
import BuyerHelpTerms from "../views/buyers/help/HelpTerms";

// supplier
import SupplierPaymentShow from "../views/suppliers/payment/PaymentShow";
import SupplierCreatePayment from "../views/suppliers/payment/CreatePayment";
import SupplierCustomer from "../views/suppliers/customers/Customer";
import SupplierCustomerShow from "../views/suppliers/customers/CustomerShow";
import SupplierUser from "../views/suppliers/users/User";
import SupplierUserCreate from "../views/suppliers/users/UserCreate";
import SupplierUserShow from "../views/suppliers/users/UserShow";
import SupplierProfile from "../views/suppliers/profile/Profile";
import SupplierProfileEdit from "../views/suppliers/profile/ProfileEdit";
import SupplierJuristic from "../views/suppliers/juristic/Juristic";
import SupplierJuristicEdit from "../views/suppliers/juristic/JuristicEdit";
import SupplierJuristicFile from "../views/suppliers/juristic/JuristicFile";
import SupplierJuristicFileEdit from "../views/suppliers/juristic/JuristicFileEdit";
import SupplierJuristicAddresss from "../views/suppliers/juristic/Address";
import SupplierJuristicBank from "../views/suppliers/juristic/JuristicBank";
import SupplierJuristicBankEdit from "../views/suppliers/juristic/JuristicBankEdit";
import SupplierRegisterVerify from "../views/suppliers/verify/register";
import SupplierRegisterVerifyKYC from "../views/suppliers/verify/registerKYC";
import SupplierRegisterVerifyJuristic from "../views/suppliers/verify/registerJuristic";
import SupplierRegisterVerifyJuristicBank from "../views/suppliers/verify/registerJuristicBank";
import SupplierRegisterVerifyJuristicCompany from "../views/suppliers/verify/registerJuristicCompany";
import SupplierRegisterVerifyReject from "../views/suppliers/verify/registerReject";
import SupplierHelpContact from "../views/suppliers/help/HelpContact";
import SupplierHelpPrivacy from "../views/suppliers/help/HelpPrivacy";
import SupplierHelpTerms from "../views/suppliers/help/HelpTerms";


Vue.use(VueRouter)

const lists = [
  {
    name: "dashboard",
    path: "/",
    component: DashboardLayout,
    meta: { requiresAuth: true},
  },
  {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "signup",
    path: "/signup",
    component: SignupPage,
  },
  {
    name: "signup.success",
    path: "/signup/success",
    component: SignupSuccessPage,
  },
  {
    name: "forgotpassword.token",
    path: "/forgot/password/:token",
    component: ForgotPasswordToken,
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    component: ForgotPasswordEmail,
  },
  {
    name: "user.confirm",
    path: "/register/confirm",
    component: UserConfirmed
  },
  {
    name: "payment.paylink",
    path: "/paylink/:id",
    component: InvoiceSummarize,
  }, 
  {
    name: "page.notFound",
    path: "/404",
    component: NotFound,
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: Dashboard,
        meta: Role.admin,
      },
      {
        name: "admin.profile",
        path: "profile",
        component: AdminProfile,
        meta: Role.admin,
      },
      {
        name: "admin.profile.edit",
        path: "profile/edit",
        component: AdminProfileEdit,
        meta: Role.admin,
      },
      {
        name: "admin.payments",
        path: "payments",
        component: AdminPayment,
        meta: Role.admin,
      },
      {
        name: "admin.payments.show",
        path: "payments/:id",
        component: AdminPaymentShow,
        meta: Role.admin,
      },
      {
        name: "admin.approve.suppliers",
        path: "approve/suppliers",
        component: AdminApproveSupplier,
        meta: Role.admin,
      },
      {
        name: "admin.approve.supplier.approve",
        path: "approve/suppliers/:id",
        component: AdminApproveSupplierApprove,
        meta: Role.admin,
      },
      {
        name: "admin.approve.payments",
        path: "approve/payments",
        component: AdminApprovePayment,
        meta: Role.admin,
      },
      {
        name: "admin.approve.payments.show",
        path: "approve/payments/:id",
        component: AdminApprovePaymentShow,
        meta: Role.admin,
      },
      {
        name: "admin.approve.cancellation.request",
        path: "approve/cancellation-request",
        component: AdminApprovePaymentCancel,
        meta: Role.admin,
      },
      {
        name: "admin.approve.cancellation.request.show",
        path: "approve/cancellation-request/:id",
        component: AdminApproveCancelPaymentShow,
        meta: Role.admin,
      },
      {
        name: 'admin.payso.suppliers',
        path: 'approve/paysolution',
        component: AdminApprovePaysolution,
        meta: Role.admin,
      },
      {
        name: "admin.suppliers",
        path: "suppliers",
        component: AdminSupplier,
        meta: Role.admin,
      },
      {
        name: "admin.suppliers.show",
        path: "suppliers/:id",
        component: AdminSupplierShow,
        meta: Role.admin,
      },
      {
        name: "admin.suppliers.company",
        path: "suppliers/:id/company",
        component: AdminSupplierCompany,
        meta: Role.admin,
      },
      {
        name: "admin.suppliers.document",
        path: "suppliers/:id/document",
        component: AdminSupplierDocument,
        meta: Role.admin,
      },
      {
        name: "admin.suppliers.profile",
        path: "suppliers/:id/profile",
        component: AdminSupplierProfile,
        meta: Role.admin,
      },
      {
        name: "admin.buyers",
        path: "buyers",
        component: AdminBuyer,
        meta: Role.admin,
      },
      {
        name: "admin.buyers.show",
        path: "buyers/:id",
        component: AdminBuyerShow,
        meta: Role.admin,
      },
      {
        name: "admin.buyers.profile",
        path: "buyers/:id/profile",
        component: AdminBuyerProfile,
        meta: Role.admin,
      },
      {
        name: "admin.buyers.document",
        path: "buyers/:id/document",
        component: AdminBuyerDocument,
        meta: Role.admin,
      },
      {
        name: "admin.users",
        path: "users",
        component: AdminUser,
        meta: Role.admin,
      },
      {
        name: "admin.users.create",
        path: "users/create",
        component: AdminUserCreate,
        meta: Role.admin,
      },
      {
        name: "admin.users.show",
        path: "users/:id",
        component: AdminUserShow,
        meta: Role.admin,
      },
      {
        name: "admin.users.bypass",
        path: "bypass/:id",
        component: AdminUserByPass,
        meta: Role.admin,
      },
      {
        name: "admin.help.contact-us",
        path: "help/contact-us",
        component: AdminHelpContact,
        meta: Role.admin,
      },
      {
        name: "admin.help.privacy-policy",
        path: "help/privacy-policy",
        component: AdminHelpPrivacy,
        meta: Role.admin,
      },
      {
        name: "admin.help.terms-and-conditions",
        path: "help/terms-and-conditions",
        component: AdminHelpTerms,
        meta: Role.admin,
      },
      {
        name: "admin.report.merchant-transaction",
        path: "report/merchant-transaction/:tranfercode/:supplierId",
        component: AdminTransactionReport,
        meta: Role.admin,
      },
      {
        name: "admin.report.transfer-to-supplier-report",
        path: "report/transfer-to-supplier-report",
        component: AdminTransfertoSupplierReport,
        meta: Role.admin
      },
      // {
      //   name: "admin.report.approvedTransferMoneyToSupplier",
      //   path: "report/approved-transfer-money-to-supplier",
      //   component: AdminReportApprovedTransferMoneyToSupplier,
      //   meta: { requiresAuth: true, authorizeRole: [Role.Admin], authView: 0 }
      // },
    ]
  },
  {
    name: 'buyer.register.kyc.new',
    path: 'kyc',
    path: "/buyer/register-verify/ekyc-new",
    component: BuyerRegisterVerifyKYCNew,
    meta: { requiresAuth: true, authorizeRole: [Role.Buyer,Role.Supplier] },
  },
  {
    path: '/buyer',
    component: DashboardLayout,
    redirect: 'buyer/dashboard',
    children: [
      {
        name: 'buyer.dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: Role.buyer,
      },
      {
        name: "buyer.register.confirm",
        path: "register-confirm/:sign",
        component: UserConfirmed,
        meta: Role.buyer,
      },
      {
        name: "buyer.register.verify",
        path: "register-verify",
        component: BuyerRegisterVerify,
        meta: Role.buyer,
      },
      {
        name: 'buyer.register.kyc',
        path: 'kyc',
        path: "register-verify/ekyc",
        component: BuyerRegisterVerifyKYC,
        meta: Role.buyer,
      },
      {
        name: 'buyer.register.credit',
        path: 'kyc',
        path: "register-verify/credit",
        component: BuyerRegisterVerifyCredit,
        meta: Role.buyer,
      },
      {
        name: 'buyer.register.calendar',
        path: 'kyc',
        path: "register-verify/calendar",
        component: BuyerRegisterVerifyCalendar,
        meta: Role.buyer,
      },
      {
        name: "buyer.payments",
        path: "payments",
        component: BuyerPayment,
        meta: Role.buyer,
      },
      {
        name: "buyer.payments.show",
        path: "payments/:id",
        component: BuyerPaymentShow,
        meta: Role.buyer,
      },
      {
        name: "buyer.calendar",
        path: "calendar",
        component: BuyerCalendar,
        meta: Role.buyer,
      },
      {
        name: "buyer.calendar.checkout",
        path: "calendar/checkout/:paymentId",
        component: BuyerCalendarCheckout,
        meta: Role.buyer,
      },
      {
        name: "buyer.calendar.renew",
        path: "calendar/:id/renew",
        component: BuyerCalendarRenew,
        meta: Role.buyer,
      },
      {
        name: "buyer.calendar.paylink",
        path: "calendar/:id/paylink",
        component: BuyerCalendarRenew,
        meta: Role.buyer,
      },
      {
        name: "buyer.credit",
        path: "credit",
        component: BuyerCredit,
        meta: Role.buyer,
      },
      {
        name: "buyer.credit.create",
        path: "credit/create",
        component: BuyerCreditCreate,
        meta: Role.buyer,
      },
      {
        name: "buyer.credit.edit",
        path: "credit/:id/edit",
        component: BuyerCreditEdit,
        meta: Role.buyer,
      },
      {
        name: "buyer.supplier",
        path: "supplier",
        component: BuyerSupplier,
        meta: Role.buyer,
      },
      {
        name: "buyer.juristic.company",
        path: "juristic/company",
        component: BuyerJuristic,
        meta: Role.buyer,
      },
      {
        name: "buyer.juristic.receipt-address",
        path: "juristic/receipt-address",
        component: BuyerJuristicAddress,
        meta: Role.buyer,
      },
      {
        name: "buyer.profile",
        path: "profile",
        component: BuyerProfile,
        meta: Role.buyer,
      },
      {
        name: "buyer.profile.edit",
        path: "profile/edit",
        component: BuyerProfileEdit,
        meta: Role.buyer,
      },
      {
        name: "buyer.help.contact-us",
        path: "help/contact-us",
        component: BuyerHelpContact,
        meta: Role.user,
      },
      {
        name: "buyer.help.privacy-policy",
        path: "help/privacy-policy",
        component: BuyerHelpPrivacy,
        meta: Role.user,
      },
      {
        name: "buyer.help.terms-and-conditions",
        path: "help/terms-and-conditions",
        component: BuyerHelpTerms,
        meta: Role.user,
      },
    ]
  },
  {
    path: '/supplier',
    component: DashboardLayout,
    redirect: '/supplier/payments',
    children: [
      {
        name: 'supplier.dashboard',
        path: 'supplier/payments',
        component: DashboardLayout,
        meta: Role.supplier,
      },
      {
        name: "supplier.register.verify",
        path: "register-verify",
        component: SupplierRegisterVerify,
        meta: Role.supplier,
      },
      {
        name: 'supplier.register.kyc',
        path: "register-verify/kyc",
        component: SupplierRegisterVerifyKYC,
        meta: Role.supplier,
      },
      {
        name: 'supplier.register.juristic_person',
        path: "register-verify/juristic_person",
        component: SupplierRegisterVerifyJuristic,
        meta: Role.supplier,
      },
      {
        name: 'supplier.register.juristic_person.company',
        path: "register-verify/juristic_person/company",
        component: SupplierRegisterVerifyJuristicCompany,
        meta: Role.supplier,
      },
      {
        name: 'supplier.register.juristic_person.bank',
        path: "register-verify/juristic_person/bank",
        component: SupplierRegisterVerifyJuristicBank,
        meta: Role.supplier,
      },
      {
        name: 'supplier.register.reject',
        path: "register-verify/reject",
        component: SupplierRegisterVerifyReject,
        meta: Role.supplier,
      },
      {
        name: 'supplier.payments',
        path: 'payments',
        component: Dashboard,
        meta: Role.supplier,
      },
      {
        name: 'supplier.create-payment',
        path: 'create-payment',
        component: SupplierCreatePayment,
        meta: Role.supplier,
      },
      {
        name: "supplier.payments.show",
        path: "payments/:id",
        component: SupplierPaymentShow,
        meta: Role.supplier,
      },
      {
        name: "supplier.customers",
        path: "customers",
        component: SupplierCustomer,
        meta: Role.supplier,
      },
      {
        name: "supplier.customers.show",
        path: "customers/:id",
        component: SupplierCustomerShow,
        meta: Role.supplier,
      },
      {
        name: "supplier.users",
        path: "users",
        component: SupplierUser,
        meta: Role.supplier,
      },
      {
        name: "supplier.users.create",
        path: "users/create",
        component: SupplierUserCreate,
        meta: Role.supplier,
      },
      {
        name: "supplier.users.show",
        path: "users/:id",
        component: SupplierUserShow,
        meta: Role.supplier,
      },
      {
        name: "supplier.profile",
        path: "profile",
        component: SupplierProfile,
        meta: Role.supplier,
      },
      {
        name: "supplier.profile.edit",
        path: "profile/edit",
        component: SupplierProfileEdit,
        meta: Role.supplier,
      },
      {
        name: "supplier.juristic.company",
        path: "juristic/company",
        component: SupplierJuristic,
        meta: Role.supplier,
      },
      {
        name: "supplier.juristic.company.edit",
        path: "juristic/company/edit",
        component: SupplierJuristicEdit,
        meta: Role.supplier,
      },
      {
        name: "supplier.juristic.files",
        path: "juristic/files",
        component: SupplierJuristicFile,
        meta: Role.supplier,
      }, // แก้ไข file เอกสารสำหกรับ supplier
      // {
      //   name: "supplier.juristic.files.edit",
      //   path: "juristic/files/edit",
      //   component: SupplierJuristicFileEdit,
      //   meta: Role.supplier,
      // },
      {
        name: "supplier.juristic.receipt-address",
        path: "juristic/receipt-address",
        component: SupplierJuristicAddresss,
        meta: Role.supplier,
      },
      {
        name: "supplier.juristic.bank",
        path: "juristic/bank",
        component: SupplierJuristicBank,
        meta: Role.supplier,
      },
      {
        name: "supplier.juristic.bank.edit",
        path: "juristic/bank/edit",
        component: SupplierJuristicBankEdit,
        meta: Role.supplier,
      },
      {
        name: "supplier.help.contact-us",
        path: "help/contact-us",
        component: SupplierHelpContact,
        meta: Role.user,
      },
      {
        name: "supplier.help.privacy-policy",
        path: "help/privacy-policy",
        component: SupplierHelpPrivacy,
        meta: Role.user,
      },
      {
        name: "supplier.help.terms-and-conditions",
        path: "help/terms-and-conditions",
        component: SupplierHelpTerms,
        meta: Role.user,
      },
    ]
  },
  // otherwise redirect to home
  { path: '*', component: NotFound }
]

// configure router
const router = new VueRouter({
  mode: "history",
  routes: lists, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }// console.log
    }
  }
})


router.beforeEach((to, from, next) => {
  const currentUser = authenticationService.currentUserValue;
  if(to.matched.some( record => record.meta.requiresAuth)){
    //** Check if the user is Authenticated */
    if(!currentUser || !currentUser.token){
      // ** user is not Authenticated
      next({name: 'login'});
    } // ** user is Authenticated
    else{
      if(to.meta.adminAuth){ // ** user is admin
          if(currentUser.role === 'admin'){
            next()
          }else{
            next({path: `${currentUser.role}`})
          }
      }else if(to.meta.buyerAuth && currentUser.role === 'buyer'){ // ** user is buyer
  
          if(currentUser.stepRegister === 1 && to.name !== 'buyer.register.confirm'){
            next({name: 'buyer.register.confirm'})
          }else if(currentUser.stepRegister === 2 && to.name !== 'buyer.register.verify'){
            next({name: 'buyer.register.verify'})
          }else if(
            (currentUser.stepRegister !== 1 && to.name === 'buyer.register.confirm') || 
            (currentUser.stepRegister !== 2 && to.name === 'buyer.register.verify'))
          {
            next({name: 'buyer.dashboard'})
          }
          // Not all condition
          else{
            next()
          }
      }else if(to.meta.supplierAuth && currentUser.role === 'supplier'){  // ** user is supplier

        if(currentUser.stepRegister !== 6  && to.name != 'supplier.register.juristic_person') {
           return next({ name: 'supplier.register.juristic_person' });
         }else if(currentUser.stepRegister === 6 && currentUser.supplierStatusApproveNumber === 0 && to.name !== 'supplier.register.juristic_person'){
            if(currentUser.stepRegister === 6  && to.name === 'supplier.register.juristic_person.company' || to.name === 'supplier.register.juristic_person.company'){
              next()
            }else{
              return next({ name: 'supplier.register.juristic_person' });
            }
         }else{
           next()
         }
      }else{
        next({path: `${currentUser.role}`})
      }
    }
  }else{ //** Allow page to load
    next()
  }
})
export default router;