<template>
  <div
    class="drop-files"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      type="file"
      multiple
      name="fields[]"
      :id="id"
      @change="onChange"
      ref="file"
      accept=".pdf,.jpg,.png,.docx"
    />
    <label class="box-upload rounded" :for="id">
      <div class="d-flex align-items-center justify-content-center">
        <eva-icon name="cloud-upload-outline" fill="#6c757d"></eva-icon>
        <div class="text-secondary px-2">
          <span class="text-primary">Browse </span> or Drag files here
        </div>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "DropFiles",
  props: {
    max: {
      typeof: Number,
      default: 10,
    },
    group: {
      typeof: Boolean,
      default: "all",
    },
  },
  data: function () {
    return {
      id: Math.random().toString(36).substr(2, 9),
      filelist: [],
      filesBrow: [],
    };
  },
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      if (this.group == "all") {
        let lists = [...this.$parent.files, ...this.filelist];
        if (lists.length <= this.max) {
          this.$parent.files = lists;
        }
      }else{
        let lists = [...this.$parent.groupFiles[this.group], ...this.filelist];
        if (lists.length <= this.max) {
          this.$parent.groupFiles[this.group] = lists;
        }
      }
       this.$refs.file.value = null
       this.filelist = []
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
<style lang="scss" scoped>
.drop-files {
  position: relative;
  width: 100%;
  input {
    height: 0;
    top: 0;
    left: 0;
    width: 0;
  }
  .box-upload {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    background-color: #f2f6fb;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-style: dashed;
  }
}
</style>
