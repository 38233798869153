<template>
  <div>
    <div class="row justify-content-center">
      <div v-if="detail" class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            >รายการอนุมัติ >
            <router-link
              class="text-secondary font-weight-bold"
              :to="{ name: 'admin.approve.suppliers' }"
              >รายการอนุมัติ Supplier</router-link
            ></span
          >
          <b> > {{ detail.supplierName }}</b>
          <div class="h3 mt-4">{{ detail.supplierName }}</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4 h-100 position-relative">
            <div class="fix-screen">
              <div class="bg-white">
                <div class="px-3 py-2-1 border-bottom font-weight-bold">
                  รายละเอียด
                </div>
                <div class="px-3 py-2-1">
                  <div class="h6 font-weight-bold mt-1">สถานะ</div>
                  <span
                    :class="
                      'badge badge-pill' +
                        (detail.statusNumber == 0 ? ' badge-warning' : '') +
                        (detail.statusNumber == 2 ? ' badge-danger' : '') +
                        (detail.statusNumber == 1 ? ' badge-success' : '')
                    "
                    >{{ detail.statusName }}</span
                  >
                  <div
                    class="
                    h6
                    font-weight-bold
                    mt-2
                    d-flex
                    justify-content-between
                  "
                  >
                    <div>ตรวจผ่านแล้ว</div>
                    <div>{{ detail.percentCheckData }}%</div>
                  </div>
                  <div class="progress" style="height: 0.5rem">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="'width:' + detail.percentCheckData + '%'"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="h6 font-weight-bold mt-3">ผู้ประสานงาน</div>
                  <span class="text-secondary small">{{
                    detail.coordinatorName
                  }}</span>
                  <div class="h6 font-weight-bold mt-3">อีเมล</div>
                  <span class="text-secondary small">{{
                    detail.coordinatorEmail
                  }}</span>
                  <div class="h6 font-weight-bold mt-3">เบอร์โทรศัพท์</div>
                  <span class="text-secondary small">{{
                    detail.coordinatorTel
                  }}</span>
                  <div class="h6 font-weight-bold mt-3">วันที่อัพเดทข้อมูล</div>
                  <span class="text-secondary small">{{
                    detail.createdAt
                  }}</span>
                </div>
              </div>
              <div
                v-if="
                  detail.statusNumber == 0 &&
                    ($parent.$parent.$parent.currentUser.adminRole == 'owner' ||
                      $parent.$parent.$parent.currentUser.adminRole ==
                        'approver' ||
                      $parent.$parent.$parent.currentUser.adminRole == 'admin')
                "
                class="mt-4"
              >
                <div class="h6 font-weight-bold mt-3">
                  ยืนยันการเปิดบัญชี Supplier
                </div>
                <div class="d-flex ">
                  <div class="w-50 pr-1">
                    <button
                      @click="$bvModal.show('modalRejectAll')"
                      class="
                    btn btn-outline-danger
                    font-weight-bold
                    d-flex flex-fill
                    align-items-center
                    justify-content-center
                    mr-0
                    w-100
                  "
                    >
                      <eva-icon
                        name="close-outline"
                        class="color-danger"
                      ></eva-icon
                      >ไม่อนุมัติ
                    </button>
                  </div>
                  <div class="w-50">
                    <button
                      @click="btnApprove()"
                      :style="!detail.statusButtonApprove ? 'opacity: 0.2' : ''"
                      class="
                    btn 
                    font-weight-bold
                    d-flex flex-fill
                    align-items-center
                    justify-content-center
                    mr-0
                    w-100
                    pl-1
                  "
                      :class="
                        !detail.statusButtonApprove
                          ? 'btn-secondary'
                          : 'btn-success'
                      "
                    >
                      <eva-icon
                        name="checkmark-outline"
                        class="color-white"
                      ></eva-icon
                      >อนุมัติ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="scroll-box">
              <div class="box container-fluid">
                <div class="row ">
                  <div class="col-12 mb-4">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                      >
                        <div class="font-weight-bold">ข้อมูลบริษัท</div>
                        <div>
                          <span
                            :class="
                              'badge badge-pill' +
                                (dataCompany.statusNumber == 0
                                  ? ' badge-warning'
                                  : '') +
                                (dataCompany.statusNumber == 2
                                  ? ' badge-danger'
                                  : '') +
                                (dataCompany.statusNumber == 1
                                  ? ' badge-success'
                                  : '')
                            "
                            >{{ dataCompany.statusName }}</span
                          >
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="d-flex justify-content-between my-3">
                          <div>
                            <div class="p-2 border rounded">
                              <b-form-checkbox v-model="checkAllCompany"
                                >เลือกทั้งหมด</b-form-checkbox
                              >
                            </div>
                          </div>
                          <div>
                            <b-dropdown
                              text="Action"
                              variant="white"
                              class="border rounded"
                            >
                              <b-dropdown-item
                                v-if="
                                  detail.statusNumber == 0 &&
                                    ($parent.$parent.$parent.currentUser
                                      .adminRole == 'owner' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'approver' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'admin')
                                "
                                @click="checkAllCompanyApprove()"
                                ><eva-icon
                                  class="color-success"
                                  name="checkmark-circle-2-outline"
                                ></eva-icon>
                                อนุมัติ</b-dropdown-item
                              >
                              <!-- <b-dropdown-item
                            ><eva-icon
                              class="color-dark"
                              name="download-outline"
                            ></eva-icon>
                            ดาวน์โหลด</b-dropdown-item
                          > -->
                            </b-dropdown>
                          </div>
                        </div>
                        <table class="table">
                          <tr
                            v-for="(item, index) in dataCompany.dataCompanies"
                            :key="index"
                          >
                            <td>
                              <b-form-checkbox
                                v-if="item.companyStatusNumber == 0"
                                v-model="item.check"
                              >
                              </b-form-checkbox>
                            </td>
                            <td class="font-weight-bold">
                              {{ item.topic }}
                            </td>
                            <td>
                              {{ item.value }}
                            </td>
                            <td style="width:50px">
                              <div
                                v-if="item.companyStatusNumber == 0"
                                class="bg-warning icon-box"
                              >
                                <eva-icon
                                  name="clock-outline"
                                  fill="#FFFFFF"
                                ></eva-icon>
                              </div>
                              <div
                                v-if="item.companyStatusNumber == 1"
                                class="bg-success icon-box"
                              >
                                <eva-icon
                                  name="checkmark-outline"
                                  fill="#FFFFFF"
                                ></eva-icon>
                              </div>
                              <div
                                class="bg-danger icon-box"
                                v-if="item.companyStatusNumber == 2"
                              >
                                <eva-icon
                                  name="close-outline"
                                  width="18px"
                                  class="color-white"
                                ></eva-icon>
                              </div>
                            </td>
                            <td class="text-right" style="width:100px">
                              <button
                                v-if="item.companyStatusNumber == 0"
                                class="btn btn-success btn-box mr-3"
                              >
                                <eva-icon
                                  @click="
                                    showApproveModal(item.name, item.topic)
                                  "
                                  class="color-white"
                                  name="checkmark-outline"
                                ></eva-icon>
                              </button>
                              <button
                                v-if="item.companyStatusNumber == 0"
                                @click="
                                  showRejectModal(
                                    item.name,
                                    item.reject,
                                    item.topic
                                  )
                                "
                                class="btn btn-outline-danger btn-box"
                              >
                                <eva-icon
                                  class="color-white"
                                  name="close-outline"
                                ></eva-icon>
                              </button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                      >
                        <div class="font-weight-bold">บัญชีธนาคาร</div>
                        <div>
                          <span
                            :class="
                              'badge badge-pill' +
                                (dataAccountBank.statusNumber == 0
                                  ? ' badge-warning'
                                  : '') +
                                (dataAccountBank.statusNumber == 2
                                  ? ' badge-danger'
                                  : '') +
                                (dataAccountBank.statusNumber == 1
                                  ? ' badge-success'
                                  : '')
                            "
                            >{{ dataAccountBank.statusName }}</span
                          >
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="d-flex justify-content-between my-3">
                          <div>
                            <div class="p-2 border rounded">
                              <b-form-checkbox v-model="checkAllBank"
                                >เลือกทั้งหมด</b-form-checkbox
                              >
                            </div>
                          </div>
                          <div>
                            <b-dropdown
                              text="Action"
                              variant="white"
                              class="border rounded"
                            >
                              <b-dropdown-item
                                v-if="
                                  detail.statusNumber == 0 &&
                                    ($parent.$parent.$parent.currentUser
                                      .adminRole == 'owner' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'approver' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'admin')
                                "
                                @click="checkAllBankApprove()"
                                ><eva-icon
                                  class="color-success"
                                  name="checkmark-circle-2-outline"
                                ></eva-icon>
                                อนุมัติ</b-dropdown-item
                              >
                              <b-dropdown-item
                                @click="downloadAll('documentBookbanks')"
                                ><eva-icon
                                  class="color-dark"
                                  name="download-outline"
                                ></eva-icon>
                                ดาวน์โหลด</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                        <div>
                          <table class="table">
                            <tr
                              v-for="(item, index) in dataAccountBank.dataBanks"
                              :key="index"
                            >
                              <td>
                                <b-form-checkbox
                                  v-if="item.bankAccountStatusNumber == 0"
                                  v-model="item.check"
                                >
                                </b-form-checkbox>
                              </td>
                              <td :colspan="item.documentBookbanks ? 2 : 0">
                                <div class="font-weight-bold">
                                  {{ item.topic }}
                                </div>
                                <div
                                  v-if="item.documentBookbanks"
                                  class="row mt-3"
                                >
                                  <div
                                    v-for="(file,
                                    index) in item.documentBookbanks"
                                    :key="index"
                                    class="col-md-4 mb-3 list-file"
                                  >
                                    <a
                                      :href="file.download"
                                      download
                                      class="
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  border
                                  p-2
                                  rounded
                                "
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      :title="file.documentFilename"
                                    >
                                      <div class="">
                                        <img
                                          height="30"
                                          :src="imgFile(file.documentMime)"
                                        />
                                      </div>
                                      <div class="pl-1 w-70">
                                        <div
                                          class="h-100 flex-column align-items-start"
                                        >
                                          <div class="mb-auto name small">
                                            {{ file.documentFilename }}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td v-if="item.documentBookbanks == undefined">
                                <img
                                  v-if="item.image"
                                  width="26px"
                                  :src="item.image"
                                  alt=""
                                />
                                {{ item.value }}
                              </td>
                              <td style="width:50px">
                                <div
                                  v-if="item.bankAccountStatusNumber == 0"
                                  class="bg-warning icon-box"
                                >
                                  <eva-icon
                                    name="clock-outline"
                                    fill="#FFFFFF"
                                  ></eva-icon>
                                </div>
                                <div
                                  v-if="item.bankAccountStatusNumber == 1"
                                  class="bg-success icon-box"
                                >
                                  <eva-icon
                                    name="checkmark-outline"
                                    fill="#FFFFFF"
                                  ></eva-icon>
                                </div>
                                <div
                                  class="bg-danger icon-box"
                                  v-if="item.bankAccountStatusNumber == 2"
                                >
                                  <eva-icon
                                    name="close-outline"
                                    width="18px"
                                    class="color-white"
                                  ></eva-icon>
                                </div>
                              </td>
                              <td class="text-right" style="width:100px">
                                <button
                                  v-if="item.bankAccountStatusNumber == 0"
                                  class="btn btn-success btn-box mr-3"
                                >
                                  <eva-icon
                                    @click="
                                      showApproveModal(item.name, item.topic)
                                    "
                                    class="color-white"
                                    name="checkmark-outline"
                                  ></eva-icon>
                                </button>
                                <button
                                  v-if="item.bankAccountStatusNumber == 0"
                                  @click="
                                    showRejectModal(
                                      item.name,
                                      item.reject,
                                      item.topic
                                    )
                                  "
                                  class="btn btn-outline-danger btn-box"
                                >
                                  <eva-icon
                                    class="color-white"
                                    name="close-outline"
                                  ></eva-icon>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                      >
                        <div class="font-weight-bold">เอกสารบริษัท</div>
                        <div>
                          <span
                            :class="
                              'badge badge-pill' +
                                (documents.statusNumber == 0
                                  ? ' badge-warning'
                                  : '') +
                                (documents.statusNumber == 2
                                  ? ' badge-danger'
                                  : '') +
                                (documents.statusNumber == 1
                                  ? ' badge-success'
                                  : '')
                            "
                            >{{ documents.statusName }}</span
                          >
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="d-flex justify-content-between my-3">
                          <div>
                            <div class="p-2 border rounded">
                              <b-form-checkbox v-model="checkAllDoc"
                                >เลือกทั้งหมด</b-form-checkbox
                              >
                            </div>
                          </div>
                          <div>
                            <b-dropdown
                              text="Action"
                              variant="white"
                              class="border rounded"
                            >
                              <b-dropdown-item
                                v-if="
                                  detail.statusNumber == 0 &&
                                    ($parent.$parent.$parent.currentUser
                                      .adminRole == 'owner' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'approver' ||
                                      $parent.$parent.$parent.currentUser
                                        .adminRole == 'admin')
                                "
                                @click="checkAllDocApprove()"
                                ><eva-icon
                                  class="color-success"
                                  name="checkmark-circle-2-outline"
                                ></eva-icon>
                                อนุมัติ</b-dropdown-item
                              >
                              <b-dropdown-item @click="downloadAll('documents')"
                                ><eva-icon
                                  class="color-dark"
                                  name="download-outline"
                                ></eva-icon>
                                ดาวน์โหลด</b-dropdown-item
                              >
                            </b-dropdown>
                          </div>
                        </div>
                        <div>
                          <table class="table">
                            <tr
                              v-for="(item, index) in documents.lists"
                              :key="index"
                            >
                              <td>
                                <b-form-checkbox
                                  v-if="item.statusNumber == 0"
                                  v-model="item.check"
                                >
                                </b-form-checkbox>
                              </td>
                              <td :colspan="item.files ? 2 : 0">
                                <div class="font-weight-bold">
                                  {{ item.topic }}
                                </div>
                                <div class="container-fluid">
                                  <div v-if="item.files" class="row mt-3">
                                    <div
                                      v-for="(file, index) in item.files"
                                      :key="index"
                                      class="col-md-4 mb-3 list-file"
                                    >
                                      <a
                                        :href="file.download"
                                        download
                                        class="
                                  d-flex
                                  justify-content-between
                                  align-items-center
                                  border
                                  p-2
                                  rounded
                                "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        :title="file.filename"
                                      >
                                        <div class="">
                                          <img
                                            height="30"
                                            :src="imgFile(file.fileMimeType)"
                                          />
                                        </div>
                                        <div class="pl-1 w-70">
                                          <div
                                            class="h-100 flex-column align-items-start"
                                          >
                                            <div class="mb-auto name small">
                                              <a :href="file.download" download="">
                                    {{ file.filename }}
                                    </a>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td v-if="item.files == undefined">
                                {{ item.value }}
                              </td>
                              <td style="width:50px">
                                <div
                                  v-if="item.statusNumber == 0"
                                  class="bg-warning icon-box"
                                >
                                  <eva-icon
                                    name="clock-outline"
                                    fill="#FFFFFF"
                                  ></eva-icon>
                                </div>
                                <div
                                  v-if="item.statusNumber == 1"
                                  class="bg-success icon-box"
                                >
                                  <eva-icon
                                    name="checkmark-outline"
                                    fill="#FFFFFF"
                                  ></eva-icon>
                                </div>
                                <div
                                  class="bg-danger icon-box"
                                  v-if="item.statusNumber == 2"
                                >
                                  <eva-icon
                                    name="close-outline"
                                    width="18px"
                                    class="color-white"
                                  ></eva-icon>
                                </div>
                              </td>
                              <td style="width:100px" class="text-right ">
                                <button
                                  v-if="item.statusNumber == 0"
                                  class="btn btn-success btn-box mr-3"
                                >
                                  <eva-icon
                                    @click="
                                      showApproveModal(item.name, item.topic)
                                    "
                                    class="color-white"
                                    name="checkmark-outline"
                                  ></eva-icon>
                                </button>
                                <button
                                  v-if="item.statusNumber == 0"
                                  @click="
                                    showRejectModal(
                                      item.name,
                                      item.reject,
                                      item.topic
                                    )
                                  "
                                  class="btn btn-outline-danger btn-box"
                                >
                                  <eva-icon
                                    class="color-white"
                                    name="close-outline"
                                  ></eva-icon>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="bg-white h-100 rounded">
                      <div class="px-3 py-2-1 border-bottom d-flex justify-content-between">
                        <div class="font-weight-bold">ข้อความเพิ่มเติมถึงผู้ขาย</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="px-3 py-2-1">
                      <VueEditor v-model="additionalRequest"></VueEditor>
                    </div>
                    <div class="px-3 py-2-1">
                      <b-button @click="saveAddRequest()" variant="primary">บันทึกข้อความ</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalReject" centered :title="ModalTitle + ' ไม่ถูกต้อง'">
      <p>
        <textarea
          class="form-control"
          placeholder="โปรดระบุสิ่งที่ต้องการแก้ไข"
          v-model="rejectText"
          cols="30"
          rows="5"
        ></textarea>
      </p>
      <template #modal-footer>
        <div class="w-100 m-0">
          <b-button variant="primary" class="w-100" @click="apiReject()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalApprove" centered :title="'อนุมัติ ' + ModalTitle">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="primary" class="" @click="apiApprove()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalRejectAll" centered title="บัญชีไม่ผ่านการอนุมัติ">
      <p>
        <select v-model="selectReject" class="form-control mb-3">
          <option value="">เลือกเหตุผล</option>
          <option
            v-for="(item, index) in reasons"
            :key="index"
            :value="item.reasonRejectId"
            >{{ item.reasonRejectName }}</option
          >
        </select>
        <textarea
          class="form-control"
          placeholder="รายละเอียดเพิ่มเติม"
          v-model="rejectText"
          cols="30"
          rows="5"
        ></textarea>
      </p>
      <template #modal-footer>
        <div class="w-100 m-0">
          <b-button
            :disabled="selectReject == ''"
            variant="primary"
            class="w-100"
            @click="apiRejectAll()"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalbtnApprove" centered title="อนุมัติ Supplier">
      <p class="text-center">อนุมัติ Supplier {{ detail.supplierName }}</p>
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalbtnApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="primary" @click="apiApproveAll()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios  from "axios";
export default {
  name: "ApproveSupplier",
  components:{
    VueEditor
  },
  data() {
    return {
      detail: {},
      dataCompany: {},
      dataAccountBank: {},
      documents: {},
      checkAllCompany: false,
      rejectKey: "",
      rejectTextKey: "",
      rejectText: "",
      ModalTitle: "",
      approveKey: "",
      checkAllBank: false,
      checkAllDoc: false,
      selectReject: "",
      reasons: [],
      additionalRequest: null
    };
  },
  created() {
    this.apiGetSupplier();
    this.apiReason();
  },
  methods: {
    apiGetSupplier() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/supplier/` + this.$route.params.id + `/show`)
        .then((res) => {
          let data = res.data.data;
          this.detail = data.detail;
          this.dataCompany = data.dataCompany;
          this.additionalRequest = data.additionalRequest;
          this.dataCompany.dataCompanies.forEach((c, k) => {
            this.dataCompany.dataCompanies[k].check = false;
          });
          this.dataAccountBank = data.dataAccountBank;
          this.documents = data.documents;
          this.documents.lists.forEach((c, k) => {
            this.documents.lists[k].check = false;
          });
          self.$hideLoader();
        });
    },
    apiReason() {
      let self = this;
      this.$showLoader();
      this.$http.get(`api/admins/supplier/reason/reject`).then((res) => {
        this.reasons = res.data.data;
        self.$hideLoader();
      });
    },
    showRejectModal(rejectKey, rejectTextKey, name) {
      this.rejectKey = rejectKey;
      this.rejectTextKey = rejectTextKey;
      this.rejectText = "";
      this.ModalTitle = name;
      this.showModalReject = true;
      this.$bvModal.show("modalReject");
    },
    showApproveModal(approveKey, title) {
      this.approveKey = approveKey;
      this.ModalTitle = title;
      this.showModalApprove = true;
      this.$bvModal.show("modalApprove");
    },
    apiReject() {
      let self = this;
      this.$showLoader();
      let data = {};
      data[this.rejectKey] = 2;
      data[this.rejectTextKey] = this.rejectText;
      this.$http
        .post(
          `api/admins/supplier/` +
            this.$route.params.id +
            `/update/data/register`,
          data
        )
        .then((res) => {
          self.apiGetSupplier();
          this.$bvModal.hide("modalReject");
          self.$hideLoader();
        });
    },
    apiApprove() {
      let self = this;
      this.$showLoader();
      let data = {};
      data[this.approveKey] = 1;
      this.$http
        .post(
          `api/admins/supplier/` +
            this.$route.params.id +
            `/update/data/register`,
          data
        )
        .then((res) => {
          self.apiGetSupplier();
          this.$bvModal.hide("modalApprove");
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
    checkAllCompanyApprove() {
      let data = {};
      this.dataCompany.dataCompanies.forEach((d) => {
        if (d.check && d.companyStatusNumber == 0) {
          data[d.name] = 1;
        }
      });
      if (Object.keys(data).length) {
        let self = this;
        this.$showLoader();
        this.$http
          .post(
            `api/admins/supplier/` +
              this.$route.params.id +
              `/update/data/register`,
            data
          )
          .then((res) => {
            self.apiGetSupplier();
            this.$bvModal.hide("modalApprove");
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
          });
      }
    },
    checkAllBankApprove() {
      let data = {};
      this.dataAccountBank.dataBanks.forEach((d) => {
        if (d.check && d.bankAccountStatusNumber == 0) {
          data[d.name] = 1;
        }
      });
      if (Object.keys(data).length) {
        let self = this;
        this.$showLoader();
        this.$http
          .post(
            `api/admins/supplier/` +
              this.$route.params.id +
              `/update/data/register`,
            data
          )
          .then((res) => {
            self.apiGetSupplier();
            this.$bvModal.hide("modalApprove");
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
          });
      }
    },
    checkAllDocApprove() {
      let data = {};
      this.documents.lists.forEach((d) => {
        if (d.check && d.statusNumber == 0) {
          data[d.name] = 1;
        }
      });
      if (Object.keys(data).length) {
        let self = this;
        this.$showLoader();
        this.$http
          .post(
            `api/admins/supplier/` +
              this.$route.params.id +
              `/update/data/register`,
            data
          )
          .then((res) => {
            self.apiGetSupplier();
            this.$bvModal.hide("modalApprove");
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
          });
      }
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case ".pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case ".docx":
          return require("@/assets/icon/file-doc@2x.png");
        case ".png":
          return require("@/assets/icon/file-png@2x.png");
        case ".jpg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
    apiRejectAll() {
      let self = this;
      this.$showLoader();
      let data = {
        reasonRejectId: this.selectReject,
        reasonRejectText: this.rejectText,
      };
      this.$http
        .post(
          `api/admins/supplier/` +
            this.$route.params.id +
            `/register/reject/all`,
          data
        )
        .then((res) => {
          this.rejectText = "";
          self.apiGetSupplier();
          self.$parent.$parent.getApiNotify();
          this.$bvModal.hide("modalRejectAll");
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          
        });
    },
    btnApprove() {
      if (this.detail.statusButtonApprove == true) {
        this.$bvModal.show("modalbtnApprove");
      }
    },
    apiApproveAll() {
      let self = this;
      this.$showLoader();
      if(parseInt(this.detail.percentCheckData) === 100){
        this.$http.patch('api/admins/supplier/approved', {supplierId: this.$route.params.id})
        .then((res) => {
          //this.rakmaoPostback(1)
          self.$parent.$parent.getApiNotify();
          this.$bvModal.hide("modalbtnApprove");
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        })
      }else{
        this.$http
        .get(
          `api/admins/supplier/` +
            this.$route.params.id +
            `/register/approve/all`
        )
        .then((res) => {
          this.rejectText = "";
          self.apiGetSupplier();
          self.$parent.$parent.getApiNotify();
          this.$bvModal.hide("modalbtnApprove");
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          //this.rakmaoPostback(1)
        });
      }
      this.$router.push({
            name: "admin.suppliers.show",
            params: { id: this.$route.params.id },
          });
    },
    downloadAll(type = "documentBookbanks") {
      if (type == "documentBookbanks") {
        this.dataAccountBank.dataBanks.forEach((b) => {
          if (b.documentBookbanks) {
            b.documentBookbanks.forEach((f) => {
              window.open(f.download);
            });
          }
        });
      } else {
        this.documents.lists.forEach((l) => {
          if (l.check === true && l.files) {
            l.files.forEach((f) => {
              window.open(f.download);
            });
          }
        });
      }
    },
    saveAddRequest(){
      let additionalRequest
      if(this.additionalRequest.length > 0){
        additionalRequest = this.additionalRequest
      }else{
        additionalRequest = null
      }
      var data = JSON.stringify({
        "supplierId": this.$route.params.id,
        "additionalRequest": additionalRequest
      });
      this.$http.patch('api/admins/supplier/update/additional-request', data)
      .then(response => {
        this.$notifications.notify({
              message: response.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
      })
    },
    rakmaoPostback(status){
      // Rakmao postback
      let data = JSON.stringify({
        "supplier_id":  this.$route.params.id,
        "status_code": status
      });
      let config = {
        method: 'post',
        url: `${process.env.VUE_APP_API_RAKMAO}/api/supplier/status`,
        headers: {
          'apikey': `${process.env.VUE_APP_RAKMAO_API_KEY}`,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  watch: {
    checkAllCompany(val) {
      this.dataCompany.dataCompanies.forEach((c, k) => {
        this.dataCompany.dataCompanies[k].check = val;
      });
    },
    checkAllBank(val) {
      this.dataAccountBank.dataBanks.forEach((c, k) => {
        this.dataAccountBank.dataBanks[k].check = val;
      });
    },
    checkAllDoc(val) {
      this.documents.lists.forEach((c, k) => {
        this.documents.lists[k].check = val;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-file .name {
  font-size: 12px !important;
}
</style>
