<template>
  <div>
    <div class="row justify-content-center">
      <div v-if="showList" class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">จัดการบัตรเครดิต</div>
        <div class="row mt-lg-4 mt-md-3">
          <div
            v-for="(card, index) in cards"
            :key="index"
            class="col-lg-4 col-md-6 mb-4"
          >
            <div
              class="
                bg-white
                h-100
                d-flex
                justify-content-between
                flex-column
                border
                rounded
                mb-3
                p-lg-3
              "
            >
              <div class="d-flex justify-content-between">
                <div class="font-weight-semibold">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'customSwitch' + index"
                      :checked="card.statusDefault"
                      v-model="card.statusDefault"
                      @change="apiDefault(card.creditCardId, $event)"
                    />
                    <label
                      class="custom-control-label"
                      :for="'customSwitch' + index"
                      >Default</label
                    >
                  </div>
                </div>
                <div>
                  <img
                    height="16px"
                    src="../../../assets/card/visa.png"
                    v-if="card.creditCardType == 'VISA'"
                  />
                  <img
                    height="20px"
                    src="../../../assets/card/mastercard.png"
                    v-if="card.creditCardType == 'Master'"
                  />
                  <img
                    height="18px"
                    src="../../../assets/card/jcb.png"
                    v-if="card.creditCardType == 'JCB'"
                  />
                </div>
              </div>
              <div class="font-weight-semibold my-4">
                <span class="mr-3">●●●●</span>
                <span class="mr-3">●●●●</span>
                <span class="mr-3">●●●●</span>
                {{ card.creditCardNumber }}
              </div>
              <div class="mb-2">
                {{ card.corpType === 'Y' ? 'CORP' : 'Normal' }} : {{ card.feeRate }}%
              </div>
              <div class="d-flex justify-content-between">
                <div class="">{{ card.creditCardName }}</div>

                <div class="f-1rem">
                  <button
                    @click="
                      $router.push({
                        name: 'buyer.credit.edit',
                        params: { id: card.creditCardId },
                      })
                    "
                    class="
                      btn btn-outline-secondary btn-small
                      min-auto
                      mr-3
                    "
                  >
                    <eva-icon
                      name="edit-outline"
                      class="dark cursor-pointer"
                      width="20px"
                    ></eva-icon>
                    แก้ไข
                  </button>
                  <button
                    @click="showModalDelete(card.creditCardId)"
                    class="btn btn-outline-danger btn-small min-auto"
                    :disabled="!card.canDelete"
                  >
                    <eva-icon
                      name="trash-2-outline"
                      class="danger cursor-pointer"
                      fill="#6c757d"
                      width="20px"
                    ></eva-icon>
                    ลบ
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mb-4">
            <div
              @click="$router.push({ name: 'buyer.credit.create' })"
              style="min-height: 138px"
              class="
                bg-white
                add-credit
                border
                d-flex
                justify-content-center
                flex-column
                rounded
                mb-3
              "
            >
              <div class="w-100 text-primary text-center">
                <div class="bg-primary rounded-circle d-initial">
                  <eva-icon name="plus-outline" class="color-white"></eva-icon>
                </div>
                <div class="h5 mt-2">เพิ่มบัตร</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered title="ต้องการลบบัตร">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="danger" class="" @click="apiRemove()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "CreditCard",
  data() {
    return {
      cards: [],
      creditCardId: null,
      showList: true,
      typeCreditCard: "",
      cardNumber: "",
      fullname: "",
      limitMoney: "",
      expiredDate: "",
      ccv: "",
      formCredit: "",
      deleteId: null,
    };
  },
  created() {
    this.apiGetCredit();
  },
  methods: {
    apiGetCredit() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/creditcard/index`)
        .then((res) => {
          this.cards = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiCreateCredit() {
      let self = this;
      this.$showLoader();

      this.$http
        .post(`api/buyers/creditcard/new/store`, {
          typeCreditCard: this.typeCreditCard,
          cardNumber: this.cardNumber,
          fullname: this.fullname,
          limitMoney: this.limitMoney,
          expiredDate: this.expiredDate,
          ccv: this.ccv,
        })
        .then((res) => {
          self.apiGetCredit();
          self.showList = true;
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          self.typeCreditCard = "";
          self.cardNumber = "";
          self.fullname = "";
          self.limitMoney = "";
          self.expiredDate = "";
          self.ccv = "";
        });
    },
    showModalDelete(creditCardId) {
      this.deleteId = creditCardId;
      this.$bvModal.show("modalApprove");
    },
    newWebCreate() {
      window.open("/buyer/credit/create", "popup", "width=1280,height=600");
    },
    apiRemove() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/paysolution/delete`, {
          creditCardId: this.deleteId,
        })
        .then((res) => {
          self.apiGetCredit();
          self.$bvModal.hide("modalApprove");
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
    apiDefault(id, e) {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/paysolution/default`, {
          creditCardId: id,
        })
        .then((res) => {
          this.apiGetCredit();
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((e) => {
          self.apiGetCredit();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-credit {
  cursor: pointer;
  height: 100%;
}
</style>
