export default [

  {
    name: 'name',
    title: 'ชื่อ',
    sortField: 'name',
    dataClass: "",
    titleClass: "",
  },

  {
    name: 'email',
    title: 'อีเมล',
    sortField: 'email',
    dataClass: "",
    titleClass: "",
  },
  {
    title: 'โทร',
    name: 'phone',
    dataClass: "",
    titleClass: "",
  },
 
  {
    name: 'actions',
    dataClass: "",
    titleClass: "",
  }
 
]