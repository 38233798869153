<template>
  <div class="d-flex h-100 align-items-center justify-content-center box-signup-type">
    <div v-if="signupType == ''" class="row justify-content-center w-100">
      <div class="col-12">
        <div class="text-center mb-3">
          <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
        </div>
        <div class="title text-center mb-5">สมัครสมาชิก</div>
      </div>
      <div class="col-lg-9">
         <div class="row justify-content-center">
          <div class="col-lg-5 col-md-5 text-center">
          <div>
            <img 
              class="img-fluid mb-5"
              src="./../../assets/image/buyer.png"
              alt=""
            />
          </div>
          <button @click="signupType = 'buyer'" class="btn btn-primary btn-lg mb-5 w-100">
            ผู้ซื้อ (Buyer)
          </button>
        </div>
        <div class="col-1 text-center d-flex justify-content-center">
          <div class="h-100 border-left w-0"></div>
        </div>
        <div class="col-lg-5 col-md-5 text-center">
          <div>
            <img
              class="img-fluid mb-5"
              src="./../../assets/image/supplier.png"
              alt=""
            />
          </div>
          <button @click="signupType = 'supplier'" class="btn btn-primary btn-lg mb-5 w-100">
            ผู้ขาย (Supplier)
          </button>
        </div>
         </div>
      </div>
      
    </div>
    <div v-else class="w-100 row justify-content-center">
      <div class="col-xl-4 col-lg-5 col-md-8">
        <Buyer v-if="signupType == 'buyer'" />
        <Supplier v-if="signupType == 'supplier'" />
      </div>
    </div>
  </div>
</template>

<script>
import Buyer from "./../../components/signup/Buyer";
import Supplier from "./../../components/signup/Supplier";
export default {
  components: {
    Buyer,
    Supplier
  },
  data() {
    return {
      signupType: "",
      url: `${process.env.VUE_APP_URL}`,
    };
  },
};
</script>
