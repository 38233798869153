import axios from "axios";
import Vue from 'vue'
import { authenticationService } from "../_services/authentication.service";

const Instance = createInstance(process.env.VUE_APP_APISERVER);

function createInstance(baseURL) {
    const authHeader = authenticationService.currentUserValue
        ? { Authorization: "Bearer " + authenticationService.currentUserValue.token }
        : {};
    const api = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            ...authHeader,
            'Access-Control-Allow-Origin': true,
            useCredentails: true,
            apikey: process.env.VUE_APP_API_KEY
        }
    });
    api.interceptors.response.use((response) => response, (error) => {
        if (error.response) {
            let err = error.response
            let status = err.status
            let data = err.data.errors
            if ([401, 403].indexOf(status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                location.reload(true);
            } else if (status >= 400 && status < 500 && data) {
                if(Object.keys(data.data).length === 0){
                    Vue.prototype.$notifications.notify(
                    {
                        message: data.message,
                        icon: 'alert-triangle',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'warning'
                    })
                }
                
            } else {
                Vue.prototype.$notifications.notify(
                    {
                        message: error.toString(),
                        icon: 'close-circle',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'danger'
                    })
            }
        } else {
            Vue.prototype.$notifications.notify(
                {
                    message: error.toString(),
                    icon: 'close-circle',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'danger'
                })
        }
        Vue.prototype.$hideLoader();
        throw error;
    });
    return api
}

export default Instance
