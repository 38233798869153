<template>
  <DatePicker
    v-model="date"
    :locale="lang"
    format="dd/MM/yyyy"
    :min-date="minDate"
    :max-date="endDate"
    :attributes="createMarking(holidays)"
    mode="date"
    trim-weeks
    @input="onInput"
  >
    <template v-slot="{ inputValue, inputEvents }">
       <div class="input-group">
          <input class="form-control border-right-0 bg-white"
          readonly
          :value="renderInputValue(inputValue)"
          placeholder="dd/mm/yyyy"
          @focus="inputEvents.focusin($event)">
          <span class="input-group-append bg-white border-left-0 border-left-radius-0">
              <span class="input-group-text bg-transparent text-secondary">
                <eva-icon
                  name="calendar-outline"
                  width="20px"
                  height="20px"
                  fill="#6a7b90"
                  ></eva-icon>
              </span>
          </span>
       </div>
    </template>
  </DatePicker>
</template>

<script>
import { DatePicker } from "v-calendar";
import { parse, format, } from 'date-fns'
const lang = "en";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      lang,
      minDate: "",
      endDate: "",
      holidays: null,
      disabledDate: null,
    };
  },
  create() {
    this.apiHolidays();
  },
  methods: {
    renderInputValue(value) {
     if (!value) return;

     const date = parse(value, 'MM/dd/yyyy', new Date())

     return format(date, 'dd/MM/yyyy');
    },
    onInput(date) {
      this.$emit("input", date);
    },
    apiHolidays() {
      this.$http.get(`api/calendar`).then((res) => {
        let holidaysList = Object.values(res.data.holidays);
        this.minDate = res.data.startDate;
        this.endDate = res.data.endDate;
        if (res.data.disableDate) {
          this.disabledDate = res.data.disableDate.date;
        }
        let holidays = holidaysList.map(({ date }) => {
          return date;
        });
        this.holidays = holidays;
      });
    },
    createMarking(holidays) {
      return [
        {
          key: "today",
          highlight: {
            style: {
              background: "#E6E8FA",
            },
            contentStyle: {
              color: "#1A32B1",
            },
          },
          dates: new Date(),
        },
        {
          dot: "red",
          dates: holidays,
        },
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.date = this.value;
      },
    },
  },
  mounted() {
    this.apiHolidays();
  },
};
</script>