<template>
    <div>
        <div class="row justify-content-center">
            <div v-if="nextStep === false" class="col-lg-11 col-md-12">
                <!-- <PaymentInfo :errors="errors"  @createdPayment="createPayment"></PaymentInfo> -->
                <div class="page-name mt-lg-4 mt-md-3 h3">สร้างรายการเรียกเก็บเงิน</div>
                <div class="row mt-lg-4 mb-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="mb-2">
                            <h5 class="px-1 font-weight-semibold">เอกสารเรียกเก็บเงิน</h5>
                            <div class="px-1 py-2 text-secondary small line-sub">กรุณากรอกเลขที่เอกสารการเรียกเก็บเงิน
                                และแนบไฟล์เอกสารทุกครั้ง เพื่อความรวดเร็วในการตรวจสอบ</div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                        <div class="bg-white h-100 rounded pt-3 pl-3 pr-3">
                            <div class="mb-3">
                                <label>Payment Reference</label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="text" class="form-control" v-model="paymentReference"
                                            placeholder="ใส่เลขที่ invoice หรือ เลขที่เอกสาร"
                                            :class="{ 'is-invalid': errors.paymentReference }" />
                                        <div v-if="errors.paymentReference" class="invalid-feedback d-block">
                                            {{ errors.paymentReference }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <label>อัปโหลดเอกสาร</label>
                                    </div>
                                    <div class="col-lg-6">
                                        <drop-files :class="{ 'is-invalid': errors.files }" v-model="files"></drop-files>
                                        <div class="mt-2 mb-3 small text-secondary text-bottom-file">
                                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB สูงสุด 10 ไฟล์
                                        </div>
                                        <div v-if="errors.files" class="invalid-feedback d-block">
                                            {{ errors.files }}
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div v-for="(file, index) in files" :key="index" class="col-12 mb-3 list-file">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <img height="60" :src="imgFile(file.name)" />
                                                    </div>
                                                    <div class="pl-3 w-70">
                                                        <div class="h-100 flex-column align-items-start">
                                                            <div class="mb-auto name">
                                                                {{ file.name }}
                                                            </div>
                                                            <small class="text-secondary size">{{ sizeFile(file.size)
                                                            }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="h-100">
                                                        <div class="d-flex align-items-center h-100">
                                                            <eva-icon @click="remove(files.indexOf(file))"
                                                                name="close-circle" class="primary cursor-pointer"
                                                                fill="#6c757d"></eva-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-lg-4 mb-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="mb-2">
                            <h5 class="px-1 font-weight-semibold">ตั้งค่าการชำระเงิน</h5>
                            <div class="px-1 py-2 text-secondary small line-sub">
                                โปรดระบุจำนวนเงินให้ตรงกับ Invoice เป็นราคาสุทธิรวม VAT 7%
                                กรณี มียอดหัก ณ.ที่จ่าย ให้ระบุจำนวนเงินเป็นยอดที่หัก ณ.ที่จ่าย แล้ว<br><span
                                    class="text-danger">รายการเรียกเก็บเงินมีระยะเวลาสูงสุด 60 วัน
                                    นับจากวันสร้างรายการ</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                        <div class="bg-white h-100 rounded p-lg-3">
                            <div class="">
                                <div class="row">
                                    <div class="col-lg-6 mb-3">
                                        <label>จำนวนเงิน</label>
                                        <div class="input-group">
                                            <input class="form-control border-right-0" type="number" placeholder="00.00"
                                                v-model="price" :class="{ 'is-invalid': errors.price }">
                                            <span class="input-group-append bg-white border-left-0">
                                                <span class="input-group-text bg-transparent" style="color:#6a7b90">
                                                    ฿</span>
                                            </span>
                                        </div>
                                        <div v-if="errors.price" class="invalid-feedback d-block"> {{ errors.price }} </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="col-lg-6 mb-3">
                                        <label>วันที่หมดอายุเอกสารเรียกเก็บเงิน</label>
                                        <Calendar v-model="invoiceExpire" :class="{ 'is-invalid': errors.invoiceExpire }">
                                        </Calendar>
                                        <div v-if="errors.invoiceExpire" class="invalid-feedback d-block">
                                            {{ errors.invoiceExpire }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-lg-4 mb-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="mb-2">
                            <h5 class="px-1 font-weight-semibold">วิธีการชำระเงิน</h5>
                            <div class="px-1 py-2 text-secondary small line-sub">
                                เลือกสัดส่วนการชำระค่าธรรมเนียมระหว่างผู้ซื้อและผู้ขาย
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                        <div class="bg-white h-100 rounded p-lg-3">
                            <div class="mb-2">
                                <label>การชำระค่าธรรมเนียมของผู้ขาย</label>
                                <div class="mt-1">
                                    <b-form-radio-group label="Individual radios" v-slot="{ ariaDescribedby }"
                                        v-model="feePricePercentRadio">
                                        <b-form-radio :aria-describedby="ariaDescribedby" value="0"
                                            name="feePricePercentRadio" class="mb-2">
                                            <span class="font-weight-normal text-dark">ไม่ชำระค่าธรรมเนียม</span>
                                        </b-form-radio>
                                        <b-form-radio :aria-describedby="ariaDescribedby" value="100"
                                            name="feePricePercentRadio" class="mb-2">
                                            <span class="font-weight-normal text-dark">ชำระค่าธรรมเนียมเองทั้งหมด</span>
                                        </b-form-radio>
                                        <b-form-radio :aria-describedby="ariaDescribedby" value="custom"
                                            name="feePricePercentRadio" class="mb-2">
                                            <span class="font-weight-normal text-dark">ต้องการชำระค่าธรรมเนียมบางส่วน</span>
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <div class="mb-3" v-if="feePricePercentRadio === true || feePricePercentRadio === 'custom'">
                                <label>เลือกสัดส่วนรับชำระค่าธรรมเนียม</label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <b-form-select v-model="feePricePercentSelected" :options="feePricePercentOptions"
                                            :class="{ 'is-invalid': errors.supplierFeePricePercent }">
                                        </b-form-select>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="text-primary font-weight-semi small">
                                    **ค่าธรรมเนียมขึ้นอยู่กับการเลือกวิธีการชำระเงินของผู้ซื้อ เริ่มต้น 1.20%-2.70%</div>
                                <div v-if="errors.supplierFeePricePercent" class="invalid-feedback d-block">
                                    {{ errors.supplierFeePricePercent }}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-lg-4 mb-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="mb-2">
                            <h5 class="px-1 font-weight-semibold">ข้อมูล Buyer</h5>
                            <div class="px-1 py-2 text-secondary small line-sub">ข้อมูลผู้ซื้อที่ต้องการเรียกเก็บเงิน</div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8">
                        <div class="bg-white rounded p-lg-3">
                            <div class="">
                                <label>อีเมลผู้ซื้อที่ต้องการเรียกเก็บเงิน</label>
                                <div class="row">
                                    <div v-if="projects" class="col-md-6">
                                        <v-select v-model="buyerEmail" :options="buyerOptions" :class="{
                                            'is-invalid':  errors.buyerEmail
                                             
                                        }"></v-select>
                                        <div v-if="errors.buyerEmail" class="invalid-feedback d-block">
                                            {{ errors.buyerEmail }}
                                        </div>
                                    </div>
                                    <div v-else class="col-md-6">
                                        <input type="email" placeholder="buyer@paysoon.net" class="form-control"
                                            v-model="buyerEmail" :class="{ 'is-invalid': errors.buyerEmail }" />
                                        <div v-if="errors.buyerEmail" class="invalid-feedback d-block">
                                            {{ errors.buyerEmail }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 text-right">
                            <button class="btn btn-primary" @click="createPayment">
                                ยืนยัน
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="nextStep === true" class="col-lg-10 col-md-10 col-sm-12">
                <Summarize :paymentsData="paymentsData" @changedStep="changeStep"></Summarize>
            </div>
        </div>
    </div>
</template>
<script>
import DropFiles from "src/components/Files/DropFiles";
import Calendar from "src/components/Calendar/Calendar";
import Summarize from "@/views/suppliers/payment/components/Summarize";


export default {
    name: "CreatePayment",
    components: {
        Calendar,
        "drop-files": DropFiles,
        Summarize
    },
    data() {
        return {
            nextStep: false,
            errors: {},
            paymentsData: {},
            supplierId: this.$parent.$parent.currentUser.supplierId ?? this.$parent.$parent.currentUser.supplierRefId,
            price: null,
            paymentReference: "",
            buyerEmail: null,
            files: [],
            invoiceExpire: "",
            supplierFeePricePercent: "",
            feePricePercentRadio: null,
            feePricePercentSelected: null,
            feePricePercentOptions: [
                { value: 10, text: "10%" },
                { value: 20, text: "20%" },
                { value: 30, text: "30%" },
                { value: 40, text: "40%" },
                { value: 50, text: "50%" },
                { value: 60, text: "60%" },
                { value: 70, text: "70%" },
                { value: 80, text: "80%" },
                { value: 90, text: "90%" }
            ],
            buyerOptions: [],
            projects: false,
        };
    },
    created() {
        this.apiGetBuyerProject()
    },
    methods: {
        apiGetBuyerProject() {
            const user = this.$parent.$parent.$parent.currentUser
            if(user.userProject){
                this.$http.get(`/api/suppliers/customer/${user.userProject}/${user.supplierId}`).then(res => {
                    const buyerResp = res.data.data
                    this.projects = true
                    this.buyerList = buyerResp
                    this.buyerOptions = buyerResp.map( elm => {
                        return {
                            label: `${elm.name} (${elm.email})`,
                            email: elm.email
                        }
                    })
                }).catch(err => {
                    this.errors = err.response.data.errors.data;
                })
            }


            // this.$http.get()
        },
        createPayment() {
            let formData = new FormData();
            const url = "api/payment/validate-create-invoice";
            let buyerMail = typeof this.buyerEmail === 'object'? this.buyerEmail.email: this.buyerEmail
            formData.append('supplierId', this.supplierId)
            formData.append('buyerEmail', buyerMail)
            formData.append('price', this.price)
            formData.append('paymentReference', this.paymentReference)
            formData.append('invoiceExpire', this.invoiceExpire)
            formData.append('supplierFeePricePercent', this.supplierFeePricePercent)
            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[]', this.files[i]);
            }
            this.$http
                .post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    let data = res.data.data;
                    this.errors = {}
                    this.nextStep = true
                    this.paymentsData = {
                        supplierId: this.supplierId,
                        price: data.price,
                        paymentReference: data.paymentReference,
                        buyerEmail: data.buyerEmail,
                        invoiceExpire: data.invoiceExpire,
                        supplierFeeRate: data.supplierFeeRate,
                        buyerFeeRate: data.buyerFeeRate,
                        fileList: data.fileList,
                    }
                })
                .catch((err) => { this.errors = err.response.data.errors.data; })
        },
        imgFile(filename) {
            var parts = filename.split(".");
            let type = parts[parts.length - 1];
            switch (type.toLowerCase()) {
                case "pdf":
                    return require("@/assets/icon/file-pdf@2x.png");
                case "docx":
                    return require("@/assets/icon/file-doc@2x.png");
                case "png":
                    return require("@/assets/icon/file-png@2x.png");
                case "jpg":
                case "jpeg":
                    return require("@/assets/icon/file-jpg@2x.png");
                default:
                    return "";
            }
        },
        sizeFile(size) {
            var i = Math.floor(Math.log(size) / Math.log(1024));
            return (
                (size / Math.pow(1024, i)).toFixed(2) * 1 +
                " " + ["B", "KB", "MB", "GB", "TB"][i]
            );
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        changeStep(value) {
            this.nextStep = value.step;
            this.errors = value.errors;
        }
    },
    watch: {
        paymentReference(value) {
            this.paymentReference = value.replace(/[^a-zA-Z0-9-]/g, "");
        },
        feePricePercentRadio(value) {
            this.supplierFeePricePercent = parseInt(value)
        },
        feePricePercentSelected(value) {
            this.supplierFeePricePercent = value
        }
    },
};
</script>
<style lang="scss">
.btn-group-toggle {
    width: 100% !important;

    label {
        text-align: left;
        width: 100% !important;
        border-radius: 4px !important;
        margin-bottom: 1rem !important;
    }
}

.policy {
    margin: 15px 20px;
}

.modal-footer {
    border-top: 1px solid #DDE2E8 !important;
}

a:hover,
a:focus {
    color: #1A32B1
}
</style>
