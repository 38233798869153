<template>
  <nav class="navbar navbar-expand-lg px-1 px-lg-0">
    <div class="container-fluid">
      <button
        type="button"
        class="navbar-toggler navbar-toggler-left"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="app-logo">
        <img
          class="d-block pl-2"
          height="40px"
          src="./../assets/logo_paysoon-white.png"
          alt=""
        />
      </div>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <base-dropdown tag="li" class="notify">
            <template slot="title">
              <eva-icon
                name="bell-outline"
                animation="pulse"
                fill="white"
              ></eva-icon>
              <span v-if="notify.badge" class="notification">{{
                notify.badge
              }}</span>
            </template>
            <div class="scroll-noti">
              <div
                v-for="(item, index) in notify.notifications"
                :key="index"
                class="dropdown-item px-3 py-2"
                href="#"
                @click="_readNotify(item.id, null, item.readed)"
              >
                <div class="d-flex align-items-stretch">
                  <div class="pt-1">
                    <div
                      class="rounded-circle text-white notify-icon"
                      :class="imageNotify(item.typeImage).class"
                    >
                      <eva-icon
                        v-if="item.typeImage == 'approve_new_supplier'"
                        name="briefcase-outline"
                        width="20px"
                        height="20px"
                        class="color-white"
                      ></eva-icon>
                      <eva-icon
                        v-if="item.typeImage == 'approve_supplier'"
                        name="file-text-outline"
                        width="20px"
                        height="20px"
                        class="color-white"
                      ></eva-icon>
                      <img
                        v-if="item.typeImage == 'approve_payment'"
                        style="margin-top:2px"
                        src="./../assets/icon/approve_white.png"
                        width="20px"
                        height="20px"
                        alt=""
                      />
                      <div
                        v-if="item.typeImage == 'approve_payment_reject'"
                        class="text-white"
                      >
                        <i
                          class="eva"
                          style="display: inline-block;left:4px;top:-4px"
                        >
                          <svg
                            version="1.1"
                            id="approve1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="20px"
                            x="0px"
                            y="0px"
                            viewBox="0 0 48 48"
                            style="enable-background:new 0 0 48 48;fill: #ffffff;"
                            xml:space="preserve"
                          >
                            <path
                              class="st0"
                              d="M34.9,29h-5.2v-0.8c0-1.4-0.8-2.6-2-3.3c0.5-2.9,1.6-5.7,3.2-8.3c1.1-1.9,1.3-4,0.8-6.1
                      c-0.6-2.1-1.9-3.8-3.8-4.9c-3.9-2.2-8.8-0.9-11,3c-1.5,2.7-1.4,6.1,0.4,8.6c1.5,2.3,2.4,5,2.8,7.7c-1.2,0.6-2,1.9-2,3.3V29h-5.2
                      C9.6,29,7,31.6,7,34.9v2.3c0,0.8,0.7,1.4,1.5,1.4h0.8v0.8c0,2,1.6,3.7,3.7,3.7h22.1c2,0,3.7-1.6,3.7-3.7v-0.8h0.8
                      c0.8,0,1.5-0.6,1.5-1.4v-2.3C40.8,31.6,38.1,29,34.9,29z M35.7,38.6v0.8c0,0.4-0.3,0.8-0.8,0.8H12.9c-0.4,0-0.8-0.3-0.8-0.8v-0.8
                      H35.7z M28.4,15.3c-1.8,2.9-2.9,6-3.5,9.3h-1.9c-0.4-3.1-1.6-6.2-3.3-8.9l0,0c-1.6-2.3-1-5.6,1.3-7.2c2.3-1.6,5.6-1,7.2,1.3
                      C29.2,11.4,29.3,13.6,28.4,15.3z M20.9,28.3c0-0.4,0.3-0.8,0.8-0.8h4.4c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5
                      V29h-5.9V28.3z M37.9,34.9l0,0.8h-28v-0.8c0-1.6,1.3-3,3-3h22.1C36.5,31.9,37.9,33.2,37.9,34.9z"
                            />
                          </svg>
                        </i>
                      </div>
                      <div v-if="user.defaultSetting == 1">
                        <eva-icon
                          v-if="
                            item.typeImage == 'payment_fail' ||
                              item.typeImage == 'payment_reject'
                          "
                          :name="imageNotify(item.typeImage).name"
                          width="30px"
                          height="30px"
                          class="color-danger bg-white"
                        ></eva-icon>
                        <i
                          v-else-if="
                            item.typeImage == 'payment_approve' ||
                              item.typeImage == 'payment_process'
                          "
                          class="eva"
                          style="display: inline-block;"
                          ><svg
                            id="payment"
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            viewBox="0 0 48 48"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2413"
                                  data-name="Rectangle 2413"
                                  width="48"
                                  height="48"
                                  transform="translate(161 2705)"
                                ></rect>
                              </clipPath>
                            </defs>
                            <g
                              id="payment-2"
                              data-name="payment"
                              transform="translate(-161 -2705)"
                              clip-path="url(#clip-path)"
                            >
                              <path
                                id="wallet"
                                d="M30.425,0H7.8a1.561,1.561,0,0,0-1.56,1.56v3.9H4.681a1.561,1.561,0,0,0-1.56,1.56v3.9H1.56A1.561,1.561,0,0,0,0,12.482v23.4a1.561,1.561,0,0,0,1.56,1.56H31.205a6.248,6.248,0,0,0,6.241-6.241V7.021A7.03,7.03,0,0,0,30.425,0ZM6.241,8.581H28.085v2.34H6.241ZM3.121,22.624H7.8a1.56,1.56,0,1,1,0,3.121H3.121Zm31.205,8.581a3.124,3.124,0,0,1-3.12,3.12H3.121V28.865H7.8a4.681,4.681,0,0,0,0-9.362H3.121V14.042h27.3a6.985,6.985,0,0,0,3.9-1.186Zm-3.12-20.362V7.021a1.561,1.561,0,0,0-1.56-1.56H9.362V3.121H30.425a3.9,3.9,0,0,1,.78,7.722Zm0,0"
                                transform="translate(166.555 2710.373)"
                              ></path>
                            </g></svg
                        ></i>
                        <eva-icon
                          v-else
                          :name="imageNotify(item.typeImage).name"
                          width="20px"
                          height="20px"
                          class="color-white"
                        ></eva-icon>
                      </div>
                      <div v-if="user.defaultSetting == 0 && user.role != 'admin'">
                        <div
                          v-if="item.typeImage == 'payment_new'"
                          class="text-white"
                        ></div>
                        <eva-icon
                          v-else-if="
                            item.typeImage == 'payment_fail' ||
                              item.typeImage == 'payment_reject'
                          "
                          :name="imageNotify(item.typeImage).name"
                          width="30px"
                          height="30px"
                          class="color-danger bg-white"
                        ></eva-icon>
                        <i
                          v-if="item.typeImage == 'payment_new'"
                          class="eva"
                          style="display: inline-block;"
                          ><svg
                            id="payment"
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            viewBox="0 0 48 48"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2413"
                                  data-name="Rectangle 2413"
                                  width="48"
                                  height="48"
                                  transform="translate(161 2705)"
                                ></rect>
                              </clipPath>
                            </defs>
                            <g
                              id="payment-2"
                              data-name="payment"
                              transform="translate(-161 -2705)"
                              clip-path="url(#clip-path)"
                            >
                              <path
                                id="wallet"
                                d="M30.425,0H7.8a1.561,1.561,0,0,0-1.56,1.56v3.9H4.681a1.561,1.561,0,0,0-1.56,1.56v3.9H1.56A1.561,1.561,0,0,0,0,12.482v23.4a1.561,1.561,0,0,0,1.56,1.56H31.205a6.248,6.248,0,0,0,6.241-6.241V7.021A7.03,7.03,0,0,0,30.425,0ZM6.241,8.581H28.085v2.34H6.241ZM3.121,22.624H7.8a1.56,1.56,0,1,1,0,3.121H3.121Zm31.205,8.581a3.124,3.124,0,0,1-3.12,3.12H3.121V28.865H7.8a4.681,4.681,0,0,0,0-9.362H3.121V14.042h27.3a6.985,6.985,0,0,0,3.9-1.186Zm-3.12-20.362V7.021a1.561,1.561,0,0,0-1.56-1.56H9.362V3.121H30.425a3.9,3.9,0,0,1,.78,7.722Zm0,0"
                                transform="translate(166.555 2710.373)"
                              ></path>
                            </g></svg
                        ></i>
                        <eva-icon
                          v-else
                          :name="imageNotify(item.typeImage).name"
                          width="20px"
                          height="20px"
                          class="color-white"
                        ></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="px-2 flex-fill">
                    <div>
                      <b>{{ item.topic }}</b>
                    </div>
                    <div class="message">
                      {{ item.message }}
                      <a
                        v-if="
                          item.typeImage == 'approve_new_supplier' ||
                            item.typeImage == 'approve_supplier'
                        "
                        class="font-weight-bold"
                        href="javascript:;"
                        @click="
                          _readNotify(item.id, {
                            name: 'admin.approve.supplier.approve',
                            params: { id: item.supplierId },
                          })
                        "
                        >{{
                          item.typeImage == "approve_new_supplier"
                            ? "ดูรายละเอียด"
                            : "ตรวจสอบรายการ"
                        }}</a
                      >

                      <a
                        v-if="item.typeImage == 'approve_payment'"
                        class="font-weight-bold"
                        href="javascript:;"
                        @click="
                          _readNotify(item.id, {
                            name: 'admin.approve.payments.show',
                            params: { id: item.paymentId },
                          })
                        "
                        >ตรวจสอบรายการ</a
                      >

                      <a
                        class="font-weight-bold"
                        v-if="item.typeImage == 'approve_payment_reject'"
                        href="javascript:;"
                        @click="
                          _readNotify(item.id, {
                            name: 'admin.approve.cancellation.request.show',
                            params: { id: item.paymentId },
                          })
                        "
                        >ตรวจสอบรายการ</a
                      >

                      <a
                        v-if="item.typeImage == 'payment_fail' && user.defaultSetting == 1"
                        class="font-weight-bold"
                        href="javascript:;"
                        @click="
                          _readNotify(item.id, {
                            name: 'buyer.payments.show',
                            params: { id: item.paymentId },
                          })
                        "
                        >ลองใหม่อีกครั้ง</a
                      >

                      <a
                        v-if="item.typeImage == 'payment_reject' && user.defaultSetting == 1"
                        
                        class="font-weight-bold"
                        href="javascript:;"
                        @click="
                          _readNotify(item.id, {
                            name: 'buyer.payments.show',
                            params: { id: item.paymentId },
                          })
                        "
                        >ชำระเงินใหม่</a
                      >
                    </div>
                    <div class="message text-secondary">
                      {{ item.date ? item.date : "ไม่ระบุ" }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div v-if="!item.readed">
                      <div class="bull text-danger h-100">&#8226;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="notify.notifications.length == 0"
              class="py-5 text-center"
            >
              <img
                class="w-50"
                src="./../assets/image/empty-approve2@2x.png"
                alt=""
              />
              <div class="mt-3">
                <b class="text-secondary">ไม่มีรายการ</b>
              </div>
            </div>
          </base-dropdown>
          <div class="d-flex align-items-center">
            <b-avatar
              variant="light"
              class="icon-profile"
              :text="user.email.substr(0, 1)"
            ></b-avatar>
            <base-dropdown tag="li" class="top-nav-dropdown">
              <template slot="title">
                <span class="text-white">{{ user.email }}</span>
              </template>
              <router-link
                v-if="linkProfile != ''"
                class="dropdown-item"
                :to="{ name: linkProfile }"
              >
                <eva-icon name="person-outline" class="color-dark"></eva-icon>
                <span class="ml-2">บัญชีของฉัน</span>
              </router-link>
              <!-- Buyer เปิดบัญชีเป็น Supplier -->
              <!-- <a 
                v-if="
                  user.role == 'buyer' &&
                    user.type == 'juristic_person' &&
                    user.supplierId == null &&
                    user.stepRegister > 3
                "
                @click="$bvModal.show('modalRegister')"
                class="dropdown-item"
                href="#"
              >
                <eva-icon
                  name="plus-circle-outline"
                  class="color-dark"
                ></eva-icon>
                <span class="ml-2">เปิดบัญชีสำหรับ Supplier</span>
              </a> -->
              <a
                v-if="
                  user.stepRegister == 6 &&
                    user.buyerId != null &&
                    user.defaultSetting == 0
                "
                @click="defaultSetting(1)"
                class="dropdown-item"
                href="#"
              >
                <eva-icon name="home-outline" class="color-dark"></eva-icon>
                <span class="ml-2">ระบบจัดการของ Buyer</span>
              </a>
              <a
                v-if="
                  user.stepRegister == 6 &&
                    user.supplierId != null &&
                    user.defaultSetting == 1
                "
                @click="defaultSetting(0)"
                class="dropdown-item"
                href="#"
              >
                <eva-icon name="home-outline" class="color-dark"></eva-icon>
                <span class="ml-2">ระบบจัดการของ Supplier</span>
              </a>
              <!-- Supplier เปิดบัญชีเป็น Buyer -->
              <!-- <a
                v-if="
                  user.role == 'supplier' &&
                    user.buyerId == null &&
                    user.buyerStatusApprove == false
                "
                @click="$bvModal.show('modalRegisterSub')"
                class="dropdown-item"
                href="#"
              >
                <eva-icon
                  name="plus-circle-outline"
                  class="color-dark"
                ></eva-icon>
                <span class="ml-2">เปิดบัญชีสำหรับ Buyer</span>
              </a> -->
              <div class="divider"></div>
              <a class="dropdown-item" href="#" @click="logout">
                <eva-icon name="log-out-outline" class="color-dark"></eva-icon>
                <span class="ml-2">ออกจากระบบ</span>
              </a>
            </base-dropdown>
          </div>
        </ul>
      </div>
    </div>
    <b-modal id="modalRegister" modal-class="regis" centered>
      <div class="w-100 text-center px-4 py-0">
        <img class="img-fluid" src="./../assets/image/supplier.png" alt="" />
        <div class="h4">เปิดบัญชีสำหรับ Supplier</div>
        <div class="form-group">
          <b-form-checkbox
            id="checkbox-1"
            v-model="accepted"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            ฉันได้อ่านและยอมรับเงื่อนไข
            <b><a href="#" class="text-promary">Privacy Policy</a></b>
          </b-form-checkbox>
        </div>
        <div class="w-100 m-0 text-center">
          <b-button
            variant="primary"
            :disabled="!accepted"
            class="w-100"
            @click="acceptRegisSub()"
          >
            ยืนยันการเปิดใช้งาน
          </b-button>
        </div>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <b-modal id="modalRegisterSub" modal-class="regis" centered>
      <div class="w-100 text-center px-4 py-0">
        <img class="img-fluid" src="./../assets/image/buyer@2x.png" alt="" />
        <div class="h4">เปิดบัญชีสำหรับ Buyer</div>
        <div class="form-group">
          <b-form-checkbox
            id="checkbox-1"
            v-model="accepted"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            ฉันได้อ่านและยอมรับเงื่อนไข
            <b><a href="#" class="text-promary">Privacy Policy</a></b>
          </b-form-checkbox>
        </div>
        <div class="w-100 m-0 text-center">
          <b-button
            variant="primary"
            :disabled="!accepted"
            class="w-100"
            @click="acceptRegisBuyer()"
          >
            ยืนยันการเปิดบัญชี
          </b-button>
        </div>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </nav>
</template>
<script>
import { authenticationService } from "../_services/authentication.service";

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      user: {},
      activeNotifications: false,
      linkProfile: "",
      accepted: false,
      notify: {
        notifications: [],
        badge: 0
      },
      notiTest: {}
    };
  },
  created() {
    this.user = this.$parent.$parent.currentUser;
    this.linkProfile = this.user.role + ".profile";
    if (this.user.role != "admin") {
      if (this.user.defaultSetting === 0) {
        this.linkProfile = "supplier.profile";
      } else {
        this.linkProfile = "buyer.profile";
      }
    } else {
    }
    this.getApiNotify();
    setInterval(() => {
      this.getApiNotify();
    }, 300000);
    this.getApiNotifyTest();
  },

  methods: {
    _readNotify(id, to = null, read = true) {
      let self = this;
      if (to) {
        this.$http.get(`api/notifications/${id}/readed`).then((res) => {
          location.href = this.$router.resolve(to).href;
        });
      } else {
        if (to == null && read == false) {
          this.$http.get(`api/notifications/${id}/readed`).then((res) => {
            this.getApiNotify();
          });
        }
      }
    },
    imageNotify(type) {
      if (this.user.role == "admin") {
        switch (type) {
          case "approve_new_supplier":
            return {
              name: "briefcase-outline",
              class: "bg-warning"
            };
          case "approve_supplier":
            return {
              name: "file-text-outline",
              class: "bg-info-blue"
            };
          case "approve_payment":
            return {
              name: "file-text-outline",
              class: "bg-primary"
            };
          case "approve_payment_reject":
            return {
              name: "file-text-outline",
              class: "bg-secondary"
            };
        }
      }

      if (this.user.role == "buyer") {
        switch (type) {
          case "payment_fail":
            return {
              name: "alert-circle",
              class: "bg-danger"
            };
          case "payment_reject":
            return {
              name: "alert-circle",
              class: "bg-danger"
            };
          case "payment_approve":
            return {
              name: "file-text-outline",
              class: "bg-info-buyer"
            };
          case "payment_process":
            return {
              name: "file-text-outline",
              class: "bg-info-buyer"
            };
          case "payment_reject_approve":
            return {
              name: "alert-circle",
              class: "bg-warning"
            };
          case "payment_cancel":
            return {
              name: "close-outline",
              class: "bg-secondary"
            };
          case "payment_success":
            return {
              name: "checkmark-outline",
              class: "bg-success"
            };
        }
      }
      if (this.user.role == "supplier") {
        switch (type) {
          case "approve_fail":
            return {
              name: "person-outline",
              class: "bg-danger"
            };
          case "approve_success":
            return {
              name: "person-outline",
              class: "bg-success"
            };
          case "approve_company_success":
            return {
              name: "file-text-outline",
              class: "bg-success"
            };
          case "approve_company_fail":
            return {
              name: "file-text-outline",
              class: "bg-danger"
            };
          case "approve_document_success":
            return {
              name: "person-outline",
              class: "bg-success"
            };
          case "approve_document_fail":
            return {
              name: "person-outline",
              class: "bg-danger"
            };
          case "approve_bank_success":
            return {
              name: "person-outline",
              class: "bg-success"
            };
          case "approve_bank_fail":
            return {
              name: "person-outline",
              class: "bg-danger"
            };
          case "payment_new":
            return {
              name: "person-outline",
              class: "bg-info-blue"
            };
          case "payment_success":
            return {
              name: "checkmark-outline",
              class: "bg-success"
            };
          case "payment_cancel":
            return {
              name: "close-outline",
              class: "bg-secondary"
            };
          case "payment_fail":
            return {
              name: "alert-circle",
              class: "bg-danger"
            };
          case "payment_reject":
            return {
              name: "alert-circle",
              class: "bg-danger"
            };
        }
      }

      return {
        name: "file-text-outline",
        class: "bg-info"
      };
    },
    getApiNotify() {
      this.$http.get(`api/notifications`).then((response) => {
        this.notify = response.data.data;
      });
    },
    getApiNotifyTest() {
      this.$http.get(`api/notification/test/lists`).then((response) => {
        this.notiTest = response.data.data;
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      authenticationService.logout();
      this.$router.push({ name: "login" });
    },
    acceptRegisSub() {
      if (this.accepted) {
        let self = this;
        this.$showLoader();
        this.$http
          .get(`api/suppliers/change/status/buyer/to/supplier`)
          .then((res) => {
            authenticationService.refreshUser(`api/buyers/profile`, true);
          });
      }
    },
    acceptRegisBuyer() {
      if (this.accepted) {
        let self = this;
        this.$showLoader();
        this.$http
          .get(`api/suppliers/change/status/supplier/to/buyer`)
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success"
            });
            setTimeout(() => {
              location.reload();
            }, 1000);
          });
      }
    },
    defaultSetting(mode) {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/default/setting`, {
          status: mode
        })
        .then((res) => {
          self.$hideLoader();
          authenticationService.refreshUser(`api/buyers/profile`, true);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.notify {
  .notify-icon {
    display: flex;
    justify-content: center;
    width: 26px;
    height: 26px;
  }

  .dropdown-menu {
    .scroll-noti {
      padding: 0rem 0;
      width: 304px;
      max-height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        background: #d6dce3;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      div {
        white-space: unset;
        .message {
          font-size: 14px;
        }
      }
      .dropdown-item {
        border-bottom: 1px solid #d6dce3;
      }
      .dropdown-item:hover,
      .dropdown-item:focus {
        color: unset !important;
        text-decoration: none;
        background-color: unset !important;
      }
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu {
      left: -54px;
      &::after,
      &::before {
        left: 69px !important;
      }
      .scroll-noti {
        width: 340px;
      }
    }
  }
}
.main-panel .navbar {
  position: fixed;
  left: 0;
  width: 100%;
}
.regis {
  .modal-body {
    padding-top: 0px !important;
  }
  .modal-header .close {
    line-height: 1 !important;
    margin-top: -32px;
  }
}
.bg-info-blue {
  background-color: rgb(114, 140, 222);
}
.bg-info-buyer {
  background-color: #26a6cc;
}

.bull {
  font-size: 3rem;
}

@media (max-width: 991px) {
  .app-logo {
    flex: 1;
    text-align: center;
    padding-right: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 1280px){
  .navbar-expand-lg .navbar-toggler {
      display: block;
  }
}
</style>
