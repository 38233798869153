<template>
    <div class="row justify-content-center">
        <div class="col-lg-11 col-md-12">
            <div class="page-name mt-lg-4 mt-md-3">
                <div class="h3 mt-4">ขั้นที่ 2: การออกใบกำกับภาษี</div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-3">
                        <h5 class="px-1">
                            รายละเอียดที่อยู่ปัจจุบัน</h5>
                            <p>โปรดกรอกข้อมูลให้ครบถ้วน</p>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label v-if="currentAddressSelected === false">ที่อยู่</label>
                                <v-select label="label" v-model="currentAddressSelected" :options="addressOptions"
                                    :reduce="(option) => option.value">
                                </v-select>
                            </div>
                        </div>
                        <div v-if="currentAddressSelected !== true">
                            <div class="row">
                                <div class="col-md-12 col-lg-12 mb-3">
                                    <label>บ้านเลขที่ ตึก ชั้น</label>
                                    <div>
                                        <p>{{ mainAddress.addressOne }}</p>
                                    </div>
                                    <div class="mt-3">
                                        <label>ถนน แขวง</label>
                                        <p>{{ mainAddress.addressTwo }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label>เขต/อำเภอ</label>
                                    <p>{{ mainAddress.amphure }}</p>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>จังหวัด</label>
                                    <p>{{ mainAddress.provinces.label}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label>ประเทศ</label>
                                    <p>{{ mainAddress.countryId.label }}</p>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>รหัสไปรษณีย์</label>
                                    <p>{{ mainAddress.postcode }}</p>
                                </div>
                            </div>

                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 mb-3">
                                    <label>ที่อยู่</label>
                                    <div>
                                        <input type="text" class="form-control" placeholder="บ้านเลขที่ ตึก ชั้น"
                                            v-model="currentAddressOne"
                                            :class="{ 'is-invalid': errors.currentAddressOne }" />
                                        <div v-if="errors.currentAddressOne" class="invalid-feedback d-block">
                                            {{ errors.currentAddressOne }}
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <input type="text" placeholder="ถนน แขวง" class="form-control"
                                            v-model="currentAddressTwo"
                                            :class="{ 'is-invalid': errors.currentAddressTwo }" />
                                        <div v-if="errors.currentAddressTwo" class="invalid-feedback d-block">
                                            {{ errors.currentAddressTwo }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label>เขต/อำเภอ</label>
                                    <div>
                                        <input type="text" class="form-control" v-model="currentAmphure"
                                            :class="{ 'is-invalid': errors.currentAmphure }" />
                                        <div v-if="errors.currentAmphure" class="invalid-feedback d-block">
                                            {{ errors.currentAmphure }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>จังหวัด</label>
                                    <div>
                                        <v-select id="recipt" v-model="currentProvince" :options="provinces"
                                            :class="{ 'is-invalid': errors.currentProvinceId }"></v-select>
                                        <div v-if="errors.currentProvinceId" class="invalid-feedback d-block">
                                            {{ errors.currentProvinceId }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>ประเทศ</label>
                                    <div>
                                        <v-select v-model="currentCountryId" :options="counties"
                                            :class="{ 'is-invalid': errors.currentCountryId }"></v-select>
                                        <div v-if="errors.currentCountryId" class="invalid-feedback d-block">
                                            {{ errors.currentCountryId }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>รหัสไปรษณีย์</label>
                                    <div>
                                        <input type="text" class="form-control" v-model="currentPostcode"
                                            :class="{ 'is-invalid': errors.currentPostcode }" maxlength="5" />
                                        <div v-if="errors.currentPostcode" class="invalid-feedback d-block">
                                            {{ errors.currentPostcode }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-3">
                        <h5 class="px-1">ที่อยู่ออกใบกำกับภาษี</h5>
                        <label
                            class="px-1 py-2 text-secondary small line-sub">โปรดเลือกที่อยู่สำหรับการออกใบกำกับภาษีและจัดส่งเอกสาร</label>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label v-if="invoiceAddress === false">ที่อยู่</label>
                                <v-select label="label" v-model="invoiceAddress" :options="invoiceOptions"
                                    :reduce="(option) => option.value">
                                </v-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mb-4">
                <button @click="previousStep()" class="btn btn-outline-secondary border mr-4">
                    ย้อนกลับ
                </button>
                <button @click="submitBtn()" class="btn btn-primary">
                    บันทึก
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        authenticationService
    } from "../../../../_services/authentication.service";
    import { StringFormat}  from "../../../../_formats/string"
    import { formatNum } from "../../../../_formats/number"
    export default {
        name: "InvoiceAddress",
        props: ["user", "mainAddress", "provinces", "counties"],
        data() {
            return {
                addressOptions: [{
                    label:'ใช้ที่อยู่ตามบัตรประชาชน',
                    value: false,
                }, {
                    label: 'ระบุที่อยู่',
                    value: true,
                }],
                invoiceOptions: [{
                    label: 'ใช้ที่อยู่ตามบัตรประชาชน',
                    value: false,
                }],
                currentAddressSelected: false,
                currentAddressOne: null,
                currentAddressTwo: null,
                currentAmphure: null,
                currentCountryId: null,
                currentProvince: null,
                currentProvinceId: null,
                currentPostcode: null,
                companyName: null,
                invoiceAddress: false,
                invoiceAddressStatus: this.invoiceAddress?? 'same idcard',
                errors: []
            }
        },
        methods: {
            submitBtn() {
                const self = this;
                // ** Current address same ID-Card address and Invoice address same ID-Card address.
               
                if (this.currentAddressSelected === false) {
                   this.saveAddress()
                } else {  // ** current address not same ID-Card address and Invoice address same ID-Card address.
                 let payload = {
                        companyName: this.companyName,
                        currentAddressOne: this.currentAddressOne,
                        currentAddressTwo: this.currentAddressTwo,
                        currentAmphure: this.currentAmphure,
                        currentProvinceId: this.currentProvince?.code,
                        currentPostcode: this.currentPostcode,
                        currentCountryId: this.currentCountryId?.code,
                        type: this.user.type
                    }
                    this.$http
                        .post(`api/buyers/validate/current-address`, payload)
                        .then(async (res) => {
                            this.saveAddress(payload)
                        }).catch((err) => {
                            self.errors = err.response.data.errors.data;
                        })
                    
                }
            },
            saveAddress(anotherAddress = null) {
                let self = this;
                self.$showLoader();
                const formData = new FormData();
                let idCardAddress = this.mainAddress
                for (const [key, group] of Object.entries(idCardAddress.files)) {
                    for (let i = 0; i < group.length; i++) {
                        formData.append(key, group[i]);
                    }
                }
                if (anotherAddress) {
                    formData.append('currentAddressSelected', this.currentAddressSelected)
                    formData.append('companyName', this.companyName)
                    formData.append('currentAddressOne', anotherAddress.currentAddressOne)
                    formData.append('currentAddressTwo', anotherAddress.currentAddressTwo)
                    formData.append('currentAmphure', anotherAddress.currentAmphure)
                    formData.append('currentProvinceId', anotherAddress.currentProvinceId)
                    formData.append('currentPostcode', anotherAddress.currentPostcode)
                    formData.append('currentCountryId', anotherAddress.currentCountryId)
                }else{
                    formData.append('currentAddressSelected', false)
                }
                formData.append('firstname', idCardAddress.firstname);
                formData.append('lastname', idCardAddress.lastname);
                formData.append('idCardAddressOne', idCardAddress.addressOne);
                formData.append('idCardAddressTwo', idCardAddress.addressTwo)
                formData.append('idCardAddressAmphure', idCardAddress.amphure)
                formData.append('idCardAddressProvince', idCardAddress.province?.code)
                formData.append('idCardAddressCountry', idCardAddress.countryId?.code)
                formData.append('idCardPostcode', idCardAddress.postcode)
                formData.append('invoiceAddressStatus', this.invoiceAddressStatus)
                formData.append('type', this.mainAddress.type)
                this.$http.patch('/api/buyers/verify-update', formData)
                    .then(async res => {
                        this.$notifications.notify({
                            message: res.data.message,
                            icon: "checkmark-circle-2",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "success",
                        });
                        authenticationService.refreshUser(`api/buyers/profile`, true);
                    })
            },
            previousStep() {
                this.$emit('changedStep', {
                    step: false,
                    errors: Object
                })
            },
        },
        watch: {
            currentAddressSelected() {
                this.invoiceOptions = [{
                    label: 'ใช้ที่อยู่ตามบัตรประชาชน',
                    value: false,
                }, {
                    label: 'ที่อยู่ปัจจุบัน',
                    value: true
                }]
            },
            invoiceAddress() {
                if (this.invoiceAddress) {
                    this.invoiceAddressStatus = 'same current';
                } else {
                    this.invoiceAddressStatus = 'same idcard';
                }
            },
            currentPostcode() {
                this.currentPostcode = formatNum.numberic(this.currentPostcode)
            },
            currentAmphure() {
                this.currentAmphure =StringFormat.ThaiString(this.currentAmphure)
            }
        }

    }
</script>

<style>

</style>