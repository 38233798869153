<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">รายละเอียดนิติบุคคล > </span>
          <span class="font-weight-semibold">ที่อยู่ใบกำกับภาษี</span>
          <div class="h3 mt-4">ที่อยู่ใบกำกับภาษี</div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-2">
              <h5 class="px-1 font-weight-semibold">รายละเอียดที่อยู่</h5>
              <div class="px-1 py-2 text-secondary small line-sub">ที่อยู่สำหรับการจัดส่งเอกสารหรือใบเสร็จ</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="">
                <div class="row">
                  <div class="col-lg-12 mb-2">
                    <v-select label="label" v-model="invoiceAddress" :options="invoiceOptions"
                      :reduce="(option) => option.value">
                    </v-select>
                  </div>
                </div>
                <div v-if="invoiceAddress === true" class="row mt-3">
                  <div class="col-lg-12 mb-3">
                    <label>ชื่อบริษัท</label>
                    <input type="text" v-model="data.companyNameTh" class="form-control" :class="{ 'is-invalid': errors.companyNameTh }"
                    />
                    <div
                      v-if="errors.companyNameTh"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyNameTh }}
                    </div>
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label>ที่อยู่</label>
                    <input type="text" v-model="data.addressOne" class="form-control" :class="{ 'is-invalid': errors.addressOne }"
                    />
                    <div
                      v-if="errors.addressOne"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.addressOne }}
                    </div>
                    <input type="text" v-model="data.addressTwo" class="form-control mt-3" :class="{ 'is-invalid': errors.addressTwo }"
                    />
                    <div
                      v-if="errors.addressTwo"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.addressTwo }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>เขต/อำเภอ</label>
                    <input type="text" v-model="data.amphure" class="form-control" :class="{ 'is-invalid': errors.amphure }"
                    />
                    <div
                      v-if="errors.amphure"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.amphure }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>จังหวัด</label>
                    <v-select
                      v-model="province"
                      :options="provinces"
                      :class="{ 'is-invalid': errors.provinceId }"
                    ></v-select>
                    <div
                      v-if="errors.provinceId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.provinceId }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>ประเทศ</label>
                    <v-select
                      v-model="countryId"
                      :options="counties"
                      :class="{ 'is-invalid': errors.countryId }"
                    ></v-select>
                    <div
                      v-if="errors.countryId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.countryId }}
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label>รหัสไปรษณีย์</label>
                    <input type="text" maxlength="5" v-model="data.postcode" class="form-control" :class="{ 'is-invalid': errors.postcode }"
                    />
                    <div
                      v-if="errors.postcode"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.postcode }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'" class="mt-3 text-right">
              <button
                @click="apiJuristic()"
                class="btn bg-white border mr-4"
              >
                ยกเลิก
              </button>
              <button
              @click="submitBtn()"
                class="btn btn-primary"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Address",
  data() {
    return {
      data: {},
        errors: {},
        province: null,
        countryId: null,
        provinces: [],
        addressTwo: "",
        addressOne: "",
        counties: [],
        invoiceOptions: [{
          label: 'ใช้ที่อยู่ตามที่อยู่จดทะเบียน',
          value: false,
        },{
          label: 'ระบุ',
          value: true,
        }],
        invoiceAddress: false,
      }
  },
  created() {
    this.apiJuristic()
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiJuristic(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/receipt`)
        .then((res) => {
          let data=res.data.data
          if(Object.keys(data).length !== 0){
            this.data = res.data.data;
            this.invoiceAddress = true
          }else{
            this.invoiceAddress = false
          }
          self.$hideLoader();
        })
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.data.provinceId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.data.countryId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn(){
      let self = this;
      this.errors = {}
      this.$showLoader();
      let invoiceAddress = this.invoiceAddress??false
      this.$http
        .patch(`api/buyers/update/receipt`, {
          companyId: this.data.companyId,
          companyNameTh: this.data.companyNameTh,
          addressOne: this.data.addressOne,
          addressTwo: this.data.addressTwo,
          amphure: this.data.amphure,
          provinceId: this.province ? this.province.code : null,
          countryId: this.countryId ? this.countryId.code : null,
          postcode: this.data.postcode,
          invoiceAddress: invoiceAddress
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function (err) {
          self.errors = err.response.data.errors.data
        })
    }
  },
  watch: {
  }
};
</script>