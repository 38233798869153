<template>
  <div>
    <div class="row justify-content-center">
      <div v-if="detail" class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            >รายการอนุมัติ >
            <router-link
              class="text-secondary font-weight-bold"
              :to="{ name: 'admin.approve.cancellation.request' }"
              >อนุมัติคำขอยกเลิก</router-link
            >
            >
          </span>
          <b>รายการชำระเงิน #{{ detail.refNo }}</b>
          <div class="h3 mt-4">รายการชำระเงิน #{{ detail.refNo }}</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="bg-white">
              <div class="px-3 py-2-1 border-bottom font-weight-bold">
                รายละเอียด
              </div>
              <div class="px-3 py-2-1">
                <div class="h6 font-weight-bold mt-1">สถานะ</div>
                <div v-html="status(detail.statusNumber,detail.statusName)"></div>
                <div class="h6 font-weight-bold mt-3">Ref No.</div>
                <span class="text-secondary small">{{ detail.refNo }}</span>
                <div class="h6 font-weight-bold mt-3">วันที่สร้าง</div>
                <span class="text-secondary small">{{ detail.createdAt }}</span>
                <div
                  v-if="detail.statusNumber == 5"
                  class="h6 font-weight-bold mt-3"
                >
                  วันที่ยกเลิก
                </div>
                <span
                  v-if="detail.statusNumber == 5"
                  class="text-secondary small"
                  >{{ detail.cancelAt }}<br />{{ detail.cancelBy }}</span
                >
              </div>
            </div>
            <div
              v-if="
                detail.statusNumber == 4 &&
                  ($parent.$parent.$parent.currentUser.adminRole == 'owner' ||
                    $parent.$parent.$parent.currentUser.adminRole ==
                      'approver' ||
                    $parent.$parent.$parent.currentUser.adminRole == 'admin')
              "
              class="mt-4"
            >
              <div class="d-flex ">
                <div class="w-50 pr-1">
                  <button
                    @click="$bvModal.show('modalRejectAll')"
                    class="
                    btn bg-white
                    font-weight-bold
                    d-flex flex-fill
                    align-items-center
                    justify-content-center
                    mr-0
                    w-100
                    text-danger
                    border-danger
                  "
                  >
                    <eva-icon
                      name="close-outline"
                      class="color-danger"
                    ></eva-icon
                    >ไม่อนุมัติ
                  </button>
                </div>
                <div class="w-50 pl-1">
                  <button
                    @click="$bvModal.show('modalbtnApprove')"
                    class="
                    btn text-white
                    font-weight-bold
                    d-flex flex-fill
                    align-items-center
                    justify-content-center
                    btn-success
                    mr-0
                    w-100
                  "
                  >
                    <eva-icon
                      name="checkmark-outline"
                      class="color-white"
                    ></eva-icon
                    >อนุมัติ
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="scroll-box">
              <div class="box container-fluid">
                <div class="row">
                  <div class="col-12 ">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                      >
                        <div class="font-weight-bold">ข้อมูล Buyer</div>
                        <div>
                          <span
                            :class="
                              'badge badge-pill' +
                                (buyer.statusNumber == 0
                                  ? ' badge-warning'
                                  : '') +
                                (buyer.statusNumber == 2
                                  ? ' badge-danger'
                                  : '') +
                                (buyer.statusNumber == 1
                                  ? ' badge-success'
                                  : '')
                            "
                            >{{ buyer.statusName }}</span
                          >
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              ผู้ชำระเงิน
                            </div>
                            <span class="text-secondary">{{ buyer.name }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">อีเมล</div>
                            <span class="text-secondary">{{
                              buyer.email
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              เบอร์โทรศัพท์
                            </div>
                            <span class="text-secondary">{{
                              buyer.phone
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-lg-4 mt-md-3">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                      >
                        <div class="font-weight-bold">ข้อมูล Supplier</div>
                        <div>
                          <span
                            :class="
                              'badge badge-pill' +
                                (supplier.statusNumber == 0
                                  ? ' badge-warning'
                                  : '') +
                                (supplier.statusNumber == 2
                                  ? ' badge-danger'
                                  : '') +
                                (supplier.statusNumber == 1
                                  ? ' badge-success'
                                  : '')
                            "
                            >{{ supplier.statusName }}</span
                          >
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              ชื่อ Supplier
                            </div>
                            <span class="text-secondary">{{
                              supplier.name
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">ธนาคาร</div>
                            <span class="text-secondary"
                              ><img
                                width="26px"
                                :src="supplier.bankLogo"
                                alt=""
                              />
                              {{ supplier.bankName }}</span
                            >
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">หมวดหมู่</div>
                            <span class="text-secondary">{{
                              supplier.supplierCategoryName
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              เลขที่บัญชี
                            </div>
                            <span class="text-secondary">{{
                              supplier.bankAccountNumber
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">ที่อยู่</div>
                            <span class="text-secondary">{{
                              supplier.address
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              ชื่อบัญชี
                            </div>
                            <span class="text-secondary">{{
                              supplier.bankAccountName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-lg-4 mt-md-3">
                    <div class="bg-white h-100 rounded">
                      <div class="px-3 py-2-1 border-bottom font-weight-bold">
                        ข้อมูลชำระเงิน
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">จำนวน</div>
                            <span class="text-secondary"
                              >฿{{ formatPrice(payment.amount, 2) }}</span
                            >
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              วันที่ชำระ
                            </div>
                            <span class="text-secondary">{{
                              payment.paymentDate
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              ค่าธรรมเนียม
                            </div>
                            <span class="text-secondary"
                              >฿{{ formatPrice(payment.fee, 2) }}</span
                            >
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              วันที่ตัดบัตร
                            </div>
                            <span class="text-secondary">{{
                              payment.paymentDebit
                            }}</span>
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">ยอดรวม (รวม VAT 7%)</div>
                            <span class="text-secondary"
                              >฿{{ formatPrice(payment.totalAmount, 2) }}</span
                            >
                          </div>
                          <div class="col-lg-6">
                            <div class="h6 font-weight-bold mt-3">
                              บัตรเครดิต
                            </div>
                            <div>
                              <img
                                width="36px"
                                v-if="payment.creditCardType == 'VISA'"
                                src="../../../../assets/card/visa1-gray.png"
                                alt=""
                              />
                              <img
                                width="36px"
                                v-if="payment.creditCardType == 'MASTER CARD'"
                                src="../../../../assets/card/mastercard1-gray.png"
                                alt=""
                              />
                              <img
                                width="36px"
                                v-if="payment.creditCardType == 'JCB'"
                                src="../../../../assets/card/jcb1-gray.png"
                                alt=""
                              />
                              <span class="text-secondary">
                                *** {{ payment.creditCardNumber }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-lg-4 mt-md-3">
                    <div class="bg-white h-100 rounded">
                      <div
                        class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-bold
                      d-flex
                      justify-content-between
                    "
                      >
                        <div>เอกสารการชำระเงิน</div>
                        <div>
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            @click="downloadAll()"
                          >
                            <eva-icon name="download-outline"></eva-icon>
                            ดาวน์โหลดทั้งหมด
                          </button>
                        </div>
                      </div>
                      <div class="px-3 py-2-1">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="h6 font-weight-bold mt-3">
                              Payment Reference
                            </div>
                            <span class="text-secondary">{{
                              document.paymentReference
                            }}</span>
                          </div>
                          <div class="col-lg-12">
                            <div class="h6 font-weight-bold mt-3">
                              เอกสารการชำระเงิน
                            </div>
                          </div>
                          <div
                            v-for="(file, index) in document.files"
                            :key="index"
                            class="col-md-6 mb-3 list-file"
                          >
                            <div
                              :title="file.filename"
                              class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                            >
                              <div class="">
                                <img
                                  width="40px"
                                  :src="imgFile(file.mimeType)"
                                />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    <a :href="file.download" download="">
                                    {{ file.filename }}
                                    </a>
                                  </div>
                                  <!-- <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small> -->
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <a :href="file.download" download="">
                                    <eva-icon
                                      name="download"
                                      class="cursor-pointer color-secondary"
                                    ></eva-icon>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalRejectAll" centered title="ไม่อนุมัติคำขอยกเลิก">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalRejectAll')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="danger" @click="changeStatus(false)">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalbtnApprove" centered title="อนุมัติคำขอยกเลิก">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalbtnApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="success" @click="changeStatus(true)">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { formatNum }from "../../../../_formats/number";
import status from "../../../../_fields/status.js";
export default {
  name: "PaymentShow",
  data() {
    return {
      statusList: status.slice(0,7).concat(status.slice(7+1)),
      allStatus: status,
      buyer: {},
      detail: {},
      document: {},
      payment: {},
      supplier: {},
    };
  },
  created() {
    this.apiGetData();
  },
  methods: {
    status(status,string) {
      let find = this.statusList.find((s) => s.id == status)
      if(find){
      return "<span class='badge badge-pill "+find.class+"'>"+find.name+"</span>";
      }else{
      return "<span class='badge badge-pill badge-warning'>"+string+"</span>";
      }
    },
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/payment/` + this.$route.params.id + `/show`)
        .then((res) => {
          let data = res.data.data;
          self.buyer = data.buyer;
          self.detail = data.detail;
          self.document = data.document;
          self.payment = data.payment;
          self.supplier = data.supplier;
          self.$hideLoader();
        });
    },
    formatPrice(a, b) {
      return formatNum.price(Number(a), Number(b));
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
    apiApprove() {
      this.$bvModal.hide("modalbtnApprove");
      this.$bvModal.hide("modalRejectAll");
      let self = this;
      this.$showLoader();
      this.$http
        .patch(`api/admins/payment/` + this.$route.params.id + `/approve`, {
          statusNumber: 5,
        })
        .then((res) => {
          self.apiGetData();
          self.$parent.$parent.getApiNotify();
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({
            name: "admin.approve.payments.show",
            params: { id: this.$route.params.id },
          });
        });
    },
    apiReject() {
      this.$bvModal.hide("modalbtnApprove");
      this.$bvModal.hide("modalRejectAll");
      let self = this;
      this.$showLoader();
      this.$http
        .patch(`api/admins/payment/` + this.$route.params.id + `/approve`, {
          statusNumber: 2,
        })
        .then((res) => {
          self.apiGetData();
          self.$parent.$parent.getApiNotify();
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
    changeStatus(approved) {
      let self = this;
      let url = "/api/payment/flag-status/updated",
        body = {
          paymentId: this.payment.paymentId,
          paymentStatus: this.payment.status,
          approved: approved,
        };
      this.$bvModal.hide("modalbtnApprove");
      this.$bvModal.hide("modalRejectAll");
      this.$http.patch(url, body)
        .then((res) => {
          self.apiGetData();
          self.$parent.$parent.getApiNotify();
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        })
    },
    downloadAll() {
      this.document.files.forEach((f) => {
        window.open(f.download);
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.list-file .name {
  font-size: 12px !important;
}
</style>
