<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-12">
            <h4>แก้ไขข้อมูลลงทะเบียนบริษัท</h4>
          </div>
          <div class="col-md-3">
            <h5 class="mt-4">ข้อมูลบริษัท</h5>
            <span class="text-secondary small line-sub"
              >โปรดใส่ข้อมูลบริษัทตามรายละเอียดของหนังสือรับรองบริษัท</span
            >
          </div>
          <div class="col-md-9 mt-4">
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div v-if="data" class="row pt-md-4">
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (TH)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.nameThAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="data.nameThAndReason.value"
                    />
                    <span
                      v-if="data.nameThAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.nameThAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (EN)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      :class="
                        data.nameEnAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      class="form-control"
                      v-model="data.nameEnAndReason.value"
                    />
                    <span
                      v-if="data.nameEnAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.nameEnAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>เลขนิติบุคคล</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      :class="
                        data.juristicNumberAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      class="form-control"
                      v-model="data.juristicNumberAndReason.value"
                    />
                    <span
                      v-if="data.juristicNumberAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.juristicNumberAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ที่อยู่</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="บ้านเลขที่ ตึก ชั้น"
                      :class="
                        data.addressOneAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.addressOneAndReason.value"
                    />
                    <span
                      v-if="data.addressOneAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.addressOneAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เขต/อำเภอ</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.amphureAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.amphureAndReason.value"
                    />
                    <span
                      v-if="data.amphureAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.amphureAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>จังหวัด</b>
                  </div>
                  <div>
                    <v-select
                    :class="
                        data.provinceIdAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="province"
                      :options="provinces"
                    ></v-select>
                    <span
                      v-if="data.provinceIdAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.provinceIdAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>ประเทศ</b>
                  </div>
                  <div>
                    <v-select
                    :class="
                        data.countryIdAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="countryId"
                      :options="counties"
                    ></v-select>
                    <span
                      v-if="data.countryIdAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.countryIdAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>รหัสไปรษณีย์</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.postcodeAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.postcodeAndReason.value"
                    />
                    <span
                      v-if="data.postcodeAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.postcodeAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เบอร์โทรศัพท์บริษัท</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      @keyup="$numberInput($event)"
                      :class="
                        data.phoneAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="data.phoneAndReason.value"
                    />
                    <span
                      v-if="data.phoneAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.phoneAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>อีเมลบริษัท</b>
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control"
                      :class="
                        data.emailAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="data.emailAndReason.value"
                    />
                    <span
                      v-if="data.emailAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.emailAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เว็บไซต์ (ถ้ามี)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.websiteAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.websiteAndReason.value"
                    />
                    <span
                      v-if="data.websiteAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.websiteAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>หมวดหมู่ Supplier</b>
                  </div>
                  <div>
                    <v-select
                    :class="
                        data.supplierCategoryAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="supplierCategoryId"
                      :options="categories"
                    ></v-select>
                     <span
                      v-if="data.supplierCategoryAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.supplierCategoryAndReason.reject }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 text-right mt-3">
              <b-button
                class="mr-3"
                @click="
                  $router.push({ name: 'supplier.register.juristic_person' })
                "
                variant="outline-secondary"
                >ยกเลิก</b-button
              >
              <b-button @click="submitBtn()" variant="primary">บันทึก</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditCompany",
  data() {
    return {
      data: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      categories: [],
      categoryId: null,
      supplierCategoryId: null,
    };
  },
  created() {
    this.apiDataCompany();
    this.apiProvinces();
    this.apiCounties();
    this.apiCategories();
  },
  methods: {
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.data.provinceIdAndReason.value
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.data.countryIdAndReason.value
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCategories() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/category/suppliers`)
        .then((res) => {
          let categories = res.data.data;
          this.categories = categories.map(function (x) {
            return (x = { label: x.categoryName, code: x.supplierCategoryId });
          });
          setTimeout(() => {
            this.supplierCategoryId = this.categories.find(
              (c) => c.code == this.data.supplierCategoryAndReason.value
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiDataCompany() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/juristics/edit/company/profile/not/approve`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.$showLoader();
      let data = {
        provinceId: this.province ? this.province.code : null,
        countryId: this.countryId ? this.countryId.code : null,
        supplierCategoryId: this.supplierCategoryId
          ? this.supplierCategoryId.code
          : null,
        amphure: this.data.amphureAndReason.value,
        addressOne: this.data.addressOneAndReason.value,

        juristicNumber: this.data.juristicNumberAndReason.value,
        postcode: this.data.postcodeAndReason.value,
        website: this.data.websiteAndReason.value,
        companyPhone: this.data.phoneAndReason.value,
        companyEmail: this.data.emailAndReason.value,

        companyNameTh: this.data.nameThAndReason.value,
        companyNameEng: this.data.nameEnAndReason.value,
      };
      this.$http
        .post(
          `api/suppliers/juristics/update/company/profile/not/approve`,
          data
        )
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "supplier.register.juristic_person" });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
}
</style>