<template>
  <div class="container-fluid">
    <div class="row bg-white justify-content-center">
      <div class="col-md-10 my-5">
        <div class="d-flex justify-content-between">
          <div>
            <ul class="list-unstyled">
              <b-media tag="li">
                <template #aside>
                  <b-avatar v-if="user.email" variant="light" size="4em" class="text-primary icon-profile-m" :text="user.email.substr(0,1)"></b-avatar>
                </template>
                <span></span>
                <div class="mt-0 mb-1">
                  <span class="badge badge-primary rounded-pill mr-3 px-3"
                    >Supplier</span
                  >
                  <span
                    v-if="user.statusNumber == 0"
                    class="badge badge-warning rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 1"
                    class="badge badge-success rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 5"
                    class="badge badge-secondary rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 2"
                    class="badge badge-danger rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <div class="font-weight-bold">
                    {{ user.email }}
                  </div>
                </div>
              </b-media>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ข้อมูลบัญชี</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>รายละเอียด</h5>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <b-button
                  v-if="user.supplierAdminRole != 'admin' && user.supplierAdminRole != 'staff'"
                    @click="$router.push({name: 'buyer.register.kyc.new'})"
                    class="btn-sm"
                    variant="outline-primary"
                  >
                    ยืนยันตัวตนใหม่</b-button
                  >
                </div>
              </div>
              <hr class="m-0" />
              <div class="row pt-md-3">
                <div  class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>ชื่อ</b>
                  </div>
                  <div v-if="user.supplierAdminRole != 'admin' && user.supplierAdminRole != 'staff'" class="text-secondary">
                    {{ user.firstname ? user.firstname : "-" }}
                  </div>
                  <div v-else>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.firstname"
                      :class="{ 'is-invalid': errors.firstname }"
                    />
                    <div v-if="errors.firstname" class="invalid-feedback d-block">
                      {{ errors.firstname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>นามสกุล</b>
                  </div>
                  <div v-if="user.supplierAdminRole != 'admin' && user.supplierAdminRole != 'staff'" class="text-secondary">
                    {{ user.lastname ? user.lastname : "-" }}
                  </div>
                  <div v-else>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.lastname"
                      :class="{ 'is-invalid': errors.lastname }"
                    />
                    <div v-if="errors.lastname" class="invalid-feedback d-block">
                      {{ errors.lastname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>อีเมล</b>
                  </div>
                  <div>
                    <input
                      readonly
                      type="text"
                      class="form-control"
                      v-model="user.email"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div v-if="errors.email" class="invalid-feedback d-block">
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>เบอร์โทร</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      @keyup="$numberInput($event)"
                      v-model="user.phone"
                      :class="{ 'is-invalid': errors.phone }"
                    />
                    <div v-if="errors.phone" class="invalid-feedback d-block">
                      {{ errors.phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button @click="submitBtn()" class="mr-3" variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'supplier.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ความปลอดภัย</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านเดิม</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="oldPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.oldPassword }"
                    />
                    <div v-if="errors.oldPassword" class="invalid-feedback d-block">
                      {{ errors.oldPassword }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านใหม่</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="newPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.newPassword }"
                    />
                    <div v-if="errors.newPassword" class="invalid-feedback d-block">
                      {{ errors.newPassword }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="apiChangePass()"
                    class="mr-3"
                    variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'buyer.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div  v-if="user.supplierStatusApprove == true" class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ค่าตั้งต้นหลังบ้าน</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-12 p-md-4">
                  <div>
                    <b-form-group
                        class="font-weight-bold"
                        label="ค่าตั้งต้น"
                      >
                        <b-form-radio
                          :value="1"
                          v-model="user.defaultSetting"
                          name="adminRole"
                        >
                          Buyer
                        </b-form-radio>
                        <b-form-radio
                          :value="0"
                          v-model="user.defaultSetting"
                          name="adminRole"
                        >
                          Supplier
                        </b-form-radio>
                      </b-form-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="apiChangeSetting()"
                    class="mr-3"
                    variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'supplier.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  data() {
    return {
      user: {},
      errors: {},
      error: null,
      oldPassword: "",
      newPassword: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      receiptBuyerCountryId: null,
      receiptBuyerProvinceId: null
    };
  },
  created() {
    this.apiProfile();
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      this.apiProfileUpdate();
    },
    apiProfileUpdate() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/profile`, {
          email: this.user.email,
          phone: this.user.phone,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
        })
        .then((res) => {
          self.$hideLoader();
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          self.$router.push({ name: 'supplier.profile' })
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiChangePass() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/users/change/password`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          self.$router.push({ name: 'supplier.profile' })
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiChangeSetting() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/default/setting`, {
          status: this.user.defaultSetting,
        })
        .then((res) => {
          self.$hideLoader();
          authenticationService.refreshUser(`api/buyers/profile`,true);
        });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.user.provinceId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.user.countryId
            );
            this.receiptBuyerCountryId = this.counties.find(
              (c) => c.code == this.user.receiptBuyerCountryId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiAddressUpdate() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/profile/address`, {
          addressOne: this.user.addressOne,
          addressTwo: this.user.addressTwo,
          amphure: this.user.amphure,
          provinceId: this.province ? this.province.code : null,
          countryId: this.countryId ? this.countryId.code : null,
          postcode: this.user.postcode,
        })
        .then((res) => {
          self.$hideLoader();
          self.$router.push({ name: 'supplier.profile' })
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    },
    apiAddressReceiptUpdate() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/receipt/address`, {
          addressOne: this.user.receiptBuyerAddressOne,
          addressTwo: this.user.receiptBuyerAddressOne,
          amphure: this.user.receiptBuyerAddressOne,
          countryId:  this.receiptBuyerCountryId ? this.receiptBuyerCountryId.code : null,
          postcode: this.user.receiptBuyerAddressOne,
          provinceId:  this.receiptBuyerProvinceId ? this.receiptBuyerProvinceId.code : null,
        })
        .then((res) => {
          self.$hideLoader();
          self.$router.push({ name: 'supplier.profile' })
          self.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    showWebVerify() {
      window.open(
        "/buyer/register-verify/ekyc-new",
        "popup",
        "width=800,height=600"
      );
    },
  },
};
</script>