<template >
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">Buyer</div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1">
                <div class="mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <select v-model="searchStatus" class="form-control">
                      <option value="">สถานะ: ทั้งหมด</option>
                      <option v-for="(item, index) in statusList"
                    :key="index" :value="item.id">สถานะ: {{item.name}}</option>
                    </select>
                  </div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        aria-describedby="button-addon2"
                        v-model="keyword"
                        @keyup.enter="search()"
                      />
                      <div class="input-group-append">
                        <button
                          @click="search()"
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon
                            name="search-outline"
                            class="color-dark"
                          ></eva-icon>
                        </button>
                      </div>
                    </div>
                    <a href="javascript:;" @click="clickExportLink" class="btn border">
                      <eva-icon name="download-outline"></eva-icon> Export
                    </a>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="w-auto">
                        <eva-icon
                          name="more-vertical"
                          class="color-secondary action-icon"
                        ></eva-icon>
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'admin.buyers.show',
                          params: { id: props.rowData.buyerId },
                        }"
                      >
                        <eva-icon
                          width="20px"
                          name="eye-outline"
                          class="color-primary"
                        ></eva-icon>
                        ดูรายละเอียด
                      </b-dropdown-item>
                      <b-dropdown-item
                         :to="{
                          name: 'admin.users.bypass',
                          params: { id: props.rowData.userId },
                        }"
                        active-class="link-active"
                      >
                        <eva-icon
                          width="20px"
                          name="shuffle-2-outline"
                          class="color-primary"
                        ></eva-icon>
                        สวมสิทธิ์
                      </b-dropdown-item>
                      <b-dropdown-item
                        href="#"
                          v-if="$parent.$parent.$parent.currentUser.adminRole == 'owner' || $parent.$parent.$parent.currentUser.adminRole == 'admin'"
                        @click="removeUser(props.rowData.buyerId)"
                      >
                        <eva-icon
                          width="20px"
                          name="trash-2-outline"
                          class="color-danger"
                        ></eva-icon>
                        ลบ
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber,props.rowData.statusName)"
                  ></div>
                  <div slot="fullname" slot-scope="props">
                    <div class="d-flex align-items-center">
                      <b-avatar class="icon-profile mr-2" :text="props.rowData.email.substr(0,1)"></b-avatar>
                      <span>{{props.rowData.fullname}}</span>
                    </div>
                  </div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                    <select v-model="perPage" class="form-control mx-1">
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="30">30</option>
                      <option :value="50">50</option>
                    </select>
                    <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
<vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-bold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <div>สถานะ Buyer</div>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    :class="
                      'row py-2-1 ' +
                      (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered title="ต้องการลบ Buyer">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="danger" class="" @click="apiRemoveUser()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/admin_buyer.js";
import Status from "../../../_fields/status_supplier.js";
import _ from "lodash";

export default {
  name: "Buyer",
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      fields: FieldsDef,
      statusList: Status,
      perPage: 10,
      tablePaginationTotal: 0,
      data: [],
      searchStatus: '',
      keyword: "",
      exportLink: ''
    };
  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();this.$refs.vuetable.refresh();
    },perPage(){
      this.$refs.vuetable.refresh();
    },
    searchStatus(){
      this.apiGetList();
    },
  },

  mounted() {
    this.apiGetList();
  },

  methods: {
    apiGetList(keyword = '') {
      let url = "api/admins/buyers/index";
      url = url + "?keyword=" + this.keyword+'&status='+ this.searchStatus;
      this.$http.get(url).then((response) => {
        this.data = response.data.data;
        this.exportLink = response.data.exportLink;
      });
    },
    clickExportLink(){
      let url = this.exportLink;
      url = url + "?keyword=" + this.keyword+'&status='+ this.searchStatus;
      window.open(url)
    },
    search() {
      this.apiGetList();
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          string +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-secondary'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    removeUser(id) {
      this.removeId = id;
      this.$bvModal.show("modalApprove");
    },
    apiRemoveUser() {
      let self = this;
      this.$showLoader();
      this.$http
        .delete(`/api/admins/buyers/` + this.removeId + `/destroy`)
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$bvModal.hide("modalApprove");
          this.apiGetList()
        });
    },
  },
};
</script>
<style src="@/assets/semantic-ui-css/semantic.css">
</style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
</style>