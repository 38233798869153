<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ช่วยเหลือ > </span>
          <span class="font-weight-semibold">เงื่อนไขและข้อกำหนด</span>
          <div class="h3 mt-4">เงื่อนไขและข้อกำหนด</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
         
          <div v-if="data" class="col-lg-12">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="p-lg-3">
                    <div class="row">
                       <div class="col-12">
                         <div v-html="data.termOfUseBuyer"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "termOfUseBuyer",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.apiGetData();
  },
  methods: {
   
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/termofuse`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
};
</script>
