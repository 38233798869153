<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">
          Supplier Merchant ID: {{ MerchantId }}
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1">
                <div class="mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <div class="px-2">
                      <downloadexcel
                        class="btn border"
                        :fetch="fetchData"
                        :fields="exportFields"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        type="xls"
                        name="ข้อมูลการโอนเงินของ Supplier.xls"
                      >
                        <eva-icon name="download-outline"></eva-icon>Export to
                        xls
                      </downloadexcel>
                    </div>
                    <div class="px-2">
                      <downloadexcel
                        class="btn border"
                        :fetch="fetchData"
                        :fields="exportFields"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        type="csv"
                        name="ข้อมูลการโอนเงินของ Supplier.csv"
                      >
                        <eva-icon name="download-outline"></eva-icon>Export to
                        CSV
                      </downloadexcel>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-3 py-2-1">
                <template>
                  <vuetable
                    ref="vuetable"
                    :api-mode="false"
                    :data="data"
                    :fields="fields"
                    :per-page="perPage"
                    :row-class="$onRowClass"
                    detail-row-component="detail-row"
                    track-by="index"
                  >
                  <template slot="index" slot-scope="props">
                    {{ props.rowIndex + 1}}
                  </template>
                  </vuetable>
                  <div style="padding-top: 10px" class="footer-table">
                    <div class="ui floated menu">
                      <div class="d-flex align-items-center">
                        <div>Showing</div>
                        <select v-model="perPage" class="form-control mx-1">
                          <option :value="10">10</option>
                          <option :value="20">20</option>
                          <option :value="30">30</option>
                          <option :value="50">50</option>
                        </select>
                        <div>items</div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="px-3 py-2-1 summary-block">
                <div class="text-right summary-content">
                  <div class="row">
                    <div class="col-md-4 offset-md-8 text-left">
                      <div class="row">
                        <div class="col-8">ยอดเงินรวม (฿) (Total)</div>
                        <div class="col-4">
                          <span class="h4">{{ summary }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">ยอดเงินที่ต้องโอนให้ Supplier (฿)</div>
                        <div class="col-4">
                          <span class="h4">{{ summaryToSupplier }}</span> 
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">Fee Net</div>
                        <div class="col-4">
                          <span class="h4">{{ summaryFeeNet }}</span> 
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">รวมรายการ (Transaction)</div>
                        <div class="col-4">
                          <span class="h4">{{ paymentCount }}</span> รายการ
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-bold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <div>สถานะรายการชำระเงิน</div>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    :class="
                      'row py-2-1 ' +
                      (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNum }from "@/_formats/number";
import { formatDate } from "@/_formats/date";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Vuetable from "vuetable-2";
import VuetablePagination from "@/components/pagination/VuetablePagination";
import FieldsDef from "@/_fields/merchant_transaction.js";
import status from "@/_fields/status.js";
import axios from "axios";
import downloadexcel  from "vue-json-excel";
export default {
  name: "TransactionTransfer",
  components: {
    Vuetable,
    VuetablePagination,
    DateRangePicker,
    downloadexcel
  },
  data() {
    return {
      monthCode: this.$route.params.tranfercode,
      supplierId: this.$route.params.supplierId,
      MerchantId: this.$route.params.merchantId,
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      paymentStatus: null,
      errors: [],
      data: [],
      summary: null,
      summaryToSupplier: null,
      summaryFeeNet: null,
      paymentCount: null,
      fields: FieldsDef,
      perPage: 10,
      tablePaginationTotal: 0,
      exportFields: null
    };
  },
  created() {
    this.apiGetData();
  },

  methods: {
    apiGetData(){
      this.$showLoader();
      let config = {
        method: "get",
        withCredentials: false,
        url: `${process.env.VUE_APP_APIGO}/transferdetlist/${this.monthCode}/${this.supplierId}`,
         headers: { 
          'apikey': process.env.VUE_APP_API_KEY },
      };
       axios(config).then(response => {
         this.data = response.data;
         this.MerchantId = this.data[0].MerchantId
         this.paymentStatus = this.data[0].Status
         this.paymentCount = this.data.length
        let pricing = this.data.map(({ TotalPrice }) => TotalPrice);
        let pricingToSupplier = this.data.map(({ TransferToSupplier }) => TransferToSupplier);
        let BuyerFeeNet = this.data.map(({ BuyerFeeNet }) => BuyerFeeNet);
        let SupplierFeeNet = this.data.map(({ SupplierFeeNet }) => SupplierFeeNet);
        let BuyerFeeNetSummary = BuyerFeeNet.reduce( (x, y) => parseFloat(x) + parseFloat(y));
        let SupplierFeeNetSummary = SupplierFeeNet.reduce( (x, y) => parseFloat(x) + parseFloat(y));
        let summary = pricing.reduce((x, y) =>  parseFloat(x) + parseFloat(y));
        this.summary = formatNum.price(summary, 2);
        this.summaryToSupplier = formatNum.price(pricingToSupplier.reduce((x, y) => parseFloat(x) + parseFloat(y)), 2);
        this.summaryFeeNet =  formatNum.price( BuyerFeeNetSummary + SupplierFeeNetSummary, 2)
         this.$hideLoader();
       }).catch(err => {
          this.error = err;
          this.$hideLoader();
       })
    }, 
    async fetchData(){
    //Transfer to Supplier	Payment Ref.	Payment Date	Pay to Supplier Date	MDR (ex.Vat)	MDR Ratio	Supplier Fee	Supplier Fee Vat	Supplier Fee net	MDR Ratio	Buyer Fee	Buyer Fee Vat	Buyer Fee net	Total Fee	Total Fee net	Bank fee	Bank fee vat	Net bank	Margin (฿)	Margin (%)
      let exportField = {
        "Supplier Merchant ID": "MerchantId",
        "Payment ID": "PaymentId",
        "Ref No.": "RefNo",
        "Card Number": "CreditCard",
        "Invoice Amount": "Amount",
        "Total": "TotalPrice", 
        "Transfer to Supplier": "TransferToSupplier",
        "Payment Ref": "RefDoc", 
        "Payment Date": {
            field: "PaymentDate",
            callback: (PaymentDate) => {
                return formatDate.thai(PaymentDate); 
            }
        },
        "วันที่โอนเงินให้ Supplier": {
            field: "PayToSupplierDate",
            callback: (PayToSupplierDate) => {
                return formatDate.thai(PayToSupplierDate); 
            }
        },
        "MDR (ex.Vat)": { field: "MDR", callback: MDR => `${MDR}%`} ,
        "MDR Ratio (Supplier)": {field: "SupplierFeeRate", callback: SupplierFeeRate => `${SupplierFeeRate}%`} ,
        "Supplier Fee": "SupplierFee" ,
        "Supplier Fee Vat": "SupplierFeeVat" ,
        "Supplier Fee net": "SupplierFeeNet" ,
        "MDR Ratio (Buyer)": { field: "BuyerFeeRate", callback: BuyerFeeRate => `${BuyerFeeRate}%`} ,
        "Buyer Fee": "BuyerFee" ,
        "Buyer Fee Vat": "BuyerFeeVat" ,
        "Buyer Fee net": "BuyerFeeNet" ,
        "Total Fee": "Fee", 
        "Total Fee Vat": "FeeVAT", 
        "Total Fee net": "FeeNet",
        "Bank Fee": "BankFee",
        "Bank Fee Vat": "BankFeeVat",
        "Bank Fee net": "BankFeeNet",
        "Margin (฿)": "Margin",
        "Margin (%)": "MarginPercent",
      }
      this.exportFields = exportField
     return this.data
    },
    startDownload(){
          this.$showLoader();
    },
    finishDownload(){
         this.$hideLoader();
    }
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
  },
    mounted() {
    this.apiGetData();
  },

};
</script>
<style lang="scss" scoped>
.summary-block {
  border-top: 1px solid #dee2e6;
}
.summary-content {
  padding: 30px;
}
.approved-btn {
    padding-top: 25px;
}
</style>