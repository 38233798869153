<template >
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">แดชบอร์ด</div>
        <div v-if="cardLists" class="row mt-lg-4 mt-md-3">
          <div
            v-for="(item, index) in cardLists"
            :key="index"
            class="col-lg-3 col-md-6"
          >
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom font-weight-bold"
              >
                {{ item.name }}
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-bold">
                      {{( index < 2 ? '฿' : '')}}{{ formatNum.price(item.money, 2).slice(0, -3) }}<small>{{ index < 2 ?
                        '.'+formatNum.price(item.money, 2).slice(-2) : ''
                      }}</small>
                    </div>
                  </div>
                  <div>
                    <div
                      class="text-center bg-primary"
                      v-if="item.statusImage == 1"
                      style="border-radius: 50px; height: 25px; width: 25px"
                    >
                      <i class="eva" style="display: inline-block;">
                        <svg id="payment" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 48 48">
                          <defs>
                            <clipPath id="clip-path">
                              <rect id="Rectangle_2413" data-name="Rectangle 2413" width="48" height="48" transform="translate(161 2705)" />
                            </clipPath>
                          </defs>
                          <g id="payment-2" data-name="payment" transform="translate(-161 -2705)" clip-path="url(#clip-path)">
                            <path id="wallet" d="M30.425,0H7.8a1.561,1.561,0,0,0-1.56,1.56v3.9H4.681a1.561,1.561,0,0,0-1.56,1.56v3.9H1.56A1.561,1.561,0,0,0,0,12.482v23.4a1.561,1.561,0,0,0,1.56,1.56H31.205a6.248,6.248,0,0,0,6.241-6.241V7.021A7.03,7.03,0,0,0,30.425,0ZM6.241,8.581H28.085v2.34H6.241ZM3.121,22.624H7.8a1.56,1.56,0,1,1,0,3.121H3.121Zm31.205,8.581a3.124,3.124,0,0,1-3.12,3.12H3.121V28.865H7.8a4.681,4.681,0,0,0,0-9.362H3.121V14.042h27.3a6.985,6.985,0,0,0,3.9-1.186Zm-3.12-20.362V7.021a1.561,1.561,0,0,0-1.56-1.56H9.362V3.121H30.425a3.9,3.9,0,0,1,.78,7.722Zm0,0" transform="translate(166.555 2710.373)"/>
                          </g>
                        </svg>
                      </i>
                    </div>
                    <div
                      class="text-center bg-danger"
                      v-if="item.statusImage == 2"
                      style="border-radius: 50px; height: 25px; width: 25px"
                    >
                      <eva-icon
                        width="20px"
                        name="cube-outline"
                        class="color-white"
                      ></eva-icon>
                    </div>
                    <div
                      class="text-center bg-warning"
                      v-if="item.statusImage == 3"
                      style="border-radius: 50px; height: 25px; width: 25px"
                    >
                      <eva-icon
                        width="20px"
                        name="briefcase"
                        class="color-white"
                      ></eva-icon>
                    </div>
                    <div
                      class="text-center bg-info"
                      v-if="item.statusImage == 4"
                      style="
                        background: #728cde;
                        border-radius: 50px;
                        height: 25px;
                        width: 25px;
                      "
                    >
                      <eva-icon
                        width="20px"
                        name="people-outline"
                        class="color-white"
                      ></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1 border-bottom font-weight-bold">
                สถิติการชำระเงิน <small class="font-weight-light text-secondary">({{chartDateRange.from}} - {{ chartDateRange.to}})</small>
              </div>

              <div class="px-lg-5 py-lg-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex"><div class="rounded-circle mr-2 mt-1" style="height:15px;width:15px;background-color: #4a5dc1;"></div>ยอดที่ชำระแล้ว<div class="rounded-circle mr-2 ml-4 mt-1" style="height:15px;width:15px;background: #f45668;"></div>ค่าธรรมเนียม</div>
                  <date-range-picker
                    v-model="dateChart"
                    :date-format="dateFormat"
                    :locale-data="datepick.local"
                    opens="left"
                  >
                    <template v-slot:input="dateChart" style="padding: 0px">
                      <div class="input-group mb-3">
                        <input
                          class="form-control w-210px text-center"
                          :value="
                            (dateChart.startDate
                              ? _dateFns(dateChart.startDate) + ' - '
                              : 'ค้นหาจากวันที่ชำระ') +
                            (dateChart.endDate
                              ? _dateFns(dateChart.endDate)
                              : '')
                          "
                        />
                        <div class="input-group-append">
                          <span class="input-group-text p-0 px-2"
                            ><eva-icon
                              name="calendar-outline"
                              class="color-secondary"
                            ></eva-icon
                          ></span>
                        </div>
                      </div>
                    </template>
                  </date-range-picker>
                </div>
                <canvas id="graph" width="400" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div v-if="suppliers" class="row mt-lg-4 mt-md-3">
          <div class="col-lg-4 col-md-6">
            <div class="bg-white h-100 rounded">
              <div
                class="
                  px-3
                  py-2-1
                  d-flex
                  align-items-center
                  justify-content-between
                  border-bottom
                "
              >
                <div>
                  <div class="py-2-1 text-secondary font-weight-bold small">
                    รายการอนุมัติ Supplier
                  </div>
                  <div class="font-weight-bold">{{ suppliers.count }}</div>
                </div>
                <div
                  class="rounded-circle text-white admin-icon"
                  style="background-color: #728cde"
                >
                  <eva-icon
                    name="file-text-outline"
                    class="color-white"
                  ></eva-icon>
                </div>
              </div>
              <div v-if="suppliers.suppliers">
                <div
                  v-for="(item, index) in suppliers.suppliers"
                  :key="index"
                  class="border-bottom"
                >
                  <div
                  @click="$router.push({name:'admin.approve.supplier.approve', params: { id:item.id } })"
                    class="
                      px-3
                      py-2-1
                      d-flex
                      align-items-center
                      justify-content-between
                      cursor-pointer
                    "
                    style="height: 60px"
                  >
                    <div class="d-flex align-items-center">
                      <div v-if="item.email" class="d-flex align-items-center">
                        <b-avatar class="icon-profile mr-2" :text="item.email.substr(0,1)"></b-avatar>
                        <span>{{item.companyName}}</span>
                      </div>
                      <div class="font-weight-bold pl-2">
                        <div class="small font-weight-bold">
                          {{ item.name }}
                        </div>
                        <div class="small text-secondary">
                          {{ item.createdAt }}
                        </div>
                      </div>
                    </div>

                    <span class="badge badge-pill badge-warning">{{
                      item.statusName
                    }}</span>
                  </div>
                </div>

                <div
                  class="px-3 py-1 text-center text-primary font-weight-bold"
                >
                  <router-link :to="{ name: 'admin.approve.suppliers' }">
                    ดูทั้งหมด ></router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="paymentWaitings" class="col-lg-4 col-md-6">
            <div class="bg-white h-100 rounded">
              <div
                class="
                  px-3
                  py-2-1
                  d-flex
                  align-items-center
                  justify-content-between
                  border-bottom
                "
              >
                <div>
                  <div class="py-2-1 text-secondary font-weight-bold small">
                    รายการอนุมัติการชำระเงิน
                  </div>
                  <div class="font-weight-bold">
                    {{ paymentWaitings.count }}
                  </div>
                </div>
                <div class="rounded-circle bg-primary text-white admin-icon">
                  <eva-icon
                    name="clipboard-outline"
                    class="color-white"
                  ></eva-icon>
                </div>
              </div>
              <div
                v-for="(item, index) in paymentWaitings.payments"
                :key="index"
                class="border-bottom"
              > 
                <div
                  @click="$router.push({name:'admin.approve.payments.show', params: { id:item.id } })"
                  class="
                    px-3
                    py-2-1
                    d-flex
                    align-items-center
                    justify-content-between
                    cursor-pointer
                  "
                  style="height: 60px"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold pl-2">
                      <div class="small font-weight-bold">{{ item.name }}</div>
                      <div class="small text-secondary">
                        ตัดบัตร: {{ item.debitDate }}
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <div class="font-weight-bold small">
                      ฿{{ formatNum.price(item.total_price, 2) }}
                    </div>
                    <span class="badge badge-pill badge-warning">{{
                      item.statusName
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="px-3 py-1 text-center text-primary font-weight-bold">
                <router-link :to="{ name: 'admin.approve.payments' }">
                  ดูทั้งหมด ></router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="bg-white h-100 rounded">
              <div
                class="
                  px-3
                  py-2-1
                  d-flex
                  align-items-center
                  justify-content-between
                  border-bottom
                "
              >
                <div>
                  <div class="py-2-1 text-secondary font-weight-bold small">
                    รายการอนุมัติคำขอยกเลิก
                  </div>
                  <div class="font-weight-bold">{{ paymentCancels.count }}</div>
                </div>
                <div class="rounded-circle bg-secondary text-white admin-icon">
                  
                      <i class="eva" style="display: inline-block;left:4px;top:-4px">
<svg version="1.1" id="approve1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" x="0px" y="0px"
                      viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
                    <path class="st0" d="M34.9,29h-5.2v-0.8c0-1.4-0.8-2.6-2-3.3c0.5-2.9,1.6-5.7,3.2-8.3c1.1-1.9,1.3-4,0.8-6.1
                      c-0.6-2.1-1.9-3.8-3.8-4.9c-3.9-2.2-8.8-0.9-11,3c-1.5,2.7-1.4,6.1,0.4,8.6c1.5,2.3,2.4,5,2.8,7.7c-1.2,0.6-2,1.9-2,3.3V29h-5.2
                      C9.6,29,7,31.6,7,34.9v2.3c0,0.8,0.7,1.4,1.5,1.4h0.8v0.8c0,2,1.6,3.7,3.7,3.7h22.1c2,0,3.7-1.6,3.7-3.7v-0.8h0.8
                      c0.8,0,1.5-0.6,1.5-1.4v-2.3C40.8,31.6,38.1,29,34.9,29z M35.7,38.6v0.8c0,0.4-0.3,0.8-0.8,0.8H12.9c-0.4,0-0.8-0.3-0.8-0.8v-0.8
                      H35.7z M28.4,15.3c-1.8,2.9-2.9,6-3.5,9.3h-1.9c-0.4-3.1-1.6-6.2-3.3-8.9l0,0c-1.6-2.3-1-5.6,1.3-7.2c2.3-1.6,5.6-1,7.2,1.3
                      C29.2,11.4,29.3,13.6,28.4,15.3z M20.9,28.3c0-0.4,0.3-0.8,0.8-0.8h4.4c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5
                      V29h-5.9V28.3z M37.9,34.9l0,0.8h-28v-0.8c0-1.6,1.3-3,3-3h22.1C36.5,31.9,37.9,33.2,37.9,34.9z"/>
                    </svg>


                    </i>
                </div>
              </div>
              <div
                v-for="(item, index) in paymentCancels.payments"
                :key="index"
                class="border-bottom"
              >
                <div
                  @click="$router.push({name:'admin.approve.cancellation.request.show', params: { id:item.id } })"
                  class="
                    px-3
                    py-2-1
                    d-flex
                    align-items-center
                    justify-content-between
                  cursor-pointer
                  "
                  style="height: 60px"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold pl-2">
                      <div class="small font-weight-bold">{{ item.name }}</div>
                      <div class="small text-secondary">
                        ตัดบัตร: {{ item.debitDate }}
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <div class="font-weight-bold small">
                      ฿{{ formatNum.price(item.total_price, 2) }}
                    </div>
                    <span class="badge badge-pill badge-warning">{{
                      item.statusName
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="px-3 py-1 text-center text-primary font-weight-bold">
                <router-link
                  :to="{ name: 'admin.approve.cancellation.request' }"
                >
                  ดูทั้งหมด ></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1 d-flex border-bottom justify-content-between">
                <div class="font-weight-bold">รายการที่จะต้องดำเนินการ</div>
                <div class="text-center text-primary font-weight-bold">
                <router-link :to="{ name: 'admin.payments' }">
                  ดูทั้งหมด ></router-link
                >
              </div>
              </div>
              <div class="px-3 py-2-1">
                
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  :row-class="$onRowClass"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                <div slot="buyerFeeStr" slot-scope="props">
                    {{props.rowData.buyerFeeStr}}<br>
                    <small class="text-secondary">({{props.rowData.buyerFeeRate}}%)</small>
                  </div>
                  <div slot="supplierFeeStr" slot-scope="props">
                    {{props.rowData.supplierFeeStr}}<br>
                    <small class="text-secondary">({{props.rowData.supplierFeeRate}}%)</small>
                  </div>
                  <div slot="actions" slot-scope="props">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="w-auto">
                        <eva-icon
                          name="more-vertical"
                          class="color-secondary action-icon"
                        ></eva-icon>
                      </template>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 0"
                        :to="{
                          name: 'admin.approve.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 4"
                        :to="{
                          name: 'admin.approve.cancellation.request.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                         width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          props.rowData.statusNumber != 0 &&
                          props.rowData.statusNumber != 4
                        "
                        :to="{
                          name: 'admin.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="18px"
                          name="clipboard-outline"
                          class="color-dark"
                        ></eva-icon>
                        ตรวจสอบ
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="$cardColumn(props.rowData.cardType,props.rowData.cardNumber)"
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>

                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-bold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <div>สถานะรายการชำระเงิน</div>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    :class="
                      'row py-2-1 ' +
                      (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import Chart from "chart.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/admin_payment.js";
import status from "../../../_fields/status.js";
import {format} from "date-fns"

export default {
  components: {
    Vuetable,
    DateRangePicker,
    VuetablePagination,
  },
  data() {
    return {
      formatNum: formatNum,
      tablePaginationTotal: 0,
      statusList: status.slice(0,7).concat(status.slice(7+1)),
      loaded: false,
      chartdata: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateChart: {
        startDate: null,
        endDate: null,
      },
      chartDateRange: [],
      datepick: {
        local: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          firstDay: 0,
        },
      },
      fields: FieldsDef,
      perPage: 10,
      data: [],
      keyword: "",
      cardLists: {},
      deleteId: null,
      suppliers: {},
      paymentWaitings: {},
      paymentCancels: {},
      searchStatus: [],
      selectStatus: "",
      charts: [],
      exportLink: '',
      myChart: null
    };
  },
  methods: {
    dateFormat(classes) {
      return classes;
    },
    _dateFns(date) {
      if (!date) return null;
      return format(date, 'dd/MMM/yyyy')
    },
    clickExportLink(){
      let url = this.exportLink;
      url = url + "?keyword=" + this.keyword + "&status=" + this.selectStatus;
      if (this.dateRange.startDate) {
        let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
        let endDate = format(this.dateRange.endDate, 'yyyy-MM-dd');
        url = url + "&dateStart=" + startDate + "&dateEnd=" + endDate;
      }
      window.open(url)
    },
    apiGetList(keyword = null) {
      let url = "api/payment/list/";
      url = `${url}?status=0,6`;//url + "?keyword=" + this.keyword + "&status=";
      if (this.dateRange.startDate) {
        let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
        let endDate = format(this.dateRange.endDate, 'yyyy-MM-dd');
        url = url + "&dateStart=" + startDate + "&dateEnd=" + endDate;
      }
      this.$http.get(url).then((response) => {
        let data = response.data.data.paymentLists;
        this.data = data.map((d) => {
          return {
            ...d,
            amountStr: '฿' + formatNum.price(d.amount, 2),
            buyerFeeStr: '฿' + formatNum.price(d.buyerFeeNet, 2),
            supplierFeeStr: '฿' + formatNum.price(d.supplierFeeNet, 2),
            feeStr: '฿' + formatNum.price(d.fee, 2),
            totalAmountStr: '฿' + formatNum.price(d.totalAmount, 2),
          };
        });
      });
    },
    apiGetCharts(){
      let startDate, endDate
      if (this.dateChart.startDate) {
        startDate = format(this.dateChart.startDate, 'yyyy-MM-dd')
        endDate = format(this.dateChart.endDate, 'yyyy-MM-dd')
      }
      let url = `api/payment/chart/${startDate}/${endDate}`
      this.$http.get(url).then((response) => {
        this.charts = response.data.data.chartLists;
        this.chartDateRange = response.data.data.dateDetail;
      });
    },
    apiGetSummary(){
      let url = "api/admins/dashboard";
      this.$http.get(url).then((response) => {
        let data = response.data.data;
        this.cardLists = data.cardLists;
        this.suppliers = data.suppliers;
        this.paymentWaitings = data.paymentWaitings;
        this.paymentCancels = data.paymentCancels;
      });
    },
    search() {
      this.apiGetList(this.keyword);
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    showModalDelete(paymentId) {
      this.deleteId = paymentId;
      this.$bvModal.show("modalApprove");
    },
    apiRemove() {
      let self = this;
      self.$bvModal.hide("modalApprove");
      // this.$showLoader();
      // this.$http
      //   .get(`api/buyers/payment/` + this.deleteId + `/cancel`)
      //   .then((res) => {
      //     self.apiGetList();
      //     self.$bvModal.hide("modalApprove");
      //     self.$hideLoader();
      //     self.$notifications.notify({
      //       message: res.data.message,
      //       icon: "checkmark-circle-2",
      //       horizontalAlign: "center",
      //       verticalAlign: "top",
      //       type: "success",
      //     });
      //   });
    },
    apiSearchStatus() {
      this.$http.get(`api/buyers/payment/status`).then((response) => {
        this.searchStatus = response.data.data;
      });
    },
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage(){
      this.$refs.vuetable.refresh();
    },
    selectStatus(val) {
      this.apiGetList();
    },
    charts(val) {
      let ref = this;
      if(this.myChart != null){
        this.myChart.destroy()
      }
      var ctx = document.getElementById("graph").getContext("2d");
      let month = val.map((d) => {
        return d.month;
      });
      let amount = val.map((d) => {
        return d.value;
      });
      let fee = val.map((d) => {
        return d.fee;
      });
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: month,
          datasets: [
            {
              label: "#ค่าธรรมเนียม",
              data: fee,
              lineTension: 0,
              fill: false,
              borderColor: "#f45668",
              pointBorderWidth: 3,
            },
            {
              label: "#ยอดที่ชำระแล้ว",
              data: amount,
              lineTension: 0,
              fill: false,
              borderColor: "#4a5dc1",
              pointBorderWidth: 3,
            },
          ],
        },
        options: {
          legend: {
            display: false,
            align: 'center',
            labels: {
              padding: 30,
              usePointStyle: true,
              pointStyle: 'circle',
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value) {
                    return "฿" + ref.formatNum.price(value, 2);
                  },
                },
              },
            ],
          },
        },
      });
    },
    dateRange() {
      this.apiGetList();
    },
    dateChart() {
      this.apiGetCharts();
    },
  },
  mounted: function () {
    this.apiGetList();
    this.apiGetCharts();
    this.apiGetSummary();
  },
};
</script>
<style lang="scss" scoped>
.st0{fill:#ffffff;}
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.rounded-circle {
  height: 35px;
  width: 35px;
  text-align: center;
  font-size: 26px;
}
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.w-210px {
  width: 240px;
}
.admin-icon {
  position: relative;
  i {
    top: -3px;
    position: absolute;
    left: 6px;
  }
}
</style>