<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ข้อมูลนิติบุคคล > </span>
          <span class="font-weight-semibold">บัญชีธนาคาร</span>
          <div class="d-flex justify-content-between">
            <div class="h3">บัญชีธนาคาร</div>
            <div class="mt-4" v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'">
              <button @click="$router.push({name:'supplier.juristic.bank.edit'})" class="btn btn-primary"> <eva-icon name="edit-outline" class="color-white"></eva-icon> แก้ไข</button>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">รายละเอียดบัญชี</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-semibold
                      d-flex
                      justify-content-between
                    "
                  >
                    <div>สถานะ</div>
                    <span
                  :class="
                    'badge badge-pill' +
                    (data.statusNumber == 0 || data.statusNumber == 4 ? ' badge-warning' : '') +
                    (data.statusNumber == 2 ? ' badge-danger' : '') +
                    (data.statusNumber == 1 ? ' badge-success' : '') +
                    (data.statusNumber == 5 ? ' badge-light' : '')
                  "
                  >{{ data.statusName }}</span
                >
                  </div>
                  <div class="px-3 pt-4 pb-0">
                    <div class="row">
                      <div class="col-lg-6 mb-4">
                        <div class="h6 font-weight-semibold">ธนาคาร</div>
                        <span class="text-secondary">
                          <img
                            width="26px"
                            :src="data.bankLogo"
                            alt=""
                          />
                          {{ data.bankName }}</span
                        >
                      </div>
                      <div class="col-lg-6 mb-4">
                        <div class="h6 font-weight-semibold">สาขา</div>
                        <span class="text-secondary">{{
                          data.bankBranch
                        }}</span>
                      </div>
                      <div class="col-lg-6 mb-4">
                        <div class="h6 font-weight-semibold">เลขที่บัญชี</div>
                        <span class="text-secondary"
                          >{{ data.bankNumber }}</span
                        >
                      </div>
                      <div class="col-lg-6 mb-4">
                        <div class="h6 font-weight-semibold">ชื่อบัญชี</div>
                        <span class="text-secondary">{{
                          data.bankUsername
                        }}</span>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class="h6 font-weight-semibold">
                          สำเนาหน้าบัญชีธนาคาร
                        </div>
                        <div v-if="data.documents" class="row mt-3">
                          <div
                            v-for="(file, index) in data.documents"
                            :key="index"
                            class="col-md-4 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                                border
                                p-2
                                rounded
                              "
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="file.filename"
                            >
                              <div class="">
                                <img
                                  height="30"
                                  :src="imgFile(file.fileMimeType)"
                                />
                              </div>
                              <div class="pl-1 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name small">
                                    <a :href="file.download" download="">
                                    {{ file.filename }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div>
                                  <a :href="file.download" download="">
                                  <eva-icon
                                    name="download"
                                    class="cursor-pointer color-secondary"
                                  ></eva-icon>
                                  </a>
                                </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Bank",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.apiJuristic();
  },
  methods: {
    apiJuristic() {
      let self = this;
      this.$showLoader();
      this.$http.get(`api/suppliers/bank/profile`).then((res) => {
        this.data = res.data.data;
        self.$hideLoader();
      });
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
  },
};
</script>