<template>
  <div class="container-fluid">
    <div class="row bg-white justify-content-center">
      <div class="col-lg-11 col-md-12 my-5">
        <div class="d-flex justify-content-between">
          <div>
            <ul class="list-unstyled">
              <b-media tag="li">
                <template #aside>
                  <b-avatar
                    v-if="user.email"
                    variant="light"
                    size="4em"
                    class="text-primary icon-profile-m"
                    :text="user.email.substr(0, 1)"
                  ></b-avatar>
                </template>
                <span></span>
                <div class="mt-0 mb-1">
                  <span class="badge badge-primary rounded-pill mr-3 px-3"
                    >Buyer</span
                  >
                  <span
                    v-if="user.statusNumber == 0"
                    class="badge badge-warning rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 1"
                    class="badge badge-success rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 5"
                    class="badge badge-secondary rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <span
                    v-if="user.statusNumber == 2"
                    class="badge badge-danger rounded-pill px-3"
                    >{{user.statusName}}</span
                  >
                  <div class="font-weight-bold">
                    {{ user.email }}
                  </div>
                </div>
              </b-media>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ข้อมูลบัญชี</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>รายละเอียด</h5>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <b-button
                    @click="$router.push({ name: 'buyer.register.kyc.new' })"
                    class="btn-sm"
                    variant="outline-primary"
                  >
                    ยืนยันตัวตนใหม่</b-button
                  >
                </div>
              </div>
              <hr class="m-0" />
              <div class="row pt-md-3">
                <div class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>ชื่อ</b>
                  </div>
                  <div class="text-secondary">
                    {{ user.firstname ? user.firstname : "-" }}
                  </div>
                </div>
                <div class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>นามสกุล</b>
                  </div>
                  <div class="text-secondary">
                    {{ user.lastname ? user.lastname : "-" }}
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>อีเมล</b>
                  </div>
                  <div>
                    <input
                      readonly
                      type="text"
                      class="form-control"
                      v-model="user.email"
                      :class="{ 'is-invalid': errors.email }"
                    />
                    <div v-if="errors.email" class="invalid-feedback d-block">
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>เบอร์โทร</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      @keyup="$numberInput($event)"
                      v-model="user.phone"
                      :class="{ 'is-invalid': errors.phone }"
                    />
                    <div v-if="errors.phone" class="invalid-feedback d-block">
                      {{ errors.phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button @click="submitBtn()" class="mr-3" variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'buyer.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="user.type && user.type != 'juristic_person'"
        class="col-md-10 mb-5"
      >
        <div class="row justify-content-between">
          <div class="col-md-3"></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>ที่อยู่</h5>
                </div>
              </div>
              <hr class="m-0" />
              <div class="row">
                <div class="col-md-12 col-lg-6 p-md-4">
                  <div>
                    <div class="mb-2">
                      <b>ที่อยู่ตามบัตรประชาชน</b>
                    </div>
                    <div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="บ้านเลขที่ ตึก ชั้น"
                          v-model="user.addressOne"
                          :class="{ 'is-invalid': errors.addressOne }"
                        />
                        <div
                          v-if="errors.addressOne"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.addressOne }}
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <input
                          type="text"
                          placeholder="ถนน แขวง"
                          class="form-control"
                          v-model="user.addressTwo"
                          :class="{ 'is-invalid': errors.addressTwo }"
                        />
                        <div
                          v-if="errors.addressTwo"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.addressTwo }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <div>
                        <b>เขต/อำเภอ</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.amphure"
                          :class="{ 'is-invalid': errors.amphure }"
                        />
                        <div
                          v-if="errors.amphure"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.amphure }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>จังหวัด</b>
                      </div>
                      <div>
                        <v-select
                          v-model="province"
                          :options="provinces"
                          :class="{ 'is-invalid': errors.provinceId }"
                        ></v-select>
                        <div
                          v-if="errors.provinceId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.provinceId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>ประเทศ</b>
                      </div>
                      <div>
                        <v-select
                          v-model="countryId"
                          :options="counties"
                          :class="{ 'is-invalid': errors.countryId }"
                        ></v-select>
                        <div
                          v-if="errors.countryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.countryId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>รหัสไปรษณีย์</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.postcode"
                          :class="{ 'is-invalid': errors.postcode }"
                        />
                        <div
                          v-if="errors.postcode"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.postcode }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <b-button
                        @click="apiAddressUpdate()"
                        class="mr-3"
                        variant="primary"
                        >บันทึก</b-button
                      >
                      <b-button
                        @click="$router.push({ name: 'buyer.profile' })"
                        variant="outline-secondary"
                        >ยกเลิก</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 p-md-4">
                  <div>
                    <div class="mb-2">
                      <b>ที่อยู่ปัจจุบัน</b>
                    </div>
                    <div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="บ้านเลขที่ ตึก ชั้น"
                          v-model="user.receiptBuyerAddressOne"
                          :class="{ 'is-invalid': errorsAdd.addressOne }"
                        />
                        <div
                          v-if="errorsAdd.addressOne"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.addressOne }}
                        </div>
                       
                      </div>
                      <div class="mt-3 mb-3">
                        <input
                          type="text"
                          placeholder="ถนน แขวง"
                          class="form-control"
                          v-model="user.receiptBuyerAddressTwo"
                          :class="{ 'is-invalid': errorsAdd.addressTwo }"
                        />
                        <div
                          v-if="errorsAdd.addressTwo"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.addressTwo }}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <div>
                        <b>เขต/อำเภอ</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.receiptBuyerAmphure"
                          :class="{ 'is-invalid': errorsAdd.amphure }"
                        />
                        <div
                          v-if="errorsAdd.amphure"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.amphure }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>จังหวัด</b>
                      </div>
                      <div>
                        <v-select
                          v-model="receiptBuyerProvinceId"
                          :options="provinces"
                          :class="{ 'is-invalid': errorsAdd.provinceId }"
                        ></v-select>
                        <div
                          v-if="errorsAdd.provinceId"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.provinceId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>ประเทศ</b>
                      </div>
                      <div>
                        <v-select
                          v-model="receiptBuyerCountryId"
                          :options="counties"
                          :class="{ 'is-invalid': errorsAdd.countryId }"
                        ></v-select>
                        <div
                          v-if="errorsAdd.countryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.countryId }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div>
                        <b>รหัสไปรษณีย์</b>
                      </div>
                      <div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.receiptBuyerPostcode"
                          :class="{ 'is-invalid': errorsAdd.postcode }"
                        />
                        <div
                          v-if="errorsAdd.postcode"
                          class="invalid-feedback d-block"
                        >
                          {{ errorsAdd.postcode }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <b-button
                        @click="apiAddressReceiptUpdate()"
                        class="mr-3"
                        variant="primary"
                        >บันทึก</b-button
                      >
                      <b-button
                        @click="$router.push({ name: 'buyer.profile' })"
                        variant="outline-secondary"
                        >ยกเลิก</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-lg-6 p-md-4">
                  <div>
                    <div class="mb-2">
                      <b>ที่อยู่ออกใบกำกับภาษี</b>
                    </div>
                    <div>
                       <v-select label="label" v-model="invoiceAddress" :options="invoiceOptions"
                          :reduce="(option) => option.value">
                        </v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <b-button
                        @click="setInvoiceAddress()"
                        class="mr-3"
                        variant="primary"
                        >บันทึก</b-button
                      >
                      <b-button
                        @click="$router.push({ name: 'buyer.profile' })"
                        variant="outline-secondary"
                        >ยกเลิก</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ความปลอดภัย</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านเดิม</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="oldPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.oldPassword }"
                    />
                    <div v-if="errors.oldPassword" class="invalid-feedback d-block">
                      {{ errors.oldPassword }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านใหม่</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="newPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.newPassword }"
                    />
                    <div v-if="errors.newPassword" class="invalid-feedback d-block">
                      {{ errors.newPassword }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="apiChangePass()"
                    class="mr-3"
                    variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'buyer.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.supplierStatusApprove == true" class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ค่าตั้งต้นหลังบ้าน</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-12 p-md-4">
                  <div>
                    <b-form-group class="font-weight-bold" label="ค่าตั้งต้น">
                      <b-form-radio
                        :value="1"
                        v-model="user.defaultSetting"
                        name="adminRole"
                      >
                        Buyer
                      </b-form-radio>
                      <b-form-radio
                        :value="0"
                        v-model="user.defaultSetting"
                        name="adminRole"
                      >
                        Supplier
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="apiChangeSetting()"
                    class="mr-3"
                    variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'supplier.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  data() {
    return {
      user: {},
      errors: {},
      errorsAdd: {},
      error: null,
      oldPassword: "",
      newPassword: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      receiptBuyerCountryId: null,
      receiptBuyerProvinceId: null,
      invoiceAddress: false,
      invoiceOptions: [{
        label: 'ใช้ที่อยู่ตามที่อยู่บัตรประชาชน',
        value: false,
      },{
        label: 'ใช้ที่อยู่ตามที่อยู่ปัจจุบัน',
        value: true,
      }]
    };
  },
  created() {
    this.apiProfile();
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          if(res.data.data.defaultDeliveryAddress){
              this.invoiceAddress = true
          }
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      this.apiProfileUpdate();
    },
    apiProfileUpdate() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/profile`, {
          email: this.user.email,
          phone: this.user.phone,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "buyer.profile" });
        })
        .catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiChangePass() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/users/change/password`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "buyer.profile" });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function(x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.user.provinceId
            );
            this.receiptBuyerProvinceId = this.provinces.find(
              (c) => c.code == this.user.receiptBuyerProvinceId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function(x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.user.countryId
            );
            this.receiptBuyerCountryId = this.counties.find(
              (c) => c.code == this.user.receiptBuyerCountryId
            );
          }, 1000);

          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiAddressUpdate() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/profile/address`, {
          addressOne: this.user.addressOne,
          addressTwo: this.user.addressTwo,
          amphure: this.user.amphure,
          provinceId: this.province ? this.province.code : null,
          countryId: this.countryId ? this.countryId.code : null,
          postcode: this.user.postcode,
        })
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: "buyer.profile" });
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiAddressReceiptUpdate() {
      let self = this;
      this.errorsAdd = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/receipt/address`, {
          addressOne: this.user.receiptBuyerAddressOne,
          addressTwo: this.user.receiptBuyerAddressTwo,
          amphure: this.user.receiptBuyerAmphure,
          countryId: this.receiptBuyerCountryId
            ? this.receiptBuyerCountryId.code
            : null,
          postcode: this.user.receiptBuyerPostcode,
          provinceId: this.receiptBuyerProvinceId
            ? this.receiptBuyerProvinceId.code
            : null,
        })
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: "buyer.profile" });
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function(err) {
          self.errorsAdd = err.response.data.errors.data;
        });
    },
    setInvoiceAddress(){
      let self = this;
      this.$showLoader();
      this.$http
        .patch(`api/buyers/update/invoice/address`, {invoiceAddress: this.invoiceAddress})
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: "buyer.profile" });
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function(err) {
          self.errorsAdd = err.response.data.errors.data;
        });
    },
    apiChangeSetting() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/default/setting`, {
          status: this.user.defaultSetting,
        })
        .then((res) => {
          self.$hideLoader();
          authenticationService.refreshUser(`api/buyers/profile`, true);
        });
    },
    showWebVerify() {
      window.open(
        "/buyer/register-verify/ekyc-new",
        "popup",
        "width=800,height=600"
      );
    },
  },
};
</script>
