<template>
    <div class="row justify-content-center">
        <div class="col-lg-11 col-md-12">
            <div class="page-name mt-lg-4 mt-md-3">
                <div class="h3 mt-4">ขั้นที่ 2: การออกใบกำกับภาษี</div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-3">
                        <h5>ที่อยู่ใบกำกับภาษี</h5>
                        <p>โปรดกรอกข้อมูลให้ครบถ้วน</p>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="col-md-12 col-lg-12 mb-3">
                            <label>ชื่อบริษัทภาษาไทย</label>
                            <p>{{ mainAddress.companyNameTH }}</p>
                        </div>
                        <div class="col-md-12 col-lg-12 mb-3">
                            <label>ชื่อบริษัทภาษาอังกฤษ</label>
                            <p>{{ mainAddress.companyNameEN }}</p>
                        </div>
                        
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label>บ้านเลขที่ ตึก ชั้น</label>
                                <div>
                                    <p>{{ mainAddress.addressOne }}</p>
                                </div>
                                <div class="mt-3">
                                    <label>ถนน แขวง</label>
                                    <p>{{ mainAddress.addressTwo }}</p>
                                </div>
                            </div>
                    
                        <div class="row pl-3">
                            <div class="col-md-6 mb-3">
                                <label>เขต/อำเภอ</label>
                                <p>{{ mainAddress.amphure }}</p>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>จังหวัด</label>
                                <p>{{ mainAddress.provinces.label}}</p>
                            </div>
                        </div>
                        <div class="row pl-3">
                            <div class="col-md-6 mb-3">
                                <label>ประเทศ</label>
                                <p>{{ mainAddress.countryId.label }}</p>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label>รหัสไปรษณีย์</label>
                                <p>{{ mainAddress.postcode }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row mt-lg-4 mb-4">
                <div class="col-lg-3 col-md-4">
                    <div class="mb-3">
                        <h5 class="px-1">ที่อยู่สำหรับการจัดส่งเอกสาร</h5>
                        <label
                            class="px-1 py-2 text-secondary small line-sub">โปรดเลือกที่อยู่สำหรับการจัดส่งเอกสาร</label>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8">
                    <div class="bg-white rounded p-lg-3">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 mb-3">
                                <label v-if="sendAddress === false">รายละเอียด</label>
                                <v-select label="label" v-model="sendAddress" :options="sendAddressOptions"
                                    :reduce="(option) => option.value">
                                </v-select>
                            </div>
                        </div>
                         <div v-if="sendAddress">
                            <div class="row">
                                    <div class="col-md-12 col-lg-12 mb-3">
                                        <label>ชื่อบริษัท</label>
                                        <div>
                                            <input type="text" class="form-control"
                                                v-model="sendAddressCompany"
                                                :class="{ 'is-invalid': errors.sendAddressCompany }" />
                                            <div v-if="errors.sendAddressCompany" class="invalid-feedback d-block">
                                                {{ errors.sendAddressCompany }}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="row">
                                
                                <div class="col-md-12 col-lg-12 mb-3">
                                    <label>รายละเอียด</label>
                                    <div>
                                        <input type="text" class="form-control" placeholder="บ้านเลขที่ ตึก ชั้น"
                                            v-model="sendAddressOne"
                                            :class="{ 'is-invalid': errors.sendAddressOne }" />
                                        <div v-if="errors.sendAddressOne" class="invalid-feedback d-block">
                                            {{ errors.sendAddressOne }}
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <input type="text" placeholder="ถนน แขวง" class="form-control"
                                            v-model="sendAddressTwo"
                                            :class="{ 'is-invalid': errors.sendAddressTwo }" />
                                        <div v-if="errors.sendAddressTwo" class="invalid-feedback d-block">
                                            {{ errors.sendAddressTwo }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label>เขต/อำเภอ</label>
                                    <div>
                                        <input type="text" class="form-control" v-model="sendAmphure"
                                            :class="{ 'is-invalid': errors.sendAmphure }" />
                                        <div v-if="errors.sendAmphure" class="invalid-feedback d-block">
                                            {{ errors.sendAmphure }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>จังหวัด</label>
                                    <div>
                                        <v-select id="recipt" v-model="sendProvince" :options="provinces"
                                            :class="{ 'is-invalid': errors.sendProvinceId }"></v-select>
                                        <div v-if="errors.sendProvinceId" class="invalid-feedback d-block">
                                            {{ errors.sendProvinceId }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>ประเทศ</label>
                                    <div>
                                        <v-select v-model="sendCountryId" :options="counties"
                                            :class="{ 'is-invalid': errors.sendCountryId }"></v-select>
                                        <div v-if="errors.sendCountryId" class="invalid-feedback d-block">
                                            {{ errors.sendCountryId }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>รหัสไปรษณีย์</label>
                                    <div>
                                        <input type="text" class="form-control" v-model="sendPostcode"
                                            :class="{ 'is-invalid': errors.sendPostcode }" maxlength="5" />
                                        <div v-if="errors.sendPostcode" class="invalid-feedback d-block">
                                            {{ errors.sendPostcode }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mb-4">
                <button @click="previousStep()" class="btn btn-outline-secondary border mr-4">
                    ย้อนกลับ
                </button>
                <button @click="submitBtn()" class="btn btn-primary">
                    บันทึก
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        authenticationService
    } from "../../../../_services/authentication.service";
    import { StringFormat }  from "../../../../_formats/string"
    import { formatNum } from "../../../../_formats/number"
    export default {
        name: "JuristicAddress",
        props: ["user", "mainAddress", "provinces", "counties"],
        data() {
            return {
                sendAddressOptions: [{
                    label: 'ใช้ที่อยู่ตามที่อยู่จดทะเบียน',
                    value: false,
                }, {
                    label: 'ระบุที่อยู่',
                    value: true,
                }],
                sendAddressOne: null,
                sendAddressTwo: null,
                sendAmphure: null,
                sendCountryId: null,
                sendProvince: null,
                sendProvinceId: null,
                sendPostcode: null,
                sendAddressCompany: null,
                sendAddress: false,
                sendAddressStatus: this.sendAddress?? 'jursitic',
                errors: []
            }
        },
        methods: {
            submitBtn() {
                const self = this;
                let payload = {
                    sendAddressCompany: this.sendAddressCompany,
                    sendAddressOne: this.sendAddressOne,
                    sendAddressTwo: this.sendAddressTwo,
                    sendAmphure: this.sendAmphure,
                    sendProvinceId: this.sendProvince?.code,
                    sendPostcode: this.sendPostcode,
                    sendCountryId: this.sendCountryId?.code,
                    type: this.user.type
                }
                if(this.sendAddress){
                   this.$http
                    .post(`api/buyers/validate/juristic-send-address`, payload)
                    .then(async (res) => {
                        this.saveAddress(payload)
                    }).catch((err) => {
                        console.log(err.response)
                        self.errors = err.response.data.errors.data;
                    }) 
                }else{
                    this.saveAddress()
                }
            },
            saveAddress(anotherAddress = null) {
                let self = this;
                self.$showLoader();
                const formData = new FormData();
                let juristicAddress = this.mainAddress
                for (const [key, group] of Object.entries(juristicAddress.files)) {
                    for (let i = 0; i < group.length; i++) {
                        formData.append(key, group[i]);
                    }
                }
                if (anotherAddress) {
                    formData.append('sendAddressSelected', this.sendAddressStatus)
                    formData.append('sendAddressCompany', this.sendAddressCompany)
                    formData.append('sendAddressOne', anotherAddress.sendAddressOne)
                    formData.append('sendAddressTwo', anotherAddress.sendAddressTwo)
                    formData.append('sendAmphure', anotherAddress.sendAmphure)
                    formData.append('sendProvinceId', anotherAddress.sendProvinceId)
                    formData.append('sendPostcode', anotherAddress.sendPostcode)
                    formData.append('sendCountryId', anotherAddress.sendCountryId)
                }
                formData.append('firstname', juristicAddress.firstname);
                formData.append('lastname', juristicAddress.lastname);
                formData.append('juristicAddressOne', juristicAddress.addressOne);
                formData.append('juristicAddressTwo', juristicAddress.addressTwo)
                formData.append('juristicAddressAmphure', juristicAddress.amphure)
                formData.append('juristicAddressProvince', juristicAddress.province?.code)
                formData.append('juristicAddressCountry', juristicAddress.countryId?.code)
                formData.append('juristicPostcode', juristicAddress.postcode)
                formData.append('sendAddressStatus', this.sendAddressStatus)
                formData.append('companyNameTH', juristicAddress.companyNameTH);
                formData.append('companyNameEN', juristicAddress.companyNameEN);
                formData.append('juristicNumber', juristicAddress.juristicNumber);
                formData.append('companyPhone', juristicAddress.companyPhone);
                formData.append('companyEmail', juristicAddress.companyEmail);
                if (juristicAddress.companyWebsite) {
                    formData.append('companyWebsite', juristicAddress.companyWebsite);
                }
                formData.append('type', this.mainAddress.type)
                this.$http.patch('/api/buyers/verify-update', formData)
                    .then(async res => {
                        this.$notifications.notify({
                            message: res.data.message,
                            icon: "checkmark-circle-2",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "success",
                        });
                        authenticationService.refreshUser(`api/buyers/profile`, true);
                        
                    })
            },
            previousStep() {
                this.$emit('changedStep', {
                    step: false,
                    errors: Object
                })
            }
        },
        watch: {
            sendAddress() {
                if (this.sendAddress) {
                    this.sendAddressStatus = 'another';
                } else {
                    this.sendAddressStatus = 'jursitic';
                }
            },
            sendPostcode() {
                this.sendPostcode = format.numberic(this.sendPostcode)
            },
            sendAmphure() {
                this.sendAmphure = StringFormat.ThaiString(this.sendAmphure)
            }
        }

    }
</script>

<style>

</style>