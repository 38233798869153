<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div v-if="user && user.stepRegister == 1 && user.type != 'natural_person'" class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-3">
            <h4>ข้อมูลนิติบุคคล</h4>
            <span class="text-secondary small line-sub">รายละเอียด</span>
          </div>
          <div
            v-if="user.type == 'juristic_person'"
            class="col-md-9 mt-5"
          >
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div class="row pt-md-4">
                <div class="col-md-6 form-group">
                  <div>
                    <b>ชื่อ</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="firstname"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>นามสกุล</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="lastname"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (TH)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyNameTh"
                      :class="{ 'is-invalid': errors.companyNameTh }"
                    />
                    <div
                      v-if="errors.companyNameTh"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyNameTh }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (EN)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyNameEng"
                      :class="{ 'is-invalid': errors.companyNameEng }"
                    />
                    <div
                      v-if="errors.companyNameEng"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyNameEng }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>เลขนิติบุคคล</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="juristicNumber"
                      :class="{ 'is-invalid': errors.juristicNumber }"
                    />
                    <div
                      v-if="errors.juristicNumber"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.juristicNumber }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ที่อยู่ปัจจุบัน</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="บ้านเลขที่ ตึก ชั้น"
                      v-model="addressOne"
                      :class="{ 'is-invalid': errors.addressOne }"
                    />
                    <div
                      v-if="errors.addressOne"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.addressOne }}
                    </div>
                  </div>
                  <div class="mt-3 mb-3">
                    <input
                      type="text"
                      placeholder="ถนน แขวง"
                      class="form-control"
                      v-model="addressTwo"
                      :class="{ 'is-invalid': errors.addressTwo }"
                    />
                    <div
                      v-if="errors.addressTwo"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.addressTwo }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เขต/อำเภอ</b>
                  </div>
                  <div>
                    <input type="text" class="form-control"
                      :class="{ 'is-invalid': errors.amphure }"
                     v-model="amphure" />
                     <div
                      v-if="errors.amphure"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.amphure }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>จังหวัด</b>
                  </div>
                  <div>
                    <v-select
                      :class="{ 'is-invalid': errors.provinceId }"
                      v-model="province"
                      :options="provinces"
                    ></v-select>
                    <div
                          v-if="errors.provinceId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.provinceId }}
                        </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>ประเทศ</b>
                  </div>
                  <div>
                    <v-select
                      :class="{ 'is-invalid': errors.countryId }"
                      v-model="countryId"
                      :options="counties"
                    ></v-select>
                    <div
                          v-if="errors.countryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.countryId }}
                        </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>รหัสไปรษณีย์</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="postcode"
                      :class="{ 'is-invalid': errors.postcode }"
                    />
                    <div
                      v-if="errors.postcode"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.postcode }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เบอร์โทรศัพท์บริษัท</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyPhone"
                      @keyup="$numberInput($event)"
                      :class="{ 'is-invalid': errors.companyPhone }"
                    />
                    <div
                      v-if="errors.companyPhone"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyPhone }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>อีเมลบริษัท</b>
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control"
                      v-model="companyEmail"
                      :class="{ 'is-invalid': errors.companyEmail }"
                    />
                    <div
                      v-if="errors.companyEmail"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyEmail }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>เว็บไซต์ (ถ้ามี)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyWebsite"
                      :class="{ 'is-invalid': errors.companyWebsite }"
                    />
                    <div
                      v-if="errors.companyWebsite"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.companyWebsite }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-md-4 pt-2">
              <div class="col-md-12 text-right">
                <b-button @click="submitBtn()" variant="primary"
                  >ยืนยัน</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.stepRegister != 1 && user.type != 'natural_person'">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mt-5">
            <img
              src="../../../assets/image/ekyc-2.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-md-12 text-center mt-3">
            <h3>
              <div class="d-flex justify-content-center">
                  <img
                    class="img-fluid mr-3"
                    style="width: 30px;"
                    src="./../../../assets/icon/success@2x.png"
                    alt=""
                  />คุณผ่านการยืนยันตัวตนเรียบร้อยแล้ว
                  <div style="padding-left:46px"></div>
                </div>
              </h3>
            <h6 class="text-secondary py-3">ระบบได้ทำการยืนยันตัวตนแล้ว</h6>
          </div>
          <div class="col-md-6">
            <div class="bg-white p-4 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h6 class="mb-0">สถานะ</h6>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <span class="badge badge-pill badge-success">อนุมัติ</span>
                </div>
              </div>
              <hr class="m-0" />
              <div class="row">
                <div class="col-md-12 px-md-4 pt-3">
                  <table class="w-100">
                    <tbody v-if="user.type == 'natural_person'">
                      <tr>
                        <th>ชื่อ - นามสกุล</th>
                        <td class="pl-3">{{ user.firstname }} {{ user.lastname }}</td>
                      </tr>
                      <tr>
                        <th>ที่อยู่</th>
                        <td  class="pl-3">{{ user.addressOne }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <th>ชื่อบริษัท</th>
                        <td  class="pl-3">{{ user.companyName }}</td>
                      </tr>

                      <tr>
                        <th>เลขนิติบุคคล</th>
                        <td  class="pl-3">{{ user.companyJuristicNumber }}</td>
                      </tr>
                      <tr>
                        <th>ที่อยู่บริษัท</th>
                        <td  class="pl-3">{{ user.companyAddress }}</td>
                      </tr>
                      <tr>
                        <th>อีเมลบริษัท</th>
                        <td  class="pl-3">{{ user.companyEmail }}</td>
                      </tr>
                      <tr>
                        <th>เบอร์โทรศัพท์บริษัท</th>
                        <td  class="pl-3">{{ user.companyPhone }}</td>
                      </tr>
                      <tr v-if="user.companyWebsite">
                        <th>เว็บไซต์</th>
                        <td  class="pl-3">{{ user.companyWebsite }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9 mt-4 px-5 mb-3 text-right">
            <button
              @click="$router.push({ name: 'buyer.register.verify' })"
              class="btn text-primary mr-3 font-weight-bold"
            >
              ทำต่อภายหลัง
            </button>
            <b-button
              @click="$router.push({ name: 'buyer.credit.create' })"
              variant="primary"
              >ถัดไป</b-button
            >
          </div>
        </div>
      </div>
      <div v-if="user.stepRegister == 1 && user.type == 'natural_person'">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mt-5">
            <img
              src="../../../assets/image/ekyc-2.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-md-12 text-center mt-3">
            <h3>
              <div class="d-flex justify-content-center">
                  <img
                    class="img-fluid mr-3"
                    style="width: 30px;"
                    src="./../../../assets/icon/success@2x.png"
                    alt=""
                  />คุณผ่านการยืนยันตัวตนเรียบร้อย
                  <div style="padding-left:46px"></div>
                </div>
              </h3>
            <h6 class="text-secondary py-3">ระบบได้ทำการยืนยันตัวตนแล้ว</h6>
          </div>
          <div class="col-md-6">
            <div class="bg-white p-4 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h6 class="mb-0">สถานะ</h6>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <span class="badge badge-pill badge-success">อนุมัติ</span>
                </div>
              </div>
              <hr class="m-0" />
              <div class="row">
                <div class="col-md-12 px-md-4 pt-3">
                  <table class="w-100">
                    <tbody v-if="user.type == 'natural_person'">
                      <tr>
                        <th>ชื่อ - นามสกุล</th>
                        <td class="pl-3">{{ user.firstname }} {{ user.lastname }}</td>
                      </tr>
                      <tr>
                        <th>ที่อยู่</th>
                        <td  class="pl-3">{{ user.addressOne + user.addressTwo }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9 mt-4 px-5 mb-3 text-right">
            <button
              @click="eKycNext('buyer.register.verify')"
              class="btn text-primary mr-3 font-weight-bold"
            >
              ทำต่อภายหลัง
            </button>
            <b-button
              @click="eKycNext('buyer.credit.create')"
              variant="primary"
              >ถัดไป</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  name: "BuyerRegisterKyc",
  data() {
    return {
      user: {},
      errors: {},
      error: null,
      firstname: "",
      lastname: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      verifyUser: false,
      ekycCode: null,
      ekycSuccess: false,
      fnameTH: "",
      lnameTH: "",
      openWindow: false,
      kychtml: ''
    };
  },
  created() {
    authenticationService.refreshUser(`api/buyers/profile`);
    this.apiProfile();
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiProfile(reload = false) {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          this.fnameTH = this.user.firstname
          this.lnameTH = this.user.lastname
          this.firstname = this.user.firstname
          this.lastname = this.user.lastname
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.errors = {}
      this.$showLoader();
      if (this.user.type == "juristic_person") {
        this.$http
          .post(`api/buyers/register/verify/juristic/person`, {
            firstname: this.fnameTH,
            lastname: this.lnameTH,
            amphure: this.amphure,
            provinceId: this.province ? this.province.code : null,
            countryId: this.countryId ? this.countryId.code : null,
            addressTwo: this.addressTwo,
            addressOne: this.addressOne,
            postcode: this.postcode,
            companyWebsite: this.companyWebsite,
            companyPhone: this.companyPhone,
            companyEmail: this.companyEmail,
            companyNameTh: this.companyNameTh,
            companyNameEng: this.companyNameEng,
            juristicNumber: this.juristicNumber,
          })
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            authenticationService.refreshUser(`api/buyers/profile`, true);
          }).catch(function (err) {
          self.errors = err.response.data.errors.data
        })
      } else {
        this.$http
          .post(`api/buyers/register/verify/natural/person`)
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            authenticationService.refreshUser(`api/buyers/profile`, true);
          });
      }
    },
    showWebVerify() {
      this.kychtml = "<iframe src='/buyer/register-verify/ekyc-new' style='height:700px;width:100%;' title='paysoon'></iframe>"
      this.openWindow = true
    },
    checkEkycNext(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/suppliers/skip/step/register`)
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: 'buyer.register.verify' })
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    eKycNext(linkTo){
      let self = this;
      this.$showLoader();
      this.$http
          .post(`api/buyers/register/verify/natural/person`)
          .then((res) => {
            self.$hideLoader();
            this.$router.push({ name: linkTo })
          });
    }
  },
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
  vertical-align: text-top;
}
th,td{
  padding-top: .4rem;
  padding-bottom: .4rem;
}
</style>