<template>
  <div class="row justify-content-center mb-5">
    <div vi0fclass="col-md-10 my-5">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center mt-5">
          <h3>
            <eva-icon style="background:#FBA414;border-radius: 150px;" name="clock-outline" width="50px" height="50px"
              class="color-white"></eva-icon> ยืนยันตัวตนของคุณ
          </h3>
          <h6 class="text-secondary">กรุณาส่งเอกสารยืนยันตัวตนของคุณมาที่ อีเมล: <span
              style="color:#1A32B1">paysoon@paysolutions.asia</span> </h6>
        </div>
        <div class="col-10 mt-4 px-5 mb-3">
          <div class="row justify-content-center">
            <div class="row justify-content-center">
              <div class="row container">
                <div class="col-md-6 text-center mt-5">
                  <div class="card" style="width: 80%;background:none;border:none">
                    <img class="card-img-top" src="@/assets/image/ekyc2-completed@2x.png" alt="Company Completed">
                  </div>

                </div>
                <div class="col-md-6 text-center mt-5">
                  <div class="card text-left" style="max-width: 100%; border:none">
                    <div class="card-header"
                      style="border-bottom:5px solid #f2f6fb !important; padding:15px 25px 10px 25px">
                      <h5>เอกสารการขอเปิดบัญชีสำหรับ ผู้ซื้อ (ฺBuyer)</h5>
                    </div>
                    <div class="card-body" style="padding: 55px 25px;">
                      <p style="margin-bottom: 5px; ">1. เอกสารสำเนาบัตรประชาชน</p>
                      <p style="margin-bottom: 5px;">2. เอกสารสำเนาทะเบียนบ้าน</p>
                      <p class="card-text" style="margin-top: 15px">
                        <span
                          style="text-decoration-line: underline;">พร้อมเซ็นสำเนาถูกต้องและประทับตราบริษัท</span><br>
                        โดยระบบจะพิจารณาอนุมัติเปิดบัญชี หลังได้รับเอกสารครบถ้วนแล้วภายใน 7 วันทำการ
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <p
                style="margin-top:1rem;background: #FFF3D5;border: 1px solid;border-radius: 5px;color: rgb(226,141,0); padding: 20px;font-size: 20px;font-weight: 600;">
                รอตรวจสอบเอกสาร</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Static",
    data() {
      return {}
    },
  }
</script>

<style>

</style>