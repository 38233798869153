<template>
  <div class="container-fluid">
    <div class="row bg-white justify-content-center">
      <div class="col-md-10 my-5">
        <div class="d-flex justify-content-between">
          <div>
            <ul class="list-unstyled">
              <b-media tag="li">
                <template #aside>
                 <b-avatar v-if="user.email" variant="light" size="4em" class="text-primary icon-profile-m" :text="user.email.substr(0,1)"></b-avatar>
                </template>
                <span></span>
                <div class="mt-0 mb-1">
                  <span class="badge badge-primary rounded-pill mr-3 px-3"
                    >Admin</span
                  >
                  <div class="font-weight-bold">
                    {{ user.email }}
                  </div>
                </div>
              </b-media>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ข้อมูลบัญชี</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h5>รายละเอียด</h5>
                </div>
              </div>
              <hr class="m-0" />
              <div class="row pt-md-3">
                <div class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>ชื่อ</b>
                  </div>
                  <div >
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.firstname"
                    :class="{ 'is-invalid': errors.firstname }"
                    />
                    <div v-if="errors.firstname" class="invalid-feedback d-block">
                      {{ errors.firstname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4 form-group">
                  <div>
                    <b>นามสกุล</b>
                  </div>
                  <div >
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.lastname"
                    :class="{ 'is-invalid': errors.lastname }"
                    />
                    <div v-if="errors.lastname" class="invalid-feedback d-block">
                      {{ errors.lastname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>อีเมล</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.email"
                    :class="{ 'is-invalid': errors.email }"
                    />
                    <div v-if="errors.email" class="invalid-feedback d-block">
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 px-md-4">
                  <div>
                    <b>เบอร์โทร</b>
                  </div>
                  <div>
                    <input
                      @keyup="$numberInput($event)"
                      type="text"
                      class="form-control input-number"
                      v-model="user.phone"
                    :class="{ 'is-invalid': errors.phone }"
                    />
                    <div v-if="errors.phone" class="invalid-feedback d-block">
                      {{ errors.phone }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button @click="submitBtn()" class="mr-3" variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'admin.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 pb-5">
        <div class="row justify-content-between">
          <div class="col-md-3"><h5>ความปลอดภัย</h5></div>
          <div class="col-md-9">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านเดิม</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="oldPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.oldPassword }"
                    />
                    <div v-if="errors.oldPassword" class="invalid-feedback d-block">
                      {{ errors.oldPassword }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 px-4 py-2-1">
                  <div>
                    <b>รหัสผ่านใหม่</b>
                  </div>
                  <div>
                    <input
                      type="password"
                      v-model="newPassword"
                      class="form-control"
                      required
                    :class="{ 'is-invalid': errors.newPassword }"
                    />
                    <div v-if="errors.newPassword" class="invalid-feedback d-block">
                      {{ errors.newPassword }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-12 px-md-4 pb-md-3">
                  <b-button
                    @click="apiChangePass()"
                    class="mr-3"
                    variant="primary"
                    >บันทึก</b-button
                  >
                  <b-button
                    @click="$router.push({ name: 'admin.profile' })"
                    variant="outline-secondary"
                    >ยกเลิก</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  data() {
    return {
      user: {},
      error: null,
      errors:{},
      oldPassword: "",
      newPassword: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      receiptBuyerCountryId: null,
      receiptBuyerProvinceId: null
    };
  },
  created() {
    this.apiProfile();
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      this.apiProfileUpdate();
    },
    apiProfileUpdate() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/buyers/update/profile`, {
          email: this.user.email,
          phone: this.user.phone,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          authenticationService.refreshUser(`api/buyers/profile`);
          this.$router.push({ name: 'admin.profile' })
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    apiChangePass() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/users/change/password`, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        this.$router.push({ name: 'admin.profile' })
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
  },
};
</script>