export default [

  {
    name: 'fullname',
    title: 'ชื่อ',
    sortField: 'fullname',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'type',
    title: 'ประเภท',
    sortField: 'type',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'email',
    title: 'อีเมล',
    sortField: 'email',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'phone',
    title: 'โทร',
    sortField: 'phone',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    title: 'สถานะ',
    name: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
 
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned",
  }
 
]