<template >
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-semibold" :to="{name:'supplier.users'}">จัดการผู้ใช้ > </router-link></span>
          <span class="font-weight-semibold">แก้ไขข้อมูลผู้ใช้</span>
          <div class="h3 mt-4">แก้ไขข้อมูลผู้ใช้</div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-2">
              <h5 class="px-1 font-weight-semibold">บัญชีผู้ใช้</h5>
              <div class="px-1 py-2 text-secondary small line-sub"
                >เพิ่มผู้ใช้งานและกำหนดสิทธิ์ของการเข้าถึงหลังจากนั้นระบบจะส่งการยืนยันการเข้าถึงให้กับผู้ใช้งานผ่านทางอีเมล</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="row">
                <div class="col-md-6 mb-3 form-group">
                  <label>ชื่อ</label>
                  <div>
                    <input
                      type="text"
                      v-model="firstname"
                      class="form-control"
                    :class="{ 'is-invalid': errors.firstname }"
                    />
                    <div
                      v-if="errors.firstname"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.firstname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-group">
                  <label>นามสกุล</label>
                  <div>
                    <input
                      type="text"
                      v-model="lastname"
                      class="form-control"
                    :class="{ 'is-invalid': errors.lastname }"
                    />
                    <div
                      v-if="errors.lastname"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.lastname }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-group">
                  <label>อีเมล</label>
                  <div>
                    <input
                      type="email"
                      v-model="email"
                      class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                    />
                    <div
                      v-if="errors.email"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.email }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-3 form-group">
                  <label>เบอร์โทร
                    <small class=" text-secondary">(Optional)</small>
                  </label>
                  <div>
                    <input
                      type="text"
                      v-model="phone"
                      class="form-control"
                      @keyup="$numberInput($event)"
                    :class="{ 'is-invalid': errors.phone }"
                    />
                    <div
                      v-if="errors.phone"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.phone }}
                    </div>
                  </div>
                </div>
                <div v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'" class="col-md-12 form-group">
                  <label>รหัสผ่าน</label>
                  <div class="">
                    <button @click="resetPass" type="button" class="btn btn-outline-primary">
                      <eva-icon name="lock-outline"></eva-icon>
                      รีเซตรหัสผ่าน
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-2">
          <div class="col-lg-3 col-md-4">
            <div class="mb-3">
              <h5 class="px-1 font-weight-semibold">สิทธิการเข้าถึง</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded pt-3 pl-3 pr-3">
              <div class="">
                <div class="">
                  <b-form-group
                    class="font-weight-semibold"
                    label="สิทธิการเข้าถึงของผู้ใช้"
                  >
                    <b-form-radio
                      v-for="(item, index) in options"
                      :key="index"
                      :value="item.value"
                      v-model="adminRole"
                      name="adminRole"
                      size="lg"
                    >
                      <small>
                        <label>{{ item.title }}</label>
                        <div class="mb-3">
                          <span class="text-secondary">
                          {{ item.text }}</span>
                        </div>
                      </small>
                    </b-form-radio>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'" class="">
          <div class="text-right">
            <b-button
              class="mr-3"
              @click="$router.push({ name: 'supplier.users' })"
              variant="outline-secondary"
              >ยกเลิก</b-button
            >
            <b-button @click="submitBtn()" variant="primary">บันทึก</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserShow",
  components: {},
  data() {
    return {
      selected: null,
      showPass: false,
      errors:{},
      options: [
        {
          text: "สามารถจัดการแลพแก้ไขข้อมูลบริษัท จัดการผู้ใช้ และดูข้อมูลของรายการชำระเงิน ขัอมูลลูกค้าได้",
          value: "admin",
          title: "Admin",
        },
        {
          text: "สามารถดูข้อมูลรายการชำระเงิน ขัอมูลลูกค้าได้",
          value: "staff",
          title: "Staff",
        },
      ],
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
      adminRole: null,
    };
  },
  created() {
    this.apiData();
  },
  methods: {
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/admins/` + this.$route.params.id + `/show`)
        .then((res) => {
          self.$hideLoader();
          let data = res.data.data;
          this.firstname = data.firstname;
          this.lastname = data.lastname;
          this.email = data.email;
          this.phone = data.phone;
          this.adminRole = data.supplierAdminRole;
        });
    },
    submitBtn() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/suppliers/admins/` + this.$route.params.id + `/update`, {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          supplierAdminRole: this.adminRole,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "supplier.users" });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
    resetPass(){
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/users/forgot/password`,{email:this.email})
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "supplier.users" });
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.w-210px {
  width: 210px;
}
</style>