<template>
  <div v-if="showSuccess != null" class="container-fluid">
    <div v-if="verifyUser == false" class="row justify-content-center mb-5">
      <div class="col-md-9 mt-5">
        <div v-if="!openWindow" class="bg-white p-2 rounded mb-1 px-4">
          <div class="row pt-md-4">
            <div class="col-md-12 form-group mt-5 text-center">
              <img
                class="img-fluid"
                src="../../../assets/image/ekyc1.png"
                alt=""
              />
            </div>
            <div class="col-md-12 form-group mb-5 text-center">
              <button @click="showWebVerify()" class="btn btn-primary">
                ยืนยันตัวตนผ่าน e-KYC
              </button>
              <br />
              <span class="small text-danger">*ต้องการกล้องและไมค์โครโฟน</span>
            </div>
          </div>
        </div>
        <div v-html="kychtml" v-else>
        </div>
        <div class="row pt-md-4">
          <div class="col-md-12 text-right">
            <b-button @click="checkEkyc(true)" variant="primary" class="mr-3">ถัดไป</b-button>
            <b-button @click="checkEkycNext()" variant="primary">ข้าม</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="verifyUser == true" class="row justify-content-center mb-5">
      <div v-if="user.stepRegister >= 4 && showSuccess == true">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mt-5">
            <img
              src="../../../assets/image/ekyc-2.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-md-12 text-center mt-3">
            <h3>คุณผ่านการยืนยันตัวตนเรียบร้อยแล้ว</h3>
            <h6 class="text-secondary">ระบบได้ทำการยืนยันตัวตนแล้ว</h6>
          </div>
          <div class="col-md-5">
            <div class="bg-white p-2 rounded mb-1">
              <div class="row">
                <div class="col-md-6 px-md-4 py-2-1">
                  <h6 class="mb-0">สถานะ</h6>
                </div>
                <div class="col-md-6 text-right px-md-4">
                  <span class="badge badge-pill badge-success">อนุมัติ</span>
                </div>
              </div>
              <hr class="m-0" />
              <div class="row">
                <div class="col-md-12 px-md-4 pt-3">
                  <table class="w-100">
                    <tbody>
                      <tr>
                        <th>ชื่อ - นามสกุล</th>
                        <td>{{ firstname }} {{ lastname }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-9 mt-4 px-5 mb-3 text-right">
            <button
              @click="$router.push({ name: 'supplier.register.verify' })"
              class="btn border text-primary mr-3"
            >
              ทำต่อภายหลัง
            </button>
            <b-button @click="submitBtn()" variant="primary">ถัดไป</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  name: "SupplierRegisterKyc",
  data() {
    return {
      user: {},
      error: null,
      firstname: "",
      lastname: "",
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      verifyUser: false,
      ekycCode: null,
      ekycSuccess: false,
      fnameTH: "",
      lnameTH: "",
      showSuccess: false,
      openWindow: false,
      kychtml: ''
    };
  },
  created() {
    // authenticationService.refreshUser(`api/buyers/profile`);
    this.apiProfile();
    this.checkEkyc();
    // this.apiProvinces();
    // this.apiCounties();
  },
  methods: {
    checkEkyc(alert = false) {
      let self = this;
      this.$showLoader();
      this.$http.post(`api/users/ekyc/check/status/register`).then((res) => {
        let data = res.data.data;
        if (data.success === true) {
          this.verifyUser = true;
          this.ekycCode = data.txid;
          this.ekycSuccess = true;
          this.fnameTH = data.card.fnameTH;
          this.lnameTH = data.card.lnameTH;
          this.firstname = data.card.fnameTH;
          this.lastname = data.card.lnameTH;
          this.showSuccess = true;
        } else if (alert == true) {
          this.$notifications.notify({
            message: "ยืนยันตัวตนไม่สำเร็จ",
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "warning",
          });
        }
        self.$hideLoader();
      });
    },
    apiProfile(reload = false) {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      if (this.user.stepRegister == 4) {
        this.$showLoader();
        this.$http
          .get(`api/suppliers/change/step/register/supplier`)
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            this.$router.push({ name: "supplier.register.juristic_person" });
          });
      } else {
        this.$router.push({ name: "supplier.register.juristic_person" });
      }
    },
    
    showWebVerify() {
      this.kychtml = "<iframe src='/buyer/register-verify/ekyc-new' style='height:700px;width:100%;' title='paysoon'></iframe>"
      this.openWindow = true
    },
    checkEkycNext(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/suppliers/skip/step/register`)
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: 'supplier.register.verify' })
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    }
  },
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
}
</style>