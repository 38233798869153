<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">
          <h3>รายการชำระเงิน <span v-if="this.$parent.$parent.$parent.currentUser.role === 'supplier'">ผู้ขาย (Supplier)</span></h3>
          <h5>{{ companyName }}</h5>
        </div>
        <div v-if="cardLists" class="row mt-lg-4 mt-md-3"> 
          <div
            v-for="(item, index) in cardLists"
            :key="index"
            class="col-lg-3 col-md-6 mb-4"
          >
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom title-card-secondary"
              >
                {{ allStatus[item.id].name }} 30 วันล่าสุด (฿)
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-semibold h5 pt-2">
                      ฿{{ formatNum.price(item.money, 2).slice(0, -3) }}.<small>{{
                        formatNum.price(item.money, 2).slice(-2)
                      }}</small>
                    </div>
                    <small>{{ item.countList }} รายการ</small>
                  </div>
                  <div>
                    <div
                      class="text-center"
                      v-if="item.statusImage == 1"
                      style="
                        background: #728cde;
                        border-radius: 50px;
                        height: 30px;
                        width: 30px;
                      "
                    >
                      <eva-icon
                        width="24px"
                        height="28px"
                        name="clock-outline"
                        class="color-white"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 2">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="checkmark-circle-2"
                        class="color-success"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 3">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="alert-circle"
                        class="color-danger"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 4">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="close-circle"
                        class="color-secondary"
                      ></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="bg-white rounded">
              <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                สถิติการชำระเงิน <small class="font-weight-light text-secondary">({{chartDateRange.from}} - {{ chartDateRange.to}})</small>
              </h5>

              <div class="px-lg-5 p-lg-3">
                <div class="form-filter d-flex justify-content-between mb-4">
                  <div class="d-flex">
                    <div
                      class="rounded-circle mr-2 mt-1 mb-2"
                      style="height:15px;width:15px;background-color: #4a5dc1;"
                    ></div>
                    ยอดที่ชำระแล้ว
                  </div>
                  <date-range-picker
                    v-model="dateChart"
                    :locale-data="datepick.local"
                    opens="left"
                  >
                    <template v-slot:input="dateChart" style="padding: 0px">
                      <div class="input-group mb-3">
                        <input
                          class="form-control w-210px text-center"
                          :value="
                            (dateChart.startDate
                              ? _dateFns(dateChart.startDate) + ' - '
                              : 'ค้นหาจากวันที่ชำระ') +
                              (dateChart.endDate
                                ? _dateFns(dateChart.endDate)
                                : '')
                          "
                        />
                        <div class="input-group-append">
                          <span class="input-group-text p-0 px-2"
                            ><eva-icon
                              name="calendar-outline"
                              class="color-secondary"
                            ></eva-icon
                          ></span>
                        </div>
                      </div>
                    </template>
                  </date-range-picker>
                </div>
                <canvas id="graph" width="400" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="p-lg-3">
                <div class="form-filter mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <select
                      class="form-control mr-lg-2 font-weight-semibold"
                      name=""
                      id=""
                      v-model="selectStatus"
                    >
                      <option value="">สถานะ ทั้งหมด</option>
                      <option
                        v-for="(item, index) in searchStatus"
                        :key="index"
                        :value="item.statusNumber"
                      >
                        {{ item.statusName }}
                      </option>
                    </select>
                    <date-range-picker
                      v-model="dateRange"
                      :locale-data="datepick.local"
                      opens="right"
                    >
                      <template v-slot:input="dateRange" style="padding: 0px">
                        <div class="input-group mb-3">
                          <input
                            class="form-control w-210px text-center"
                            :value="
                              (dateRange.startDate
                                ? _dateFns(dateRange.startDate) + ' - '
                                : 'ค้นหาจากวันที่ชำระ') +
                                (dateRange.endDate
                                  ? _dateFns(dateRange.endDate)
                                  : '')
                            "
                          />
                          <div class="input-group-append">
                            <span class="input-group-text p-0 px-2"
                              ><eva-icon
                                name="calendar-outline"
                                class="color-secondary"
                              ></eva-icon
                            ></span>
                          </div>
                        </div>
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        v-model="keyword"
                        aria-describedby="button-addon2"
                        @keyup.enter="apiGetList()"
                      />
                      <div class="input-group-append">
                        <button
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          @click="apiGetList()"
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon name="search-outline"></eva-icon
                          ><i class="fas fa-search fa-2x"></i>
                        </button>
                      </div>
                    </div>
                    <a
                      href="javascript:;"
                      @click="clickExportLink"
                      class="btn border"
                    >
                      <eva-icon name="download-outline"></eva-icon> Export
                    </a>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  :row-class="$onRowClass"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="w-auto">
                        <eva-icon
                          name="more-vertical"
                          class="color-secondary action-icon"
                        ></eva-icon>
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'supplier.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="20px"
                          name="eye-outline"
                          class="color-primary"
                        ></eva-icon>
                        ดูรายละเอียด
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="
                      $cardColumn(
                        props.rowData.cardType,
                        props.rowData.cardNumber
                      )
                    "
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3 status-desc">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-semibold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <h5 class="mb-0">สถานะรายการชำระเงิน</h5>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index" 
                    :class="
                      'row py-2-1 ' +
                        (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNum } from "../../../_formats/number";
import Chart from "chart.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/supplier_payment.js";
import status from "../../../_fields/status.js";
import {format} from "date-fns"

export default {
  components: {
    Vuetable,
    DateRangePicker,
    VuetablePagination,
  },
  data() {
    return {
      formatNum: formatNum,
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      loaded: false,
      chartdata: null,
      dateChart: {
        startDate: null,
        endDate: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      datepick: {
        local: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          firstDay: 0,
        },
      },
      exportLink: "",
      fields: FieldsDef,
      perPage: 10,
      tablePaginationTotal: 0,
      data: [],
      keyword: "",
      cardLists: {},
      deleteId: null,
      suppliers: {},
      paymentWaitings: {},
      paymentCancels: {},
      searchStatus: [],
      selectStatus: "",
      charts: [],
      myChart: null,
      chartDateRange: [],
      companyName: "",
    };
  },
  created() {
    this.apiSearchStatus();
  },

  methods: {
    _dateFns(date) {
      if(!date) return null;
      return format(date, 'dd/MMM/yyyy')
    },
    clickExportLink(){
      let url = `api/exports/payment?keyword=${this.keyword}&status=${this.selectStatus}`
        if (this.dateRange.startDate) {
          let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
          let endDate =  format(this.dateRange.endDate, 'yyyy-MM-dd');
          url = `${url}&dateStart=${startDate}&dateEnd=${endDate}`;
        }
        this.$http.get(url, {
          responseType: 'blob'
          }).then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement('a');
          
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `payments${format(new Date(), 'ddMMyyyy')}.csv`);
            document.body.appendChild(fileLink)
            fileLink.click();
          })
    },
    apiGetList(keyword = null) {
      let url = "api/payment/list";
      url = url + "?keyword=" + this.keyword + "&status=" + this.selectStatus;
      if (this.dateRange.startDate) {
        let startDate = format(this.dateRange.startDate, 'yyyy-MM-dd');
        let endDate = format(this.dateRange.endDate, 'yyyy-MM-dd');
        url = url + "&dateStart=" + startDate + "&dateEnd=" + endDate;
      }
    
      this.$http.get(url).then((response) => {
        let data = response.data.data.paymentLists;
        this.companyName = response.data.data.companyName;
        this.suppliers = response.data.data.suppliers;
        this.paymentWaitings = response.data.data.paymentWaitings;
        this.paymentCancels = response.data.data.paymentCancels;
        this.data = data.map((d) => {
          return {
            ...d,
            amountStr: "฿" + formatNum.price(d.amount, 2),
            feeStr: "฿" + formatNum.price(d.fee, 2),
            totalAmountStr: "฿" + formatNum.price(d.totalNet, 2),
          };
        });
        this.exportLink = response.data.exportLink;
      });
    },
    apiGetCharts(){
      let startDate, endDate
      if (this.dateChart.startDate) {
        startDate = format(this.dateChart.startDate, 'yyyy-MM-dd')
        endDate = format(this.dateChart.endDate, 'yyyy-MM-dd')
      }
      let url = `api/payment/chart/${startDate}/${endDate}`
      this.$http.get(url).then((response) => {
        this.charts = response.data.data.chartLists;
        this.chartDateRange = response.data.data.dateDetail;
      });
    },
    apiSummary(){
      let url = "api/payment/monthly-summary";
      this.$http.get(url).then((response) => {
        this.cardLists = response.data.data.cardLists;
      });
    },
    search() {
      this.apiGetList(this.keyword);
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    showModalDelete(paymentId) {
      this.deleteId = paymentId;
      this.$bvModal.show("modalApprove");
    },
    apiSearchStatus() {
      this.$http.get(`api/buyers/payment/status`).then((response) => {
        this.searchStatus = response.data.data;
      });
    },
    apiRemove() {
    },
  },
  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
    selectStatus(val) {
      this.apiGetList();
    },
    charts(val) {
      let ref = this;
      if (this.myChart != null) {
        this.myChart.destroy();
      }
      var ctx = document.getElementById("graph").getContext("2d");
      let month = val.map((d) => {
        return d.month;
      });
      let amount = val.map((d) => {
        return d.value;
      });
      this.myChart = this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: month,
          datasets: [
            {
              label: `#ยอดที่ชำระแล้ว`,
              data: amount,
              lineTension: 0,
              fill: false,
              borderColor: "#4a5dc1",
              backgroundColor: "#ffffff",
              pointBorderWidth: 3,
            },
          ],
        },
        options: {
          legend: {
            display: false,
            align: "center",
            labels: {
              padding: 30,
              usePointStyle: true,
              pointStyle: "circle",
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function(value) {
                    return "฿" + ref.formatNum.price(value, 2);
                  },
                },
              },
            ],
          },
        },
      });
    },
    dateRange() {
      this.apiGetList();
    },
    dateChart() {
      this.apiGetCharts();
    },
  },
  mounted: function() {
    this.apiGetCharts();
    this.apiGetList();
    this.apiSummary();
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.rounded-circle {
  height: 35px;
  width: 35px;
  text-align: center;
  font-size: 26px;
}
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.w-210px {
  width: 240px;
}
.admin-icon {
  position: relative;
  i {
    top: -3px;
    position: absolute;
    left: 6px;
  }
}
</style>
