export default [
  {
    title: 'Ref no.',
    name: 'refNo',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'refNo',
  },
  {
    title: 'ID',
    name: 'paymentId',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentId',
  },
  {
    title: 'Supplier',
    name: 'supplierName',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'supplierName',
  },
  {
    title: 'จำนวน',
    name: 'amountStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'amount',
  },
  {
    title: 'ค่าธรรมเนียม',
    name: 'feeStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'fee',
  },
  {
    title: 'ยอดรวม',
    name: 'totalAmountStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'totalAmount',
  },
  {
    title: 'บัตร',
    name: 'cardNumber',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
 
  {
    title: 'วันที่ตัดบัตร	',
    name: 'debitCardDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'debitCardDateFormat',
  },
  {
    title: 'วันที่ชำระ',
    name: 'paymentDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentDateFormat',
  },
  {
    title: 'สถานะ',
    name: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned w-120px",
  },
 
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned w-80px",
  }
]