export default [
    {
        id: 0,
        name: "รอตรวจสอบ",
        desc: "บัญชีผู้ใช้ยังไม่ได้ทำการยืนยันตัวตนและลงทะเบียนข้อมูลบริษัท หรือเจ้าหน้าที่กำลังดำเนินการตรวจสอบรายละเอียด",
        class: "badge-warning",
    },
    {
        id: 2,
        name: "ไม่อนุมัติ",
        desc: "บัญชีผู้ใช้ไม่ผ่านการอนุมัติและไม่สามารถเปิดใช้งานได้",
        class: "badge-danger",
    },
    {
        id: 1,
        name: "เปิดใช้งาน",
        desc: "บัญชีผู้ใช้ได้รับการอนุมัติและเปิดใช้งานแล้ว",
        class: "badge-success",
    },
    {
        id: 5,
        name: "ปิดใช้งาน",
        desc: "บัญชีผู้ใช้ถูกปิดใช้งาน",
        class: "badge-secondary",
    }
]