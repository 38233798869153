<template>
  <div>
    <div class="row justify-content-center">
      <div
        v-if="showconfirm == false && showSuccess == false"
        class="col-lg-11 col-md-12"
      >
        <div class="page-name mt-lg-4 mt-md-3 h3">เพิ่มการชำระเงินล่วงหน้า</div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-2">
              <h5 class="px-1 font-weight-semibold">ข้อมูล Supplier</h5>
              <div class="px-1 py-2 text-secondary small line-sub"
                  >โปรดเลือกหมวดหมู่และชื่อผู้รับชำระเงินที่ต้องการ</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8" >
            <div class="bg-white h-100 rounded p-lg-3">
              <div class="mb-3">
                <b-form-radio-group v-if="paymentStatus !== 9" 
                  label="Individual radios"
                  v-model="selectSupplier"
                  :options="selectSupplierOptions"
                  value-field="value"
                  text-field="text"
                  disabled-field="notEnabled"
                  name="selectSupplier"
                >
                </b-form-radio-group>
              </div>
              <div class="">
                <div class="row mb-3" >
                  <div v-if="selectSupplier == 'category'" class="col-12">
                    <label>หมวดหมู่ Supplier</label>
                    <v-select
                      v-model="supplierCategoryId"
                      :options="selectCategories"
                      :class="{
                        'is-invalid':
                          selectSupplier == 'category' &&
                          errors.supplierId &&
                          supplierCategoryId == null,
                      }"
                    ></v-select>
                  </div>
                  <div v-if="selectSupplier == 'all'" class="col-12">
                    <label>Supplier</label>
                    <v-select
                      v-if="paymentStatus !== 9"
                      v-model="supplierId"
                      :options="selectSuppliers"
                      @search="onSearch"
                      :class="{
                        'is-invalid':
                          selectSupplier == 'all' && errors.supplierId,
                      }"
                    ></v-select>
                    <b-form-input class="mt-2" v-if="paymentStatus === 9" v-model="company.companyName" readonly></b-form-input>
                    <div
                      v-if="selectSupplier == 'all' && errors.supplierId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.supplierId }}
                    </div>
                  </div>
                  <div
                    v-if="
                      selectSupplier == 'category' && supplierCategoryId != null
                    "
                    class="col-12"
                  >
                    <label>Supplier</label>
                    <v-select 
                      v-model="supplierId"
                      :options="selectSuppliers"
                      :class="{
                        'is-invalid':
                          selectSupplier == 'category' && errors.supplierId,
                      }"
                    ></v-select>
                  </div>
                  <div
                    v-if="selectSupplier == 'category' && errors.supplierId"
                    class="invalid-feedback d-block col-12"
                  >
                    {{ errors.supplierId }}
                  </div>
                  <div v-if="company.supplierId" class="col-12">
                    <div class="font-weight-semibold mt-3">รายละเอียด Supplier</div>
                    <div class="px-3 py-2-1 border rounded bg-light mt-2">
                      <div class="row">
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >ชื่อ Supplier:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              {{ company.companyName }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >ธนาคาร:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              <img :src="company.bankImage" width="20px" />
                              {{ company.bankName }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >หมวดหมู่:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              {{ company.supplierCategoryName }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >เลขบัญชี:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              {{ company.accountBankNumber }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >ที่อยู่:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              {{ company.fullAdress }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-md-12">
                          <div class="row">
                            <label class="col-5 font-weight-semibold small"
                              >ชื่อบัญชี:</label
                            >
                            <div class="col-7 text-secondary small pl-0">
                              {{ company.accountBankName }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectSupplier == 'new'" class="col-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <label>
                          ชื่อ Supplier <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          v-model="companyNameTh"
                          placeholder="ชื่อบริษัท"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              companyNameTh == '' && errors.companyNameTh,
                          }"
                        />
                        <div class="col-12">
                          <div
                            v-if="errors.companyNameTh"
                            class="invalid-feedback d-block"
                          >
                            {{ errors.companyNameTh }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label>
                          เลขนิติบุคคล
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          v-model="juristicNumber"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              juristicNumber == '' && errors.juristicNumber,
                          }"
                        />
                        <div class="col-12">
                          <div
                            v-if="errors.juristicNumber"
                            class="invalid-feedback d-block"
                          >
                            {{ errors.juristicNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label>
                          เบอร์โทรศัพท์ติดต่อ <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          @keyup="$numberInput($event)"
                          v-model="companyPhone"
                          :class="{
                            'is-invalid':
                              companyPhone == '' && errors.companyPhone,
                          }"
                          class="form-control"
                        />
                        <div class="col-12">
                          <div
                            v-if="errors.companyPhone"
                            class="invalid-feedback d-block"
                          >
                            {{ errors.companyPhone }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <label>อีเมล</label>
                        <input
                          type="email"
                          v-model="companyEmail"
                          :class="{
                            'is-invalid':
                              companyEmail == '' && errors.companyEmail,
                          }"
                          class="form-control"
                        />
                        <div class="col-12">
                          <div
                            v-if="errors.companyEmail"
                            class="invalid-feedback d-block"
                          >
                            {{ errors.companyEmail }}
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <eva-icon
                          name="alert-circle-outline"
                          width="20px"
                          class="color-secondary"
                        ></eva-icon>
                        <span class="text-secondary">
                          ทีมงานจะไม่สามารถอนุมัติรายการได้ทันทีจนกว่าจะดำเนินการกับทาง
                          Supplier เรียบร้อยแล้ว</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-3">
              <h5 class="px-1 font-weight-semibold">เอกสารการชำระเงิน</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white h-100 rounded pt-3 pl-3 pr-3">
              <div class="mb-3">
                <label>Payment Reference</label>
                <div class="row">
                  <div class="col-lg-6">
                    <input
                      v-model="paymentRef"
                      :readonly="paymentStatus === 9"
                      type="text"
                      class="form-control"
                      placeholder="ใส่เลขที่ invoice หรือ เลขที่เอกสาร"
                      :class="{ 'is-invalid': errors.paymentRef }"
                    />
                    <div
                      v-if="errors.paymentRef"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.paymentRef }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="row mb-3">
                  <div class="col-12">
                    <label>อัปโหลดเอกสาร</label>
                  </div>
                  <div class="col-lg-6" v-if="paymentStatus !== 9">
                    <drop-files
                      :class="{ 'is-invalid': errors.files }"
                    ></drop-files>
                    <div
                      class="mt-2 mb-3 small text-secondary text-bottom-file"
                    >
                      ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB สูงสุด
                      10 ไฟล์
                    </div>
                    <div v-if="errors.files" class="invalid-feedback d-block">
                      {{ errors.files }}
                    </div>
                  </div>
                  <div class="col-md-6"> 
                    <div class="row">
                      <div
                        v-for="(file, index) in files"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                      <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                        <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                            </div>
                          </div>
                          <div class="h-100" v-if="file.download">
                            <div class="d-flex align-items-center h-100">
                               <a :href="file.download" download="">
                                    <eva-icon
                                      name="download"
                                      class="cursor-pointer color-secondary"
                                    ></eva-icon>
                                  </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-2">
              <h5 class="px-1 font-weight-semibold">ตั้งค่าการชำระเงิน</h5>
              <div class="px-1 py-2 text-secondary small line-sub">
                  โปรดตรวจสอบให้แน่ใจว่าคุณกำหนดเวลาการชำระเงินล่วงหน้าถูกต้อง<eva-icon name="info-outline" width="20px"
                  height="20px"
                  fill="#6a7b90"
                  id="tooltip-target"></eva-icon>
                   <b-tooltip target="tooltip-target" triggers="hover focus" class="text-primary">
                    <ul class="tool-tip">
                      <li>
                        <p class="small mb-1">เมือสร้างรายการชำระเงินเเล้ว ระบบจะใช้เวลา 1 วันทำการเพื่อพิจารณาอนุมัติ</p>
                        <span class="text-secondary">- สามารถเลือกวันตัดบัตรล่วงหน้าได้ไม่เกิน 7 วัน นับจากวันที่สร้างรายการ (กันวงเงิน) </span>
                      </li>
                      <li><p class="small">ระบบจะโอนเงินให้ผู้ขายภายใน 2 วันทำการนับจากวันตัดบัตร</p></li>
                      <li><p class="small">จุดสีเเดงในปฎิทิน คือ วันหยุดนักขัตฤกษ์ และวันทำการของบริษัท</p></li>
                      <li>
                        <p class="small mb-1">สามารถเลือกวันตัดบัตรเป็นวันใดก็ได้ เเต่การอนุมัติเเละการโอนเงินจะเป็นวันทำการเท่านั้น เช่น</p>
                        <ul class="text-secondary">
                          <li>ผู้ซื้อสร้างรายการ 2 มิ.ย. เเต่วันที่ 3มิ.ย.เป็นวันหยุดนักขัตฤกษ์  4-5มิ.ย. เป็นวันหยุดเสาร์ อาทิตย์</li>
                          <li>ระบบจะพิจารณาอนุมัติวันที่ 6 มิ.ย. </li>
                          <li>ผู้ซื้อสามารถเลือกวันตัดบัตรเป็น วันที่ 7 หรือ 8 มิ.ย.</li>
                          <li>ระบบจะโอนเงินใน 2วันทำการนับจากวันตัดบัตร ได้เเก่ 9 หรือ 10 มิ.ย.</li>
                        </ul>
                      </li>
                  </ul>
                  </b-tooltip>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white h-100 rounded p-lg-3">
              <div class="">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label>จำนวนเงิน</label>
                    <div class="input-group">
                      <input
                       :readonly="paymentStatus === 9"
                      type="number"
                      v-model="price"
                      placeholder="00.00"
                      class="form-control  border-right-0"
                      :class="{ 'is-invalid': errors.price }"
                      />
                      <span class="input-group-append bg-white border-left-0">
                          <span class="input-group-text bg-transparent" style="color:#6a7b90"> ฿</span>
                      </span>
                    </div>
                    <div v-if="errors.price" class="invalid-feedback d-block">
                      {{ errors.price }}
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6 mb-3">
                    <label>วันตัดบัตร</label>
                    <VCalendar v-model="paymentDate"></VCalendar>
                    <div
                      v-if="errors.paymentDate"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.paymentDate }}
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div
                    v-if="
                      (textPaymentDate.message &&
                        textPaymentDate.message != '') ||
                      textPaymentDate.message3
                    "
                    class="col-lg-12 mb-3"
                  >
                    <label>เงินจะถูกโอนในวันที่</label>
                    <div class="">
                      {{ textPaymentDate.message }}
                      <span class="text-primary">{{
                        textPaymentDate.message2
                      }}</span>
                      <span class="text-danger">{{
                        textPaymentDate.message3
                      }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div
                    v-if="paymentStatus === 9"
                    class="col-lg-12 mb-3"
                  >
                    <label>สัดส่วนชำระค่าธรรมเนียม</label>
                    <div>
                      <span>{{
                        data.payment.buyerFeeRate
                      }}%</span>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-3">
              <h5 class="px-1 font-weight-semibold">วิธีการชำระเงิน</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="">
                <label>เลือกบัตรเครดิต</label>
                <div class="row align-items-end mb-3">
                  <div class="col-lg-6 mt-2">
                    <div
                      v-for="(item, index) in credits"
                      :key="index"
                      class="credit-select"
                    >
                      <input
                        type="radio"
                        :id="'cre' + index"
                        name="credit"
                        :value="item.creditCardId"
                        v-model="creditCardId"
                      />
                      <label
                        :class="{
                          'is-invalid': errors.creditCardId,
                        }"
                        class="list select-credit"
                        :for="'cre' + index"
                      >
                        <div>
                          <span>***</span>
                          {{ item.creditCardNumber }}
                          <span style="margin-left: 10px">
                            {{
                              `${item.corpType === "Y" ? "CORP" : "Normal"}: ${
                                item.feeRate
                              }%`
                            }}</span
                          >
                        </div>
                        <div>
                          <img
                            height="20px"
                            :src="imgCard(item.creditCardType)"
                            alt=""
                          />
                        </div>
                      </label>
                    </div>
                    <div class="credit-select">
                      <b-card no-body class="list mb-0">
                        <b-card-header
                          header-tag="header"
                          class="p-0"
                          role="tab"
                        >
                          <div
                            @click="
                              $router.push({ name: 'buyer.credit.create' })
                            "
                          >
                            เพิ่มบัตรใหม่
                          </div>
                        </b-card-header>
                      </b-card>
                    </div>
                    <div
                      v-if="errors.creditCardId"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.creditCardId }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paymentStatus !== 9" class="mt-3 text-right">
              <button class="btn btn-primary" @click="createCalendar()">
                ยืนยัน
              </button>
            </div>
            <div v-else class="mt-lg-4 mt-4 bg-white rounded">
              <div class="p-lg-3">
                <div class="row">
                  <div class="col-lg-7">
                    <h5 class="px-1 font-weight-semibold">ยืนยันการทำรายการ</h5>
                    <div class="px-1 py-2 text-secondary small line-sub">ตกลงทำรายการชำระเงินล่วงหน้าสำหรับการเรียกเก็บเงินของผู้ขาย (Supplier)</div>
                  </div>
                  <div class="col-lg-5 mt-3 text-right">
                   <div class="">
                      <button class="btn btn-outline-danger mr-3" @click="rejectPayment"> <eva-icon name="close-outline"></eva-icon> ปฏิเสธ </button>
                      <button class="btn btn-primary" @click="createCalendar()"> ยืนยัน </button>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showconfirm == true && showSuccess == false"
        class="col-lg-10 col-md-10 col-sm-12"
      >
        <div class="row justify-content-center show-confirm-payment">
          <div class="col-12 text-center">
            <img
              class="img-fluid"
              src="@/assets/image/schedule1.png"
              alt=""
            />
          </div>
          <div class="col-12 text-center mb-4 mt-4">
            <h2>สรุปเพิ่มการชำระเงินล่วงหน้า</h2>
          </div>
          <div v-if="response.rentalCompany" class="col-lg-8 col-xl-6">
            <div class="bg-white rounded">
              <div class="pt-4 pr-4 pl-4 mb-4">
                <div
                  class="
                    h6
                    font-weight-semibold
                    border-bottom
                    pb-2
                    mt-1
                    d-flex
                    justify-content-between
                  "
                >
                  <div>รายการชำระ</div>
                  <div>จำนวน</div>
                </div>
                <div class="pb-2 mt-3 d-flex justify-content-between">
                  <div class="text-secondary">{{ response.supplierName }}</div>
                  <div class="font-weight-semi">{{ formatNum.price(response.rentalCompany, 2) }}฿</div>
                </div>
                <div v-if="paymentStatus !== 9"
                  class="pb-2 mt-1 d-flex justify-content-between"
                  :class="{
                    'border-bottom pb-3': !response.statusShowSpecialFee,
                  }"
                >
                  <div class="text-secondary">ค่าธรรมเนียม</div>
                  <div class="font-weight-semi">{{ formatNum.price(response.fee, 2) }}฿</div>
                </div>
                <div v-else
                  class="h6 pb-2 mt-1 d-flex justify-content-between"
                  :class="{
                    'border-bottom pb-3': !response.statusShowSpecialFee,
                  }"
                >
                  <div class="text-secondary">สัดส่วนชำระค่าธรรมเนียม {{ data.payment.buyerFeeRate }}%</div>
                  <div class="font-weight-semi">{{ formatNum.price(response.fee, 2) }}฿</div>
                </div>
                <div
                  v-if="response.statusShowSpecialFee"
                  class="
                    h6
                    border-bottom
                    pb-3
                    mt-1
                    d-flex
                    justify-content-between
                  "
                >
                  <div class="text-secondary">ค่าธรรมเนียมเพิ่มเติม</div>
                  <div>{{ formatNum.price(response.specialFee, 2) }}฿</div>
                </div>
                <div class="h5 mt-3 d-flex justify-content-between">
                  <div class="">ยอดรวม (รวม VAT 7%)</div>
                  <div class="text-primary">
                    {{ formatNum.price(response.total, 2) }}฿
                  </div>
                </div>
                <div class="h6 pb-4 mt-3 d-flex justify-content-between">
                  <div class="">บัตรเครดิต</div>
                  <div>
                    <img
                      width="36px"
                      v-if="response.creditCardType == 'VISA'"
                      src="@/assets/card/visa1-gray.png"
                      alt=""
                    />
                    <img
                      width="36px"
                      v-if="response.creditCardType == 'MASTER CARD'"
                      src="@/assets/card/mastercard1-gray.png"
                      alt=""
                    />
                    <img
                      width="36px"
                      v-if="response.creditCardType == 'JCB'"
                      src="@/assets/card/jcb1-gray.png"
                      alt=""
                    />

                    {{ response.creditCardNumber }}
                  </div>
                </div>
                <div class="row text-center mb-2">
                  <div class="col-md-6 mb-4">
                    <div class="border p-lg-3 rounded">
                      <span class="font-weight-semibold">วันที่ตัดบัตร</span><br />
                      <span class="text-secondary">{{
                        response.debitPayment
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="border p-lg-3 rounded">
                      <span class="font-weight-semibold">วันที่ชำระ</span><br />
                      <span class="text-secondary">{{
                        response.tranferDate
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectSupplier == 'new'" class="mt-3 text-right mb-5">
              <div class="alert alert-warning text-left" role="alert">
                <div class="d-flex">
                  <eva-icon
                    name="alert-circle-outline"
                    width="20px"
                    class="color-warning"
                  ></eva-icon>
                  <div class="pl-3" style="color: #bc7500">
                    ทางทีมงานจะไม่สามารถอนุมัติรายการได้ทันทีจนกว่าจะดำเนินการกับทาง
                    Supplier เรียบร้อยแล้ว
                  </div>
                </div>
              </div>
            </div>

            <b-form-checkbox
              id="checkbox-1"
              v-model="terms"
              name="checkbox-1"
              class="privacy-policy-text"
              :value="true"
              :unchecked-value="false"
            >
            ข้าพเจ้ารับทราบและยอมรับ <a class="text-promary" href="https://paysoon.net/term-condition.html" target="_blank">ข้อตกลงและเงื่อนไขบริการ</a> เเละ <a class="text-promary" href="https://paysoon.net/privacy-policy.html" target="_blank">นโยบายข้อมูลส่วนบุคคล</a>  รวมทั้ง <a class="text-primary" href="https://paysoon.net/privacy-consent.html" target="_blank">การให้ความยินยอมในการเปิดเผยข้อมูล</a> เพื่อให้ เพย์ โซลูชั่น เก็บ รวบรวม ใช้ข้อมูลส่วนบุคคล ประกอบการสมัครใช้บริการ รวมทั้งแจ้งข่าวสารที่เกี่ยวกับบริการระบบรับชำระเงินออนไลน์

            </b-form-checkbox>
            <div class="mt-3 text-right mb-5">
              <button
                class="btn bg-white border mr-4"
                @click="showconfirm = false"
              >
                ย้อนกลับ
              </button>
              <button
                :disabled="isDisabled"
                class="btn btn-primary"
                @click="createCalendar()"
              >
                เพิ่มการชำระเงินล่วงหน้า
              </button>
            </div>
          </div>
        </div>
      </div>
      <template>
        <div>
          <b-modal
            ref="empty-creditcard-modal"
            hide-footer
            hide-header
            centered
            no-close-on-backdrop
            no-close-on-esc
          >
            <img src="@/assets/image/card1.png" class="mx-auto d-block" />
            <div class="d-block text-center">
              <h5>คุณยังไม่มีบัตรที่ใช้ในการชำระเงิน</h5>
              <p class="text-secondary line-sub">
                กรุณาเพิ่มบัตรเครดิตของคุณให้เรียบร้อยก่อนทำรายการ หากท่านเพิ่มบัตรใช้งานแล้วกรุณาตั้งค่า บัตร default
              </p>
            </div>
            <button
              class="mt-2 btn btn-primary btn-block"
              @click="$router.push({name:'buyer.credit'})"
            >
              เพิ่มบัตรเครดิต
            </button>
            <button
              class="mt-3 btn btn-bg-white border btn-block mb-4"
              @click="$router.push({name: 'buyer.dashboard'})"
            >
              ไว้ทีหลัง
            </button>
          </b-modal>
        </div>
      </template>

      <template>
        <b-modal id="policy-modal" size="lg" scrollable>
            <div v-html="termofuse"></div>
          <template #modal-footer>
            <div class="w-100">
             <b-form-checkbox
              id="checkbox-1"
              v-model="terms"
              name="checkbox-1"
              class="policy"
              :value="true"
              :unchecked-value="false"
            >
              ฉันได้อ่านและยอมรับเงื่อนไข
              <b class="h6">นโยบายการให้บริการ และ นโยบายการชำระเงิน</b>
            </b-form-checkbox>
              <button
                :disabled="isDisabled"
                class="btn btn-primary btn-group-toggle btn-lg"
                @click="createCalendar()"
              >
                เพิ่มการชำระเงินล่วงหน้า
              </button>
            </div>
          </template>
        </b-modal>
      </template>
    </div>
  </div>
</template>
<script>
import DropFiles from "src/components/Files/DropFiles";
import VCalendar from "src/components/Calendar/VCalendar";
import { formatNum }from "../../../_formats/number"; 
export default {
  name: "Calendar",
  components: {
    "drop-files": DropFiles,
    VCalendar
  },
  data() {
    return {
      user: {},
      data: {},
      errors: {},
      formatNum: formatNum,
      selected: "radio1",
      selectSupplier: "all",
      selectSupplierOptions:[
        {text: "เลือกทั้งหมด", value: 'all' },
        { text: "เลือกจากหมวดหมู่", value: 'category'}
      ],
      options: [
        { text: "*** 5432", value: "radio1"},
        { text: "เพิ่มบัตรใหม่", value: "radio2"},
      ],
      counties: [],
      countryId: null,
      btnConfirm: false,
      categories: [],
      suppliers: [],
      selectSuppliers: [],
      selectCategories: [],
      supplierId: null,
      supplierCategoryId: null,
      company: {
          accountBankName: null,
          accountBankNumber: null, 
          bankImage: null, 
          companyId: null, 
          companyName: null, 
          fullAdress: null,
          supplierCategoryId: null, 
          supplierCategoryName: null, 
          supplierId: null, 
          supplierName: null
      },
      credits: [],
      creditCardId: null,
      paymentRef: "",
      paymentStatus: null,
      price: null,
      paymentDate: "",
      paymentSpeed: "",
      fee: 100,
      files: [],
      showconfirm: false,
      response: {},
      showSuccess: false,
      addCredit: {
        ccv: null,
        typeCreditCard: null,
        fullname: null,
        cardNumber: null,
        limitMoney: null,
        expiredDate: null,
      },
      companyEmail: "",
      companyPhone: "",
      juristicNumber: "",
      companyNameTh: "",
      textPaymentDate: {},
      terms: false,
      enabled: false,
      termofuse: null
    };
  },
  created() {
    this.apiData();
    this.apiProfile();
    this.apiCategory();
    this.apiCredit();
    this.apiSuppliers();
    this.apiGetTerms();
  },
  methods: {
    apiProfile() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiData() {
      let self = this;
      this.$http
        .get(`api/buyers/payment/` + this.$route.params.id + `/show`)
        .then((response) => {
          this.data = response.data.data;
          this.price = this.data.payment.amount;
          this.supplierId = {
            label: this.data.supplier.name,
            code: this.data.supplier.id,
            supplierId: this.data.supplier.id,
          };
          this.selectSupplierOptions[1].notEnabled = true
          this.files = this.data.document.files
         if( this.data.detail.statusNumber === 9) {
         };
          this.paymentStatus = this.data.detail.statusNumber??null
          this.paymentRef = this.data.document.paymentReference??null
          self.$http
            .get(
              `api/buyers/supplier/company/` + self.data.supplier.id + `/detail`
            )
            .then((res) => {
              self.company = res.data.data;
              self.$hideLoader();
            });
        });
    },
    apiGetTerms() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/termofuse`)
        .then((res) => {
          this.termofuse = res.data.data.termOfUseBuyer;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search(loading, search, vm) {
      this.$http.get(`api/datas/get/counties?keyword=` + search).then((res) => {
        let counties = res.data.data;
        this.counties = counties.map(function (x) {
          return (x = { label: x.countryName, code: x.countryId });
        });
        loading(false);
      });
    },
    apiSuppliers(cat = null) {
      let self = this;
      this.$showLoader();
      if (cat) {
        this.$http
          .get(`api/buyers/category/` + cat + `/suppliers`)
          .then((res) => {
            let suppliers = res.data.data;
            this.suppliers = suppliers;
            this.selectSuppliers = suppliers.map(function (x) {
              return (x = {
                label: x.companyName,
                code: x.supplierId,
                supplierId: x.supplierId,
              });
            });
            self.$hideLoader();
          })
          .catch(function (err) {
            self.error = err;
            self.$hideLoader();
          });
      } else {
        this.$http
          .get(`api/buyers/suppliers/index/alls`)
          .then((res) => {
            let suppliers = res.data.data;
            this.suppliers = suppliers;
            this.selectSuppliers = suppliers.map(function (x) {
              return (x = {
                label: x.companyName,
                code: x.supplierId,
                supplierId: x.supplierId,
              });
            });
            self.$hideLoader();
          })
          .catch(function (err) {
            self.error = err;
            self.$hideLoader();
          });
      }
    },
    apiCategory() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/category/suppliers`)
        .then((res) => {
          let categories = res.data.data;
          this.categories = categories;
          this.selectCategories = categories.map(function (x) {
            return (x = {
              label: x.categoryName,
              code: x.supplierCategoryId,
              supplierCategoryId: x.supplierCategoryId,
            });
          });
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCredit() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/creditcard/index`)
        .then((res) => {
          this.credits = res.data.data.filter((d) => d.active);
          let cre = this.credits.find(
            ({ statusDefault }) => statusDefault === true
          );
          if (cre) {
            this.creditCardId = cre.creditCardId;
          } else {
            this.$refs["empty-creditcard-modal"].show();
          }
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    rejectPayment(){
      let self = this;
      this.errors = {};
      this.$showLoader();
      let body = {
          paymentId: this.$route.params.id,
          paymentStatus: this.paymentStatus,
          approved: false,
      };
      this.$http.patch('/api/payment/flag-status/updated', body).then((response) => {
        this.$router.push({ name: 'buyer.dashboard'})
      })
    },
    createCalendar() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      let url
      if(this.paymentStatus === 9){
         url =
          self.showconfirm === true && this.enabled === true
          ? `api/payment/${this.$route.params.id }`
          : `api/payment/validate-update-payment`;
        var formData = {
          price: this.price,
          paymentDate: this.paymentDate,
          buyerFeeRate: this.data.payment.buyerFeeRate,
          supplierFeeRate: this.data.payment.supplierFeeRate,
          creditCardId: this.creditCardId,
          supplierId: this.company.supplierId,
        }
        var config = {
            headers: { 
              'Content-Type': 'application/json'
            }
        }
      }else{
        url =
        self.showconfirm === true && this.enabled === true
          ? `api/buyers/calendar/payment/store`
          : `api/buyers/calendar/payment/check/validate`;
        var formData = new FormData();
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
        for (let i = 0; i < this.files.length; i++) {
          formData.append("files[]", this.files[i]);
        }
        formData.append("paymentRef", this.paymentRef);
        formData.append("price", Number(this.price).toFixed(2));
        formData.append("paymentDate", this.paymentDate);
        formData.append("creditCardId", this.creditCardId);
        if (this.selectSupplier == "new") {
          formData.append("supplierRadio", 3);
          formData.append("companyNameTh", this.companyNameTh);
          formData.append("juristicNumber", this.juristicNumber);
          formData.append("companyPhone", this.companyPhone);
          formData.append("companyEmail", this.companyEmail);
          formData.append("supplierId", 0);
        } else {
          formData.append(
            "supplierId",
            this.supplierId ? this.supplierId.supplierId : null
          );
        }
        for (let i = 0; i < this.files.length; i++) {
          formData.append("files[]", this.files[i]);
        }
      }
      if(this.paymentStatus === 9 && self.showconfirm === true && this.enabled === true){
        let updatePayment = {
              paymentId: this.$route.params.id,
              creditCardType: this.response.creditCardType === 'MASTER CARD'? 'Master': this.response.creditCardType,
              creditCardNumber: this.response.creditCardNumber,
              creditCardId: this.response.creditCardId,
              tranferDate: this.response.date.tranferDate,
              debitCardDate: this.response.date.debitPayment,
              total: this.response.total,
              oldFee: this.response.oldFee??0,
              oldFeeVat: this.response.oldFeeVat??0,
              oldTotal: this.response.oldTotal??0,
              supplierFee: this.response.supplierFee, 
              supplierFeeVat: parseFloat(this.response.supplierFeeVat.toFixed(2)), 
              supplierFeeNet: parseFloat(this.response.supplierFeeNet.toFixed(2)), 
              fee: this.response.fee,
              feeVat: parseFloat(this.response.feeVat.toFixed(2)),
              feeNet: parseFloat(this.response.feeNet.toFixed(2))
            }
        this.$http.patch(url, updatePayment, config).then(response => {
            this.$router.push({
                name: "buyer.calendar.checkout",
                params: { paymentId: this.$route.params.id },
              });
        }).catch(err =>{
           self.errors = err.response.data.errors.data;
        })
      }else{
        this.$http
          .post(url, formData, config)
          .then((res) => {
            self.response = res.data.data;
            let response =  res.data.data
            self.$hideLoader();
            if (self.showconfirm === true && this.enabled === true) {
              let id = response.paymentId;
              this.$router.push({
                name: "buyer.calendar.checkout",
                params: { paymentId: id },
              });
          }
          self.showconfirm = true;
        })
        .catch(function (err) {
          self.errors = err.response.data.errors.data;
        });
      }

      
    },
    imgFile(filename) {
      console.log(filename)
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    reloadToBoard() {
      location.href = this.$router.resolve({ name: "buyer.dashboard" }).href;
    },
    createCredit() {
      let self = this;
      this.$showLoader();

      this.$http
        .post(`api/buyers/creditcard/store`, this.addCredit)
        .then((res) => {
          this.credit = res.data.data;
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.apiCredit();
        });
    },
    newWebCreate() {
      window.open("/buyer/credit/create", "popup", "width=1280,height=600");
    },
    imgCard(type) {
      switch (type) {
        case "VISA":
          return require("../../../assets/card/visa1-gray.png");
        case "Master":
          return require("../../../assets/card/mastercard1-gray.png");
        case "JCB":
          return require("../../../assets/card/jcb1-gray.png");
        default:
          return "";
      }
    },
    todayDate() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  computed: {
    isDisabled: function () {
      this.enabled = this.terms;
      return !this.enabled;
    },
  },
  watch: {
    supplierCategoryId(val) {
      if (val == null) return;
      this.apiSuppliers(val.code);
    },
    selectSupplier(val) {
      this.company = {};
      this.supplierCategoryId = null;
      if (val == "all") {
        this.apiSuppliers();
      }
    },
    supplierId(val) {
      if (!val) {
        this.company = {};
        return;
      }
      let sub = this.suppliers.find(
        ({ supplierId }) => supplierId === val.code
      );
      if (sub) {
        let self = this;
        this.$showLoader();
        this.$http
          .get(`api/buyers/supplier/company/` + sub.supplierId + `/detail`)
          .then((res) => {
            this.company = res.data.data;
            self.$hideLoader();
          });
      } else {
        this.company = {};
      }
    },
    paymentDate(val) {
      this.$showLoader();
      this.$http
        .post(`api/buyers/check/paymentdate/on/calendar`, { paymentDate: val })
        .then((res) => {
          this.textPaymentDate = res.data;
          this.$hideLoader();
        });
    },
    paymentRef(value){
        this.paymentRef = value.replace(/[^a-zA-Z0-9-]/g, "");
    },
  },
};
</script>
<style lang="scss">
.btn-group-toggle {
  width: 100% !important;
  label {
    text-align: left;
    width: 100% !important;
    border-radius: 4px !important;
    margin-bottom: 1rem !important;
  }
}
</style>
