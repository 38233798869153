<template>
  <div>
    <h4>Loading...</h4>
    <div id="formSubmit">
    </div>
  </div>
</template>
<script>
export default {
  name: "ChenkoutCalendar",
  data() {
    return {
      fiormSubmit: "",
    };
  },
  created() {
    this.$showLoader();
    this.apiGetFormCheckout();
  },
  methods: {
    apiGetFormCheckout() {
      let self = this;
      this.$showLoader();
      this.$http
        .post(`api/buyers/paysolutions/checkout`, { paymentId: this.$route.params.paymentId })
        .then((res) => {
          console.log(res.data.data);
          $("#formSubmit").html(res.data.data);
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
};
</script>
  