<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div class="col-md-10 my-5">
        <div class="row justify-content-between">
          <div class="col-md-12">
            <h4>แก้ไขข้อมูลลงทะเบียนบริษัท</h4>
          </div>
          <div class="col-md-3">
            <h5 class="mt-4">ข้อมูลบริษัท</h5>
            <span class="text-secondary small line-sub"
              >โปรดใส่ข้อมูลบริษัทตามรายละเอียดของหนังสือรับรองบริษัท</span
            >
          </div>
          <div class="col-md-9 mt-4">
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div v-if="data" class="row pt-md-4">
                <div class="col-md-6 form-group">
                  <div>
                    <b>ธนาคาร</b>
                  </div>
                  <div>
                    <v-select
                      :class="
                        data.bankIdAndReason.approve == 2 ? 'border-danger' : ''
                      "
                      v-model="bankId"
                      :options="banks"
                    >
                    <template v-slot:option="option">
                              <img :src="option.img" width="20px" alt="" />
                              {{ option.label }}
                            </template>
                            </v-select>
                    <span
                      v-if="data.bankIdAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.bankIdAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>สาขา</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.bankBranchAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.bankBranchAndReason.value"
                    />
                    <span
                      v-if="data.bankBranchAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.bankBranchAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เลขที่บัญชี</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.bankNumberAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.bankNumberAndReason.value"
                    />
                    <span
                      v-if="data.bankNumberAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.bankNumberAndReason.reject }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>ชื่อบัญชีธนาคาร</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      :class="
                        data.bankNameAndReason.approve == 2
                          ? 'border-danger'
                          : ''
                      "
                      v-model="data.bankNameAndReason.value"
                    />
                    <span
                      v-if="data.bankNameAndReason.approve == 2"
                      class="text-danger small"
                      >*{{ data.bankNameAndReason.reject }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 text-right mt-3">
              <b-button
                class="mr-3"
                @click="
                  $router.push({ name: 'supplier.register.juristic_person' })
                "
                variant="outline-secondary"
                >ยกเลิก</b-button
              >
              <b-button @click="submitBtn()" variant="primary">บันทึก</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditBank",
  data() {
    return {
      data: "",
      banks: [],
      bankId: null,
    };
  },
  created() {
    this.apiDataBank();
    this.apiBank();
  },
  methods: {
    apiBank() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/banks`)
        .then((res) => {
          let banks = res.data.data;
          this.banks = banks.map(function (x) {
            return (x = { label: x.bankName, code: x.bankId,img: x.bankImage });
            
          });
          setTimeout(() => {
            this.bankId = this.banks.find(
              (c) => c.code == this.data.bankIdAndReason.value
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiDataBank() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/juristics/edit/bank/profile`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      let self = this;
      this.$showLoader();
      let data = {
        bankId: this.bankId
          ? this.bankId.code
          : null,
        bankBranch: this.data.bankBranchAndReason.value,
        bankNumber: this.data.bankNumberAndReason.value,
        bankUsername: this.data.bankNameAndReason.value,
      };
      this.$http
        .post(
          `api/suppliers/juristics/update/bank/profile`,
          data
        )
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "supplier.register.juristic_person" });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
}
</style>