export default [

  {
    name: 'supplierName',
    title: 'Supplier',
    sortField: 'supplierName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'coordinatorName',
    title: 'ผู้ประสานงาน',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'createdAt',
    title: 'วันที่',
    sortField: 'createdAtFormat',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'statusName',
    title: 'สถานะ',
    sortField: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned",
  }
]