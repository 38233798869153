<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            >ข้อมูลนิติบุคคล >
            <router-link
              class="text-secondary font-weight-semibold"
              :to="{ name: 'supplier.juristic.bank' }"
              >บัญชีธนาคาร</router-link
            >
            >
          </span>
          <span class="font-weight-semibold">แก้ไขบัญชีธนาคาร</span>
          <div class="h3 mt-4">แก้ไขบัญชีธนาคาร</div>
        </div>
        <div class="row mt-lg-4 mb-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">รายละเอียดบัญชี</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="row">
                <div class="col-md-6 form-group">
                  <label>ธนาคาร</label>
                  <div>
                    <v-select
                      v-model="bankId"
                      :options="banks"
                      :class="{ 'is-invalid': errors.bankId || report.bankId  }"
                    >
                      <template v-slot:option="option">
                        <img :src="option.img" width="20px" alt="" />
                        {{ option.label }}
                      </template>
                    </v-select>
                    <div
                    v-if="report.bankId"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.bankId }}
                  </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>สาขา</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.bankBranch"
                      :class="{ 'is-invalid': errors.bankBranch || report.bankBranch  }"
                    />
                    <div
                      v-if="errors.bankBranch"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.bankBranch }}
                    </div>
                    <div
                    v-if="report.bankBranch"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.bankBranch }}
                  </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>เลขที่บัญชี</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.bankNumberNoFormat"
                      :class="{ 'is-invalid': errors.bankNumber || report.bankNumber  }"
                    />
                    <div
                      v-if="errors.bankNumber"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.bankNumber }}
                    </div>
                    <div
                    v-if="report.bankNumber"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.bankNumber }}
                  </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>ชื่อบัญชีธนาคาร</label>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data.bankUsername"
                      :class="{ 'is-invalid': errors.bankUsername || report.bankUsername  }"
                    />
                    <div
                      v-if="errors.bankUsername"
                      class="invalid-feedback d-block"
                    >
                      {{ errors.bankUsername }}
                    </div>
                    <div
                    v-if="report.bankUsername"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.bankUsername }}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">เอกสารบัญชีธนาคาร</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="row">
                <div class="col-lg-6">
                  <label>สำเนาหน้าสมุดบัญชีธนาคาร</label>
                </div>
                <div class="col-lg-6">
                  <div class="mt-1">
                    <drop-files :class="{ 'is-invalid': errors.files }" :max="10"></drop-files>
                    <div
                      class="small text-secondary text-bottom-file mb-3"
                    >
                      ไฟล์ .pdf, .jpg, .png, .doc ขนาดไม่เกิน 10 MB
                      สูงสุด 10 ไฟล์
                    </div>
                    <div
                v-if="errors.files"
                class="invalid-feedback d-block"
              >
                {{ errors.files }}
              </div>
                  </div>
                  <div class="row">
                    <div
                      v-for="(file, index) in files"
                      :key="index"
                      class="col-12 mb-3 list-file"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="">
                          <img height="60" :src="imgFile(file.name)" />
                        </div>
                        <div class="pl-3 w-70">
                          <div
                            class="h-100 flex-column align-items-start"
                          >
                            <div class="mb-auto name">
                              {{ file.name }}
                            </div>
                            <small class="text-secondary size">{{
                              sizeFile(file.size)
                            }}</small>
                          </div>
                        </div>
                        <div class="h-100">
                          <div class="d-flex align-items-center h-100">
                            <eva-icon
                              @click="remove(files.indexOf(file))"
                              name="close-circle"
                              class="primary cursor-pointer"
                              fill="#6c757d"
                            ></eva-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 text-right">
              <button
                class="btn btn-outline-secondary border mr-4"
                @click="$router.push({ name: 'supplier.juristic.bank' })"
              >
                ยกเลิก
              </button>
              <button class="btn btn-primary" @click="submitBtn()">
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropFiles from "src/components/Files/DropFiles";
export default {
  name: "Bank",
  components: {
    "drop-files": DropFiles,
  },
  data() {
    return {
      data: {},
      report:{},
      banks: [],
      errors: {},
      bankId: null,
      files: [],
    };
  },
  created() {
    this.apiJuristic();
    this.apiBank();
  },
  methods: {
    apiJuristic() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http.get(`api/suppliers/bank/profile`).then((res) => {
        this.data = res.data.data;
        this.report = res.data.errors ? res.data.errors : {};
        self.$hideLoader();
      });
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
    apiBank() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/banks`)
        .then((res) => {
          let banks = res.data.data;
          this.banks = banks.map(function(x) {
            return (x = {
              label: x.bankName,
              code: x.bankId,
              img: x.bankImage,
            });
          });
          setTimeout(() => {
            this.bankId = this.banks.find((c) => c.code == this.data.bankId);
          }, 1000);
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    imgFile(filename) {
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    remove(i, group = null) {
      if (group) {
        this.groupFiles[group].splice(i, 1);
      } else {
        this.files.splice(i, 1);
      }
    },
    submitBtn() {
      this.$bvModal.hide("modalApprove");
      let self = this;
      this.$showLoader();
      var formData = new FormData();
      formData.append("bankId", this.bankId ? this.bankId.code : null);
      formData.append("bankBranch", this.data.bankBranch);
      formData.append("bankNumber", this.data.bankNumberNoFormat);
      formData.append("bankUsername", this.data.bankUsername);

      for (let i = 0; i < this.files.length; i++) {
        formData.append("bookBankCopies[]", this.files[i]);
      }
      this.$http
        .post(`api/suppliers/update/bank/profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          this.$router.push({ name: "supplier.juristic.bank" });
        })
        .catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    },
  },
};
</script>
