<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div
        v-if="user.stepRegister == 5 && showSuccess == false"
        class="col-md-10 my-5"
      >
        <div v-if="step == 1" class="row justify-content-between">
          <div class="col-md-3">
            <h4>ลงทะเบียนนิติบุคคล</h4>
            <h5 class="mt-4">รายละเอียด</h5>
            <span class="text-secondary small line-sub"
              >โปรดใส่ข้อมูลบริษัทตามรายละเอียดของหนังสือรับรองบริษัท</span
            >
          </div>
          <div class="col-md-9 mt-5">
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div class="row pt-md-4">
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (TH)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyNameTh"
                    :class="{ 'is-invalid': errors.companyNameTh }"
                    />
                    <div v-if="errors.companyNameTh" class="invalid-feedback d-block">
                      {{ errors.companyNameTh }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ชื่อบริษัท (EN)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyNameEng"
                    :class="{ 'is-invalid': errors.companyNameEng }"
                    />
                    <div v-if="errors.companyNameEng" class="invalid-feedback d-block">
                      {{ errors.companyNameEng }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>เลขนิติบุคคล</b>
                  </div>
                  <div>
                    <input
                      maxlength="13"
                      type="text"
                      class="form-control"
                      v-model="juristicNumber"
                    :class="{ 'is-invalid': errors.juristicNumber }"
                    />
                    <div v-if="errors.juristicNumber" class="invalid-feedback d-block">
                      {{ errors.juristicNumber }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div>
                    <b>ที่อยู่</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="บ้านเลขที่ ตึก ชั้น"
                      v-model="addressOne"
                    :class="{ 'is-invalid': errors.addressOne }"
                    />
                    <div v-if="errors.addressOne" class="invalid-feedback d-block">
                      {{ errors.addressOne }}
                    </div>
                  </div>
                  <div class="mt-3 mb-3">
                    <input
                      type="text"
                      placeholder="ถนน แขวง"
                      class="form-control"
                      v-model="addressTwo"
                    :class="{ 'is-invalid': errors.addressTwo }"
                    />
                    <div v-if="errors.addressTwo" class="invalid-feedback d-block">
                      {{ errors.addressTwo }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เขต/อำเภอ</b>
                  </div>
                  <div>
                    <input type="text" class="form-control" v-model="amphure" :class="{ 'is-invalid': errors.amphure }"
                    />
                    <div v-if="errors.amphure" class="invalid-feedback d-block">
                      {{ errors.amphure }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>จังหวัด</b>
                  </div>
                  <div>
                    <v-select
                      v-model="province"
                      :options="provinces"
                      :class="{ 'is-invalid': errors.provinceId }"
                    ></v-select>
                    <div
                          v-if="errors.provinceId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.provinceId }}
                        </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>ประเทศ</b>
                  </div>
                  <div>
                    <v-select
                      v-model="countryId"
                      :options="counties"
                      :class="{ 'is-invalid': errors.countryId }"
                    ></v-select>
                    <div
                          v-if="errors.countryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.countryId }}
                        </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>รหัสไปรษณีย์</b>
                  </div>
                  <div>
                    <input
                      maxlength="5"
                      type="text"
                      class="form-control"
                      v-model="postcode"
                    :class="{ 'is-invalid': errors.postcode }"
                    />
                    <div v-if="errors.postcode" class="invalid-feedback d-block">
                      {{ errors.postcode }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เบอร์โทรศัพท์บริษัท</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyPhone"
                      @keyup="$numberInput($event)"
                    :class="{ 'is-invalid': errors.companyPhone }"
                    />
                    <div v-if="errors.companyPhone" class="invalid-feedback d-block">
                      {{ errors.companyPhone }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>อีเมลบริษัท</b>
                  </div>
                  <div>
                    <input
                      type="email"
                      class="form-control"
                      v-model="companyEmail"
                    :class="{ 'is-invalid': errors.companyEmail }"
                    />
                    <div v-if="errors.companyEmail" class="invalid-feedback d-block">
                      {{ errors.companyEmail }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เว็บไซต์ (ถ้ามี)</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="companyWebsite"
                    :class="{ 'is-invalid': errors.companyWebsite }"
                    />
                    <div v-if="errors.companyWebsite" class="invalid-feedback d-block">
                      {{ errors.companyWebsite }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>หมวดหมู่ Supplier</b>
                  </div>
                  <div>
                    <v-select
                      v-model="supplierCategoryId"
                      :class="{ 'is-invalid': errors.supplierCategoryId }"
                      :options="categories"
                    ></v-select>
                    <div
                          v-if="errors.supplierCategoryId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.supplierCategoryId }}
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <h5 class="mt-4">บัญชีธนาคาร</h5>
            <span class="text-secondary small line-sub"
              >โปรดระบุรายละเอียดของบัญชีธนาคารเพื่อรับเงินจากบริษัท
              เพย์โซลูชั่น จำกัด</span
            >
          </div>
          <div class="col-md-9 mt-4">
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div class="row pt-md-4">
                <div class="col-md-6 form-group">
                  <div>
                    <b>ธนาคาร</b>
                  </div>
                  <div>
                    <v-select v-model="bankId" :options="banks"
                    :class="{ 'is-invalid': errors.bankId }"
                    >
                      <template v-slot:option="option">
                              <img :src="option.img" width="20px" alt="" />
                              {{ option.label }}
                            </template>
                    </v-select>
                    <div
                          v-if="errors.bankId"
                          class="invalid-feedback d-block"
                        >
                          {{ errors.bankId }}
                        </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>สาขา</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bankBranch"
                    :class="{ 'is-invalid': errors.bankBranch }"
                    />
                    <div v-if="errors.bankBranch" class="invalid-feedback d-block">
                      {{ errors.bankBranch }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>เลขที่บัญชี</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bankNumber"
                    :class="{ 'is-invalid': errors.bankNumber }"
                    />
                    <div v-if="errors.bankNumber" class="invalid-feedback d-block">
                      {{ errors.bankNumber }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div>
                    <b>ชื่อบัญชีธนาคาร</b>
                  </div>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bankUsername"
                    :class="{ 'is-invalid': errors.bankUsername }"
                    />
                    <div v-if="errors.bankUsername" class="invalid-feedback d-block">
                      {{ errors.bankUsername }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-md-4">
              <div class="col-md-12 text-right">
                <b-button @click="nextStep()" variant="primary"
                  >ถัดไป</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row justify-content-between">
          <div class="col-md-3">
            <h4>เอกสารนิติบุคคล</h4>
            <h5 class="mt-4">ดาวน์โหลดเอกสาร</h5>
            <span  class="text-secondary small line-sub"
              >สามารถดาวน์โหลดเอกสารสัญญาเพื่อลงทะเบียนเป็นผู้รับชำระเงินได้ที่นี่</span
            >
            <div class="w-100 mt-5">
              <a
                :href="downloadDoc()"
                class="btn btn-warning text-white btn-sm mt-3"
                download
                target="_blank"
                ><eva-icon name="download-outline" fill="#ffffff"></eva-icon
                >ดาวน์โหลดเอกสารสัญญา</a
              >
            </div>
          </div>
          <div class="col-md-9 mt-5">
            <div class="bg-white rounded mb-1">
              <div class="border-bottom py-2-1 px-4">
                <h5 class="mb-0">อัปโหลดเอกสาร</h5>
              </div>
              <div class="py-2-1 px-4">
                <div class="row">
                  <div class="col-lg-6">
                    <div>
                      <b>หนังสือรับรองบริษัท</b>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files
                        :max="1"
                        group="companyCertificates"
                        :class="{ 'is-invalid': errors.companyCertificates }"
                      ></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        1 ไฟล์
                      </div>
                      <div v-if="errors.companyCertificates"  class="invalid-feedback d-block" >
                            {{ errors.companyCertificates }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file, index) in groupFiles.companyCertificates"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.companyCertificates.indexOf(
                                      file
                                    ),
                                    'companyCertificates'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 border-top my-3"></div>
                  <div class="col-lg-6">
                    <div>
                      <b>สำเนาบัตรประชาชนของกรรมการ</b>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files :class="{ 'is-invalid': errors.idCardCopies }" :max="7" group="idCardCopies"></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        7 ไฟล์
                      </div>
                      <div v-if="errors.idCardCopies"  class="invalid-feedback d-block" >
                            {{ errors.idCardCopies }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file, index) in groupFiles.idCardCopies"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.idCardCopies.indexOf(file),
                                    'idCardCopies'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 border-top my-3"></div>
                  <div class="col-lg-6">
                    <div>
                      <b>สำเนาทะเบียนบ้านของกรรมการ</b>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files
                        :max="7"
                        :class="{ 'is-invalid': errors.houseRegistrationCopies }"
                        group="houseRegistrationCopies"
                      ></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        7 ไฟล์
                      </div>
                      <div v-if="errors.houseRegistrationCopies"  class="invalid-feedback d-block" >
                            {{ errors.houseRegistrationCopies }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file,
                        index) in groupFiles.houseRegistrationCopies"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.houseRegistrationCopies.indexOf(
                                      file
                                    ),
                                    'houseRegistrationCopies'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 border-top my-3"></div>
                  <div class="col-lg-6">
                    <div>
                      <b>แบบฟอร์มใบแจ้งหนี้ (เดือนล่าสุด)</b>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files :class="{ 'is-invalid': errors.invoices }" :max="1" group="invoices"></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        1 ไฟล์
                      </div>
                      <div v-if="errors.invoices"  class="invalid-feedback d-block" >
                            {{ errors.invoices }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file, index) in groupFiles.invoices"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.invoices.indexOf(file),
                                    'invoices'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 border-top my-3"></div>
                  <div class="col-lg-6">
                    <div>
                      <b>สำเนาหน้าสมุดบัญชีธนาคาร</b>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files :class="{ 'is-invalid': errors.bookBankCopies }" :max="1" group="bookBankCopies"></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        1 ไฟล์
                      </div>
                      <div v-if="errors.bookBankCopies"  class="invalid-feedback d-block" >
                            {{ errors.bookBankCopies }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file, index) in groupFiles.bookBankCopies"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.bookBankCopies.indexOf(file),
                                    'bookBankCopies'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 border-top my-3"></div>
                  <div class="col-lg-6">
                    <div>
                      <b
                        >สัญญาเพื่อสมัครเป็นผู้รับชำระเงินกับบริษัท
                        เพย์โซลูชั่น</b
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mt-3">
                      <drop-files
                      :class="{ 'is-invalid': errors.contractToPaysolutions }"
                        :max="1"
                        group="contractToPaysolutions"
                      ></drop-files>
                      <div class="small text-secondary text-bottom-file">
                        ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                        1 ไฟล์
                      </div>
                      <div v-if="errors.contractToPaysolutions"  class="invalid-feedback d-block" >
                            {{ errors.contractToPaysolutions }}
                          </div>
                    </div>

                    <div class="row mt-3">
                      <div
                        v-for="(file,
                        index) in groupFiles.contractToPaysolutions"
                        :key="index"
                        class="col-12 mb-3 list-file"
                      >
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img height="60" :src="imgFile(file.name)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                {{ file.name }}
                              </div>
                              <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small>
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <eva-icon
                                @click="
                                  remove(
                                    groupFiles.contractToPaysolutions.indexOf(
                                      file
                                    ),
                                    'contractToPaysolutions'
                                  )
                                "
                                name="close-circle"
                                class="primary cursor-pointer"
                                fill="#6c757d"
                              ></eva-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <div class="row pt-md-4">
              <div class="col-md-12 text-right">
                <button @click="step = 1" class="btn border text-primary mr-3">
                  ย้อนกลับ
                </button>
                <b-button @click="submitBtn()" variant="primary"
                  >ยืนยัน</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="showSuccess === true">
           <!-- for eKYC -->
        <!-- <div class="row justify-content-center">
          <div class="col-md-6 text-center mt-5">
            <img
              src="../../../assets/image/company2-completed.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
     
        <div class="row justify-content-center">
          <div class="col-md-5 text-center mt-3">
            <h3><eva-icon name="checkmark-circle-2" class="color-success" width="40" height="40"></eva-icon> ลงทะเบียนเรียบร้อยแล้ว</h3>
            <h5>กรุณาส่งสัญญาและเอกสารฉบับจริงภายใน 7 วัน</h5>
            <span class="text-secondary">
              พร้อมลงนามและประทับตราฉบับบจริง มาที่บริษัท เพย์โซลูชั่น จำกัด
              เจ้าหน้าที่จะตรวจสอบเอกสารและอนุมัติภายใน 7 วัน
              และหากได้รับเอกสารฉบับจริงเรียบร้อยแล้ว การเปิดบัญชีสำหรับ
              Supplier จะเสร็จสมบูรณ์
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-5 mt-4 mb-3">
            <b-button class="w-100 py-2-1" @click="reload()" variant="primary"
              >รอตรวจสอบเอกสาร</b-button
            >
          </div>
        </div> -->
        <div class="row justify-content-center" style="margin-top:130px">
          <div class="col-md-5 text-center mt-3">
            <h3><eva-icon name="checkmark-circle-2" class="color-success" width="40" height="40"></eva-icon> ลงทะเบียนเรียบร้อยแล้ว</h3>
            <h5 class="text-secondary">กรุณาส่งสัญญาและเอกสารฉบับจริงมายัง บริษัท เพย์ โซลูชั่น จำกัด ภายใน 7 วัน</h5>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="row container">
            <div class="col-md-6 text-center mt-5">
              <div class="card" style="width: 100%;background:none;border:none">
                <img class="card-img-top" src="../../../assets/image/company2-completed@2x.png" alt="Company Completed">
                <div class="card-body text-left">
                   <h5 class="card-title" style="margin-bottom:25px;">ที่อยู่จัดส่งเอกสาร</h5>
                  <p class="card-text" style="border-left: 2px solid orange;padding-left: 20px;">
                    <strong>บริษัท เพย์ โซลูชั่น จำกัด</strong><br>
                    เลขที่ 90 อาคารซีดับเบิ้ลยู ทาวเวอร์ อาคารบี ชั้น 25 ยูนิต B2502 ถ.รัชดาภิเษก แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310
                  </p>
                </div>
              </div>

            </div>
            <div class="col-md-6 text-center mt-5">
                <div class="card text-left" style="max-width: 100%; border:none">
                  <div class="card-header" style="border-bottom:5px solid #f2f6fb !important; padding:15px 25px 10px 25px"><h5>เอกสารการขอเปิดบัญชีสำหรับ ผู้ขาย (Supplier)</h5></div>
                    <div class="card-body" style="padding: 55px 25px;">
                        <p style="margin-bottom: 5px; ">1. เอกสารสัญญา (ที่ลงนามแล้ว)</p>
                        <p style="margin-bottom: 5px;">2. เอกสารประกอบการสมัครตามที่อัปโหลดไฟล์</p>
                        <ul>
                          <li>หนังสือรับรองบริษัท</li>
                          <li>สำเนาบัตรประชาชนของกรรมการ</li>
                          <li>สำเนาทะเบียนบ้านของกรรมการ</li>
                          <li>แบบฟอร์มใบแจ้งหนี้ (เดือนล่าสุด)</li>
                          <li>สำเนาหน้าสมุดบัญชีธนาคาร</li>
                          <li>สัญญาเพื่อสมัครเป็นผู้รับชำระเงินกับบริษัทเพย์โซลูชั่น</li>
                        </ul>
                      <p class="card-text" style="margin-top: 15px">
                        <span style="text-decoration-line: underline;" >พร้อมเซ็นสำเนาถูกต้องและประทับตราบริษัท</span><br>
                        โดยระบบจะพิจารณาอนุมัติเปิดบัญชี หลังได้รับเอกสารครบถ้วนแล้วภายใน 7 วันทำการ
                      </p>
                    </div>
                </div>
                <!-- <b-button class="w-100 py-2-1" @click="reload()" variant="primary"
                          >ดูสถานะการตรวจสอบเอกสาร</b-button
                        > -->
            </div>
          </div>
         
         
        </div>
      </div>
      <div
        class="col-12"
        v-if="user.supplierStatusApprove == false && user.stepRegister == 6"
      >
        <div class="row justify-content-center">
          <div class="col-md-10 text-center mt-5">
            <img
              src="../../../assets/image/appeovedDoc.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12 text-center mt-3">
            <h3>รอตรวจสอบเอกสาร</h3>
            <span class="text-secondary">
              เจ้าหน้าที่กำลังตรวจสอบเอกสาร ใช้ระยะเวลาตรวจสอบเอกสาร 1-2
              วันทำการ
            </span>
          </div>
        </div>
        <div class="row justify-content-center mt-4 mb-3">
    
        </div>
        <div v-if="waitingApprove.additionalRequest" class="row justify-content-center mt-4 mb-3">
          <div class="col-md-8 px-3 py-2-1 bg-white border-bottom"><div class="float-left font-weight-bold">ข้อความเพิ่มเติมจากทีมงาน Paysoon</div></div>
          <div class="col-md-8 px-3 py-2-1 bg-white border-bottom">
            <div v-html="waitingApprove.additionalRequest"></div>
          </div>
        </div>
        <div class="row justify-content-center mt-4 mb-3">
          <div v-if="(waitingApprove.requireFiles)" class="col-md-8 px-3 py-2-1 bg-white border-bottom">
            <div class="float-left font-weight-bold">เอกสารที่ยังไม่ได้อัปโหลด</div>
          </div>
          <div  v-if="(waitingApprove.requireFiles)" class="col-md-8 p-0 bg-white border-bottom mb-4">
            <div v-for="(requireFile, index) in waitingApprove.requireFiles"
                :key="index" 
                :class="`px-3 py-2-1 border-bottom ${(errors[requireFile])?'border-left-4 border-danger bg-danger-back':'bg-white'}`">
                 <div class="w-100 d-flex justify-content-between">
                    <div class="float-left">{{ waitingApprove.requireFilesTH[index] }} </div>
                    <!-- <div class="bg-warning icon-box" >
                      <eva-icon name="clock-outline" fill="#FFFFFF"></eva-icon>
                    </div> -->

                 </div>
                 <div class="mt-3">
                    <drop-files  :max="10" :group="requireFile"></drop-files>
                    <div class="small text-secondary text-bottom-file">
                      ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                      10 ไฟล์
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div
                      v-for="(file, index) in groupFiles[requireFile]"
                      :key="index"
                      class="col-md-6 mb-3 list-file"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="">
                          <img height="60" :src="imgFile(file.name)" />
                        </div>
                        <div class="pl-3 w-70">
                          <div class="h-100 flex-column align-items-start">
                            <div class="mb-auto name">
                              {{ file.name }}
                            </div>
                            <small class="text-secondary size">{{
                              sizeFile(file.size)
                            }}</small>
                          </div>
                        </div>
                        <div class="h-100">
                          <div class="d-flex align-items-center h-100">
                            <eva-icon
                              @click="
                                remove(
                                  groupFiles[requireFile].indexOf(file),
                                  requireFile
                                )
                              "
                              name="close-circle"
                              class="primary cursor-pointer"
                              fill="#6c757d"
                            ></eva-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-3 text-center">
                    <button
                      class="btn btn-primary"
                      @click="apiUploadFile(requireFile)"
                    >
                      อัปโหลด
                    </button>
                  </div>
            </div>
          </div>
          <div
            v-if="waitingApprove.dataCompany"
            class="col-md-8 px-3 py-2-1 bg-white border-bottom"
          >
            <div class="float-left font-weight-bold">ข้อมูลบริษัท</div>
            <span
              :class="
                'badge badge-pill float-right ' +
                  (waitingApprove.dataCompany.statusNumber == 0
                    ? ' badge-warning'
                    : '') +
                  (waitingApprove.dataCompany.statusNumber == 2
                    ? ' badge-danger'
                    : '') +
                  (waitingApprove.dataCompany.statusNumber == 1
                    ? ' badge-success'
                    : '')
              "
              >{{ waitingApprove.dataCompany.statusName }}</span
            >
          </div>
          <div
            v-if="waitingApprove.dataCompany"
            :class="
              'col-md-8 px-3 py-2-1' +
                (waitingApprove.dataCompany.statusNumber == 2
                  ? ' border-left-4 border-danger bg-danger-back'
                  : ' bg-white')
            "
          >
            <div class="w-100 d-flex justify-content-between">
              <div class="float-left">รายละเอียดบริษัท</div>
              <div
                v-if="waitingApprove.dataCompany.statusNumber == 0"
                class="bg-warning icon-box"
              >
                <eva-icon name="clock-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="waitingApprove.dataCompany.statusNumber == 1"
                class="bg-success icon-box"
              >
                <eva-icon name="checkmark-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="waitingApprove.dataCompany.statusNumber == 2"
                class="d-flex"
              >
                <router-link
                  :to="{ name: 'supplier.register.juristic_person.company' }"
                >
                  <span class="text-primary cursor-pointer mr-5">
                    <eva-icon
                      name="edit-outline"
                      width="18px"
                      class="color-primary"
                    ></eva-icon>
                    แก้ไข</span
                  >
                </router-link>
                <div class="bg-danger icon-box">
                  <eva-icon name="close-outline" fill="#FFFFFF"></eva-icon>
                </div>
              </div>
            </div>
            <div v-if="waitingApprove.dataCompany.statusNumber == 2">
              <ul class="mb-0">
                <li
                  v-for="(item, index) in waitingApprove.dataCompany
                    .reasonReject"
                  :key="index"
                  class="text-danger"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="waitingApprove.dataAccountBank"
            class="col-md-8 px-3 py-2-1 bg-white border-bottom mt-4"
          >
            <div class="float-left font-weight-bold">บัญชีธนาคาร</div>
            <span
              :class="
                'badge badge-pill float-right ' +
                  (waitingApprove.dataAccountBank.statusNumber == 0
                    ? ' badge-warning'
                    : '') +
                  (waitingApprove.dataAccountBank.statusNumber == 2
                    ? ' badge-danger'
                    : '') +
                  (waitingApprove.dataAccountBank.statusNumber == 1
                    ? ' badge-success'
                    : '')
              "
              >{{ waitingApprove.dataAccountBank.statusName }}</span
            >
          </div>
          <div
            v-if="waitingApprove.dataAccountBank"
            :class="
              'col-md-8 px-3 py-2-1 border-bottom' +
                (waitingApprove.dataAccountBank.statusNumber == 2
                  ? ' border-left-4 border-danger bg-danger-back'
                  : ' bg-white')
            "
          >
            <div class="w-100 d-flex justify-content-between">
              <div class="float-left">รายละเอียดบัญชี</div>
              <div
                v-if="waitingApprove.dataAccountBank.statusNumber == 0"
                class="bg-warning icon-box"
              >
                <eva-icon name="clock-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="waitingApprove.dataAccountBank.statusNumber == 1"
                class="bg-success icon-box"
              >
                <eva-icon name="checkmark-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="waitingApprove.dataAccountBank.statusNumber == 2"
                class="d-flex"
              >
                <router-link
                  :to="{ name: 'supplier.register.juristic_person.bank' }"
                >
                  <span class="text-primary cursor-pointer mr-5">
                    <eva-icon
                      name="edit-outline"
                      width="18px"
                      class="color-primary"
                    ></eva-icon>
                    แก้ไข</span
                  >
                </router-link>
                <div class="bg-danger icon-box">
                  <eva-icon name="close-outline" fill="#FFFFFF"></eva-icon>
                </div>
              </div>
            </div>
            <div v-if="waitingApprove.dataAccountBank.statusNumber == 2">
              <ul class="mb-0">
                <li
                  v-for="(item, index) in waitingApprove.dataAccountBank
                    .reasonReject"
                  :key="index"
                  class="text-danger"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="waitingApprove.dataAccountBank"
            :class="
              'col-md-8 px-3 py-2-1' +
                (waitingApprove.dataAccountBank.documentBookbanks
                  .statusNumber == 2
                  ? ' border-left-4 border-danger bg-danger-back'
                  : ' bg-white')
            "
          >
            <div class="w-100 d-flex justify-content-between">
              <div class="float-left ">สำเนาหน้าสมุดบัญชีธนาคาร</div>
              <div
                v-if="
                  waitingApprove.dataAccountBank.documentBookbanks
                    .statusNumber == 0
                "
                class="bg-warning icon-box"
              >
                <eva-icon name="clock-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="
                  waitingApprove.dataAccountBank.documentBookbanks
                    .statusNumber == 1
                "
                class="bg-success icon-box"
              >
                <eva-icon name="checkmark-outline" fill="#FFFFFF"></eva-icon>
              </div>
              <div
                v-if="
                  waitingApprove.dataAccountBank.documentBookbanks
                    .statusNumber == 2
                "
                class="d-flex"
              >
                <span
                  @click="showUploadFile('#collapseBookBank')"
                  class="text-primary cursor-pointer mr-5"
                >
                  <eva-icon
                    name="cloud-upload-outline"
                    width="18px"
                    class="color-primary"
                  ></eva-icon>
                  อัปโหลดไฟล์</span
                >
                <div class="bg-danger icon-box">
                  <eva-icon name="close-outline" fill="#FFFFFF"></eva-icon>
                </div>
              </div>
            </div>
            <div
              v-if="
                waitingApprove.dataAccountBank.documentBookbanks.statusNumber ==
                  2
              "
            >
              <ul class="mb-0">
                <li
                  v-for="(item, index) in waitingApprove.dataAccountBank
                    .documentBookbanks.reasonReject"
                  :key="index"
                  class="text-danger"
                >
                  {{ item }}
                </li>
              </ul>
              <div id="collapseBookBank" class="d-none">
                <div class="mt-3">
                  <drop-files :class="{ 'is-invalid': errors.bookBankCopies }" :max="10" group="bookBankCopies"></drop-files>
                  <div class="small text-secondary text-bottom-file">
                    ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด 10
                    ไฟล์
                  </div>
                  <div v-if="errors.bookBankCopies"  class="invalid-feedback d-block" >
                            {{ errors.bookBankCopies }}
                          </div>
                </div>
                <div class="row mt-3">
                  <div
                    v-for="(file, index) in groupFiles.bookBankCopies"
                    :key="index"
                    class="col-md-6 mb-3 list-file"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="">
                        <img height="60" :src="imgFile(file.name)" />
                      </div>
                      <div class="pl-3 w-70">
                        <div class="h-100 flex-column align-items-start">
                          <div class="mb-auto name">
                            {{ file.name }}
                          </div>
                          <small class="text-secondary size">{{
                            sizeFile(file.size)
                          }}</small>
                        </div>
                      </div>
                      <div class="h-100">
                        <div class="d-flex align-items-center h-100">
                          <eva-icon
                            @click="
                              remove(
                                groupFiles.bookBankCopies.indexOf(file),
                                'bookBankCopies'
                              )
                            "
                            name="close-circle"
                            class="primary cursor-pointer"
                            fill="#6c757d"
                          ></eva-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="my-3 text-center">
                  <button
                    class="btn btn-primary"
                    @click="apiUploadFile('bookBankCopies')"
                  >
                    อัปโหลด
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="waitingApprove.documents"
            class="col-md-8 px-3 py-2-1 bg-white border-bottom mt-4"
          >
            <div class="float-left font-weight-bold">เอกสารบริษัท</div>
            <span
              :class="
                'badge badge-pill float-right ' +
                  (waitingApprove.documents.documentStatusNumber == 0
                    ? ' badge-warning'
                    : '') +
                  (waitingApprove.documents.documentStatusNumber == 2
                    ? ' badge-danger'
                    : '') +
                  (waitingApprove.documents.documentStatusNumber == 1
                    ? ' badge-success'
                    : '')
              "
              >{{ waitingApprove.documents.documentStatusName }}</span
            >
          </div>
          <div v-if="waitingApprove.documents" class="col-md-8 p-0">
            <div class="border-white border-left border-right px-3 py-2-1">
              รายการทั้งหมด
            </div>
            <div
              v-for="(document, index) in waitingApprove.documents.documents"
              :key="index"
              :class="
                'px-3 py-2-1 border-bottom' +
                  (document.statusNumber == 2
                    ? ' border-left-4 border-danger bg-danger-back'
                    : ' bg-white')
              "
            >
              <div class="w-100 d-flex justify-content-between">
                <div class="float-left">{{ document.documentTypeNameTh }}</div>
                <div
                  v-if="document.statusNumber == 0"
                  class="bg-warning icon-box"
                >
                  <eva-icon name="clock-outline" fill="#FFFFFF"></eva-icon>
                </div>
                <div
                  v-if="document.statusNumber == 1"
                  class="bg-success icon-box"
                >
                  <eva-icon name="checkmark-outline" fill="#FFFFFF"></eva-icon>
                </div>
                <div v-if="document.statusNumber == 2" class="d-flex">
                  <span
                    @click="showUploadFile('#collapse' + document.type)"
                    class="text-primary cursor-pointer mr-5"
                  >
                    <eva-icon
                      name="cloud-upload-outline"
                      width="18px"
                      class="color-primary"
                    ></eva-icon>
                    อัปโหลดไฟล์</span
                  >
                  <div class="bg-danger icon-box">
                    <eva-icon name="close-outline" fill="#FFFFFF"></eva-icon>
                  </div>
                </div>
              </div>
              <div v-if="document.statusNumber == 2">
                <ul class="mb-0">
                  <li class="text-danger">
                    {{ document.reasonReject }}
                  </li>
                </ul>
                <div :id="'collapse' + document.type" class="d-none">
                  <div class="mt-3">
                    <drop-files  :max="10" :group="document.type"></drop-files>
                    <div class="small text-secondary text-bottom-file">
                      ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB สูงสุด
                      10 ไฟล์
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div
                      v-for="(file, index) in groupFiles[document.type]"
                      :key="index"
                      class="col-md-6 mb-3 list-file"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="">
                          <img height="60" :src="imgFile(file.name)" />
                        </div>
                        <div class="pl-3 w-70">
                          <div class="h-100 flex-column align-items-start">
                            <div class="mb-auto name">
                              {{ file.name }}
                            </div>
                            <small class="text-secondary size">{{
                              sizeFile(file.size)
                            }}</small>
                          </div>
                        </div>
                        <div class="h-100">
                          <div class="d-flex align-items-center h-100">
                            <eva-icon
                              @click="
                                remove(
                                  groupFiles[document.type].indexOf(file),
                                  document.type
                                )
                              "
                              name="close-circle"
                              class="primary cursor-pointer"
                              fill="#6c757d"
                            ></eva-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-3 text-center">
                    <button
                      class="btn btn-primary"
                      @click="apiUploadFile(document.type)"
                    >
                      อัปโหลด
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticationService } from "../../../_services/authentication.service";
import DropFiles from "src/components/Files/DropFiles";
import { StringFormat }  from "../../../_formats/string"
import { formatNum } from "../../../_formats/number"
export default {
  name: "SupplierRegisterJuristic",
  components: {
    "drop-files": DropFiles,
  },
  data() {
    return {
      user: {},
      error: null,
      errors: {},
      showSuccess: false,
      step: 1,
      amphure: "",
      province: null,
      countryId: null,
      postcode: "",
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
      postcode: "",
      companyWebsite: "",
      juristicNumber: "",
      companyPhone: "",
      companyEmail: "",
      companyNameTh: "",
      companyNameEng: "",
      categories: [],
      categoryId: null,
      banks: [],
      bankId: null,
      bankBranch: "",
      bankNumber: "",
      bankUsername: "",
      supplierCategoryId: null,
      files: [],
      groupFiles: {
        companyCertificates: [],
        idCardCopies: [],
        houseRegistrationCopies: [],
        companyEstablishmentListCopies: [],
        listOfShareholdersCopies: [],
        memorandumCopies: [],
        invoices: [],
        bookBankCopies: [],
        contractToPaysolutions: [],
        houseRegistrationCopies: [],
      },
      waitingApprove: {},
      linkDownloadDoc: "",
    };
  },
  created() {
    authenticationService.refreshUser(`api/buyers/profile`);
    this.apiProfile();
    this.$http.get(`/api/download/file/contact/paysolution`).then((res) => {
      this.linkDownloadDoc = res.data.data;
    });
  },
  methods: {
    apiProfile(reload = false) {
      let self = this;
      self.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          if (res.data.data.stepRegister != 6) {
            this.apiProvinces();
            this.apiCounties();
            this.apiCategories();
            this.apiBank();
          } else {
            this.showSuccess = true
            this.apiWaitingApprove();
          }
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function(x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function(x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCategories() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/category/suppliers`)
        .then((res) => {
          let categories = res.data.data;
          this.categories = categories.map(function(x) {
            return (x = { label: x.categoryName, code: x.supplierCategoryId });
          });
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiBank() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/banks`)
        .then((res) => {
          let banks = res.data.data;
          this.banks = banks.map(function(x) {
            return (x = { label: x.bankName, code: x.bankId,img: x.bankImage });
            
          });
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    imgFile(filename) {
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    remove(i, group = null) {
      if (group) {
        this.groupFiles[group].splice(i, 1);
      } else {
        this.filelist.splice(i, 1);
      }
    },
    nextStep() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      if (this.user.type == "juristic_person") {
        var formData = {
          provinceId: this.province ? this.province.code : null,
          countryId: this.countryId ? this.countryId.code : null,
          supplierCategoryId: this.supplierCategoryId
            ? this.supplierCategoryId.code
            : null,
          bankId: this.bankId ? this.bankId.code : null,
          amphure: this.amphure,
          addressOne: this.addressOne,
          addressTwo: this.addressTwo,
          juristicNumber: this.juristicNumber,
          addressTwo: this.addressTwo,
          postcode: this.postcode,
          website: this.companyWebsite,
          companyPhone: this.companyPhone,
          companyEmail: this.companyEmail,
          companyNameTh: this.companyNameTh,
          companyNameEng: this.companyNameEng,
          bankBranch: this.bankBranch,
          bankNumber: this.bankNumber,
          bankUsername: this.bankUsername,
        };

        this.$http
          .post(`api/suppliers/register/validate`, formData)
          .then((res) => {
            self.$hideLoader();
            self.step = 2;
          }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
      }
    },
    submitBtn() {
      let self = this;
      this.errors = {};
      this.$showLoader();
      if (this.user.type == "juristic_person") {
        var formData = new FormData();
        formData.append(
          "provinceId",
          this.province ? this.province.code : null
        );
        formData.append(
          "countryId",
          this.countryId ? this.countryId.code : null
        );
        formData.append(
          "supplierCategoryId",
          this.supplierCategoryId ? this.supplierCategoryId.code : null
        );
        formData.append("bankId", this.bankId ? this.bankId.code : null);
        formData.append("amphure", this.amphure);
        formData.append("addressOne", this.addressOne);
        formData.append("addressTwo", this.addressTwo);
        formData.append("juristicNumber", this.juristicNumber);
        formData.append("addressTwo", this.addressTwo);
        formData.append("postcode", this.postcode);
        formData.append("website", this.companyWebsite);
        formData.append("companyPhone", this.companyPhone);
        formData.append("companyEmail", this.companyEmail);
        formData.append("companyNameTh", this.companyNameTh);
        formData.append("companyNameEng", this.companyNameEng);
        formData.append("bankBranch", this.bankBranch);
        formData.append("bankNumber", this.bankNumber);
        formData.append("bankUsername", this.bankUsername);

        for (const [key, group] of Object.entries(this.groupFiles)) {
          for (let i = 0; i < group.length; i++) {
            formData.append(key + "[]", group[i]);
          }
        }
        this.$http
          .post(`api/suppliers/register`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            authenticationService.refreshUser(`api/buyers/profile`);
            self.showSuccess = true;
          }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
      }
    },
    reload() {
      location.reload();
    },
    apiWaitingApprove() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/summary/waiting/approve/documents`)
        .then((res) => {
          this.waitingApprove = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    showUploadFile(id) {
      if ($(id).hasClass("d-none")) {
        $(id).removeClass("d-none");
      } else {
        $(id).addClass("d-none");
      }
    },
    apiUploadFile(group) {
      let self = this;
      this.$showLoader();
      var formData = new FormData();
      for (let i = 0; i < this.groupFiles[group].length; i++) {
        formData.append(group + "[]", this.groupFiles[group][i]);
      }
      this.$http
        .patch(`api/suppliers/juristics/upload/document/${group}/files`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          self.$hideLoader();
           let {status, message, icon} = res.data.errors? {status:'danger', message: res.data.errors.message, icon: "nc-icon nc-simple-remove"}: {status:'success', message: res.data.message, icon:"checkmark-circle-2"};
          this.$notifications.notify({
            message: message,
            icon: icon,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: status,
          });
          if(res.data.errors){
             self.errors = res.data.errors.data
          }
          this.apiWaitingApprove();
        });
    },
    downloadDoc() {
      return this.linkDownloadDoc;
    },
  },
  watch:{
    companyNameTh(){
      this.companyNameTh = StringFormat.ThaiString(this.companyNameTh)
    },
    companyNameEng(){
      this.companyNameEng = StringFormat.EnglishString(this.companyNameEng)
    },
    juristicNumber(){ this.juristicNumber = formatNum.numberic(this.juristicNumber)},
    amphure(){ this.amphure = StringFormat.ThaiString(this.amphure)},
    postcode(){ this.postcode = formatNum.numberic(this.postcode)}
  }
};
</script>
<style lang="scss" scoped>
th {
  white-space: nowrap;
}
</style>
