<template>
    <div>
        <div class="row justify-content-center show-confirm-payment">
            <div class="col-12 text-center">
                <img class="img-fluid mt-4" src="@/assets/image/add-invoice.png" alt="" width="250"/>
            </div>
            <div class="col-12 text-center mb-4 mt-2">
                <h2>สรุปรายการเรียกเก็บเงิน</h2>
                <div v-if="this.$parent.projects">เรียกเงินเก็บจาก : {{ this.$parent.buyerEmail.label }}</div>
                <div v-else>เรียกเงินเก็บจาก : {{ paymentsData.buyerEmail }}</div>
                <div>Payment Reference: {{ paymentsData.paymentReference }}</div>
            </div>
             <div class="col-lg-8 col-xl-6">
                <div class="bg-white rounded">
                    <div class="pt-4 pr-4 pl-4 pb-4 mb-4">
                        <div class="h6 font-weight-semibold border-bottom pb-3 mt-1 d-flex justify-content-between">
                            <div>รายการชำระ</div>
                            <div>จำนวน</div>
                        </div>
                        <div class="pb-2 mt-4 d-flex justify-content-between">
                            <div class="h5">ค่าสินค้าและบริการ</div>
                            <div class="h5 text-primary">{{ formatNum.price(paymentsData.price, 2) }}฿</div>
                        </div>
                        <div class="h6 d-flex justify-content-between">
                            <div class="text-secondary">สัดส่วนชำระค่าธรรมเนียม ดังนี้ <p class="text-secondary mt-1 mb-0 small">การชำระค่าธรรมเนียมของผู้ขาย</p></div>  
                        </div>
                        <div class="mt-2 d-flex justify-content-between">
                            <div class="">- ผู้ขาย (รวม VAT 7%)</div>
                            <div class="font-weight-semibold">{{ paymentsData.supplierFeeRate}}%</div>
                        </div>
                        <div class="pb-4 mt-2 d-flex justify-content-between">
                            <div class="">- ผู้ซื้อ (รวม VAT 7%)</div>
                            <div class="font-weight-semibold">{{ paymentsData.buyerFeeRate}}%</div>
                        </div>
                        <div class="row text-center mb-2">
                                <div class="col-12 ">
                                <div class="border p-lg-3 invoice-expire">
                                    <span class="font-weight-semibold">วันที่หมดอายุการเรียกเก็บเงิน</span><br />
                                    <span class="text-secondary">
                                        {{ FormatDate.thai(paymentsData.invoiceExpire) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="terms"
                    name="checkbox-1"
                    class="privacy-policy-text"
                    :value="true"
                    :unchecked-value="false">
                    ข้าพเจ้ารับทราบและยอมรับ <a class="text-promary" href="https://paysoon.net/term-condition.html" target="_blank">ข้อตกลงและเงื่อนไขบริการ</a> เเละ <a class="text-promary" href="https://paysoon.net/privacy-policy.html" target="_blank">นโยบายข้อมูลส่วนบุคคล</a>  รวมทั้ง <a class="text-primary" href="https://paysoon.net/privacy-consent.html" target="_blank">การให้ความยินยอมในการเปิดเผยข้อมูล</a> เพื่อให้ เพย์ โซลูชั่น เก็บ รวบรวม ใช้ข้อมูลส่วนบุคคล ประกอบการสมัครใช้บริการ รวมทั้งแจ้งข่าวสารที่เกี่ยวกับบริการระบบรับชำระเงินออนไลน์

                </b-form-checkbox>
                <div class="mt-3 text-right mb-5">
                    <button
                        class="btn bg-white border mr-4"
                        @click="previousStep">
                        ย้อนกลับ
                    </button>
                    <button
                        :disabled="isDisabled"
                        class="btn btn-primary"
                        @click="createPayment()">
                        เพิ่มรายการเรียกเก็บเงิน
                    </button>
                </div>
             </div>
        </div>
        <template>
            <b-modal id="policy-modal" size="lg" scrollable>
                <div v-html="termofuse"></div>
            <template #modal-footer>
                <div class="w-100">
                <b-form-checkbox
                id="checkbox-1"
                v-model="terms"
                name="checkbox-1"
                class="policy"
                :value="true"
                :unchecked-value="false"
                >
                ฉันได้อ่านและยอมรับเงื่อนไข
                <b class="h6">นโยบายการให้บริการ และ นโยบายการชำระเงิน</b>
                </b-form-checkbox>
                <button
                    :disabled="isDisabled"
                    class="btn btn-primary btn-group-toggle btn-lg"
                    @click="createPayment()"
                >
                    เพิ่มรายการเรียกเก็บเงิน
                </button>
                </div>
            </template>
            </b-modal>
        </template>
        <template>
           <b-modal id="payment-link-modal" centered hide-footer>
                <div class="d-block text-center">
                    <div class="col-12 col-md-8 pb-4 mx-auto"><img class="img-fluid" width="90" src="@/assets/icon/success.svg" ></div>
                    <h3 class="mt-3">สร้างรายการเรียบร้อยแล้ว</h3>
                    <p>คัดลอกลิงก์ส่งให้ผู้ซื้อเพื่อยืนยันการทำรายการ</p>
                    <div class="row m-1">
                        <div class="col-12 col-md-8 p-1"><input v-model="paymentLink" ref="paymentLink" style="background-color: #fff;" type="text" class="form-control text-primary" readonly></div>
                        <div class="col-12 col-md-4 p-1"><button class="btn bg-white border btn-block" @click="copyLink" >คัดลอกลิงก์</button></div>
                    </div>
                    <div class="row m-2"><b-button class="mt-3" variant="primary" block @click="createCompletd">เสร็จสิ้น</b-button></div>
                </div>
            </b-modal>
        </template>
    </div>
</template>

<script>
import { formatNum }from "../../../../_formats/number"; 
import { formatDate } from "../../../../_formats/date"; 
    export default {
        name: "Summarize",
        props: ['paymentsData'],
        data() {
            return {
                terms: false,
                enabled: false,
                termofuse: null,
                paymentLink: null,
                paymentId: null,
                dismissSecs: 1.5,
                dismissCountDown: 0,
                showDismissibleAlert: false,
                formatNum: formatNum,
                FormatDate: formatDate,
            }
        },
        created() {
            this.apiGetTerms()
        },
        computed: {
            isDisabled: function () {
                this.enabled = this.terms;
                return !this.enabled;
            },
        },
        methods: {
            createPayment(){
                let self = this;
                self.$showLoader();
                let data = this.paymentsData
                let payload = {
                    buyerId: data.buyerEmail,
                    amountPrice: parseFloat(data.price),
                    totalPrice: 0.1,
                    supplierId: data.supplierId,
                    paymentReference: data.paymentReference,
                    invoiceExpire: data.invoiceExpire,
                    supplierFeeRate: data.supplierFeeRate,
                    buyerFeeRate: data.buyerFeeRate,
                    fileList: data.fileList,
                    
                }
                 this.$http.post('/api/payment/create-payment', payload).then((response) => {
                    self.$hideLoader();
                    let data = response.data.data;
                    this.paymentLink = data.invoice_url
                    this.paymentId = data.payment_id
                    this.$bvModal.show('payment-link-modal')
                })
                .catch((error) => {
                    console.error(error)
                })
                return
               
            },
            apiGetTerms() {
                let self = this;
                this.$showLoader();
                this.$http
                    .get(`api/suppliers/termofuse`)
                    .then((res) => {
                        this.termofuse = res.data.data.termOfUseSupplier;
                        self.$hideLoader();
                    })
                    .catch(function(err) {
                        self.error = err;
                        self.$hideLoader();
                });
            },
            previousStep(){
                this.$emit('changedStep', { step: false, errors: Object})
            },
            async copyLink(){
               try {
                await navigator.clipboard.writeText(this.paymentLink);
                    this.$notifications.notify({
                    message: 'คัดลอกลิงก์เรียบร้อยแล้ว',
                    icon: "checkmark-circle-2",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                });
                } catch(err) {
                    this.$notifications.notify({
                    message: 'ไม่สามารถคัดลอกลิงก์ได้',
                    icon: "alert-triangle",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "warning",
                });
                }
            },
            createCompletd(){
                this.$router.push({ name: 'supplier.payments.show', params: { id: this.paymentId }})
            }
        }
    }
</script>
<style scoped lang="scss">
    .invoice-expire{
        border-radius: 5px;
    }
</style>