<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">แดชบอร์ด ผู้ซื้อ (Buyer)</div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-4 col-md-12 mb-4">
            <div
              class="
                d-flex
                flex-column
                justify-content-end
                box-hover
                bg-primary
                text-white text-center
                rounded
                p-lg-3
                h-100
              "
            >
              <div class="add-schedule">
                <img
                  class="img-fluid"
                  src="@/assets/icon/add-schedule.svg"
                  alt=""
                />
              </div>
              <button
                @click="$router.push({ name: 'buyer.calendar' })"
                class="btn btn-primary dark w-100"
              >
                <b>เพิ่มการชำระเงินล่วงหน้า</b>
              </button>
            </div>
          </div>
          <div v-if="defaultCard" class="col-lg-4 col-md-12 mb-4">
            <div
              class="
                d-flex
                flex-column
                justify-content-between
                box-hover
                bg-white
                rounded
                p-lg-3
                h-100
              "
            >
              <div class="row mb-3">
                <div class="col-md-6 text-left pr-md-0">
                  <div class="h5 font-weight-semibold mb-3">บัตรเครดิตหลัก</div>
                </div>
                <div class="col-md-12 mt-2">
                    <img class="img-fluid credit-card" src="@/assets/image/img-creditcard.jpg" alt="">
                    <div class="credit-card-number">
                      <img height="16px" src="../../../assets/card/visa.png"
                        v-if="defaultCard.creditCardType == 'VISA'" />
                      <img height="20px" src="../../../assets/card/mastercard.png"
                        v-if="defaultCard.creditCardType == 'Master'" />
                      <img height="18px" src="../../../assets/card/jcb.png"
                        v-if="defaultCard.creditCardType == 'JCB'" />
                      <div class="font-weight-bold">
                        <span class="mr-3 dot">●●●●</span>
                        <span class="mr-3 dot">●●●●</span>
                        <span class="mr-3 dot">●●●●</span>
                        {{ defaultCard.creditCardNumber }}
                      </div>
                    </div>
                </div>
              </div>
              <button
                @click="$router.push({ name: 'buyer.credit' })"
                class="btn btn-ligth bg-white border text-dark w-100"
              >
                <b>จัดการบัตร</b>
              </button>
            </div>
          </div>
          <div v-if="data.userDetail" class="col-lg-4 col-md-12 mb-4">
            <div
              class="
                d-flex
                flex-column
                justify-content-between
                box-hover
                text-left
                bg-white
                rounded
                p-lg-3
                h-100
                profile-box
              "
            >
              <div class="mb-3">
                <div>สวัสดี,</div>
                <div class="h5 font-weigth-blod">
                  {{ data.userDetail.name }}!
                </div>
                <div class="text-secondary d-flex">
                  <eva-icon
                    width="20px"
                    name="email-outline"
                    class="color-secondary mr-1"
                  ></eva-icon>
                  <div class="small pt-1">{{ data.userDetail.email }}</div>
                </div>
                <div class="text-secondary d-flex">
                  <eva-icon
                    width="20px"
                    name="phone-outline"
                    class="color-secondary mr-1"
                  ></eva-icon>
                  <div class="small pt-1">{{ data.userDetail.phone }}</div>
                </div>
              </div>
              <div>
                <button
                  @click="$router.push({ name: 'buyer.profile' })"
                  class="btn btn-ligth bg-white border text-dark min-150"
                >
                  <b>จัดการบัญชี</b>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cardLists" class="row">
          <div
            v-for="(item, index) in cardLists"
            :key="index"
            class="col-lg-3 col-md-6 mb-4"
          >
            <div class="bg-white h-100 rounded">
              <div
                class="px-3 py-2-1 text-secondary border-bottom title-card-secondary"
              >
                {{ allStatus[item.id].name }} 30 วันล่าสุด (฿)
              </div>
              <div class="px-3 py-2-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-semibold h5 pt-2">
                      ฿{{ formatNum.price(item.money, 2).slice(0, -3) }}.<small>{{
                        formatNum.price(item.money, 2).slice(-2)
                      }}</small>
                    </div>
                    <small>{{ item.countList }} รายการ</small>
                  </div>
                  <div>
                    <div
                      class="text-center"
                      v-if="item.statusImage == 1"
                      style="background: #728cde;border-radius: 50px;height: 30px;width: 30px"
                    >
                      <eva-icon
                        width="24px"
                        height="28px"
                        name="clock-outline"
                        class="color-white"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 2">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="checkmark-circle-2"
                        class="color-success"
                      ></eva-icon>
                    </div>
                    <div class="text-center " v-if="item.statusImage == 3">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="alert-circle"
                        class="color-danger"
                      ></eva-icon>
                    </div>
                    <div class="text-center" v-if="item.statusImage == 4">
                      <eva-icon
                        width="36px"
                        height="36px"
                        name="close-circle"
                        class="color-secondary"
                      ></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12">
            <div class="bg-white rounded">
              <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                รายการที่ต้องดำเนินการ 
              </h5>
              <div class="px-3 py-2-1">
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  :row-class="$onRowClass"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content class="w-auto">
                        <eva-icon
                          name="more-vertical"
                          class="color-secondary action-icon"
                        ></eva-icon>
                      </template>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber === 9"
                        :to="{
                          name: 'buyer.calendar.paylink',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="20px"
                          name="diagonal-arrow-right-up"
                          class="color-primary"
                        ></eva-icon>
                        ทำรายการชำระเงิน
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          props.rowData.statusCancelNumber < 6 &&
                            (props.rowData.statusNumber === 0 ||
                            props.rowData.statusNumber === 9 ) &&
                            (props.rowData.statusCancelNumber == 4 ||
                              props.rowData.statusCancelNumber == 5)
                        "
                        href="#"
                        @click="
                          showModalDelete(
                            props.rowData.paymentId,
                            props.rowData.statusCancelNumber,
                            props.rowData.statusCancel
                          )
                        "
                      >
                        <eva-icon
                          width="20px"
                          name="radio-button-on"
                          class="color-warning"
                        ></eva-icon>
                        {{ props.rowData.statusCancel }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          props.rowData.statusCancelNumber === 4 &&
                            props.rowData.statusNumber === 1
                        "
                        href="#"
                        @click="
                         showModalDelete(
                            props.rowData.paymentId,
                            props.rowData.statusNumber,
                             props.rowData.statusCancel
                          )
                        "
                      >
                        <eva-icon
                          width="20px"
                          name="radio-button-on"
                          class="color-warning"
                        ></eva-icon>
                        {{ props.rowData.statusCancel }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{
                          name: 'buyer.payments.show',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="20px"
                          name="eye-outline"
                          class="color-primary"
                        ></eva-icon>
                        ดูรายละเอียด
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 2"
                        :to="{
                          name: 'buyer.calendar.renew',
                          params: { id: props.rowData.paymentId },
                        }"
                      >
                        <eva-icon
                          width="20px"
                          name="credit-card-outline"
                          class="color-primary"
                        ></eva-icon>
                        ชำระเงินใหม่
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.rowData.statusNumber == 4"
                        href="#"
                        @click="
                          showModalDeleteCancel( props.rowData.paymentId,
                            props.rowData.statusNumber)
                        "
                      >
                        <eva-icon
                          width="20px"
                          name="radio-button-on"
                          class="color-warning"
                        ></eva-icon>
                        ยกเลิกคำขอ
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="
                      $cardColumn(
                        props.rowData.cardType,
                        props.rowData.cardNumber
                      )
                    "
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>
                        Showing
                      </div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3 status-desc">
          <div class="col-12">
            <div class="bg-white rounded">
              <div
                class="px-3 py-2-1 border-bottom font-weight-semibold"
                v-b-toggle.accordion-1
              >
                <div class="d-flex justify-content-between">
                  <h5 class="mb-0">สถานะรายการชำระเงิน</h5>
                  <eva-icon name="arrow-ios-downward"></eva-icon>
                </div>
              </div>
              <b-collapse
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <div class="px-3 py-2-1">
                  <div
                    v-for="(item, index) in statusList"
                    :key="index"
                    :class="
                      'row py-2-1 ' +
                        (statusList.length != index + 1 ? 'border-bottom' : '')
                    "
                  >
                    <div class="col-md-2">
                      <span :class="'badge badge-pill ' + item.class">{{
                        item.name
                      }}</span>
                    </div>
                    <div class="col-md-10">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered :title="statusName">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="warning" class="" @click="apiChangeStatus()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="modalDeleteCancel" centered title="ต้องการยกเลิกคำขอ">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalDeleteCancel')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="warning" class="" @click="apiChangeStatus()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/buyer_payment.js";
import status from "../../../_fields/status.js";

export default {
  name: "dashboard",
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
     statusList: status.slice(0,7).concat(status.slice(7+1)),
      allStatus: status,
      fields: FieldsDef,
      payments: [],
      data: {},
      defaultCard: {},
      perPage: 10,
      formatNum: formatNum,
      deleteId: null,
      statusCancel: null,
      currentStatus: null,
      tablePaginationTotal: 0,
      statusName: null,
      cardLists: [],
    };
  },
  created() {
  },
  methods: {
    apiData() {
      let url = `api/payment/list?buyer=${this.$parent.$parent.$parent.currentUser.buyerId}&status=9`;
      this.$http.get(url).then((response) => {
        this.data = response.data.data;
        this.payments = this.data.paymentLists.map((d) => {
          return {
            ...d,
            amountStr: "฿" + formatNum.price(d.amount, 2),
            feeStr: "฿" + formatNum.price(d.buyerFeeNet, 2),
            totalAmountStr: "฿" + formatNum.price(d.totalAmount, 2),
          };
        });
      });
    },
    apiCard() {
      let url = "api/buyers/creditcard/index";
       this.$http.get(url).then((response) => {
         let cards = response.data.data
         this.defaultCard = cards.find( ({statusDefault}) => statusDefault === true)
       })
    },
    apiSummary(){
      let url = "api/payment/monthly-summary";
      this.$http.get(url).then((response) => {
        this.cardLists = response.data.data.cardLists;
      });
    },
    search() {
      this.apiData(this.keyword);
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id === status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.payments.length < 1) return;

      let local = this.payments;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    showModalDelete(paymentId, currentStatus, statusText) {
      this.deleteId = paymentId;
      this.currentStatus = currentStatus;
      this.statusCancel = false;
      this.statusName = `ต้องการ${statusText}`;
      this.$bvModal.show("modalApprove");
    },
    showModalDeleteCancel(paymentId, currentStatus) {
      this.deleteId = paymentId;
      this.currentStatus = currentStatus;
      this.statusCancel = false;
      this.$bvModal.show("modalDeleteCancel");
    },
    apiChangeStatus() {
      let url = "/api/payment/flag-status/updated",
      body = {
        paymentId: this.deleteId,
        paymentStatus: this.currentStatus,
        approved: this.statusCancel,
      },
      modal = this.currentStatus === 4 ? "modalDeleteCancel":"modalApprove"
      this.$bvModal.hide(`${modal}`);
      this.$showLoader();
      this.$http.patch(url, body).then(response => {
        this.$bvModal.hide(`${modal}`);
        this.apiData();
          this.$hideLoader();
          this.$notifications.notify({
            message: response.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
      }).catch(error => console.log(error))
    },
  },
  watch: {
    payments(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
      setTimeout(() => {
        $(".vuetable-body td div.td-danger")
          .parent()
          .parent()
          .addClass("td-danger");
      }, 100);
    },
  },
  mounted: function() {
    this.apiData();
    this.apiCard();
    this.apiSummary();
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 0.5rem;
}
.profile-box {
  background-image: url("./../../../assets/image/account-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
.min-150 {
  min-width: 150px;
}
.credit-card { border-radius: 5px; width: 80px; margin-right: 12px; }
.credit-card-number{ display: inline-grid; align-items: center; }
.credit-card-number .dot{font-size: 8px; margin-right: .5rem;}
.add-schedule{ padding: 26px; }
</style>
