import { formatNum } from "../_formats/number";
import { formatDate } from "../_formats/date";
export default [
  {
    name: "index",
    title: "No.",
    titleClass: "center aligned",
    dataClass: "right aligned"
  },
  {
    title: "Supplier Merchant ID",
    name: "MerchantId",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "MerchantId"
  },
  {
    title: "Payment Id",
    name: "PaymentId",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "PaymentId"
  },
  {
    title: "RefNo",
    name: "RefNo",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "RefNo"
  },
  {
    title: "Card Number",
    name: "CreditCard",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "CreditCard"
  },
  {
    title: "Invoice Amount",
    name: "Amount",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "Amount",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Total (฿)",
    name: "TotalPrice",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "TotalPrice",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Transfer to Supplier",
    name: "TransferToSupplier",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "TransferToSupplier",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Payment Ref.",
    name: "RefDoc",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "RefDoc"
  },
  {
    title: "Payment Date",
    name: "PaymentDate",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "PaymentDate",
    formatter: value => formatDate.thai(value)
  },
  {
    title: "Pay to Supplier Date",
    name: "PayToSupplierDate",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "PayToSupplierDate",
    formatter: value => formatDate.thai(value)
  },
  // {
  //   title: "Fee (฿)",
  //   name: "Fee",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "Fee",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Fee Vat (฿)",
  //   name: "FeeDVAT",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "FeeVAT",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Fee net(฿)",
  //   name: "FeeNet",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "FeeNet",
  //   formatter: value => formatNum.price(value, 2)
  // },
  
  // {
  //   title: "MDR (ex.Vat)",
  //   name: "MDR",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "MDR",
  //   formatter: value => `${formatNum.price(value, 2)}%`
  // },
  // {
  //   title: "MDR Ratio",
  //   name: "SupplierFeeRate",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "MDR",
  //   formatter: value => `${formatNum.price(value, 2)}%`
  // },
  {
    title: "Supplier Fee",
    name: "SupplierFee",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "SupplierFee",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Supplier Fee Vat",
    name: "SupplierFeeVat",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "DDSupplierFeeVatD",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Supplier Fee net",
    name: "SupplierFeeNet",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "SupplierFeeNet",
    formatter: value => formatNum.price(value, 2)
  },
  // {
  //   title: "MDR Ratio",
  //   name: "BuyerFeeRate",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "MDRRatio",
  //   formatter: value => `${formatNum.price(value, 2)}%`
  // },
  {
    title: "Buyer Fee",
    name: "BuyerFee",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "BuyerFee",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Buyer Fee Vat",
    name: "BuyerFeeVat",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "BuyerFeeVat",
    formatter: value => formatNum.price(value, 2)
  },
  {
    title: "Buyer Fee net",
    name: "BuyerFeeNet",
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: "BuyerFeeNet",
    formatter: value => formatNum.price(value, 2)
  },
  // {
  //   title: "Total Fee",
  //   name: "TotalFee",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "TotalFee",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Tatal Fee VAT",
  //   name: "TotalFeeVat",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "TotalFeeVat",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Total Fee net",
  //   name: "TotalFeeNet",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "TotalFeeNet",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Bank fee",
  //   name: "BankFee",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "BankFee",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Bank fee vat",
  //   name: "BankFeeVat",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "BankFeeVat",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Net bank",
  //   name: "BankFeeNet",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "BankFeeNet",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Margin (฿)",
  //   name: "Margin",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "Margin",
  //   formatter: value => formatNum.price(value, 2)
  // },
  // {
  //   title: "Margin (%)",
  //   name: "MarginPercent",
  //   dataClass: "center aligned",
  //   titleClass: "center aligned",
  //   sortField: "MarginPercent",
  //   formatter: value => formatNum.price(value, 2)
  // }
];
