<template >
    <div>
      <div class="row justify-content-center">
        <div class="col-lg-11 col-md-12">
          <div class="page-name mt-lg-4 mt-md-3 h3">Supplier</div>
          <div class="row mt-lg-4 mt-md-3">
            <div class="col-12">
              <div class="bg-white rounded">
                <div class="px-3 py-2-1">
                  <div class="mb-2 d-flex justify-content-between">
                    <div class="form-inline">
                      <select v-model="searchStatus" class="form-control mr-3">
                        <option value="">สถานะ: ทั้งหมด</option>
                        <option v-for="(item, index) in statusList"
                      :key="index" :value="item.id">สถานะ: {{item.name}}</option>
                      </select>
                    </div>
                    <div class="form-inline">
                      <div class="input-group mr-lg-2">
                        <input
                          type="text"
                          class="form-control border-right-0"
                          placeholder="ค้นหา"
                          aria-describedby="button-addon2"
                          v-model="keyword"
                          @keyup.enter="search()"
                        />
                        <div class="input-group-append">
                          <button
                            @click="search()"
                            class="
                              btn
                              border border-left-0
                              py-0
                              min-auto
                              text-secondary
                            "
                            type="button"
                            id="button-addon2"
                          >
                            <eva-icon
                              name="search-outline"
                              class="color-dark"
                            ></eva-icon>
                          </button>
                        </div>
                      </div>
                      <a href="javascript:;" @click="clickExportLink" class="btn border">
                        <eva-icon name="download-outline"></eva-icon> Export
                      </a>
                    </div>
                  </div>
                  <vuetable
                    ref="vuetable"
                    :api-mode="false"
                    :fields="fields"
                    :per-page="perPage"
                    :data-manager="dataManager"
                    pagination-path="pagination"
                    @vuetable:pagination-data="onPaginationData"
                  >
                  <div slot="actions" slot-scope="props">
                    <b-nav >
                      <b-nav-item @click="connectPaysoMerchant(props.rowData.supplierId, props.rowData.paysolutionMerchant)">
                        <eva-icon name="flip-2" class="color-primary"></eva-icon>
                        เชื่อมบัญชี
                      </b-nav-item>
                    </b-nav>
                  </div>
                    <div
                      slot="statusName"
                      slot-scope="props"
                      v-html="status(props.rowData.statusNumber,props.rowData.statusName)"
                    ></div>
                    <div slot="companyName" slot-scope="props">
                      <div class="d-flex align-items-center">
                        <b-avatar class="icon-profile mr-2" :text="props.rowData.email.substr(0,1)"></b-avatar>
                        <span>{{props.rowData.companyName}}</span>
                      </div>
                    </div>
                  </vuetable>
                  <div style="padding-top: 10px" class="footer-table">
                    <div class="ui floated menu">
                      <div class="d-flex align-items-center">
                        <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                          items
                        </div>
                        <div
                          v-if="tablePaginationTotal"
                          class="ml-3 pl-3 border-left text-nowrap"
                        >
                          {{ tablePaginationTotal }} items
                        </div>
                      </div>
                    </div>
                    <vuetable-pagination
                      ref="pagination"
                      @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-lg-4 mt-md-3">
            <div class="col-12">
              <div class="bg-white rounded">
                <div
                  class="px-3 py-2-1 border-bottom font-weight-bold"
                  v-b-toggle.accordion-1
                >
                  <div class="d-flex justify-content-between">
                    <div>สถานะ Supplier</div>
                    <eva-icon name="arrow-ios-downward"></eva-icon>
                  </div>
                </div>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <div class="px-3 py-2-1">
                    <div
                      v-for="(item, index) in statusList"
                      :key="index"
                      :class="
                        'row py-2-1 ' +
                        (statusList.length != index + 1 ? 'border-bottom' : '')
                      "
                    >
                      <div class="col-md-2">
                        <span :class="'badge badge-pill ' + item.class">{{
                          item.name
                        }}</span>
                      </div>
                      <div class="col-md-10">
                        {{ item.desc }}
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="modalLinkMerchant" centered title="ผูกบัญชีกับ Pay Solution">
        <b-container>
          <div class="form-group">
              <label for="payso-merchant-id">Pay Solution Merchant ID</label>
              <b-form-input 
                type="number"
                maxLength="6"
                id="payso-merchant-id"
                v-model="paysoMerchantId"
                :class="{ 'is-invalid': errors.paysoMerchantId }"
              ></b-form-input>
              <div
                v-if="errors.paysoMerchantId"
                class="invalid-feedback"
              >
                {{errors.paysoMerchantId}}
              </div>
            </div>
        </b-container>
        <template #modal-footer>
          <div class="w-100 m-0 text-right">
            <b-button
              variant="light"
              class="mr-3"
              @click="$bvModal.hide('modalLinkMerchant')"
            >
              ยกเลิก
            </b-button>
            <b-button variant="primary" class="" @click="apiConnectPaysoMerchant()">
              ผูกบัญชี
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </template>
  <script>
  import Vuetable from "vuetable-2";
  import VuetablePagination from "src/components/pagination/VuetablePagination";
  import FieldsDef from "../../../_fields/admin_supplier_payso_merchant.js";
  import Status from "../../../_fields/status_supplier.js";
  import { formatNum } from "../../../_formats/number"
  import _ from "lodash";
  
  export default {
    name: "SupplierConnectPaySolution",
    components: {
      Vuetable,
      VuetablePagination,
    },
    data() {
      return {
        fields: FieldsDef,
        statusList: Status,
        searchAction: 1,
        perPage: 10,
        tablePaginationTotal: 0,
        data: [],
        searchStatus: '',
        keyword: "",
        exportLink: '',
        submitted: false,
        paysoMerchantId: '',
        supplierId:'',
        errors:[]
      };
    },
    watch: {
      data(newVal, oldVal) {
        this.$refs.vuetable.resetData();
        this.$refs.vuetable.refresh();
      },
      perPage(){
        this.$refs.vuetable.refresh();
      },
      searchAction(){
        this.apiGetList();
      },
      searchStatus(){
        this.apiGetList();
      },
      paysoMerchantId(value){
        this.paysoMerchantId = formatNum.numberic(value);
      }
    },
    mounted() {
      this.apiGetList();
    },
    methods: {
      apiGetList() {
        let url = "api/v2/supplier/list";
        url = `${url}?keyword=${this.keyword}&status=${this.searchStatus}`;
        this.$http.get(url).then((response) => {
          this.data = response.data.data;
          this.exportLink = response.data.exportLink;
        });
      },
      clickExportLink(){
        let url = this.exportLink;
        url = url + "?keyword=" + this.keyword+'&status='+ this.searchStatus;
        window.open(url)
      },
      search() {
        this.apiGetList();
      },
      status(status, string) {
        let find = this.statusList.find((s) => s.id == status);
        if (find) {
          return (
            "<span class='badge badge-pill " +
            find.class +
            "'>" +
            string +
            "</span>"
          );
        } else {
          return (
            "<span class='badge badge-pill badge-secondary'>" + string + "</span>"
          );
        }
      },
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
      },
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },
      dataManager(sortOrder, pagination) {
        if (this.data.length < 1) return;
  
        let local = this.data;
        
        // sortOrder can be empty, so we have to check for that as well
        if (sortOrder.length > 0) {
          
          local = _.orderBy(
            local,
            sortOrder[0].sortField,
            sortOrder[0].direction
          );
        }
        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.perPage
        );
        
        let from = pagination.from - 1;
        let to = from + this.perPage;
  
        return {
          pagination: pagination,
          data: _.slice(local, from, to),
        };
      },
      onActionClicked(action, data) {
        console.log("slot actions: on-click", data.name);
      },
      connectPaysoMerchant(supplierId, merchantId){
        this.paysoMerchantId = merchantId
        this.supplierId = supplierId
        this.$bvModal.show("modalLinkMerchant");
      },
      apiConnectPaysoMerchant() {
        let self = this;
        this.submitted = true
        this.$showLoader();
        this.$http
          .patch(`api/admins/supplier/link-merchant`, {
            supplierId: this.supplierId,
            paysoMerchantId: this.paysoMerchantId
          })
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            this.$bvModal.hide("modalLinkMerchant");
            this.apiGetList()
          }).catch(error =>{
            self.errors = error.response.data.errors.data
          });
      },
    },
  };
  </script>
  <style src="@/assets/semantic-ui-css/semantic.css">
  </style>
  <style>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 20px;
  }
  </style>