export default [
  {
    title: 'Ref no.',
    name: 'refNo',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'refNo',
  },
  {
    title: 'ID',
    name: 'paymentId',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentId',
  },
  {
    title: 'Buyer',
    name: 'buyerName',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'buyerName',
  },
  {
    title: 'Amount',
    name: 'amountStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'amount',
  },
  {
    title: 'Buyer Fee (VAT)',
    name: 'buyerFeeStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'buyerFeeNet',
  },
  {
    title: 'Supplier Fee (VAT)',
    name: 'supplierFeeStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'supplierFeeNet',
  },
  {
    title: 'Fee (VAT)',
    name: 'feeStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'fee',
  },
  {
    title: 'Total',
    name: 'totalAmountStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'totalAmount',
  },
  {
    title: 'บัตร',
    name: 'cardNumber',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    title: 'Supplier',
    name: 'supplierName',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'supplierName',
  },
  {
    title: 'วันที่ทำรายการ',
    name: 'createDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'createDateFormat',
  },
  {
    title: 'วันที่ตัดบัตร',
    name: 'debitCardDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'debitCardDateFormat',
  },
  {
    title: 'วันที่ชำระ',
    name: 'paymentDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentDateFormat',
  },
  {
    title: 'สถานะ',
    name: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
 
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned",
  }
]