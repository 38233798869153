<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ช่วยเหลือ > </span>
          <span class="font-weight-semibold">ติดต่อเรา</span>
          <div class="h3 mt-4">ติดต่อเรา</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">ข้อมูลติดต่อ</h5>
            </div>
          </div>
          <div v-if="data" class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                    ฝ่ายบริการลูกค้า
                  </h5>
                  <div class="px-3 pt-0 pb-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="h6 font-weight-semibold mt-3">
                          เบอร์โทรศัพท์
                        </div>
                        <div class=" text-secondary">
                          {{ data.phone ? data.phone : "-" }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="h6 font-weight-semibold mt-3">
                          เบอร์โทรศัพท์มือถือ
                        </div>
                        <div class=" text-secondary">
                          {{ data.mobile ? data.mobile : "-" }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="h6 font-weight-semibold mt-3">
                          LINE ID
                        </div>
                        <div class=" text-secondary">
                          {{ data.lineId ? data.lineId : "-" }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="h6 font-weight-semibold mt-3">
                          อีเมล
                        </div>
                        <div class=" text-secondary">
                          {{ data.email ? data.email : "-" }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="h6 font-weight-semibold mt-3">
                          ที่อยู่
                        </div>
                        <div class=" text-secondary">
                          {{ data.fullAddress ? data.fullAddress : "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contact",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.apiGetData();
  },
  methods: {
   
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/contactus`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
};
</script>
