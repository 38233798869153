<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"
            >ข้อมูลนิติบุคคล > <router-link class="text-secondary font-weight-bold" :to="{name:'supplier.juristic.files'}">เอกสารนิติบุคคล</router-link> >
          </span>
          <b>แก้ไขเอกสารนิติบุคคล</b>
          <div class="page-name mt-lg-4 mt-md-3 d-flex justify-content-between">
            <div class="h3">แก้ไขเอกสารนิติบุคคล</div>
            <div></div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="">
              <div class="px-1 font-weight-bold">อัปโหลดเอกสาร</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-lg-6">
                        <div>
                          <b>หนังสือรับรองบริษัท</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="companyCertificates"
                            :class="{ 'is-invalid': errors.companyCertificates }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.companyCertificates"  class="invalid-feedback d-block" >
                            {{ errors.companyCertificates }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(
                              file, index
                            ) in groupFiles.companyCertificates"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.companyCertificates.indexOf(
                                          file
                                        ),
                                        'companyCertificates'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>สำเนาบัตรประชาชนของกรรมการ</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="idCardCopies"
                            :class="{ 'is-invalid': errors.idCardCopies }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.idCardCopies"  class="invalid-feedback d-block" >
                            {{ errors.idCardCopies }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(file, index) in groupFiles.idCardCopies"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.idCardCopies.indexOf(file),
                                        'idCardCopies'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>สำเนาทะเบียนบ้านของกรรมการ</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="houseRegistrationCopies"
                            :class="{ 'is-invalid': errors.houseRegistrationCopies }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.houseRegistrationCopies"  class="invalid-feedback d-block" >
                            {{ errors.houseRegistrationCopies }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(
                              file, index
                            ) in groupFiles.houseRegistrationCopies"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.houseRegistrationCopies.indexOf(
                                          file
                                        ),
                                        'houseRegistrationCopies'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>สำเนารายการจดทะเบียนจัดตั้งบริษัท (บอจ.3)</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="companyEstablishmentListCopies"
                            :class="{ 'is-invalid': errors.companyEstablishmentListCopies }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.companyEstablishmentListCopies"  class="invalid-feedback d-block" >
                            {{ errors.companyEstablishmentListCopies }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(
                              file, index
                            ) in groupFiles.companyEstablishmentListCopies"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.companyEstablishmentListCopies.indexOf(
                                          file
                                        ),
                                        'companyEstablishmentListCopies'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>สำเนาหนังสือบัญชีรายชื่อผู้ถือหุ้น (บอจ.5)</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="listOfShareholdersCopies"
                            :class="{ 'is-invalid': errors.listOfShareholdersCopies }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.listOfShareholdersCopies"  class="invalid-feedback d-block" >
                            {{ errors.listOfShareholdersCopies }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(
                              file, index
                            ) in groupFiles.listOfShareholdersCopies"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.listOfShareholdersCopies.indexOf(
                                          file
                                        ),
                                        'listOfShareholdersCopies'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>สำเนารายการหนังสือบริคณห์สนธิ (บอจ.2)</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files
                            :max="10"
                            group="memorandumCopies"
                            :class="{ 'is-invalid': errors.memorandumCopies }"
                          ></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.memorandumCopies"  class="invalid-feedback d-block" >
                            {{ errors.memorandumCopies }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(file, index) in groupFiles.memorandumCopies"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.memorandumCopies.indexOf(
                                          file
                                        ),
                                        'memorandumCopies'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top my-3"></div>
                      <div class="col-lg-6">
                        <div>
                          <b>แบบฟอร์มใบแจ้งหนี้ (เดือนล่าสุด)</b>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mt-1">
                          <drop-files :max="10" :class="{ 'is-invalid': errors.invoices }" group="invoices"></drop-files>
                          <div
                            class="small text-secondary text-bottom-file mb-4"
                          >
                            ไฟล์ .pdf, .jpg, .png, .doc ขนาดไฟล์ไม่เกิน 10 MB
                            สูงสุด 10 ไฟล์
                          </div>
                          <div v-if="errors.invoices"  class="invalid-feedback d-block" >
                            {{ errors.invoices }}
                          </div>
                        </div>

                        <div class="row">
                          <div
                            v-for="(file, index) in groupFiles.invoices"
                            :key="index"
                            class="col-12 mb-3 list-file"
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="">
                                <img height="60" :src="imgFile(file.name)" />
                              </div>
                              <div class="pl-3 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name">
                                    {{ file.name }}
                                  </div>
                                  <small class="text-secondary size">{{
                                    sizeFile(file.size)
                                  }}</small>
                                </div>
                              </div>
                              <div class="h-100">
                                <div class="d-flex align-items-center h-100">
                                  <eva-icon
                                    @click="
                                      remove(
                                        groupFiles.invoices.indexOf(file),
                                        'invoices'
                                      )
                                    "
                                    name="close-circle"
                                    class="primary cursor-pointer"
                                    fill="#6c757d"
                                  ></eva-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3 text-right mb-5">
                  <button
                    class="btn bg-white border mr-4"
                    @click="$router.push({ name: 'supplier.juristic.files' })"
                  >
                    ยกเลิก
                  </button>
                  <button class="btn btn-primary" @click="$bvModal.show('modalApprove')">
                    บันทึก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered title="">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <div class="w-100 text-center">
          <img src="../../../assets/image/document.png" class="img-fluid" alt="">
          </div>
          <div class="w-100 text-center">
            <h5 class="my-3">กรุณาส่งเอกสารฉบับจริงภายใน 7 วัน</h5>
            <p class="px-2">เนื่องจากคุณได้อัปโหลดเอกสารบริษัทใหม่ กรุณาส่งเอกสารฉบับจริง พร้อมลงนามและตราประทับ มาที่บริษัทเพย์โซลูชั่น จำกัด และรอตรวจสอบเอกสารอีกครั้ง</p>
          </div>

          <b-button variant="primary" class="w-100" @click="submitBtn()">
            ตกลง
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DropFiles from "src/components/Files/DropFiles";

export default {
  name: "JuristicDocs",
  components: {
    "drop-files": DropFiles,
  },
  data() {
    return {
      documents: {},
      files: [],
      errors:{},
      groupFiles: {
        companyCertificates: [],
        idCardCopies: [],
        houseRegistrationCopies: [],
        companyEstablishmentListCopies: [],
        listOfShareholdersCopies: [],
        memorandumCopies: [],
        invoices: [],
        bookBankCopies: [],
        contractToPaysolutions: [],
      },
    };
  },
  created() {
    this.apiData();
  },
  methods: {
    imgFile(filename) {
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    remove(i, group = null) {
      if (group) {
        this.groupFiles[group].splice(i, 1);
      } else {
        this.filelist.splice(i, 1);
      }
    },
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/documents`)
        .then((res) => {
          self.documents = res.data.data.documents;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn() {
      this.$bvModal.hide('modalApprove')
      let self = this;
      this.$showLoader();
      var formData = new FormData();
      for (const [key, group] of Object.entries(this.groupFiles)) {
        for (let i = 0; i < group.length; i++) {
          formData.append(key + "[]", group[i]);
        }
      }
      this.$http
        .post(`api/suppliers/juristics/upload/document/more`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          setTimeout(() => {
          this.$router.push({ name: "supplier.juristic.files" });
        }, 500);
        })
        .catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
        
    },
  },
};
</script>