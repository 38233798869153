<template>
  <div class="wrapper">
    <side-bar :menus="menus"> </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <div class="top-margin"></div>
      <dashboard-content
        @click="toggleSidebar"
        class="container-fluid page-content"
      >
      </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import { authenticationService } from "../_services/authentication.service";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
export default {
  name: "View",
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  data() {
    return {
      currentUser: {},
      menus: [],
      notify: {
        countPaymentCancel: 0,
        countPaymentWaiting: 0,
        countSupplier: 0,
      },
      menuAdmin: [
        {
          to: "admin.dashboard",
          title: "แดชบอร์ด",
          icon: "grid-outline",
        },
        {
          to: "admin.payments",
          title: "รายการชำระเงิน",
          icon: "custom-payment",
        },
        {
          title: "รายการอนุมัติ",
          icon: "clipboard-outline",
          child: [
            {
              to: "admin.approve.suppliers",
              title: "อนุมัติ Supplier",
              noti: 0,
            },
            {
              to: "admin.approve.payments",
              title: "อนุมัติการชำระเงิน",
              noti: 0,
            },
            {
              to: "admin.approve.cancellation.request",
              title: "อนุมัติคำขอยกเลิก",
              noti: 0,
            },
          ],
        },
        {
          title: "รายการธุรกรรม",
          icon: "clipboard-outline",
          child: [
            // {
            //   to: "admin.report.merchant-transaction",
            //   title: "รายงานการชำระเงิน",
            //   noti: 0,
            // },
            {
              to: "admin.report.transfer-to-supplier-report",
              title: "ข้อมูลการโอนเงินให้ Supplier",
              noti: 0,
            },
            // {
            //   to: "admin.report.approvedTransferMoneyToSupplier",
            //   title: "ยืนยันการโอนเงินให้ Supplier",
            // },
          ],
        },
        {
          to: "admin.suppliers",
          title: "Supplier",
          icon: "briefcase-outline",
        },
        {
          to: "admin.buyers",
          title: "Buyer",
          icon: "people-outline",
        },
        {
          to: "admin.payso.suppliers",
          title: "ผูกบัญชี Pay Solution",
          icon:"flip-2"
        },
        {
          to: "admin.users",
          title: "จัดการผู้ใช้",
          icon: "list-outline",
        },
        {
          title: "ช่วยเหลือ",
          icon: "question-mark-circle-outline",
          child: [
            {
              to: "admin.help.contact-us",
              title: "ติดต่อเรา",
            },
            {
              to: "admin.help.privacy-policy",
              title: "นโยบายความเป็นส่วนตัว",
            },
            {
              to: "admin.help.terms-and-conditions",
              title: "เงื่อนไขและข้อกำหนด",
            },
          ],
        },
      ],
      menuBuyer: [
        {
          to: "buyer.dashboard",
          title: "แดชบอร์ด",
          icon: "grid-outline",
        },
        {
          to: "buyer.payments",
          title: "รายการชำระเงิน",
          icon: "custom-payment",
        },
        {
          to: "buyer.calendar",
          title: "การชำระเงินล่วงหน้า",
          icon: "calendar-outline",
        },
        {
          to: "buyer.credit",
          title: "จัดการบัตรเครดิต",
          icon: "credit-card-outline",
        },
        {
          to: "buyer.supplier",
          title: "รายชื่อ Supplier",
          icon: "file-text-outline",
        },
        {
          type: "juristic_person",
          title: "ข้อมูลนิติบุคคล",
          icon: "briefcase-outline",
          child: [
            {
              to: "buyer.juristic.company",
              title: "รายละเอียดนิติบุคคล",
            },
            {
              to: "buyer.juristic.receipt-address",
              title: "ที่อยู่ใบกำกับภาษี",
            },
          ],
        },
        {
          title: "ช่วยเหลือ",
          icon: "question-mark-circle-outline",
          child: [
            {
              to: "buyer.help.contact-us",
              title: "ติดต่อเรา",
            },
            {
              to: "buyer.help.privacy-policy",
              title: "นโยบายความเป็นส่วนตัว",
            },
            {
              to: "buyer.help.terms-and-conditions",
              title: "เงื่อนไขและข้อกำหนด",
            },
          ],
        },
      ],
      menuSupplier: [
        {
          to: "supplier.payments",
          title: "รายการชำระเงิน",
          icon: "custom-payment",
        },
        {
          to: "supplier.create-payment",
          title: "สร้างรายการเรียกเก็บเงิน",
          icon: "file-text-outline",
        },
        {
          to: "supplier.customers",
          title: "ลูกค้า",
          icon: "people-outline",
        },
        {
          to: "supplier.users",
          title: "จัดการผู้ใช้",
          icon: "list-outline",
          hide: ["staff"],
        },
        {
          title: "ข้อมูลนิติบุคคล",
          icon: "briefcase-outline",
          hide: ["staff"],
          child: [
            {
              to: "supplier.juristic.company",
              title: "รายละเอียดนิติบุคคล",
            },
            {
              to: "supplier.juristic.files",
              title: "เอกสารนิติบุคคล",
            },
            {
              to: "supplier.juristic.bank",
              title: "บัญชีธนาคาร",
            },
            {
              to: "supplier.juristic.receipt-address",
              title: "ที่อยู่ใบกำกับภาษี",
            },
          ],
        },
        {
          title: "ช่วยเหลือ",
          icon: "question-mark-circle-outline",
          child: [
            {
              to: "supplier.help.contact-us",
              title: "ติดต่อเรา",
            },
            {
              to: "supplier.help.privacy-policy",
              title: "นโยบายความเป็นส่วนตัว",
            },
            {
              to: "supplier.help.terms-and-conditions",
              title: "เงื่อนไขและข้อกำหนด",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.currentUser = authenticationService.currentUserValue;
    if (authenticationService.currentUserValue) {
      if (this.$router.currentRoute.name === "dashboard") {
        switch (authenticationService.currentUserValue.role) {
          case "admin":
            this.$router.push({ name: "admin.dashboard" });
            return;
          case "buyer":
            this.$router.push({ name: "buyer.dashboard" });
            return;
          case "supplier":
            this.$router.push({ name: "supplier.payments" });
            return;
        }
      }
      if (authenticationService.currentUserValue.role === "admin") {
        this.getApiNotify();
        setInterval(() => {
          this.getApiNotify();
        }, 300000);
        if (
          this.currentUser.adminRole == "viewer" ||
          this.currentUser.adminRole == "approver"
        ) {
          this.menuAdmin.splice(5, 1);
        }
        this.menus = this.menuAdmin;
      } else {
        if (authenticationService.currentUserValue.defaultSetting === 0) {
          if (this.currentUser.supplierAdminRole == "staff") {
            this.menuSupplier.splice(2, 1);
            this.menuSupplier.splice(2, 1);
          }
          this.menus = this.menuSupplier;
        } else {
          if (this.currentUser.type != "juristic_person") {
            this.menuBuyer.splice(5, 1);
          }
          this.menus = this.menuBuyer;
        }
      }
    }
  },
  methods: {
    getApiNotify() {
      this.$http.get(`api/admin/badge/sidebar`).then((response) => {
        let noti = response.data.data;
        this.menuAdmin[2].child[0].noti = noti.countSupplier;
        this.menuAdmin[2].child[1].noti = noti.countPaymentWaiting;
        this.menuAdmin[2].child[2].noti = noti.countPaymentCancel;
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    attrSet(index) {
      return {
        [`v-b-toggle.collapse-${index}`]: "",
      };
    },
  },
};
</script>
<style lang="scss">
.top-margin {
  margin-top: 55px;
}
</style>