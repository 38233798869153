<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="text-center mb-4">
          <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
        </div>
        <div v-if="!success" class="box box-signup">
          <form class="" @submit.prevent="onSubmit">
            <div class="title text-center">ลืมรหัสผ่าน</div>
            <div class="form-group">
              <label for="username">อีเมล</label>
              <b-form-input
                id="username"
                 :class="{ 'is-invalid': errors.email }"
                v-model="username"
              ></b-form-input>
              <div v-if="errors.email" class="invalid-feedback d-block">
                      {{ errors.email }}
                    </div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary w-100 text-center"
                :disabled="loading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  v-show="loading"
                ></span>
                <span>ยืนยัน</span>
              </button>
            </div>
          </form>
        </div>
        <div
          v-if="success"
          class="box px-lg-5 px-sm-2 py-lg-4 py-sm-2 text-center"
        >
          <img
            src="./../../assets/image/changepass-completed.png"
            class="img-fuild"
          />
          <div class="h5 mt-4">
            ระบบส่งลิงก์เปลี่ยนรหัสผ่านไปที่อีเมลของท่านแล้ว
          </div>
          <router-link
            :to="{ name: 'login' }"
            class="btn btn-primary w-100 text-center mt-3"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-show="loading"
            ></span>
            <span>เข้าสู่ระบบ</span>
          </router-link>
        </div>
        <div v-if="!success" class="my-3 text-center">
          <router-link :to="{ name: 'login' }">กลับหน้าเข้าสู่ระบบ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      username: "",
      submitted: false,
      errors:{},
      loading: false,
      returnUrl: "",
      error: "",
      success: false,
    };
  },
  validations: {
    username: { required, email },
  },
  created() {},
  methods: {
    onSubmit() {
      let self = this;
      this.submitted = true;
      self.errors = {}
      self.loading = true;
      this.$http
        .post(`api/users/forgot/password`, {
          email: this.username,
        })
        .then((res) => {
          self.success = true;
          self.loading = false;
        })
        .catch(function(err) {
          self.loading = false;
          self.errors = err.response.data.errors.data;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 28px;
  margin-bottom: 1.5rem;
}
.box {
  background-color: #ffffff;
}
</style>