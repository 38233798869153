<template>
  <div>
    <div v-if="data.supplierName" class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-bold" :to="{name:'admin.suppliers'}">Supplier</router-link> >
          <router-link class="text-secondary font-weight-bold" :to="{name:'admin.suppliers.show',id:data.supplierId}">{{data.companyNameTh}}</router-link> > 
          </span>
          <b> ข้อมูลบริษัท</b>
         <div class="page-name d-flex justify-content-between">
            <div class="h3 mt-lg-4">
            ข้อมูลบริษัท
          </div>
        </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="">
              <div class="py-2-1 font-weight-bold">รายละเอียด</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div
                    class="px-3 py-2-1 border-bottom font-weight-bold d-flex justify-content-between"
                  >
                    <div>สถานะ</div>
                    
                    <span
                        :class="
                          'badge badge-pill' +
                          (data.statusNumber == 0
                            ? ' badge-warning'
                            : '') +
                          (data.statusNumber == 2
                            ? ' badge-danger'
                            : '') +
                          (data.statusNumber == 1
                            ? ' badge-success'
                            : '')
                        "
                        >{{ data.statusName }}</span
                      >
                  </div>
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">
                              ชื่อบริษัท (TH)
                            </div>
                            <div class="text-secondary">{{data.companyNameTh}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">
                              ชื่อบริษัท (EN)
                            </div>
                            <div class="text-secondary">{{data.companyNameEn}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">เลขนิติบุคคล</div>
                            <div class="text-secondary">{{data.juristicNumber}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">ที่อยู่</div>
                            <div class="text-secondary">
                              {{data.address}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">
                              เบอร์โทรศัพท์บริษัท
                            </div>
                            <div class="text-secondary">{{data.phone}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">อีเมลบริษัท</div>
                            <div class="text-secondary">{{data.email}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">
                              เว็บไซต์
                            </div>
                            <div class="text-secondary">{{data.website}}</div>
                          </div>
                          <div class="col-lg-12 mb-3">
                            <div class="h6 font-weight-bold">
                              หมวดหมู่ Supplier
                            </div>
                            <div class="text-secondary">{{data.supplierCategoryName}}</div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row justify-content-center mt-5">
      <div class="col-12 mt-5 text-center"><h3>ไม่พบข้อมูล</h3></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Juristic",
  data() {
    return {
      data:{}
    }
  },
  created() {
    this.apiData()
  },
  methods: {
    apiData(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/admins/supplier/all/status/`+this.$route.params.id+`/data/company`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
    }
  },
};
</script>