import { BehaviorSubject } from "rxjs";

import { handleResponse } from "../_helpers/handle-response";
import http from '../_http/http'

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const sessionUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("sessionUser"))
);
export const authenticationService = {
  login,
  logout,
  refreshUser,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  sessionUser: sessionUserSubject.asObservable(),
  get sessionUserValue() { return sessionUserSubject.value;}
};

function login(email, password) {
  return http.post(
    `api/users/login`,
    { email, password }
  )
    .then(handleResponse)
    .then((res) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let user = res.data.data
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    })
    .catch(function(error) {
      return error.response.data;
    });
}
function refreshUser(path,reload = false) {
  http
    .get(path)
    .then((res) => {
      let newUser = res.data.data;
      let user = JSON.parse(localStorage.getItem("currentUser"))
      newUser.token = user.token
      localStorage.setItem("currentUser", JSON.stringify(newUser));
      if(reload == true){
        location.reload()
      }
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
