<template>
  <div>
    <div class="row justify-content-center">
      <div v-if="detail" class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary"><router-link class="text-secondary font-weight-semibold" :to="{name:'supplier.payments'}">รายการชำระเงิน</router-link> > </span>
          <span class="font-weight-semibold"> รายการชำระเงิน #{{ detail.refNo }}</span>
          <div class="h3 mt-4">รายการชำระเงิน #{{ detail.refNo }}</div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="bg-white mb-4">
              <h5 class="px-3 py-2-1 border-bottom font-weight-semibold">
                รายละเอียด
              </h5>
              <div class="px-3 py-2-1 pb-4">
                <div class="h6 font-weight-semibold mt-1">สถานะ</div>
                <div
                  v-html="
                    status(detail.statusNumber,detail.statusName)
                  "
                ></div>
                <div class="h6 font-weight-semibold mt-3">Ref No.</div>
                <span class="text-secondary small">{{ detail.refNo }}</span>
                <div class="h6 font-weight-semibold mt-3">วันที่สร้าง</div>
                <span class="text-secondary small">{{ detail.createdAt }}</span>
              </div>
            </div>
             <div class="pt-0" v-if="detail.statusNumber === 9">
               <div class="py-2">
                 <b-button class="text-center" variant="primary" block   @click="copyLink"><eva-icon name="link" class="color-white"></eva-icon> คัดลอกลิงก์</b-button>
               </div>
               <div class="py-2">
                 <b-button class="text-center text-white" variant="warning" block @click="sendEmail"><eva-icon name="refresh" class="color-white"></eva-icon> ส่งอีเมลอีกครั้ง</b-button>
               </div>
               <div class="mt-2 mb-4">
                <button
                v-if="
                  (detail.statusNumber == 0 || detail.statusNumber === 9)&&
                    (payment.statusCancelNumber == 4 ||
                      payment.statusCancelNumber == 5)
                "
                class="btn border bg-white w-100 font-weight-semibold"
                @click="
                  showModalDelete(
                    payment.paymentId,
                    payment.statusCancelNumber,
                    payment.statusCancel
                  )
                "
              >
                <eva-icon
                  width="16px"
                  class="color-secondary"
                  name="radio-button-on"
                ></eva-icon>
                {{ payment.statusCancel }}
              </button>
               </div>
              </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      d-flex
                      justify-content-between
                    "
                  >
                    <h5 class="mb-0">ข้อมูลลูกค้า</h5>
                    <div>
                      <span
                        :class="
                          'badge badge-pill' +
                            (buyer.statusNumber == 0 ? ' badge-warning' : '') +
                            (buyer.statusNumber == 2 ? ' badge-danger' : '') +
                            (buyer.statusNumber == 1 ? ' badge-success' : '')
                        "
                        >{{ buyer.statusName }}</span
                      >
                    </div>
                  </div>
                  <div class="px-3 pt-2 pb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">
                          ผู้ชำระเงิน
                        </div>
                        <span v-if="buyer.name" class="text-secondary">{{ buyer.name }}</span>
                        <span v-else class="text-secondary">-</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">อีเมล</div>
                        <span class="text-secondary">{{ buyer.email }}</span>
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">
                          เบอร์โทรศัพท์
                        </div>
                        <span v-if="buyer.phone" class="text-secondary">{{ buyer.phone }}</span>
                        <span v-else class="text-secondary">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-4">
                <div class="bg-white h-100 rounded">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-semibold
                      d-flex
                      justify-content-between
                    "
                  >
                    <h5>การชำระเงิน</h5>
                    <div>
                      <button
                        @click="downloadAll()"
                        type="button"
                        class="btn btn-outline-primary"
                      >
                        <eva-icon name="download-outline"></eva-icon>
                        ดาวน์โหลดทั้งหมด
                      </button>
                    </div>
                  </div>
                  <div class="px-3 pt-2 pb-4">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">จำนวน</div>
                        <span class="text-secondary"
                          >฿{{ formatPrice(payment.amount, 2) }}</span
                        >
                
                          <div class="h6 font-weight-semibold mt-3">สัดส่วนชำระค่าธรรมเนียม</div>
                          <span v-if="payment.supplierFeeRate > 0" class="text-secondary"
                            >{{ payment.supplierFeeRate }}%</span>
                          <span v-else class="text-secondary">-</span >
                          <div class="h6 font-weight-semibold mt-3">ค่าธรรมเนียมที่ต้องชำระ</div>
                          <span v-if="payment.supplierFeePrice > 0" class="text-secondary"
                            >฿{{ payment.supplierFeePrice }}</span>
                          <span v-else class="text-secondary">-</span >
  
                      </div>
                      <div class="col-lg-6">
                        <div class="h6 font-weight-semibold mt-3">วันที่ชำระ</div>
                        <span v-if="payment.paymentDate" class="text-secondary">{{ payment.paymentDate }}</span>
                        <span v-else class="text-secondary">-</span>
                        <div v-if="detail.statusNumber === 9">
                          <div class="h6 font-weight-semibold mt-3">วันหมดอายุการเรียกเก็บเงิน</div>
                          <span v-if="detail.expireAt" class="text-secondary">{{ detail.expireAt }}</span>
                          <span v-else class="text-secondary">-</span>
                        </div>
                          <div class="h6 font-weight-semibold mt-3">ยอดเงินที่จะได้รับ</div>
                          <span v-if="payment.supplierIncome !== 0.1" class="text-secondary"
                          >฿{{ formatPrice(payment.supplierIncome, 2) }}</span>
                          <span v-else class="text-secondary">-</span>
                      </div>
                      <div class="col-lg-12">
                        <div class="h6 font-weight-semibold mt-3">
                          Payment Reference
                        </div>
                        <span class="text-secondary">{{
                          document.paymentReference
                        }}</span>
                      </div>
                      <div class="col-lg-12">
                        <div class="h6 font-weight-semibold mt-3">
                          เอกสารการชำระเงิน
                        </div>
                      </div>
                      <div
                        v-for="(file, index) in document.files"
                        :key="index"
                        class="col-md-6 mb-3 list-file"
                      >
                        <div
                          :title="file.name"
                          class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                        >
                          <div class="">
                            <img width="40px" :src="imgFile(file.mimeType)" />
                          </div>
                          <div class="pl-3 w-70">
                            <div class="h-100 flex-column align-items-start">
                              <div class="mb-auto name">
                                <a :href="file.download" download="">
                                    {{ file.name }}
                                    </a>
                              </div>
                              <!-- <small class="text-secondary size">{{
                                sizeFile(file.size)
                              }}</small> -->
                            </div>
                          </div>
                          <div class="h-100">
                            <div class="d-flex align-items-center h-100">
                              <a :href="file.download" download="">
                                <eva-icon
                                  name="download"
                                  class="cursor-pointer color-secondary"
                                ></eva-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered :title="statusName">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="warning" class="" @click="apiChangeStatus()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { formatNum }from "../../../_formats/number";
import status from "../../../_fields/status.js";
export default {
  name: "PaymentShow",
  data() {
    return {
      buyer: {},
      detail: {},
      document: {},
      payment: {},
      supplier: {},
      deleteId: null,
      statusName:null,
     statusList: status.slice(0,7).concat(status.slice(7+1)),
        allStatus: status,
      dismissSecs: 1.5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  created() {
    this.apiGetData();
  },
  methods: {
    apiGetData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/payment/` + this.$route.params.id + `/show`)
        .then((res) => {
          let data = res.data.data;
          self.buyer = data.buyer;
          self.detail = data.detail;
          self.document = data.document;
          self.payment = data.payment;
          self.supplier = data.supplier;
          self.$hideLoader();
        });
    },
    sendEmail(){
      this.$showLoader();
      this.$http
        .post(`api/mailer/supplier/payment/init-invoice`, {
          "paymentId": this.payment.paymentId,
          "email": this.buyer.email
        })
        .then((res) => {
          this.$hideLoader();
          this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
      })
    },
    formatPrice(a, b) {
      return formatNum.price(Number(a), Number(b));
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    imgFile(filename) {
      switch (filename.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return require("@/assets/icon/file-png@2x.png");
      }
    },
    downloadAll() {
      this.document.files.forEach((f) => {
        window.open(f.download);
      });
    },
    async copyLink(){
      try {
          await navigator.clipboard.writeText(this.detail.invoiceLink);
          this.$notifications.notify({
            message: 'คัดลอกลิงก์เรียบร้อยแล้ว',
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        } catch($e) {
          this.$notifications.notify({
            message: 'ไม่สามารถคัดลอกลิงก์ได้',
            icon: "alert-triangle",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "warning",
          });
      }
    },

    showModalDelete(paymentId, currentStatus, statusText) {
      this.deleteId = paymentId;
      this.currentStatus = currentStatus;
      this.statusCancel = false;
      this.statusName = `ต้องการ${statusText}`;
      this.$bvModal.show("modalApprove");
    },
    apiChangeStatus() {
      let self = this;
      let url = "/api/payment/flag-status/updated",
      body = {
        paymentId: this.deleteId,
        paymentStatus: this.currentStatus,
        approved: this.statusCancel,
      },
      modal = this.currentStatus === 4 ? "modalDeleteCancel":"modalApprove"
      this.$bvModal.hide(`${modal}`);
      this.$showLoader();
      this.$http.patch(url, body).then(response => {
        self.apiGetData()
        this.$bvModal.hide(`${modal}`);
          this.$hideLoader();
          this.$notifications.notify({
            message: response.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
      }).catch(error => {
        this.$notifications.notify({
            message: 'ไม่สามารถส่งอีเมล์ได้',
            icon: "alert-triangle",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
      })
    },
  },
  mounted: function() {
      this.apiGetData()
  },
};
</script>
<style lang="scss" scoped>
.list-file .name {
  font-size: 12px !important;
}
</style>
