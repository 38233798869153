export default [
  {
    title: 'Ref no.',
    name: 'refNo',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'refNo',
  },
  {
    title: 'Payment ID',
    name: 'paymentId',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentId',
  },
 
  {
    title: 'จำนวน',
    name: 'amountStr',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'amount',
  },
  {
    title: 'วันที่ชำระ',
    name: 'paymentDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'paymentDateFormat',
  },
  {
    title: 'สถานะ',
    name: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned",
  }
]