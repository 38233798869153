<template>
  <div>
    <div v-if="data" class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">
            <router-link class="text-secondary font-weight-bold" :to="{name:'admin.buyers'}">Buyer</router-link> >
          <router-link class="text-secondary font-weight-bold" :to="{name:'admin.buyers.show',id:data.buyerId}">{{data.name}}</router-link> >
          </span>
          <b> แก้ไขข้อมูลผู้ใช้</b>
          <div class="page-name d-flex justify-content-between">
            <div class="h3 mt-lg-4">แก้ไขข้อมูลผู้ใช้</div>
          </div>
        </div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-lg-3 col-md-4">
            <div class="">
              <div class="py-2-1 font-weight-bold">ข้อมูลผู้ใช้</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div
                    class="
                      px-3
                      py-2-1
                      border-bottom
                      font-weight-bold
                      d-flex
                      justify-content-between
                    "
                  >
                    <div>รายละเอียด</div>
                  </div>
                  <div class="px-3 py-3">
                    <div class="row">
                      <div class="col-lg-6 mb-3">
                        <div class="h6 font-weight-bold">ชื่อ - นามสุล</div>
                        <div class="text-secondary">
                          {{ data.name }}
                        </div>
                      </div>
                      <div class="col-lg-6 mb-3">
                        <div class="h6 font-weight-bold">เบอร์โทรศัพท์</div>
                        <div class="text-secondary">
                          {{ data.phone }}
                        </div>
                      </div>
                      <div class="col-lg-12 mb-3">
                        <div class="h6 font-weight-bold">อีเมล</div>
                        <input type="email" v-model="data.email" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 px-md-4 pb-md-3 text-right">
                <b-button
                  @click="
                    $router.push({
                      name: 'admin.buyers.show',
                      id: $route.params.id,
                    })
                  "
                  variant="outline-secondary"
                  class="mr-3"
                  >ยกเลิก</b-button
                >
                <b-button @click="submitBtn()" variant="primary"
                  >ยืนยัน</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Profile",
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.apiData();
  },
  methods: {
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(
          `api/admins/buyers/` +
            this.$route.params.id +
            `/get/data`
        )
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        });
    },
    submitBtn(){
      let self = this;
      this.$showLoader();
      this.$http
        .patch(
          `api/admins/buyers/` +
            this.$route.params.id +
            `/update`
        ,{
          email: this.data.email
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        });
    }
  },
};
</script>