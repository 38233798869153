<template>
  <div>
    <b-navbar variant="faded" type="light">
      <b-navbar-brand class="mx-auto" href="#"><img class="fluid" src="@/assets/logo_paysoon-rgb.png" alt="Paysoon"
          width="150"></b-navbar-brand>
    </b-navbar>
    <section class="top">
      <b-container class="my-5">
        <b-row>
          <b-col md="6" offset-md="3" class="text-center">
            <b-img class="mb-3" fluid
              src="https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/payment_request.png" width="200"></b-img>
            <h3 class="mt-2 text-yellow">รายการชำระเงิน: {{ paymentId }}</h3>
            <p class="status mb-1">สถานะ <b-badge>เรียกเก็บเงิน</b-badge>
            </p>
            <p class="time">{{ detail.createdAt }}</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="middle">
      <b-container class="mb-3 middle-content">
        <b-col>
          <b-col md="8" offset-md="2" class="text-center">
            <b-card class="text-left">
              <div class="text-primary">
                <h5 class="text-primary">คุณมีรายการเรียกเก็บเงินจากผู้ขาย (Supplier)</h5>
                <p style="font-weight: 500;">โปรดตรวจสอบเเละยืนยันทำรายการชำระเงิน</p>
              </div>
              <div class="title-payment-list font-weight-bold text-dark p-2">
                รายละเอียดการเรียกเก็บเงิน
              </div>
              <div>
                <table class="table">
                  <tbody>
                    <tr>
                      <th class="text-default f-w-500">ผู้ขาย: </th>
                      <td>{{ supplier.name }}</td>
                    </tr>
                    <tr>
                      <th class="text-default f-w-500">ผู้ชำระเงิน: </th>
                      <td  v-if="buyer.company_name">{{ buyer.company_name }}</td>
                      <td  v-else>{{ buyer.firstname }} {{ buyer.lastname }}</td>
                   
                    </tr>
                    <tr>
                      <th class="text-default f-w-500">Payment Ref: </th>
                      <td>{{ document.paymentReference }}</td>
                    </tr>
                    <tr>
                      <th class="text-default f-w-500">จำนวน </th>
                      <td>{{ formatNum.price(payment.amount, 2) }}฿</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row text-center my-3">
                  <div class="col-12 mx-auto">
                    <div class="border rounded p-lg-3 invoice-expire">
                      <span class="font-weight-bold">วันที่หมดอายุการเรียกเก็บเงิน</span><br />
                      <span class="text-secondary">
                        {{ detail.expireAt }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <b-button @click="gotoPayment" variant="primary">ตรวจสอบรายการ</b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-col>
      </b-container>
    </section>
    <section class="bottom">
      <b-container>
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="6" class="order-md-2 paysoon-content">
                <h3 class="text-white font-weight-normal"><span style="font-weight:bold;color:#ffd600">Paysoon</span>
                  บริการชำระเงินออนไลน์ที่ช่วยสร้างสภาพคล่องให้ธุรกิจด้วยบัตรเครดิต</h3>
                <p class="text-white">ง่าย ครบ จบในแพลทฟอร์มเดียว</p>
                <b-button href="https://paysoon.net/service.html" class="btn-paysoon">ดูรายละเอียด</b-button>
              </b-col>
              <b-col md="6" class="order-md-1 paysoon-content-img">
                <b-img fluid src="https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/img-paysoon.png"
                  width="500"></b-img>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footer>
      <div class="container">
        <b-row>
          <b-col md="4" xl="6">
            <div class="footer-logo mb-4">
              <a><img class="fluid" src="@/assets/logo_paysoon-rgb.png" alt="Paysoon" width="150"></a>
            </div>
            <div class="footer-tittle">
              <div class="footer-pera">
                <p>เลขที่ 90 อาคารซีดับเบิ้ลยู ทาวเวอร์ อาคารบี ชั้น 25 ยูนิต B2502 ถ.รัชดาภิเษก แขวงห้วยขวาง เขตห้วยขวาง
                  กรุงเทพมหานคร 10310</p>
                <p><span class="font-weight-bold">โทร: </span><a href="tel:02-821-6163">02-821-6163</a></p>
                <p><span class="font-weight-bold">ฝ่ายขาย (Sale): </span><a href="tel:094-424-8943">094-424-8943</a></p>
              </div>
            </div>
          </b-col>
          <b-col md="4" xl="3">
            <div class="single-footer-caption mb-40">
              <div class="footer-tittle">
                <h4 class="text-primary mb-4">เพย์ ซูน</h4>
                <ul class="footer-nav">
                  <li><a href="https://paysoon.net/about.html">เกี่ยวกับเรา</a></li>
                  <li><a href="https://paysoon.net/service.html">บริการของเรา</a></li>
                  <li><a href="https://paysoon.net/privacy-policy.html">นโยบายความเป็นส่วนตัว</a></li>
                  <li><a href="https://paysoon.net/term-condition.html">ข้อตกลงและเงื่อนไข</a></li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col md="4" xl="3">
            <div class="single-footer-caption mb-40">
              <div class="footer-tittle">
                <h4 class="text-primary">ช่วยเหลือ</h4>
                <ul class="footer-nav">
                  <!-- <li><a href="https://paysoon.net/help.html">ช่วยเหลือ</a></li> -->
                  <li><a href="https://paysoon.net/contact.html">ติดต่อเรา</a></li>
                </ul>
                <!-- social -->
                <div class="footer-social">
                  <a href="https://www.facebook.com/Paysoon.net" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-facebook-f" /></a>
                  <a href="https://lin.ee/uw6qMmk" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-line" /></a>
                  <a href="tel:02-821-6163">
                    <font-awesome-icon icon="fa-solid fa-phone" /></a>
                  <a href="mailto:paysoon@paysolutions.asia">
                    <font-awesome-icon icon="fa-solid fa-envelope" /></a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </footer>
  </div>
</template>
<script>
  import {
    library
  } from '@fortawesome/fontawesome-svg-core'
  import {
    faEnvelope,
    faPhone
  } from '@fortawesome/free-solid-svg-icons'
  import {
    faFacebookF,
    faLine
  } from '@fortawesome/free-brands-svg-icons';
  import {
    FontAwesomeIcon
  } from '@fortawesome/vue-fontawesome'
  import { formatNum }from "../../_formats/number";
  import dayjs from 'dayjs'
  import timezone from 'dayjs/plugin/timezone';
  import axios from 'axios';
  library.add(faEnvelope, faPhone, faFacebookF, faLine);
  dayjs.extend(timezone)
  dayjs.tz.setDefault(`${process.env.TZ}`)
  export default {
    name: 'Invoice',
    components: {
      'font-awesome-icon': FontAwesomeIcon
    },
    data() {
      return {
        paymentId: this.$route.params.id,
        detail: {},
        payment: {},
        supplier: {},
        buyer: {},
        document: {},
        formatNum: formatNum
      }
    },
    create() {},
    methods: {
      apiData() {
        let config = {
        method: "get",
        url: `${process.env.VUE_APP_APISERVER}/api/payment/data/${this.$route.params.id}`,
        headers: { 
          'apikey': `${process.env.VUE_APP_API_KEY}`, 
          'Content-Type': 'application/json'
        }
      };
      axios(config).then(response => {
          let data = response.data.data;
          let curr = dayjs()
          if(curr.diff(data.detail.expireInvoice, 'day') >= 0 || data.detail.statusNumber != 9){
             this.$router.push({name:'page.notFound'})
          }
          this.detail = data.detail
          this.payment = data.payment
          this.supplier = data.supplier
          this.buyer = data.buyer
          this.document = data.document;
      }).catch(err => 
        this.$router.push({name:'page.notFound'})
      )
    },
    gotoPayment(){
      location.href = `/buyer/calendar/${this.paymentId}/paylink`
    }
    },
    watch: {

    },
    mounted: function() {
      this.apiData()
  },
  }
</script>
<style lang="scss" scoped>
  section {
    overflow: auto;
  }

  .navbar {
    background-color: #fff;
    height: 60px;
  }

  .top {
    background: url('https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/bg-blue.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .middle {
    background: linear-gradient(0deg, rgba(230, 241, 253, 1) 0%, rgba(255, 255, 255, 0) 40%);
  }

  .bottom {
    background-color: #0d18ad;
  }

  .f-w-500 {
    font-weight: 500 !important;
  }

  .text-default {
    color: #354560 !important;
  }

  .text-yellow {
    font-size: 2rem;
    color: #fed702 !important;
  }

  .status {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffff;
  }

  .status span.badge {
    border-radius: 15px !important;
    background-color: #fff3d5 !important;
    color: #e28d00 !important;
  }

  .time {
    color: #fff;
    font-size: 1.2rem
  }

  .title-payment-list {
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
  }

  .invoice-expire {
    padding: 2vh;
  }

  .btn:hover {
    background-position: right;
  }

  .btn {
    text-transform: capitalize;
    padding: 24px 26px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    line-height: 1;
    -moz-user-select: none;
    line-height: 0;
    margin-bottom: 0;
    cursor: pointer;
    -webkit-transition: color 0.4s linear;
    -o-transition: color 0.4s linear;
    transition: color 0.4s linear;
    position: relative;
    z-index: 1;
    border: 0;
    overflow: hidden;
    margin: 0;
  }

  .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    background: #00156A;
    z-index: 1;
    border-radius: 5px;
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    -o-transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    border-radius: 0px;
  }

  .btn:hover::before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    z-index: -1;
  }

  .btn.focus,
  .btn:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .middle-content .card {
    border-radius: 20px;
  }

  .table th,
  .table td {
    border: none;
  }

  .btn-paysoon {
    background: #FFD600 !important;
    color: #1A32B1;
  }

  .btn-paysoon::before {
    background: #FBA414;
  }

  .top {
    height: auto;
  }

  .middle {
    position: relative;
    margin-top: -2rem;
    padding: 0;
  }

  .card {
    padding: 2rem 4rem;
  }

  .card-body {
    padding: 0rem;
  }

  .paysoon-content {
    margin-top: 10vh;
  }

  footer {
    overflow: auto;
    background-color: #FFFF;
  }

  .footer-pera>p {
    margin: 0;
  }

  .footer-nav {
    list-style-type: none;
    padding: 0;
  }

  .footer-nav>li>a {
    color: #1f2e49
  }

  .footer-social {
    margin-top: 25px;
  }

  .footer-social a {
    background: #e8f1fb;
    color: #1A32B1;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 45px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
  }

  .footer-social a:hover {
    color: #ffffff;
    background: #1A32B1;
  }

  .footer-social a i {
    display: inline-block;
  }

  .footer-social a i.fa-line {
    font-size: 30px;
    top: 4px;
    position: relative;
  }

  footer .container {
    margin-top: 2rem;
    padding: 10px 20px;
  }

  @media (max-width: 575.98px) {

    .middle-content {
      position: relative;
      margin-top: 2vh !important;
      padding: 0;
    }

    .middle-content .col,
    .middle-content .col .col-md-8 {
      padding: 0
    }

    .middle-content .card {
      padding: 2rem 2rem;
    }

    .paysoon-content {
      text-align: center;
      margin: 5vh 2vh !important;
    }

    footer .container {
      margin-top: 3rem;
      padding: 10px 20px;
    }

    .footer-tittle {
      margin-top: 4vh;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (min-width: 575.99px) and (max-width: 767.98px) {
    .top {
      height: 40vh;
    }

    .paysoon-content {
      margin: 5vh 2vh;
      text-align: center;
    }

    .paysoon-content>p {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (min-width: 767.99px) and (max-width: 991.98px) {
    .top .container {
      margin-bottom: 3rem !important;
    }

    .middle-content {
      position: relative;
      margin-top: 3vh;
      padding: 0;
    }

    .paysoon-content {
      margin-top: 1vh;
      padding-top: 20px;
      padding-bottom: 5px;
    }

    .paysoon-content>h3 {
      font-size: 1.6rem !important
    }

    footer .container {
      margin-top: 2rem;
      padding: 10px 20px;
    }
  }

  // Large devices (desktops, less than 1200px)
  @media (min-width: 991.99px) and (max-width: 1199.98px) {
    .top .container {
      margin-bottom: 5rem !important;
    }

    .middle-content {
      position: relative;
      padding: 0;
    }

    .card {
      padding: 2rem 4rem;
    }

    .card-body {
      padding: 0rem;
    }

    .paysoon-content {
      padding-top: 8vh;
    }

    footer .container {
      margin-top: 2rem;
      padding: 10px 20px;
    }
  }
</style>
