export default [
    {
        id: 0,
         name: "รอตรวจสอบ",
         desc: "ลูกค้าดำเนินการสร้างรายการแล้ว และรอเจ้าหน้าที่ดำเนินการอนุมัติรายการชำระเงิน",
         class: "badge-warning",
     },
     {
         id: 1,
         name: "อนุมัติ",
          desc: "เจ้าหน้าที่ได้ดำเนินการอนุมัติรายการชำระเงินแล้ว",
          class: "badge-info",
      },
      {
         id: 2,
         name: "ปฏิเสธ",
          desc: "ระบบไม่สามารถหักเงินตามรายละเอียดของรายการชำระเงินที่ผู้ถือบัตรกำหนดได้ หรือ เจ้าหน้าที่ไม่อนุมัติรายการชำระเงิน",
          class: "badge-danger",
      },
      {
         id: 3,
         name: "ชำระแล้ว",
          desc: "เจ้าหน้าที่ได้ดำเนินการโอนเงินเข้าบัญชีธนาคาร Supplier ตามรายละเอียดขอรายการชำระเงินที่กำหนด",
          class: "badge-success",
      },
     {
        id: 4,
        name: "ส่งคำขอยกเลิก",
         desc: "ลูกค้าส่งคำขอยกเลิกรายการชำระเงิน และรอเจ้าหน้าที่ดำเนินการยกเลิกรายการชำระเงิน",
         class: "badge-warning",
     },
     {   
         id: 5,
         name: "ยกเลิก",
         desc: "รายการชำระเงินถูกยกเลิก",
         class: "badge-secondary",
     },
     {
        id: 6,
        name: "ดำเนินการ",
         desc: "ระบบได้ดำเนินการหักเงินตามรายละเอียดของรายการชำระเงินที่ผู้ถือบัตรกำหนด",
         class: "badge-info",
     },
     {
        id: 7,
        name: "รอชำระ",
         desc: "รอลูกค้าทำรายการชำระหรือระบบทำการเปลี่ยนสถานะ",
         class: "badge-info",
     },
     {   
        id: 9,
        name: "เรียกเก็บเงิน",
        desc: "รายการชำระเงินแบบเรียกเก็บเงิน",
        class: "badge-warning",
    }
]