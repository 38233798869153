<template>
  <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center">
      <div class="col-lg-5 col-md-8">
        <!-- <div class="alert alert-info">
            <strong>Normal User</strong> - U: user P: user<br />
            <strong>Administrator</strong> - U: admin P: admin
          </div> -->
        <div class="text-center mb-4">
          <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
        </div>
        <div class="box box-login text-center">
          <div class="text-center mt-5">
          <img class="img-fluid" src="./../../assets/image/register-completd.png" alt="" />
          </div>
          <h5 class="mt-5">บัญชีของคุณถูกสร้างเรียบร้อยแล้ว</h5>
          <div class=" text-secondary small">กรุณาเปิดใช้งานบัญชี โดยตรวจสอบอีเมลของคุณ</div>
          <div>
            <button
              @click="$router.push({ name: 'login' })"
              class="btn btn-primary w-100 text-center mt-3 mb-4"
              :disabled="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                v-show="loading"
              ></span>
              <span>เข้าสู่ระบบ</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

// import { router } from "../../_helpers/router";
// import { authenticationService } from "../../_services/authentication.service";

export default {
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      username: "",
      submitted: false,
      loading: false,
      returnUrl: "",
      error: "",
      success: "",
    };
  },
  validations: {
    username: { required, email },
  },
  created() {},
  methods: {
    onSubmit() {
      this.submitted = true;
      this.success = "";

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      setTimeout(() => {
        this.success = "ระบบส่งลิงก์เปลี่ยนรหัสผ่านไปที่อีเมลของท่านแล้ว";
        this.loading = false;
      }, 300);
      return;
      //   this.loading = true;
      //   authenticationService
      //     .forgotpassword(this.username)
      //     .then(router.push(this.returnUrl), (error) => {
      //       this.error = error;
      //       this.loading = false;
      //     });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 28px;
  margin-bottom: 1.5rem;
}
.box {
  background-color: #ffffff;
}
</style>