<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright text-center">
        © Copyright {{data.date}} 
        <a href="https://www.paysolutions.asia" target="_bank"><img src="https://www.paysolutions.asia/assets/images/logo-paysolutions@2x.png" alt=""></a>
        <!-- Version App -->
        v.{{ this.$version }}
      </div>
    </div>
  </footer>
</template>
<script>
import { version } from '../../package.json';
export default {
  name: "footer",
  data() {
    let dt = new Date
    return {
      data: {
        date: dt.getFullYear(),
        version: version
      }
    };
  },
};
</script>
<style scoped>
img{
  width: 50px;
}
.footer {
  background: #f2f6fb;
  margin-top: -25px;
  width: 100%;
  position: relative;
  height: 25px;
} 
.footer,
.footer .container-fluid,
.footer .container-fluid .copyright {
}
.container-fluid .copyright {
  float: unset;
  padding: 2px 0 0 0;
  margin: 0;
  font-size: 0.7rem;
}
</style>
