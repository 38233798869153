export default [

  {
    name: 'companyName',
    title: 'Supplier',
    sortField: 'companyName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'coordinatorName',
    title: 'ผู้ประสานงาน',
    sortField: 'coordinatorName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'paysolutionMerchant',
    title: 'Merchant ID',
    sortField: 'coordinatorName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'email',
    title: 'อีเมล',
    sortField: 'email',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    name: 'phone',
    title: 'โทร',
    sortField: 'phone',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
  {
    title: 'วันที่ลงทะเบียน',
    name: 'createDate',
    dataClass: "center aligned",
    titleClass: "center aligned",
    sortField: 'createDateFormat',
  },
  {
    title: 'สถานะ',
    name: 'statusName',
    dataClass: "center aligned",
    titleClass: "center aligned",
  },
 
  {
    name: 'actions',
    dataClass: "center aligned",
    titleClass: "center aligned",
  }
 
]