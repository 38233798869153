<template >
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3 h3">อนุมัติคำขอยกเลิก</div>
        <div class="row mt-lg-4 mt-md-3">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="px-3 py-2-1">
                <div class="mb-2 d-flex justify-content-start">
                  <div class="form-inline"></div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        aria-describedby="button-addon2"
                        v-model="keyword"
                        @keyup.enter="search()"
                      />
                      <div class="input-group-append">
                        <button
                        @click="search()"
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon name="search-outline" class="color-dark"></eva-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  :row-class="$onRowClass"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                <div slot="buyerFeeStr" slot-scope="props">
                    {{props.rowData.buyerFeeStr}}<br>
                    <small class="text-secondary">({{props.rowData.buyerFeeRate}}%)</small>
                  </div>
                  <div slot="supplierFeeStr" slot-scope="props">
                    {{props.rowData.supplierFeeStr}}<br>
                    <small class="text-secondary">({{props.rowData.supplierFeeRate}}%)</small>
                  </div>
                  <div slot="actions" slot-scope="props">
                    <router-link
                      :to="{
                        name: 'admin.approve.cancellation.request.show',
                        params: { id: props.rowData.paymentId },
                      }"
                    >
                      <eva-icon
                        name="eye-outline"
                        class="color-secondary eye-outline"
                      ></eva-icon>
                    </router-link>
                  </div>
                  <div
                    slot="statusName"
                    slot-scope="props"
                    v-html="status(props.rowData.statusNumber)"
                  ></div>
                  <div
                    slot="cardNumber"
                    slot-scope="props"
                    v-html="$cardColumn(props.rowData.cardType,props.rowData.cardNumber)"
                  ></div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                    <select v-model="perPage" class="form-control mx-1">
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="30">30</option>
                      <option :value="50">50</option>
                    </select>
                    <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
<vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../../_fields/admin_payment.js";
import { formatNum }from "../../../../_formats/number";
import status from "../../../../_fields/status.js";
import _ from "lodash";

export default {
  name: "AdminPayment",
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      statusList: status.slice(0,7).concat(status.slice(7+1)),
      allStatus: status,
      fields: FieldsDef,
      tablePaginationTotal: 0,
      perPage: 10,
      data: [],
      keyword: ""
    };
  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();this.$refs.vuetable.refresh();
    },perPage(){
      this.$refs.vuetable.refresh();
    },
  },

  mounted() {
    this.apiGetList()
  },

  methods: {
    apiGetList(keyword = null){
      let url = "api/payment/list?status=4"
      if(keyword){
        url = url+'?keyword=' +keyword
      }
      this.$http.get(url).then((response) => {
      let data = response.data.data.paymentLists;
      this.data = data.map((d) => {
          return {
            ...d,
            amountStr: '฿' + formatNum.price(d.amount, 2),
            buyerFeeStr: '฿' + formatNum.price(d.buyerFeeNet, 2),
            supplierFeeStr: '฿' + formatNum.price(d.supplierFeeNet, 2),
            feeStr: '฿' + formatNum.price(d.fee, 2),
            totalAmountStr: '฿' + formatNum.price(d.totalAmount, 2),
          };
        });
    });
    },
    search(){
      this.apiGetList(this.keyword)
    },
    status(status, string) {
      let find = this.statusList.find((s) => s.id == status);
      if (find) {
        return (
          "<span class='badge badge-pill " +
          find.class +
          "'>" +
          find.name +
          "</span>"
        );
      } else {
        return (
          "<span class='badge badge-pill badge-warning'>" + string + "</span>"
        );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
  },
};
</script>
<style src="@/assets/semantic-ui-css/semantic.css">
</style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
</style>