export default [
  {
    name: '__checkbox',   
    titleClass: 'center aligned',
    dataClass: 'center aligned',
  },
  {
    name: 'userName',
    title: 'ชื่อ',
    sortField: 'userName',
  },

  {
    name: 'userEmail',
    title: 'อีเมล',
    sortField: 'userEmail',
  },
  {
    title: 'สิทธิการเข้าถึง',
    name: 'userRole',
  },
 
  {
    name: 'actions',
  }
 
]