<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ข้อมูลนิติบุคคล > </span>
          <span class="font-weight-semibold">รายละเอียดนิติบุคคล</span>
          <div class="d-flex justify-content-between">
            <div class="h3">รายละเอียดนิติบุคคล</div>
            <div class="mt-4" v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'">
              <button @click="$router.push({name:'supplier.juristic.company.edit'})"
                class="btn btn-primary">
                <eva-icon name="edit-outline" class="color-white"></eva-icon>
                  แก้ไข
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-lg-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-2">
              <h5 class="px-1 font-weight-semibold">รายละเอียด</h5>
              <div class="px-1 py-2 text-secondary small line-sub"
                >สามารถแก้ไขรายละเอียดของข้อมูลบริษัทได้ ทางเจ้าหน้าที่จะทำการตรวจสอบข้อมูลและอนุมัติการเปลี่ยนแปลงให้ภายใน 1-2 วันทำการ</div>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="px-3 py-2-1 border-bottom font-weight-semibold d-flex justify-content-between">
                    <div>สถานะ</div>
                    <span
                  :class="
                    'badge badge-pill' +
                    (data.statusNumber == 0 || data.statusNumber == 4 ? ' badge-warning' : '') +
                    (data.statusNumber == 2 ? ' badge-danger' : '') +
                    (data.statusNumber == 1 ? ' badge-success' : '') +
                    (data.statusNumber == 5 ? ' badge-light' : '')
                  "
                  >{{ data.statusName }}</span
                >
                  </div>
                  <div class="px-3 pt-4 pb-0">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">
                              ชื่อบริษัท (TH)
                            </div>
                            <div class="text-secondary">{{data.companyNameTh}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">
                              ชื่อบริษัท (EN)
                            </div>
                            <div class="text-secondary">{{data.companyNameEng}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">เลขนิติบุคคล</div>
                            <div class="text-secondary">{{data.companyJuristic}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">ที่อยู่</div>
                            <div class="text-secondary">
                              {{data.companyAddress}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">
                              เบอร์โทรศัพท์บริษัท
                            </div>
                            <div class="text-secondary">{{data.companyPhone}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">อีเมลบริษัท</div>
                            <div class="text-secondary">{{data.companyEmail}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">
                              เว็บไซต์
                            </div>
                            <div class="text-secondary">{{data.companyWebsite}}</div>
                          </div>
                          <div class="col-lg-12 mb-4">
                            <div class="h6 font-weight-semibold">
                              หมวดหมู่ Supplier
                            </div>
                            <div class="text-secondary">{{data.supplierCategoryName}}</div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Juristic",
  data() {
    return {
      data:{}
    }
  },
  created() {
    this.apiJuristic()
  },
  methods: {
    apiJuristic(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/juristic/profile`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
    }
  },
};
</script>