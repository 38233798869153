<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div>
          <div class="d-flex justify-content-between">
            <div class="page-name mt-lg-4 mt-md-3 h3">จัดการผู้ใช้</div>
            <div class="mt-4">
              <button
                v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'"
                @click="$router.push({ name: 'supplier.users.create' })"
                class="btn btn-primary"
              >
                <eva-icon name="plus-outline" class="color-white"></eva-icon>
                เพิ่มผู้ใช้
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-md-2">
          <div class="col-12">
            <div class="bg-white rounded">
              <div class="p-lg-3">
                <div class="form-filter mb-2 d-flex justify-content-between">
                  <div class="form-inline">
                    <select v-model="searchStatus" class="form-control">
                      <option value="">บทบาท: ทั้งหมด</option>
                      <option value="super">บทบาท: Super Admin</option>
                      <option value="admin">บทบาท: Admin</option>
                      <option value="staff">บทบาท: Staff</option>
                    </select>
                  </div>
                  <div class="form-inline">
                    <div class="input-group mr-lg-2">
                      <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="ค้นหา"
                        aria-describedby="button-addon2"
                        v-model="keyword"
                        @keyup.enter="search()"
                      />
                      <div class="input-group-append">
                        <button
                          @click="search()"
                          class="
                            btn
                            border border-left-0
                            py-0
                            min-auto
                            text-secondary
                          "
                          type="button"
                          id="button-addon2"
                        >
                          <eva-icon
                            name="search-outline"
                            class="color-dark"
                          ></eva-icon>
                        </button>
                      </div>
                    </div>
                    <div>
                      <b-dropdown
                        text="Action"
                        variant="white"
                        class="border rounded"
                      >
                        <b-dropdown-item @click="removeUser('checkbox')"
                          ><eva-icon
                            width="20px"
                            name="trash-2-outline"
                            class="color-danger"
                          ></eva-icon>
                          ลบ</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <vuetable
                  ref="vuetable"
                  :api-mode="false"
                  track-by="userId"
                  :fields="fields"
                  :per-page="perPage"
                  :data-manager="dataManager"
                  @vuetable:checkbox-toggled="checkboxItem"
                  @vuetable:checkbox-toggled-all="checkboxItem"
                  pagination-path="pagination"
                  @vuetable:pagination-data="onPaginationData"
                >
                  <div slot="actions" slot-scope="props">
                    <router-link
                    v-if="
                        $parent.$parent.currentUser.supplierAdminRole != 'staff' && props.rowData.userRole != 'Super Admin'
                      "
                      :to="
                        $parent.$parent.$parent.currentUser.userId !=
                        props.rowData.userId
                          ? {
                              name: 'supplier.users.show',
                              params: { id: props.rowData.userId },
                            }
                          : { name: 'supplier.profile' }
                      "
                      :class="
                        $parent.$parent.$parent.currentUser.userId !=
                        props.rowData.id
                          ? 'mr-3'
                          : ''
                      "
                    >
                      <eva-icon
                        width="20px"
                        name="edit-outline"
                        class="color-primary"
                      ></eva-icon>
                    </router-link>
                    <span
                      class="cursor-pointer"
                      v-if="
                        $parent.$parent.currentUser.supplierAdminRole != 'staff' &&
                        $parent.$parent.$parent.currentUser.userId !=
                        props.rowData.userId &&
                        props.rowData.userRole != 'Super Admin'
                      "
                      @click="removeUser(props.rowData.userId)"
                    >
                      <eva-icon
                        width="20px"
                        name="trash-2-outline"
                        class="color-danger"
                      ></eva-icon>
                    </span>
                  </div>

                  <div slot="userName" slot-scope="props">
                    <div class="d-flex align-items-center">
                      <b-avatar
                        class="icon-profile mr-2"
                        :text="props.rowData.userName.substr(0, 1)"
                      ></b-avatar>
                      <span>{{ props.rowData.userName }}</span>
                    </div>
                  </div>
                </vuetable>
                <div style="padding-top: 10px" class="footer-table">
                  <div class="ui floated menu">
                    <div class="d-flex align-items-center">
                      <div>Showing</div>
                      <select v-model="perPage" class="form-control mx-1">
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="30">30</option>
                        <option :value="50">50</option>
                      </select>
                      <div>
                        items
                      </div>
                      <div
                        v-if="tablePaginationTotal"
                        class="ml-3 pl-3 border-left text-nowrap"
                      >
                        {{ tablePaginationTotal }} items
                      </div>
                    </div>
                  </div>
                  <vuetable-pagination
                    ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                  ></vuetable-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalApprove" centered title="ต้องการลบผู้ใช้">
      <template #modal-footer>
        <div class="w-100 m-0 text-right">
          <b-button
            variant="light"
            class="mr-3"
            @click="$bvModal.hide('modalApprove')"
          >
            ยกเลิก
          </b-button>
          <b-button variant="danger" class="" @click="apiRemoveUser()">
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";
import VuetablePagination from "src/components/pagination/VuetablePagination";
import FieldsDef from "../../../_fields/supplier_user.js";
import Status from "../../../_fields/status_supplier.js";
import _ from "lodash";

export default {
  name: "User",
  components: {
    Vuetable,
    VuetablePagination,
  },
  data() {
    return {
      fields: FieldsDef,
      statusList: Status,
      perPage: 10,
      tablePaginationTotal: 0,
      data: [],
      keyword: "",
      searchStatus: "",
      removeId: null,
      checkUser: [],
    };
  },

  watch: {
    data(newVal, oldVal) {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    perPage() {
      this.$refs.vuetable.refresh();
    },
    searchStatus() {
      this.apiGetList();
    },
  },

  mounted() {
    this.apiGetList();
  },

  methods: {
    apiGetList() {
      let url = "api/suppliers/admins/index";
      url = url + "?keyword=" + this.keyword + "&status=" + this.searchStatus;
      this.$http.get(url).then((response) => {
        this.data = response.data.data;
      });
    },
    checkboxItem(isChecked, dataItem) {
      this.checkUser = this.$refs.vuetable.selectedTo;
    },
    search() {
      this.apiGetList();
    },
    status(status, txt) {
      switch (status) {
        case 1:
          return (
            "<span class='badge badge-pill badge-success'>" + txt + "</span>"
          );
        case 2:
          return (
            "<span class='badge badge-pill badge-danger'>" + txt + "</span>"
          );
        default:
          return (
            "<span class='badge badge-pill badge-warning'>" + txt + "</span>"
          );
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.tablePaginationTotal = this.$refs.pagination.tablePagination.total
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },
    removeUser(id) {
      this.removeId = id;
      this.$bvModal.show("modalApprove");
    },
    apiRemoveUser() {
      let self = this;
      this.$showLoader();
      if (this.removeId == "checkbox") {
        this.$http
          .post(`api/suppliers/select/delete`,{removeFileld: this.checkUser})
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            this.$bvModal.hide("modalApprove");
            this.apiGetList();
          });
      } else {
        this.$http
          .delete(`api/suppliers/admins/` + this.removeId + `/delete`)
          .then((res) => {
            self.$hideLoader();
            this.$notifications.notify({
              message: res.data.message,
              icon: "checkmark-circle-2",
              horizontalAlign: "center",
              verticalAlign: "top",
              type: "success",
            });
            this.$bvModal.hide("modalApprove");
            this.apiGetList();
          });
      }
    },
  },
};
</script>
<style src="@/assets/semantic-ui-css/semantic.css"></style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
