<template>
  <div>
    <div class="text-center mb-4">
      <a :href="url" rel="noopener noreferrer">
          <img src="./../../assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
         </a>
    </div>
    <div class="box box-signup">
      <form class="" @submit.prevent="onSubmit">
        <div class="title text-center">สมัครสมาชิก Buyer</div>
        <label>ประเภท</label>
        <div class="form-row">
          <div class="col-lg-6 col-md-6" v-for="(item, index) in userTypes" :key="index">
            <div
              class="form-check form-check-inline custom w-100 mb-3"
              @click="userType = item.value"
            >
              <input
                v-model="userType"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                :id="'inlineRadio' + index"
                :value="item.value"
              />
              <label
                :class="
                  'form-check-label ' +
                  (errors.role ? 'is-invalid' : '')
                "
                :for="'inlineRadio' + index"
                >{{ item.text }}</label
              >
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="username">อีเมล</label>
          <b-form-input
            id="username"
            :class="{ 'is-invalid': errors.email }"
            v-model="username"
          ></b-form-input>
          <div
                v-if="errors.email"
                class="invalid-feedback d-block"
              >
                {{errors.email}}
              </div>
        </div>
        <div class="form-group">
          <label for="mobile">เบอร์โทรศัพท์มือถือ</label>
          <b-form-input
            id="mobile"
            :class="{ 'is-invalid': errors.phone }"
            @keyup="$numberInput($event)"
            v-model="mobile"
          ></b-form-input>
          <div
                v-if="errors.phone"
                class="invalid-feedback d-block"
              >
                {{errors.phone}}
              </div>
        </div>
        <div class="form-group">
          <label htmlFor="password">รหัสผ่าน</label>
          <b-input-group >
                <template #append>
                  <button class="icon-input" @click="showPass = !showPass" :class="{ 'is-invalid': errors.password }" type="button"><eva-icon :name="!showPass ? 'eye-off-outline' : 'eye-outline'" fill="#6c757d"></eva-icon></button>
                </template>
                <b-form-input :type="!showPass ? 'password' : 'text'"
                 v-model="password"
                :class="{ 'is-invalid': errors.password }"
                name="password"
                ></b-form-input>
              </b-input-group>
              <div
                v-if="errors.password"
                class="invalid-feedback d-block"
              >
                {{errors.password}}
              </div>
        </div>
        <div class="form-group">
          <b-form-checkbox
            id="checkbox-1"
            v-model="accepted"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
            class="privacy-policy-text"
          >
          ข้าพเจ้ารับทราบและยอมรับ <a class="text-promary" href="https://paysoon.net/term-condition.html" target="_blank">ข้อตกลงและเงื่อนไขบริการ</a> เเละ <a class="text-promary" href="https://paysoon.net/privacy-policy.html" target="_blank">นโยบายข้อมูลส่วนบุคคล</a>  รวมทั้ง <a class="text-primary" href="https://paysoon.net/privacy-consent.html" target="_blank">การให้ความยินยอมในการเปิดเผยข้อมูล</a> เพื่อให้ เพย์ โซลูชั่น เก็บ รวบรวม ใช้ข้อมูลส่วนบุคคล ประกอบการสมัครใช้บริการ รวมทั้งแจ้งข่าวสารที่เกี่ยวกับบริการระบบรับชำระเงินออนไลน์
      
          </b-form-checkbox>
        </div>
        <div class="form-group">
          <button
            class="btn btn-primary w-100 text-center"
            :disabled="loading || !accepted"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-show="loading"
            ></span>
            <span>สมัครสมาชิก</span>
          </button>
        </div>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <div v-if="success" class="alert alert-success">{{ success }}</div>
      </form>
    </div>
    <div class="mt-4 text-center">
      <b
        >มีบัญชีผู้ใช้?
        <router-link :to="{ name: 'login' }">เข้าสู่ระบบ</router-link></b
      >
    </div>
    <b-modal id="modal-scoped" centered hide-header hide-footer>
      <template #default="{ hide }">
        <button type="button" @click="hide()" class="close">×</button>
        <div class="text-center mt-3 p-lg-4">
          <h3>กรอกหมายเลข OTP <br />ที่ส่งไปยังเบอร์โทรศัพท์ของคุณ</h3>
          <div>เบอร์โทรศัพท์ {{ mobile }} (Ref: {{refKey}})</div>
          <div class="box-otp mt-4">
            <input
              maxlength="1"
              pattern="[0-9]"
              v-for="(item, index) in inputOTP"
              :key="index"
              type="text"
              v-model="inputOTP[index]"
              class="form-control"
              @keyup="inputRegExp(index)"
            />
          </div>
          <div class="mt-4">
           <i class="fas fa-redo fa-lg"></i> ยังไม่ได้รหัส? <b class="text-primary cursor-pointer" @click="onSubmit(true)">ส่งอีกครั้ง</b>
            
          </div>
          <button class="btn btn-primary w-100 mt-4" :disabled="numberOTP().length != 6" @click="apiConfirmOTP()">ยืนยัน</button>
        </div>
      </template>
    </b-modal>
   
  </div>
</template>

<script>
import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
const alphaNumValidator = helpers.regex("alphaNum", /^[0-9\d.]*$/i);

export default {
  data() {
    return {
      url: `${process.env.VUE_APP_URL}`,
      loading: false,
      error: "",
      errors: {},
      success: "",
      userType: null,
      submitted: false,
      userTypes: [
        { text: "บุคคลธรรมดา", value: "natural_person" },
        { text: "นิติบุคคล", value: "juristic_person" },
      ],
      username: "",
      password: "",
      mobile: "",
      accepted: false,
      modalOTP: false,
      inputOTP: ["", "", "", "", "", ""],
      showPass: false,
      expiredOTP: "",
      otpKey: "",
      registerKey: "",
      refKey: "",
      termofuse: null
    };
  },
  validations: {
    userType: { required },
    username: { required, email },
    password: { required, minLength: minLength(4) },
    mobile: {
      required,
      alphaNumValidator,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
  },
  created() {
    this.apiGetTerms();
  },
  methods: {
    onSubmit(re = false) {
      this.errors = {}
      this.submitted = true;
      let self = this
      this.$showLoader();
      this.$http
        .post(`api/users/register`,{
          email : this.username,
          phone : this.mobile,
          password: this.password,
          role: 'buyer',
          buyerType: this.userType
        })
        .then((res) => {
          self.inputOTP = ["", "", "", "", "", ""]
          self.registerKey  = res.data.data.registerKey
          self.refKey  = res.data.data.refKey
          self.$hideLoader();
          self.$bvModal.show("modal-scoped");
          setTimeout(() => {
            $('.box-otp input')[0].focus()
          }, 500);
        })
        .catch(function (err) {
          self.errors = err.response.data.errors.data
        })
    },
    apiConfirmOTP(){
      this.submitted = true;
      let self = this
      this.$showLoader();
      this.$http
        .post(`api/users/register/check/otp`,{
          phone : this.mobile,
          registerKey: this.registerKey,
          otpKey: this.numberOTP(),
        })
        .then((res) => {
          self.$hideLoader();
          this.$router.push({name:'signup.success'})
        })
        .catch(function (err) {
          self.$hideLoader();
          self.inputOTP = ["", "", "", "", "", ""]
          setTimeout(() => {
            $('.box-otp input')[0].focus()
          }, 500);
        });
    },
    numberOTP() {
      return this.inputOTP.join("");
    },
    inputRegExp(index) {
      const inputOTP = this.inputOTP[index];
      var reg = new RegExp("^[0-9]$");
      if (reg.test(inputOTP)) {
        if ($(".box-otp input")[index + 1]) {
          $(".box-otp input")[index + 1].focus();
        }
      }
    },
    apiGetTerms() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/termofuse`)
        .then((res) => {
          this.termofuse = res.data.data.termOfUseBuyer;
          self.$hideLoader();
        })
        .catch(function(err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  },
  watch: {
    inputOTP() {
      for (let index = 0; index < this.inputOTP.length; index++) {
        const inputOTP = this.inputOTP[index];
        var reg = new RegExp("^[0-9]$");
        if (!reg.test(inputOTP)) {
          this.inputOTP[index] = "";
        }
      }
    },
  },
};
</script>
<style lang="scss">
.box {
  background-color: #ffffff;
}
.box-otp {
  width: 100%;
  display: flex;
  justify-content: center;
  input {
    margin: 0 0.5rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    width: 3rem;
    height: 3rem;
  }
}
</style>