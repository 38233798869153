<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div
        v-if="
          user.stepRegister == 2 &&
          dataLoadCompalte == true &&
          credits.length == 0
        "
        class="col-md-10 my-5"
      >
        <div class="row justify-content-between">
          <div class="col-md-3">
            <h4>เพิ่มบัตรเครดิต</h4>
            <span class="text-secondary small">ข้อมูลบัตรเครดิต</span>
          </div>
          <div class="col-md-9 mt-5">
            <div class="bg-white p-2 rounded mb-1 px-4">
              <div class="row pt-md-4">
                <div class="col-md-12 form-group mt-5 text-center">
                  <img
                    class="img-fluid"
                    src="../../../assets/image/ekyc1.png"
                    alt=""
                  />
                </div>
                <div class="col-md-12 form-group mb-5 text-center">
                  <button @click="newWebCreate()" class="btn btn-primary">
                    <eva-icon
                      name="plus-circle-outline"
                      class="color-white"
                    ></eva-icon>
                    เพิ่มบัตรเครดิต
                  </button>
                </div>
              </div>
            </div>
            <div class="row pt-md-4 pt-2">
              <div class="col-md-12 text-right">
                <b-button @click="apiCredit(true)" variant="primary"
                  >ถัดไป</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.stepRegister > 2 || credits.length > 0 || showNext == true">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mt-5">
            <img
              src="../../../assets/image/card2-completed.png"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="col-md-12 text-center mt-3">
            <h3>เพิ่มบัตรเครดิตเรียบร้อยแล้ว</h3>
            <span class="text-secondary small line-sub">
              ระบบเพิ่มบัตรเครดิตของคุณเรียบร้อยแล้ว
            </span>
          </div>
          <div class="col-9 mt-4 px-5 mb-3 text-right">
            <button
              @click="$router.push({ name: 'buyer.register.verify' })"
              class="btn border text-primary mr-3"
            >
              ทำต่อภายหลัง
            </button>
            <b-button
              @click="apiNextStep()"
              variant="primary"
              >ถัดไป</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticationService } from "../../../_services/authentication.service";
export default {
  name: "BuyerRegisterKyc",
  data() {
    return {
      user: {},
      error: null,
      typeCreditCard: "",
      cardNumber: "",
      fullname: "",
      limitMoney: "",
      expiredDate: "",
      ccv: "",
      credit: {
        buyerId: null,
        creditCardId: null,
        creditCardNumber: null,
        creditCardType: null,
        defaultSetting: null,
        name: null,
      },
      credits: [],
      dataLoadCompalte: false,
      showNext: false
    };
  },
  created() {
    authenticationService.refreshUser(`api/buyers/profile`);
    this.apiProfile();
    this.apiCredit();
  },
  methods: {
    apiProfile(reload = false) {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/profile`)
        .then((res) => {
          this.user = res.data.data;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    newWebCreate() {
      window.open("/buyer/credit/create", "popup", "width=1280,height=600");
    },
    apiCredit(next = false) {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/creditcard/index`)
        .then((res) => {
          this.credits = res.data.data;
          if (next) {
            this.dataLoadCompalte = true;
            this.showNext = true
          } else {
            this.dataLoadCompalte = true;
          }
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiNextStep() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/buyers/paysolution/creditcard/check/step`)
        .then((res) => {
          self.$hideLoader();
          this.$router.push({ name: 'buyer.register.calendar' })
        });
    },
  },
};
</script>
