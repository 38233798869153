<template>
    <div  class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8">
                <div class="text-center mb-4">
                    <img src="@/assets/logo_paysoon-rgb.png" width="245px" alt="logo-paysoon" />
                </div>
                <div v-if="!confirmedProcess && !success" class="box box-signup">
                    <form class="" @submit.prevent="onSubmit">
                        <div class="title text-center">ยืนยันการใช้งานบัญชี</div>
                        <div class="form-group">
                            <label for="username">อีเมล</label>
                            <b-form-input id="username" :class="{ 'is-invalid': errors.email }" v-model="email">
                            </b-form-input>
                            <div v-if="errors.email" class="invalid-feedback d-block">
                                {{ errors.email }}
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary w-100 text-center" :disabled="loading">
                                <span class="spinner-border spinner-border-sm" v-show="loading"></span>
                                <span>ส่งคำขอ</span>
                            </button>
                        </div>
                    </form>
                </div>
                <div v-if="!confirmedProcess && success" class="box px-lg-5 px-sm-2 py-lg-4 py-sm-2 text-center">
                    <img src="https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/email-send.png" class="img-fuild" />
                    <div class="h5 mt-4">
                        ระบบส่งลิงก์ยืนยันบัญชีไปที่อีเมลของท่านแล้ว
                    </div>
                </div>
                <div v-if="confirmedProcess && confirmed" class="box px-lg-5 px-sm-2 py-lg-4 py-sm-2 text-center">
                    <img src="https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/active_account%402x.png" class="img-fuild" />
                    <div class="h5 mt-4">
                        {{ message }}
                    </div>
                </div>
                <div v-if="confirmedProcess && !confirmed" class="box px-lg-5 px-sm-2 py-lg-4 py-sm-2 text-center">
                    <img src="https://paysoon-img-public.s3.ap-southeast-1.amazonaws.com/reject_account%402x.png" class="img-fuild" />
                    <div class="h5 mt-4">
                        ไม่สามารถยืนยันบัญชีได้ กรุณาตรวจสอบ Email หรือส่งคำขอยืนยันบัญชีอีกครั้ง
                    </div>
                </div>
                <!-- <div v-if="confirmedProcess && !confirmed"  class="my-3 text-center">
                    <router-link to="/register/confirm">ยืนยันการใช้งานบัญชีอีกครั้ง</router-link>
                </div> -->
                <div  class="my-3 text-center">
                    <router-link :to="{ name: 'login' }">กลับหน้าเข้าสู่ระบบ</router-link>
                </div>
              
            </div>
        </div>
    </div>
</template>

<script>
import { authenticationService } from "../../_services/authentication.service";
    import {
        required,
        email
    } from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                email: "",
                errors: {},
                loading: false,
                returnUrl: "",
                error: "",
                confirmedProcess: false,
                confirmed: false,
                success: false,
                message: ""
            };
        },
        validations: {
            username: {
                required,
                email
            },
        },
  
        beforeCreate() {
           let sign = this.$route.params.sign
           console.log(sign)
                if(sign){
                    let self = this;
                    self.$showLoader()
                    this.$http.post('api/users/register/confirm', { sign: this.$route.params.sign })
                    .then(res => {
                        self.$hideLoader()
                        this.confirmedProcess = true
                        this.confirmed = true
                        this.message = res.data.message
                        authenticationService.refreshUser(`api/buyers/profile`);
                     }).catch(error => {
                            this.confirmed = false
                            self.error
                            console.log(error.response)
                            this.$notifications.notify({
                            message: error.response.data.message,
                             icon: "alert-triangle",
                            horizontalAlign: "center",
                            verticalAlign: "top",
                            type: "danger",
                        });
                     }
                     
                     )
                }else{
                   this.confirmedProcess = false 
                }
        },
        methods: {
            onSubmit() {
                let self = this;
                this.submitted = true;
                self.errors = {}
                self.loading = true;
                this.$http
                    .post(`api/mailer/users/reqest-confirm`, {
                        email: this.email,
                    })
                    .then((res) => {
                        self.success = true;
                        self.loading = false;
                    })
                    .catch(function (err) {
                        self.loading = false;
                        self.errors = err.response.data.errors.data;
                    });
            },
        },

    };
</script>
<style lang="scss" scoped>
    .title {
        font-size: 28px;
        margin-bottom: 1.5rem;
    }

    .box {
        background-color: #ffffff;
    }
</style>