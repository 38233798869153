<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">ข้อมูลนิติบุคคล > </span>
          <span class="font-weight-semibold">เอกสารนิติบุคคล</span>
          <div class="d-flex justify-content-between">
            <div class="h3"> เอกสารนิติบุคคล</div>
            <div class="mt-4">
              <button @click="downloadAll()" type="button" class="btn btn-primary">
                <i class="fas fa-download fa-lg"></i> ดาวน์โหลดทั้งหมด
              </button>
              <!-- <button
              v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'"
                type="button"
                class="btn btn-outline-primary"
               @click="$router.push({name:'supplier.juristic.files.edit'})"
              >
                <i class="fas fa-pen fa-lg"></i> แก้ไข
              </button> -->
            </div>
          </div>
        </div>
        <div class="row mt-lg-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">เอกสารที่อัปโหลด</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="row">
              <div class="col-12">
                <div class="bg-white rounded">
                  <div class="px-3 py-2-1 border-bottom font-weight-semibold d-flex justify-content-between">
                  <div>สถานะ</div>
                    <span
                      :class="
                        'badge badge-pill float-right ' +
                        (documents.statusNumber == 0
                          ? ' badge-warning'
                          : '') +
                        (documents.statusNumber == 2
                          ? ' badge-danger'
                          : '') +
                        (documents.statusNumber == 1
                          ? ' badge-success'
                          : '')
                      "
                      >{{ documents.statusName }}</span
                    >
                  </div>
                  <div class="px-3 py-3">
                    <div v-if="documents.lists" class="row">

                      <div v-for="(list, index) in documents.lists" :key="index" class="col-lg-12 pb-2">
                        <div class="border-bottom py-3">
                          <div class="h6 font-weight-semibold mb-3">
                            {{list.topic}}
                          </div>
                          <div class="row">
                            <div v-for="(file, index) in list.files" :key="index" class="col-md-6 mb-3">
                              <div :title="file.filename" class="d-flex justify-content-between list-file">
                                <div class="">
                                <img
                                  height="40px"
                                  :src="imgFile(file.fileMimeType)"
                                />
                              </div>
                              <div class="pl-1 w-70">
                                <div
                                  class="h-100 flex-column align-items-start"
                                >
                                  <div class="mb-auto name small">
                                    <a :href="file.download" download="">
                                    {{ file.filename }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div>
                                  <a :href="file.download" download="">
                                  <eva-icon
                                    name="download"
                                    class="cursor-pointer color-secondary"
                                  ></eva-icon>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "JuristicDocs",
  data() {
    return {
      documents: {},
    }
  },
  created() {
    this.apiData()
  },
  methods: {
    imgFile(filename) {
      var parts = filename.split(".");
      let type = parts[parts.length - 1];
      switch (type.toLowerCase()) {
        case "pdf":
          return require("@/assets/icon/file-pdf@2x.png");
        case "docx":
          return require("@/assets/icon/file-doc@2x.png");
        case "png":
          return require("@/assets/icon/file-png@2x.png");
        case "jpg":
        case "jpeg":
          return require("@/assets/icon/file-jpg@2x.png");
        default:
          return "";
      }
    },
    sizeFile(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    apiData() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/documents`)
        .then((res) => {
          self.documents = res.data.data.documents;
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
  
    downloadAll(){
      var timeout = 0
      this.documents.lists.forEach(l => {
        if (l.files) {
          l.files.forEach(f => {
            timeout += 1000
            setTimeout(() => {
            window.open(f.download)
            }, timeout);
          })
        }
      });
    }
  },
};
</script>