<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-11 col-md-12">
        <div class="page-name mt-lg-4 mt-md-3">
          <span class="text-secondary">รายละเอียดนิติบุคคล > </span>
          <span class="font-weight-semibold">ที่อยู่ใบกำกับภาษี</span>
          <div class="h3 mt-4">ที่อยู่ใบกำกับภาษี</div>
        </div>
        <div class="row mt-lg-4">
          <div class="col-lg-3 col-md-4">
            <div class="mb-4">
              <h5 class="px-1 font-weight-semibold">รายละเอียดที่อยู่</h5>
            </div>
          </div>
          <div class="col-lg-9 col-md-8">
            <div class="bg-white rounded p-lg-3">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <label>ชื่อบริษัท</label>
                  <input type="text" v-model="data.companyNameTh" class="form-control" :class="{ 'is-invalid': errors.companyNameTh || report.companyNameTh }"
                  />
                  <div
                    v-if="errors.companyNameTh"
                    class="invalid-feedback d-block"
                  >
                    {{ errors.companyNameTh }}
                  </div>
                  <div
                    v-if="report.companyNameTh"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.companyNameTh }}
                  </div>
                </div>
                <div class="col-lg-12 mb-3">
                  <label>ที่อยู่</label>
                  <input type="text" placeholder="บ้านเลขที่ ตึก ชั้น" v-model="data.receiptAddressOne" class="form-control" :class="{ 'is-invalid': errors.receiptAddressOne || report.addressOne }"
                  />
                  <div
                    v-if="errors.receiptAddressOne"
                    class="invalid-feedback d-block"
                  >
                    {{ errors.receiptAddressOne }}
                  </div>
                  <div
                    v-if="report.receiptAddressOne"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.receiptAddressOne }}
                  </div>
                  <input type="text" placeholder="ถนน แขวง" v-model="data.receiptAddressTwo" class="form-control mt-3" :class="{ 'is-invalid': errors.receiptAddressTwo || report.receiptAddressTwo }"
                  />
                  <div
                    v-if="errors.receiptAddressTwo"
                    class="invalid-feedback d-block"
                  >
                    {{ errors.receiptAddressTwo }}
                  </div>
                  <div
                    v-if="report.receiptAddressTwo"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.receiptAddressTwo }}
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <label>เขต/อำเภอ</label>
                  <input type="text" v-model="data.amphure" class="form-control" :class="{ 'is-invalid': errors.amphure || report.amphure }"
                  />
                  <div
                    v-if="errors.amphure"
                    class="invalid-feedback d-block"
                  >
                    {{ errors.amphure }}
                  </div>
                  <div
                    v-if="report.amphure"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.amphure }}
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <label>จังหวัด</label>
                  <v-select
                    v-model="province"
                    :options="provinces"
                    :class="{ 'is-invalid': errors.provinceId || report.provinceId }"
                  ></v-select>
                  <div
                    v-if="report.provinceId"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.provinceId }}
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <label>ประเทศ</label>
                  <v-select
                    v-model="countryId"
                    :options="counties"
                    :class="{ 'is-invalid': errors.countryId || report.countryId }"
                  ></v-select>
                  <div
                    v-if="report.countryId"
                    class="invalid-feedback d-block"
                  >
                    *{{ report.countryId }}
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <label>รหัสไปรษณีย์</label>
                  <input type="text" v-model="data.postcode" class="form-control" :class="{ 'is-invalid': errors.postcode || report.postcode }"
                  />
                  <div
                    v-if="errors.postcode"
                    class="invalid-feedback d-block"
                  >
                    {{ errors.postcode }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$parent.$parent.currentUser.supplierAdminRole != 'staff'" class="mt-4 text-right">
              <button
                @click="apiJuristic()"
                class="btn btn-outline-secondary border mr-4"
              >
                ยกเลิก
              </button>
              <button
              @click="submitBtn()"
                class="btn btn-primary"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Address",
  data() {
    return {
      data:{},
      report:{},
      errors: {},
      province: null,
      countryId: null,
      provinces: [],
      addressTwo: "",
      addressOne: "",
      counties: [],
    }
  },
  created() {
    this.apiJuristic()
    this.apiProvinces();
    this.apiCounties();
  },
  methods: {
    apiJuristic(){
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/suppliers/receipt`)
        .then((res) => {
          this.data = res.data.data;
          self.$hideLoader();
        })
    },
    apiProvinces() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/provinces`)
        .then((res) => {
          let provinces = res.data.data;
          this.provinces = provinces.map(function (x) {
            return (x = { label: x.provinceName, code: x.provinceId });
          });
          setTimeout(() => {
            this.province = this.provinces.find(
              (c) => c.code == this.data.provinceId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    apiCounties() {
      let self = this;
      this.$showLoader();
      this.$http
        .get(`api/datas/get/counties`)
        .then((res) => {
          let counties = res.data.data;
          this.counties = counties.map(function (x) {
            return (x = { label: x.countryName, code: x.countryId });
          });
          setTimeout(() => {
            this.countryId = this.counties.find(
              (c) => c.code == this.data.countryId
            );
          }, 1000);
          self.$hideLoader();
        })
        .catch(function (err) {
          self.error = err;
          self.$hideLoader();
        });
    },
    submitBtn(){
      let self = this;
      this.errors = {};
      this.$showLoader();
      this.$http
        .post(`api/suppliers/update/receipt`, {
          companyId: this.data.companyId,
          companyNameTh: this.data.companyNameTh,
          receiptAddressOne: this.data.receiptAddressOne,
          receiptAddressTwo: this.data.receiptAddressTwo,
          amphure: this.data.amphure,
          provinceId: this.province ? this.province.code : null,
          countryId: this.countryId ? this.countryId.code : null,
          postcode: this.data.postcode,
        })
        .then((res) => {
          self.$hideLoader();
          this.$notifications.notify({
            message: res.data.message,
            icon: "checkmark-circle-2",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        }).catch(function(err) {
          self.errors = err.response.data.errors.data;
        });
    }
  },
};
</script>